import Cookies from 'js-cookie';
import {
  CognitoIdentityProviderClient,
  InitiateAuthCommand,
  RespondToAuthChallengeCommand,
} from '@aws-sdk/client-cognito-identity-provider';
import { timeFromNow } from '../../../utils/common';

const COOKIE_MAGICUUID = 'TDTK-MagicUUID';
const COOKIE_UID = 'TDTK-uid';
const COOKIE_ADMIN = 'TDTK-isAdmin';
const COGNITO_CLIENT = new CognitoIdentityProviderClient({ region: 'us-east-1' });
const COGNITO_CLIENT_ID = import.meta.env.REACT_APP_COGNITO_CLIENT_ID;

export const ACCESS_CODE_LEN = 7;

export const DELIVERY_METHODS = {
  email: { label: 'Email Me', value: 'EMAIL' },
  sms: { label: 'Text Me', value: 'SMS' },
  call: { label: 'Call Me', value: 'VOICE_ASSISTANT' },
};

export const CHALLENGES = {
  login: 'LOGIN', // UI only
  init: 'INITIAL', // UI only
  uuid: 'MAGIC_UUID', // send "token" from query parameters
  token: 'CATAPULT_TOKEN', // send catapult token
  logout: 'CATAPULT_TOKEN_WITH_SIGNOUT', // response from backend, force logout or signup exit
  verify: 'SELECT_NUMBER_OR_EMAIL', // send medium
  code: 'CODE', // send received code from sms, call, or email
  reload: 'RESELECT_PHONE_OR_EMAIL', // restart verification
};

export const removeSignupCookies = () => {
  Cookies.remove(COOKIE_ADMIN);
  Cookies.remove(COOKIE_MAGICUUID);
  Cookies.remove(COOKIE_UID);
};

export const getSignupCookies = () => ({
  isAdmin: !!Cookies.get(COOKIE_ADMIN),
  magicUUID: Cookies.get(COOKIE_MAGICUUID),
  userId: Cookies.get(COOKIE_UID),
});

export const getSignupParams = (params: URLSearchParams) => {
  const cookies = getSignupCookies();
  return {
    isAdmin: params.get('r') === 'a' || cookies.isAdmin,
    magicUUID: params.get('token') || cookies.magicUUID,
    userId: params.get('id') || cookies.userId,
  };
};

export const setSignupCookies = (params: URLSearchParams) => {
  const { isAdmin, magicUUID, userId } = getSignupParams(params);
  const cookieOptions = { expires: timeFromNow(15) };

  if (magicUUID) {
    Cookies.set(COOKIE_MAGICUUID, magicUUID, cookieOptions);
  }

  if (userId) {
    Cookies.set(COOKIE_UID, userId, cookieOptions);
  }

  if (isAdmin) {
    Cookies.set(COOKIE_ADMIN, isAdmin, cookieOptions);
  }
};

export type CognitoResponseType = {
  ChallengeName: string;
  ChallengeParameters?: {
    challenge: string;
    attemptsRemaining?: string;
    resendsRemaining?: string;
    mediums?: string;
    phoneNumbers?: string;
    emails?: string;
  };
  Session?: string;
  AuthenticationResult?: { [key: string]: string };
};

export type CognitoOptionsType = {
  onSuccess?: (data: CognitoResponseType) => void;
  onFailure?: (err: Error) => void;
  onRequest?: () => void;
};

export const initAuth = async (options?: CognitoOptionsType) => {
  const { userId } = getSignupCookies();

  options?.onRequest?.();

  try {
    const data = await COGNITO_CLIENT.send(
      new InitiateAuthCommand({
        AuthFlow: 'CUSTOM_AUTH',
        AuthParameters: {
          USERNAME: userId,
        },
        ClientId: COGNITO_CLIENT_ID,
      })
    );
    options?.onSuccess?.(data as unknown as CognitoResponseType);
  } catch (err) {
    options?.onFailure?.(err as Error);
  }
};

type ResponseChallengeInputType = {
  challenge: CognitoResponseType;
  answer: {
    magicUUID?: string;
    chosenEmail?: string;
    chosenNumber?: string;
    code?: string;
    token?: string;
    alternateAction?: string;
  };
};

export const respondToChallenge = async (input: ResponseChallengeInputType, options?: CognitoOptionsType) => {
  const { userId } = getSignupCookies();

  options?.onRequest?.();

  try {
    const data = await COGNITO_CLIENT.send(
      new RespondToAuthChallengeCommand({
        ClientId: COGNITO_CLIENT_ID,
        ChallengeName: 'CUSTOM_CHALLENGE',
        Session: input.challenge.Session,
        ChallengeResponses: {
          USERNAME: userId,
          ANSWER: JSON.stringify(input.answer),
        },
      })
    );
    options?.onSuccess?.(data as unknown as CognitoResponseType);
  } catch (err) {
    options?.onFailure?.(err as Error);
  }
};

export const restartVerification = (challenge: CognitoResponseType, options?: CognitoOptionsType) =>
  respondToChallenge({ challenge, answer: { alternateAction: CHALLENGES.verify } }, options);

export const isNotAuthorizedError = (err?: Error) => err?.name === 'NotAuthorizedException';

export const isComplete = (challenge?: CognitoResponseType) =>
  challenge?.AuthenticationResult?.AccessToken &&
  challenge?.AuthenticationResult?.ExpiresIn &&
  challenge?.AuthenticationResult?.IdToken &&
  challenge?.AuthenticationResult?.RefreshToken &&
  challenge?.AuthenticationResult?.TokenType;

export const hasExhaustedAttempts = (challenge?: CognitoResponseType) =>
  Number(challenge?.ChallengeParameters?.attemptsRemaining) === 1 &&
  Number(challenge?.ChallengeParameters?.resendsRemaining) === 0;
