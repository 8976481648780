import React from 'react';
import { formatPhoneNumberIntl } from 'react-phone-number-input';

export function staffPhoneLink(ext = '', value = '') {
  return (
    <a className='hide-arrow' href={`tel:${value ? value.replace(/[ ]/g, '') : ''}${ext ? `;ext=${ext}` : ''}`}>
      {`${value ? formatPhoneNumberIntl(value) : ''}${ext ? ` x${ext}` : ''}`}
    </a>
  );
}
