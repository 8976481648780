import React from 'react';
import { AppStateContext, AppStateDispatchContext, reducer } from './context/AppContext';
import GateKeeper from './Gatekeeper';
import ModalProvider from './components/ui/modal/ModalProvider';
import { initialAppState } from './context/initialState';

function App() {
  /**
   * React.useMemo helps prevent re-renders if the state is updated but doesn't change.
   */
  function AppWrapper(appState) {
    return React.useMemo(
      () => (
        <ModalProvider key='app'>
          {' '}
          {/* Apricot 4 modals */}
          <AppStateDispatchContext.Provider value={dispatch}>
            <AppStateContext.Provider value={appState}>
              <GateKeeper />
            </AppStateContext.Provider>
          </AppStateDispatchContext.Provider>
        </ModalProvider>
      ),
      [appState]
    );
  }

  // Global App state.
  const [appState, dispatch] = React.useReducer(reducer, initialAppState);

  return [AppWrapper(appState)];
}

export default App;
