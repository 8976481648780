import React from 'react';
import PropTypes from 'prop-types';
import { localStateReducer } from '../../../../../utils/common';
import { useMutation } from '@apollo/client';
import { UPDATE_ROOM } from '../../../../../apollo/mutations';
import { ResizeContext } from '../../../../../context/ResizeContext';
import Input from '../../../../ui/form/Input';
import { CloseButton, YellowButton } from '@cb/apricot-react';

function AdjustGridForm({ adjustGridRef = {}, room = {}, toggleGridForm = () => {} }) {
  function handleRoomShapeSubmit(e) {
    e && e.preventDefault();

    const columns = parseInt(localState.shapeForm.columns, 10);
    const rows = parseInt(localState.shapeForm.rows, 10);

    setLocalState({
      applyButtonEnabled: false,
    });

    // Ensure the validation criteria is met.
    if (
      !isNaN(columns) &&
      !isNaN(rows) &&
      columns >= minShape.columns &&
      rows >= minShape.rows &&
      columns <= maxShape.columns &&
      rows <= maxShape.rows
    ) {
      // Set focus on Adjust Seats element.
      if (adjustGridRef && adjustGridRef.current) {
        adjustGridRef.current.focus();
      }

      updateRoom({
        variables: {
          input: {
            id: room.id,
            seatingChartPDFCurrent: false,
            shape: {
              x: columns,
              y: rows,
            },
            seatingStatus: 'drafted',
          },
        },
        optimisticResponse: {
          updateRoom: {
            id: room.id,
            capacity: room.capacity,
            created: new Date().getTime(),
            doors: room.doors,
            groupTypes: room.groupTypes,
            seatingStatus: 'drafted',
            shape: {
              x: columns,
              y: rows,
              __typename: 'Shape',
            },
            status: room.status,
            staff: room.staff,
            students: room.students,
            testBookRanges: [
              ...room.testBookRanges.map((t) => ({
                start: t.start,
                end: t.end,
                __typename: 'TestingBookRangeType',
              })),
            ],
            title: room.title,
            unusedSeats: room.unusedSeats,
            updated: new Date().getTime(),
            __typename: 'RoomType',
          },
        },
      })
        .then(() => {
          setLocalState({
            applyButtonEnabled: true,
          });

          toggleGridForm(false);
        })
        .catch((e) => {
          console.error('Error saving room shape: ', e);

          setLocalState({
            applyButtonEnabled: true,
            shapeForm: {
              columns: room && room.shape && room.shape.x ? room.shape.x : 5,
              rows: room && room.shape && room.shape.y ? room.shape.y : 6,
            },
          });
        });
    }
  }

  function handleGridCloseKeyDown(e) {
    // If they shift+tab out of the popover, close it.
    if (!!e.tabKey && e.shiftKey) {
      // shift & tab
      e && e.preventDefault && e.preventDefault();

      toggleGridForm(false);

      if (adjustGridRef && adjustGridRef.current) {
        // Set focus on Adjust Seats element.
        adjustGridRef.current.focus();
      }
    }
  }

  function handleGridCloseClick() {
    toggleGridForm(false);

    if (adjustGridRef && adjustGridRef.current) {
      // Set focus on Adjust Seats element.
      adjustGridRef.current.focus();
    }
  }

  function handleRoomShapeChange(field, num) {
    return function () {
      const numInt = parseInt(num, 10);
      const cleanNum = !isNaN(numInt) ? (numInt > 20 ? 20 : numInt < minShape[field] ? minShape[field] : numInt) : '';

      const applyEnabled =
        minShape[field] && maxShape[field] && cleanNum && cleanNum <= maxShape[field] && cleanNum >= minShape[field];

      setLocalState({
        applyButtonEnabled: applyEnabled,
        shapeForm: {
          ...localState.shapeForm,
          [field]: cleanNum,
        },
      });
    };
  }

  function handleRoomTextChange(field) {
    return function (e) {
      if (field && e && e.target) {
        handleRoomShapeChange(field, e.target.value)();
      }
    };
  }

  function calculateMinimumDimensions() {
    // Determine the minimum shape based on the unused seats or students seated.
    const shape = {
      columns: 1,
      rows: 1,
    };

    // Unused seats.
    if (room && room.unusedSeats && room.unusedSeats.length) {
      shape.columns = Math.max(...room.unusedSeats.map((seat) => seat.x || 1));
      shape.rows = Math.max(...room.unusedSeats.map((seat) => seat.y || 1));
    }

    // Students seated.
    if (room && room.students && room.students.length) {
      room.students.forEach((student) => {
        if (student.assignedSeat && student.assignedSeat.x && student.assignedSeat.y) {
          shape.columns = Math.max(student.assignedSeat.x, shape.columns);
          shape.rows = Math.max(student.assignedSeat.y, shape.rows);
        }
      });
    }

    return shape;
  }

  function handleApplyKeyDown(e) {
    // If they tab out of the popover, close it.
    if (!!e.tabKey && !e.shiftKey) {
      // tab
      toggleGridForm(false);
    }
  }

  // Local state.
  const [localState, setLocalState] = React.useReducer(localStateReducer, {
    applyButtonEnabled: true,
    currentStudent: {},
    shapeForm: {
      columns: room && room.shape && room.shape.x ? room.shape.x : 5,
      rows: room && room.shape && room.shape.y ? room.shape.y : 6,
    },
    showExpandedGrid: false,
    showPopover: false,
    unused: false,
    x: -1,
    y: -1,
  });

  // Apollo.
  const [updateRoom] = useMutation(UPDATE_ROOM);

  // Resize State.
  const windowSize = React.useContext(ResizeContext);

  // If screen size changes to desktop while the mobile expanded screen is showing turn it off.
  React.useEffect(() => {
    setLocalState({
      showExpandedGrid: false,
      showPopover: false,
    });
  }, [windowSize.mobile]);

  const minShape = calculateMinimumDimensions();

  // Current maximum for rooms, 20x20.
  const maxShape = {
    columns: 20,
    rows: 20,
  };

  return (
    <form onSubmit={handleRoomShapeSubmit}>
      <div className='pb-4 cb-align-right'>
        <CloseButton
          data-cb-popover-close
          onKeyDown={handleGridCloseKeyDown}
          onClick={handleGridCloseClick}
          label='Close Adjust Grid Form'
          className='align-self-right'
        />
      </div>
      <div className='row'>
        <div className='col-sm-5'>Columns:</div>
        <div className='col-xs-1 px-0'>
          <button
            className='w-100 p-0 btn--plus-minus'
            data-automation='adjust-grid-form-columm-remove'
            disabled={localState.shapeForm.columns <= 1 || localState.shapeForm.columns <= minShape.columns}
            onClick={handleRoomShapeChange('columns', localState.shapeForm.columns - 1)}
            type='button'
          >
            <span className='cb-sr-only'>Remove a column from the seating chart.</span>&mdash;
          </button>
        </div>
        <div className='col-xs-5'>
          <Input
            id='seating-chart-columns-input'
            label='Seating Chart total columns'
            labelClassName='cb-sr-only'
            name='Seating Chart total columns'
            onChange={handleRoomTextChange('columns')}
            style={{ textAlign: 'center' }}
            value={`${localState.shapeForm.columns}`}
          />
        </div>
        <div className='col-xs-1 px-0'>
          <button
            className='w-100 p-0 btn--plus-minus'
            data-automation='adjust-grid-form-column-add'
            disabled={localState.shapeForm.columns >= maxShape.columns}
            onClick={handleRoomShapeChange('columns', localState.shapeForm.columns + 1)}
            type='button'
          >
            <span className='cb-sr-only'>Add a column to the seating chart.</span>+
          </button>
        </div>
      </div>
      <div className='row text-left mt-4'>
        <div className='col-sm-5' style={{ paddingTop: '0.6rem' }}>
          Rows:
        </div>
        <div className='col-xs-1 px-0'>
          <button
            className='w-100 p-0 btn--plus-minus'
            data-automation='adjust-grid-form-row-remove'
            disabled={localState.shapeForm.rows <= 1 || localState.shapeForm.rows <= minShape.rows}
            type='button'
            onClick={handleRoomShapeChange('rows', localState.shapeForm.rows - 1)}
          >
            <span className='cb-sr-only'>Remove a row from the seating chart.</span>&mdash;
          </button>
        </div>
        <div className='col-xs-5'>
          <Input
            id='seating-chart-rows-input'
            label='Seating Chart total rows'
            labelClassName='cb-sr-only'
            name='Seating Chart total rows'
            onChange={handleRoomTextChange('rows')}
            style={{ textAlign: 'center' }}
            value={`${localState.shapeForm.rows}`}
          />
        </div>
        <div className='col-xs-1 px-0'>
          <button
            className='w-100 p-0 btn--plus-minus'
            data-automation='adjustGridForm-row-add'
            disabled={localState.shapeForm.rows >= maxShape.rows}
            onClick={handleRoomShapeChange('rows', localState.shapeForm.rows + 1)}
            type='button'
          >
            <span className='cb-sr-only'>Add a row to the seating chart.</span>+
          </button>
        </div>
      </div>
      <div className='clearfix mt-4'>
        <YellowButton
          className='cb-float-right'
          disabled={!localState.applyButtonEnabled}
          id='updateShape'
          name='updateShape'
          onKeyDown={handleApplyKeyDown}
          type='submit'
          value='submit'
        >
          Apply<span className='cb-sr-only'> new rows and columns to this seating chart.</span>
        </YellowButton>
      </div>
    </form>
  );
}

AdjustGridForm.propTypes = {
  adjustGridRef: PropTypes.object,
  room: PropTypes.object,
  toggleGridForm: PropTypes.func,
};

export default AdjustGridForm;
