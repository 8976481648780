import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { formatStudentName } from '../../../../../../utils/common';
import { useMutation } from '@apollo/client';
import { UPDATE_STUDENT } from '../../../../../../apollo/mutations';
import './Attendance.scss';
import IconStatus from '../../../../../ui/icons/IconStatus';
import { BlackButton, NakedButton } from '@cb/apricot-react';

function StudentRosterCard({ student = {}, type = '' }) {
  const { pathname } = useLocation();

  const [updateStudent, { loading }] = useMutation(UPDATE_STUDENT);

  function markStudentPresent() {
    updateStudent({
      variables: {
        input: {
          id: student?.id,
          checkedInCenter: true,
          checkedInRoom: true,
        },
      },
    })
      .then(() => {
        // state update handled by Apollo
      })
      .catch(() => {});
  }

  function undoMarkStudentPresent() {
    updateStudent({
      variables: {
        input: {
          id: student?.id,
          checkedInRoom: false,
        },
      },
    })
      .then(() => {
        // state update handled by Apollo
      })
      .catch(() => {});
  }

  return (
    // TODO : Use <ul> or <ol> - The ARIA list / listitem roles don't distinguish between ordered and unordered lists.
    <div role='listitem' className='student-roster-card-container row m-0 mb-3'>
      <div className='col-xs-4 col-md-7 p-0'>
        <Link
          aria-describedby={
            type === 'preRostered'
              ? undefined
              : type === 'ready'
              ? `${student?.id}-ready`
              : student?.joinedAndPresent
              ? `${student?.id}-present`
              : undefined
          }
          id={student?.id}
          to={{
            pathname: '/students/get/' + student?.id,
            state: {
              from: pathname,
            },
          }}
        >
          {formatStudentName(student)}
        </Link>
      </div>
      <div className='col-xs-8 col-md-5 p-0'>
        {type === 'preRostered' ? (
          <div></div>
        ) : type === 'ready' ? (
          <IconStatus
            color='green3'
            icon='check-fill'
            id={`${student?.id}-ready`}
            text='Ready'
            textClass='cb-font-size-small cb-roboto-bold cb-margin-right-4'
            wrapperClass='flex-row-reverse'
          />
        ) : student?.joinedAndPresent ? (
          <div className='display-flex justify-content-end align-items-end flex-column'>
            <IconStatus
              color='green3'
              icon='check-fill'
              iconClass='cb-margin-top-4'
              id={`${student?.id}-present`}
              text='Present'
              textClass='cb-font-size-small cb-roboto-bold cb-margin-right-4'
              wrapperClass='flex-row-reverse'
            />
            <NakedButton
              small
              noPadding
              data-automation={`undo-mark-present-button-${student.candRegNo}`}
              onClick={undoMarkStudentPresent}
              disabled={loading}
            >
              UNDO
            </NakedButton>
          </div>
        ) : (
          <div className='cb-align-right'>
            <BlackButton
              small
              className='cb-no-padding cb-width-md-100-up cb-width-xs-50-up'
              data-automation={`mark-present-button-${student.candRegNo}`}
              onClick={markStudentPresent}
              disabled={loading}
            >
              <span className='cb-margin-2'>Mark Present</span>
            </BlackButton>
          </div>
        )}
      </div>
    </div>
  );
}

StudentRosterCard.propTypes = {
  student: PropTypes.object,
  type: PropTypes.string,
};

export default StudentRosterCard;
