const iamCoordinatorRoles = {
  800: 'Test Day Coordinator',
  803: 'TDTK Administrator',
};

const iamRoles = {
  801: 'Test Day Staff',
  802: 'Test Day School Day Staff',
  ...iamCoordinatorRoles,
};

export { iamCoordinatorRoles };
export default iamRoles;
