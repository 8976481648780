import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import ModalDialogWrapper from '../../../../../ui/modal/ModalDialogWrapper';
import { ModalDispatchContext } from '../../../../../ui/modal/ModalContext';
import ModalFooter from '../../../../../ui/modal/ModalFooter';

function ClearAllSeatingChartConfirmationModal({ room, setLocalState, updateStudents, updateRoom }) {
  function handleClose() {
    dispatchModal(null);
  }

  // Modal Context.
  const dispatchModal = useContext(ModalDispatchContext);

  return (
    <ModalDialogWrapper
      modalId='ClearAllSeatingChartConfirmationModal'
      onClose={handleClose}
      open
      title='Are you sure?'
      variant='error'
    >
      <React.Fragment key='.0'>
        <p>
          Selecting <b>Continue</b> will reset the seating chart and undo all seat assignments.
        </p>
      </React.Fragment>
      <ModalFooter
        buttons={[
          {
            handler: handleClose,
            id: 'clear-all-seating-chart-confirmation-modal-cancel',
            style: 'default',
            text: 'Cancel',
          },
          {
            id: 'clear-all-seating-chart-confirmation-modal-continue',
            style: 'yellow',
            text: 'Continue',
            handler: () => {
              if (room && room.students) {
                setLocalState({
                  loading: true,
                });

                updateRoom({
                  variables: {
                    input: {
                      id: room.id,
                      seatingStatus: 'incomplete',
                      shape: room.shape
                        ? {
                            x: room.shape.x || 5,
                            y: room.shape.y || 6,
                          }
                        : {
                            x: 5,
                            y: 6,
                          },
                      unusedSeats: null,
                    },
                  },
                }).then(() => {
                  setLocalState({
                    loading: false,
                  });
                });

                // Only update if there are students.
                if (room && room.students && room.students.length) {
                  setLocalState({
                    loading: true,
                  });

                  updateStudents({
                    variables: {
                      input: {
                        students: room.students.map((student) => ({
                          id: student.candRegNo,
                          assignedSeat: null,
                          testBookNumber: null,
                        })),
                      },
                    },
                  }).then(() => {
                    setLocalState({
                      loading: false,
                    });
                  });
                }
              }
              handleClose();
            },
          },
        ]}
      />
    </ModalDialogWrapper>
  );
}

ClearAllSeatingChartConfirmationModal.propTypes = {
  room: PropTypes.object,
  setLocalState: PropTypes.func,
  updateStudents: PropTypes.func,
  updateRoom: PropTypes.func,
};

export default ClearAllSeatingChartConfirmationModal;
