import React from 'react';
import PropTypes from 'prop-types';
import { roles } from '../../../../constants/roles';
import { parsePhoneNumbers } from '../../../../utils/common';
import { ResizeContext } from '../../../../context/ResizeContext';
import { staffPhoneLink } from './utils';
import { Link } from 'react-router-dom';
import IconStatus from '../../../ui/icons/IconStatus';

function StaffRosterRow({ handleCheckItems = () => {}, options = {}, staff = [] }) {
  function checkStaff(staffId) {
    return function () {
      handleCheckItems([staffId]);
    };
  }

  function mobileComponent() {
    return <li>{staffId ? <Link to={`/staff/get/${staffId}`}>{staffDisplayMobile}</Link> : staffDisplayMobile}</li>;
  }

  function getIconProps(active) {
    return active ? { color: 'green3', icon: 'check-fill' } : {};
  }

  function desktopComponent() {
    return (
      <tr>
        <td>
          <label htmlFor={checkId}>
            <input
              checked={options.checked ? 'checked' : ''}
              id={checkId}
              name={checkName}
              onChange={checkStaff(staff.id)}
              type='checkbox'
              value={staff.id}
            />
            <span className='cb-sr-only'>Select {staffTitle} for a bulk operation</span>
          </label>
        </td>
        <th scope='row'>
          {staffId ? <Link to={`/staff/get/${staffId}`}>{staffTitle}</Link> : staffTitle}
          <br />
          <span className='cb-sr-only'>Email: </span>
          {staff.email}
        </th>
        <td>
          <ul className='m-0 p-0' style={{ listStyle: 'none' }}>
            {parsePhoneNumbers(staff.phone).map((number) => {
              const type = number.type.charAt(0).toUpperCase() + number.type.slice(1);
              const value = number.phoneNumber;
              const ext = number.ext ? number.ext : '';
              return value ? (
                <li key={type + value}>
                  <strong>{type}</strong>: <br />
                  {value ? staffPhoneLink(ext, value) : null}
                </li>
              ) : (
                ''
              );
            })}
          </ul>
        </td>
        <td>{roleTitle}</td>
        <td className='text-break'>{roomTitle}</td>
        <td>
          <IconStatus
            {...getIconProps(staff.confirmed)}
            text={staff.confirmed ? 'Confirmed' : 'Unconfirmed for this administration'}
          />
        </td>
        <td>
          <IconStatus {...getIconProps(staff.active)} text={staff.active ? 'Granted' : 'Not granted'} />
        </td>
      </tr>
    );
  }

  // Resize state.
  const windowSize = React.useContext(ResizeContext);

  const roleTitle = roles[staff.role] || 'Unassigned';
  const roomTitle = staff.room ? staff.room.title : 'Unassigned';
  const staffId = parseInt(staff.id, 10) < 0 ? undefined : staff.id;
  const staffTitle = `${staff.lastName ? staff.lastName + ', ' : ''}${staff.firstName}`;

  const checkId = `bulk-check-${staff.id}`;
  const checkName = `bulk-check[${staff.id}]`;

  const staffDisplayMobile = (
    <div className='tdtk-item-text'>
      <p className='tdtk-item-title cb-roboto-bold'>
        <span className='name'>{staffTitle}</span>
      </p>
    </div>
  );

  return windowSize.mobile ? mobileComponent() : desktopComponent();
}

StaffRosterRow.propTypes = {
  handleCheckItems: PropTypes.func,
  options: PropTypes.object,
  staff: PropTypes.object,
};

export default StaffRosterRow;
