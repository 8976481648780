let orgEventSession;
let userSession;

try {
  orgEventSession = JSON.parse(sessionStorage.getItem('orgEvent'));
  userSession = JSON.parse(sessionStorage.getItem('user'));
} catch (e) {
  orgEventSession = {};
  userSession = {};
}

export const initialAppState = {
  globalError: null,
  orgEvent: {
    asmtEventId: '',
    asmtEventTypeCd: '',
    asmtId: '',
    asmtSubtypeCd: '',
    closure: null,
    dapInd: false,
    eventEndDt: '',
    eventStartDt: '',
    eventTitle: '',
    featureFlags: [],
    orgId: '',
    siteId: '',
    testCenterCd: '',
    testEvent: 'sat_national' /** @todo: Set this dynamically when we start using other test types. */,
    title: '',
    testCenterTypeCd: '',
    enableEssaySection: false,
    ...orgEventSession,
  },
  user: {
    confirmed: false,
    id: '',
    loginAttemptFailed: false,
    missingUserRecord: false,
    rid: 0,
    role: '',
    room: null,
    username: '',
    welcomed: false,
    allStudentsPageSizePref: 30,
    ...userSession,
  },
  features: [],
};
