import React from 'react';
import get from 'lodash/get';
import GroupsInfo from './GroupsInfo';
import { GET_TEST_CENTER } from '../../../apollo/queries';
import QueryComponent from '../../common/network/QueryComponent';
import { useTitle } from '../../../constants/useTitle';
import ErrorNotification from '../../ui/message/ErrorNotification';

function Container() {
  useTitle('Voucher Data');
  return (
    <React.Fragment>
      <div className='row'>
        <div className='col-sm-6'>
          <ErrorNotification className='my-2' title='Attention California Coordinators'>
            <p>Use the Adia mobile app (not ETS) to approve staff timesheets.</p>
          </ErrorNotification>
        </div>
      </div>
      <h1 className='tdtk-h1 py-4' data-automation='heading-h1'>
        Voucher Data
      </h1>

      <QueryComponent query={{ kind: 'GetTestCenter', specification: GET_TEST_CENTER }}>
        {(data) => {
          const site = get(data, 'viewer.site');
          if (site) {
            return <GroupsInfo site={site} />;
          } else {
            return <p>Student roster information is unavailable.</p>;
          }
        }}
      </QueryComponent>
    </React.Fragment>
  );
}

Container.displayName = 'VoucherInfoContainer';

export default Container;
