import React, { useContext } from 'react';
import ModalDialogWrapper from '../../../../../ui/modal/ModalDialogWrapper';
import { ModalDispatchContext } from '../../../../../ui/modal/ModalContext';
import ModalFooter from '../../../../../ui/modal/ModalFooter';

function DoorAdditionConfirmationModal() {
  function handleClose() {
    dispatchModal(null);
  }

  // Modal Context.
  const dispatchModal = useContext(ModalDispatchContext);

  return (
    <ModalDialogWrapper
      modalId='DoorAdditionConfirmationModal'
      onClose={handleClose}
      open
      title='We‘ve added your door to the seating chart.'
      variant='success'
    >
      <ModalFooter
        buttons={[
          {
            handler: handleClose,
            id: 'door-addition-confirmation-modal-ok',
            style: 'yellow',
            text: 'OK',
          },
        ]}
      />
    </ModalDialogWrapper>
  );
}

export default DoorAdditionConfirmationModal;
