import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Spinner from '../../../ui/loading/SpinnerWrapper';
import { cb } from '../../../../widgetLibrary';
import { breakOutQueryString, localStateReducer } from '../../../../utils/common';
import { useStateValue } from '../../../../context/AppContext';
import RolloverImage from './RolloverImage';
import { cbAdminRoleProps } from '../../../../constants/roles';
import { isCBAdmin } from '../../../../utils/user';

const TESTING_MODE_FLAG = 'testing';

const missingPhotoPlaceholderUrl =
  'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDQiIGhlaWdodD0iNDQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQogIDx0aXRsZT5TdHVkZW50IEF2YXRhcjwvdGl0bGU+DQogIDxnPg0KICAgIDxwYXRoIGZpbGw9IiNmZmZmZmYiIGlkPSJzdmdfMSIgZD0ibTMyLjMyNTAxMiwxMC4yMzYwMTdhOS45MDgyNTQsOS45MDgyNTQgMCAxIDEgLTkuOTA4MjU0LC05Ljg4MDEwNmE5Ljg4MDEwNiw5Ljg4MDEwNiAwIDAgMSA5LjkwODI1NCw5Ljg4MDEwNnoiIGNsYXNzPSJzdmctc3R1ZGVudC1hdmF0YXIiIC8+DQogICAgPHBhdGggZmlsbD0iI2ZmZmZmZiIgaWQ9InN2Z18yIiBkPSJtMzkuODEyNSwzOS4yMjg5MmMwLDUuNjI5NjkgLTM0LjUxLDUuNjI5NjkgLTM0LjUxLDBjMCwtOC40NDQ1MzUgNy43MTI2NzUsLTE0LjM4Mzg1OCAxNy4yNTUsLTE0LjM4Mzg1OHMxNy4yNTUsNS45MzkzMjMgMTcuMjU1LDE0LjM4Mzg1OHoiIGNsYXNzPSJzdmctc3R1ZGVudC1hdmF0YXIiIC8+DQogIDwvZz4NCjwvc3ZnPg0K';

const preloadImage = (url, callback) => {
  const theImage = new Image();

  if (typeof callback === 'function') {
    theImage.onerror = () => callback(false);
    theImage.onload = () => callback(true);
  }

  theImage.src = url;
};

const getResponse = (data) => {
  const messages = {
    1001: 'Photo not required.',
    1002: 'Photo not required.',
    1003: 'Photo not required.',
    1004: 'Photo not required.',
    1005: 'Photo unavailable. Check SOAR photo roster.',
    404: 'Invalid registration number. Call us.',
    default: 'Photo unavailable. Please refresh and try again.',
  };

  const result = {
    image: '',
    error: '',
  };

  if (!data?.noImageCode && data?.image) {
    result.image = data.image;
  } else {
    // Set the error if available, otherwise use the default.
    result.error = messages[data?.noImageCode?.code] || messages['default'];
    result.image = missingPhotoPlaceholderUrl;
  }

  return result;
};

const isBase64 = (input) => {
  const pattern = /[^A-Z0-9+/=]/i;
  const length = input?.length || 0;

  // using atob() on the client side is depreciated and slow
  if (length % 4 !== 0 || pattern.test(input)) {
    return false;
  } else {
    const firstPaddingChar = input.indexOf('=');

    return (
      firstPaddingChar === -1 ||
      firstPaddingChar === length - 1 ||
      (firstPaddingChar === length - 2 && input[length - 1] === '=')
    );
  }
};

// NOTE: ideally we should filter query string params through router hooks but
// these checks are temporary and breakOutQueryString is used elsewhere too
const isUserTesting = (user) => {
  let result = false;

  try {
    if (typeof window !== 'undefined') {
      const query = breakOutQueryString(window.location.search);

      result = isCBAdmin(user) && query[TESTING_MODE_FLAG].trim().length > 0;
    }
  } catch {
    result = false;
  }

  return result;
};

function Photo({ regId = '', studentName = '', studentImage = '' }) {
  const { user } = useStateValue();
  const canUseRolloverImage = isUserTesting(user);
  const isValidImage = (studentImage || '').trim().length > 0;

  // TODO: deprecated
  function loadOldImage(regId) {
    const url = import.meta.env.REACT_APP_REGIMAGE_SERVICE_URL;
    const finalTarget = url && regId ? `${url}${regId}` : null;

    if (finalTarget && cb.core.iam.getAuthenticationToken() && cb.core.iam.getAuthorizationToken()) {
      const servicePromise = new Promise((resolve, reject) => {
        axios
          .get(finalTarget, {
            timeout: 10000,
            headers: {
              'accept': 'application/json, text/javascript',
              'X-CB-Catapult-Authentication-Token': cb.core.iam.getAuthenticationToken(),
              'X-CB-Catapult-Authorization-Token': cb.core.iam.getAuthorizationToken(),
            },
          })
          .then(({ data }) => {
            // Check for the response.
            const response = getResponse(data);

            // Ensure this component is still mounted, in case they navigate away before it's finished.
            if (isMounted.current) {
              setLocalState({
                oldError: response.error,
                oldImage: response.image,
                oldLoading: false,
              });
              resolve({
                body: 'Service Success!',
                status: '200',
              });
            } else {
              reject({
                body: 'Photo Component unmounted before finishing successfully.',
                status: '200',
              });
            }
          })
          .catch((e) => {
            let response = '';
            // Ensure this component is still mounted, in case they navigate away before it's finished.
            if (isMounted.current) {
              // See if the call timed out.
              if (e?.response?.status === 404) {
                // Timed out.
                response = getResponse({
                  noImageCode: {
                    code: 404,
                  },
                });
              } else {
                response = getResponse(null);
              }

              // Photo service throws a 404 for some reason.
              setLocalState({
                oldError: response.error,
                oldImage: response.image,
                oldLoading: false,
              });

              reject({
                body: response.error,
                status: '404',
              });
            } else {
              reject({
                body: 'Photo Component unmounted before finishing a failure.',
                status: '404',
              });
            }
          });
      });
    } else {
      // Photo service URL isn't in the .env or fails for some other reason.
      setLocalState({
        oldError: 'Photo unavailable. Check SOAR photo roster.',
        oldLoading: false,
      });
    }
  }

  // TODO: the rollover params are temporary
  function buildImg(encodedImageOrUrl, studentName, rolloverEncodedImageOrUrl = null, rolloverImageError = null) {
    const props = {
      alt: studentName,
    };

    props.src = encodedImageOrUrl;

    // TODO: eventually have this be able to detect the MIME type
    if (canUseRolloverImage && rolloverEncodedImageOrUrl) {
      props.title = rolloverImageError;

      if (isBase64(rolloverEncodedImageOrUrl)) {
        props.hoverSrc = `data:image/jpeg;base64,${rolloverEncodedImageOrUrl}`;
      } else {
        props.hoverSrc = rolloverEncodedImageOrUrl;
      }

      return <RolloverImage {...props} />;
    } else {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <img {...props} />;
    }
  }

  // Check to ensure the component is still mounted.
  // TODO: deprecated
  const isMounted = React.useRef(true);

  // Local state.
  const [localState, setLocalState] = React.useReducer(localStateReducer, {
    error: '',
    oldError: '',
    oldImage: missingPhotoPlaceholderUrl,
    image: isValidImage ? studentImage : missingPhotoPlaceholderUrl,
    oldLoading: canUseRolloverImage,
    loading: true,
  });

  React.useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  React.useEffect(() => {
    if (isValidImage) {
      if (canUseRolloverImage) {
        // TODO: deprecated
        // grab the old image from our image server and load it up
        loadOldImage(regId);
      }

      // we already have the image, np api call required
      preloadImage(localState.image, (success) => {
        if (success) {
          setLocalState({
            loading: false,
          });
        } else {
          const errorResponse = getResponse(null);

          setLocalState({
            error: errorResponse.error,
            image: errorResponse.image,
            loading: false,
          });
        }
      });
    } else {
      const errorResponse = getResponse(null);

      setLocalState({
        error: errorResponse.error,
        image: errorResponse.image,
        loading: false,
      });
    }
  }, []);

  return localState.loading ? (
    <div className='photo'>
      {<img src={missingPhotoPlaceholderUrl} className='photo--img' alt='student placeholder' />}
      <div style={{ marginTop: '-1rem' }}>
        <Spinner />
      </div>
    </div>
  ) : (
    <div>
      {localState.image && localState.error === '' ? (
        buildImg(localState.image, studentName, localState.oldImage, localState.oldError)
      ) : (
        <div className='photo'>
          <img src={localState.image} className='photo--img' alt='student placeholder' />
          <br />
          {localState.error}
        </div>
      )}
    </div>
  );
}

Photo.propTypes = {
  regId: PropTypes.string,
  studentImage: PropTypes.string,
  studentName: PropTypes.string,
};

export default Photo;
