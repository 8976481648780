import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ModalDispatchContext } from '../ModalContext';
import ModalWrapper from '../ModalWrapper';
import ModalFooter from '../ModalFooter';

// this is very similar to the regular TwoButtonModal but is needed to work around a bug in Apricot 4's dialog modals
// once the bug is fixed, this component can be retired
// https://jira.collegeboard.org/browse/AOPS-36286

export default function TwoButtonFormModal({
  body = '',
  modalId,
  primaryButtonHandler = () => {},
  primaryButtonLabel = 'Continue',
  primaryButtonStyle = 'yellow',
  secondaryButtonHandler = () => {},
  secondaryButtonLabel = 'Cancel',
  secondaryButtonStyle = 'black',
  title = 'Are you sure?',
  trigger = '',
  variant = 'success',
  clickOverlayToClose = false,
  disabled = false,
}) {
  const dispatchModal = useContext(ModalDispatchContext);

  function closeModal() {
    dispatchModal(null);
    secondaryButtonHandler();
  }

  function handlePrimaryButton() {
    dispatchModal(null);
    primaryButtonHandler();
  }

  return (
    <ModalWrapper
      enableChildOverflow
      footer={
        <ModalFooter
          buttons={[
            {
              handler: closeModal,
              id: `btn-secondary-${modalId}`,
              style: secondaryButtonStyle,
              text: secondaryButtonLabel,
            },
            {
              handler: handlePrimaryButton,
              id: `btn-primary-${modalId}`,
              style: primaryButtonStyle,
              text: `${primaryButtonLabel}`,
              disabled,
            },
          ]}
        />
      }
      modalId={`modal-${modalId}`}
      onClose={closeModal}
      open
      title={title}
      trigger={trigger}
      variant={variant}
      clickOverlayToClose={clickOverlayToClose}
    >
      <>{body}</>
    </ModalWrapper>
  );
}

TwoButtonFormModal.propTypes = {
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  modalId: PropTypes.string.isRequired,
  primaryButtonHandler: PropTypes.func,
  primaryButtonLabel: PropTypes.string,
  primaryButtonStyle: PropTypes.string,
  secondaryButtonHandler: PropTypes.func,
  secondaryButtonLabel: PropTypes.string,
  secondaryButtonStyle: PropTypes.string,
  title: PropTypes.string,
  trigger: PropTypes.string,
  variant: PropTypes.oneOf(['error', 'info', 'input', 'success']).isRequired,
  clickOverlayToClose: PropTypes.bool,
  disabled: PropTypes.bool,
};
