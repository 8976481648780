import get from 'lodash/get';
import flatten from 'lodash/flatten';
import { appErrors } from '../constants/appErrors';

function _extractErrors(payload, uiPrefix, messages = {}) {
  return payload.map((item) => {
    const fieldName = item.field.indexOf('.') !== -1 ? `${item.field.split('.')[1]}` : item.field;
    const index = item.field.indexOf('.') !== -1 ? `-${item.field.split('.')[0]}` : '';

    return {
      errorMessage: get(messages, `${fieldName}.${item.validation}`) || get(messages, item.validation) || item.message,
      key: `error-${uiPrefix}-${fieldName}${index}`,
      uiName: `${uiPrefix}-${fieldName}${index}`,
    };
  });
}

function _extractAppErrors(error, uiPrefix) {
  const appError = appErrors[error.code] || {};
  return [
    {
      uiName: `${uiPrefix}-${appError.field || 'app-error'}`,
      errorMessage: appError.message,
      key: `error-${uiPrefix}-${appError.field}`,
    },
  ];
}

export function buildValidationErrorMessages(errors, uiPrefix, messages = {}, emailDomain) {
  const generatedErrors = errors
    .filter((err) => err.message === 'validation_error' || err.message === 'app_error')
    .map((err) => {
      if (err.message === 'validation_error') {
        const response = _extractErrors(err.payload, uiPrefix, messages);
        // if there is a 'validation_error', we are extracting email domain name and modifiying the errors messages to show users a more specific feedback on the error.
        if (emailDomain) {
          response[0].errorMessage = response[0].errorMessage.replaceAll(
            `%%${errors[0].payload[0].validation}%%`,
            emailDomain
          );
        }

        return response;
      } else if (err.message === 'app_error') {
        return _extractAppErrors(err.payload, uiPrefix);
      } else {
        return [];
      }
    });

  return flatten(generatedErrors);
}

export function buildNetworkErrorMessages(errors, uiPrefix, messages = {}) {
  const generatedErrors = _extractErrors(errors, uiPrefix, messages);

  return flatten(generatedErrors);
}
