import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Icon } from '@cb/apricot-react-icon';
import { CREATE_CLOSURE } from '../../../apollo/mutations';
import { localStateReducer, scrollTo, createCleanMarkup, toLocaleUTCDateString } from '../../../utils/common';
import { ModalDispatchContext } from '../../ui/modal/ModalContext';
import { useMutation } from '@apollo/client';
import { useStateValue } from '../../../context/AppContext';
import Checkbox from '../../ui/form/Checkbox';
import OneButtonModal from '../../ui/modal/standard/OneButtonModal';
import RadioButton from '../../ui/form/RadioButton';
import RadioButtonGroup from '../../ui/form/RadioButtonGroup';
import Select from '../../ui/form/Select';
import Spinner from '../../ui/loading/SpinnerWrapper';
import './ClosureForm.scss';
import { YellowButton } from '@cb/apricot-react';

const MSG_PLEASE_CALL = 'Please call TDTK support for further assistance.';
function ClosureForm({ reasons = [], showFull = true, staticContent = true }) {
  const dispatchModal = useContext(ModalDispatchContext);
  const navigate = useNavigate();

  // Outputs a date based on the YYYY MM DD stuff we're getting from the database.
  function generateMakeupDate() {
    // Ensure the makeup date exists to avoid creating a bad date object.
    return selected !== undefined && selected.makeupDay > -1 && selected.makeupMonth > -1 && selected.makeupYear > -1
      ? new Date(selected.makeupYear, selected.makeupMonth - 1, selected.makeupDay, 0, 0, 0, 0)
      : null;
  }

  // Sanitize the HTML from the CMS.
  function generateNextStepsHtml() {
    return selected?.nextSteps ? createCleanMarkup(selected.nextSteps) : null;
  }

  // If the CMS selected "ETS Other", then we return true to indicate we should show the Next Steps.
  function isEtsOther() {
    return selected !== undefined && (selected?.eventOther || false);
  }

  // Update the selected reason.
  function handleChangeReason(val) {
    setLocalState({
      agreement: [],
      canMakeup: '',
      errorMessageReason: val ? '' : 'Why do you need to close is required.',
      selectedReason: val || '',
    });
  }

  function handleChangeCanMakeup(val) {
    setLocalState({
      canMakeup: val || '',
    });
  }

  function handleChangeAgreement(val) {
    setLocalState({
      agreement: val ? 'yes' : '',
      errorMessageAgreement: val ? '' : 'Check the box before you submit this form.',
    });
  }

  function handleSubmit(e) {
    e && e.preventDefault && e.preventDefault();

    setLocalState({
      loading: true,
    });

    const newClosure = {
      canSupportMakeup: localState.canMakeup || null,
      reason: localState.selectedReason,
    };

    // Redirect them to the full form if they are on the homepage, otherwise, submit it.
    if (showFull) {
      // Create a closure! THIS IS FINAL, there's no "update" after this.
      createClosure({
        variables: {
          input: newClosure,
        },
      })
        .then(() => {
          // If they're on the homepage, redirect them to the form proper.
          window.top.location.href = '/test-center-closure/confirmation';
        })
        .catch((e) => {
          /**
           * @todo: add server-side error handling.
           */
          const errorMessage = e?.graphQLErrors?.[0]?.payload?.message || e?.message || '';
          console.error('error creating closure', errorMessage, e);

          setLocalState({
            loading: false,
          });

          dispatchModal(
            <OneButtonModal
              body={`Unable to report a Test Center closure. ${MSG_PLEASE_CALL}`}
              modalId='closureFormError'
              title='An error occured.'
              variant='error'
            />
          );
        });
    } else {
      // If they're on the homepage, redirect them to the form proper.
      navigate(`/test-center-closure/add/${localState.selectedReason}`);
    }
  }

  // Global App state.
  const {
    orgEvent: { asmtEventId, eventStartDt },
  } = useStateValue();

  // Apollo.
  const [createClosure] = useMutation(CREATE_CLOSURE);

  // Local state.
  const [localState, setLocalState] = React.useReducer(localStateReducer, {
    agreement: [],
    canMakeup: null,
    errorMessageReason: '',
    loading: false,
    selectedReason: useParams()?.selectedReason,
  });

  // This is what is currently selected in the dropdown.
  const selected = reasons && reasons.length >= 1 && reasons.find(({ title }) => title === localState.selectedReason);

  const etsOther = isEtsOther();
  const makeupDate = generateMakeupDate();
  const nextSteps = generateNextStepsHtml();

  const closureDates = {
    1881: '2021-09-25', // August 28, 2021.
    1882: '2021-10-16', // October 2, 2021.
    1883: '2021-11-20', // November 6, 2021.
    1884: '2021-12-18', // December 4, 2021.
    1885: '2021-03-26', // March 12, 2022.
  };

  // NOTE: currently we have to remove a ' - Sunday' suffix on the title string
  // since the backend is appending that to the title for Sunday site closures
  // To Do: Need to be removed once the DB architecture changes are done.
  const reasonOptions = reasons.map((reason) => ({
    label: reason?.title || '',
    value: reason?.title || '',
  }));
  // Pre-pend with "Select" if there is more than one option.
  if (reasons.length > 1) {
    reasonOptions.unshift({ title: 'Select', value: '', label: 'Select' });
  }

  // If they are visiting this for the first time on the full page, scroll to the top of the form.
  React.useEffect(() => {
    if (showFull) {
      scrollTo('closure-form-h1');
    }
  }, [showFull]);

  const startDate = eventStartDt;
  const isMakeupEvent = asmtEventId.includes('_0002') || asmtEventId.includes('_0003') ? true : false;

  if (staticContent) {
    return (
      <div>
        <React.Fragment>
          <p role='presentation' className='text-center mb-4'>
            <Icon name='megaphone' color='black1' size='32' />
          </p>
          <h2 className='tdtk-h2 text-center' id='closure-form-h2-how-to-close'>
            How to Close Your Test Center
          </h2>
        </React.Fragment>

        <div className='row'>
          <div className={'px-4 my-4'}>
            <React.Fragment>
              <p className='mb-2'>
                <strong>
                  Call TAS at <a href='tel:+18665026384'>866-502-6384</a> and select option 3 <br /> or Email{' '}
                  <a href='mailto:tas@ets.org?subject=Test%20Center%20Closure'>tas@ets.org</a>.
                </strong>
              </p>
              <p className='mb-2'>If you call after hours, leave a message including:</p>
              <ul>
                <li>Your name and phone number</li>
                <li>Your test center code and name</li>
                <li>Your city and state</li>
              </ul>
            </React.Fragment>
          </div>
        </div>
      </div>
    );
  }

  return (
    <form
      className={`${showFull ? 'box-card shadow mt-2' : ''}`}
      style={{ padding: showFull ? '3rem' : '' }}
      onSubmit={handleSubmit}
    >
      {showFull ? (
        <React.Fragment>
          <h1 className='tdtk-h1' id='closure-form-h1'>
            Report Test Center Closure
          </h1>
          {reasons.length > 1 ? (
            <div className='row'>
              <div className='col-xs-12 col-sm-6'>
                <p className='my-4'>
                  Submitting this form closes your test center for the SAT administration
                  {startDate
                    ? ` on ${toLocaleUTCDateString(new Date(startDate), {
                        weekday: 'long',
                        month: 'long',
                        day: 'numeric',
                        year: 'numeric',
                      })}, `
                    : null}{' '}
                  and cannot be undone.
                </p>
                <p className='mb-4'>We&rsquo;ll list next steps on the confirmation screen.</p>
                <hr />
              </div>
            </div>
          ) : null}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <p role='presentation' className='text-center mb-4'>
            <Icon name='megaphone' color='black1' size='32' />
          </p>
          <h2 className='tdtk-h2 text-center' id='closure-form-h2-how-to-close'>
            How to Close Your Test Center
          </h2>
          {reasons.length > 1 ? (
            <p className='mt-4'>Tell us why you need to close and we&rsquo;ll tell you what to do next.</p>
          ) : null}
        </React.Fragment>
      )}

      {reasons.length > 0 && !reasons[0]?.eventOther ? (
        <div className='row mb-4'>
          <div className={`col-xs-12 ${showFull ? 'col-sm-6' : ''}`}>
            <p className='mb-4'>* = Required</p>
            <Select
              errorMessage={localState.errorMessageReason}
              id='closure-form-reasons'
              label='Why do you need to close?'
              labelClassName='cb-roboto-bold mb-2'
              name='closure-form-reasons'
              onChange={handleChangeReason}
              required={true}
              value={localState.selectedReason}
              values={[...reasonOptions]}
            />
          </div>
        </div>
      ) : !isMakeupEvent ? (
        <p className='mb-4'>CMS content is missing. Please run the CMS_IMPORT to load CMS content.</p>
      ) : (
        ''
      )}
      {showFull ? (
        <React.Fragment>
          {
            // Only show a makeup if this is not marked as "Other" and has a makeup date.
            !etsOther && reasons.length > 1 && makeupDate ? (
              <div className='row'>
                <div className='col-xs-12 col-sm-6'>
                  <fieldset>
                    <hr />
                    <h2 className='tdtk-h4 pb-4' id='closure-form-h2-makeup-date'>
                      Makeup Date
                    </h2>
                    <div className='makeup-date mb-4 cb-blue5-bg'>
                      <span className='makeup-date--month text--color-white w-100 d-block text-center'>
                        {toLocaleUTCDateString(makeupDate, {
                          month: 'short',
                        })}
                      </span>
                      <span className='makeup-date--day text--color-white w-100 d-block text-center'>
                        {toLocaleUTCDateString(makeupDate, {
                          day: 'numeric',
                        })}
                      </span>
                      <span className='makeup-date--year text--color-white w-100 d-block text-center'>
                        {toLocaleUTCDateString(makeupDate, {
                          year: 'numeric',
                        })}
                      </span>
                    </div>
                    <div className='pt-2'>
                      <div className='tdtk-radio-legend cb-roboto-bold mb-2'>
                        {`Can your test center offer a makeup test${
                          makeupDate
                            ? ` on ${toLocaleUTCDateString(makeupDate, {
                                weekday: 'long',
                                month: 'long',
                                day: 'numeric',
                                year: 'numeric',
                              })}`
                            : null
                        }?`}
                      </div>
                      <div className='mb-4'>
                        IMPORTANT: If you select “Yes,” we’ll let students know that they can take the makeup at your
                        test center.
                      </div>
                      <RadioButtonGroup
                        id='closure-form-can-makeup'
                        key='closure-form-can-makeup'
                        name='closure-form-can-makeup'
                        onChange={handleChangeCanMakeup}
                        required={true}
                        value={localState.canMakeup || '-'}
                        vertical
                      >
                        <RadioButton
                          id='closure-form-can-makeup-yes'
                          key='closure-form-can-makeup-yes'
                          label='Yes'
                          value='YES'
                        />
                        <RadioButton
                          id='closure-form-can-makeup-no'
                          key='closure-form-can-makeup-no'
                          label='No'
                          value='NO'
                        />
                        <RadioButton
                          id='closure-form-can-makeup-maybe'
                          key='closure-form-can-makeup-maybe'
                          label='Maybe'
                          value='MAYBE'
                        />
                      </RadioButtonGroup>
                    </div>
                    <hr />
                  </fieldset>
                </div>
              </div>
            ) : null
          }
        </React.Fragment>
      ) : null}

      {etsOther || (reasons && reasons.length < 1) || isMakeupEvent ? (
        <div className='row'>
          <div className={`px-4 my-4 ${showFull ? 'col-sm-6' : ''}`}>
            {
              // Use the "Next Steps" WYSIWYG text if it exists, otherwise use default.
              nextSteps ? (
                [<div key={localState.selectedReason + '_next-steps'} dangerouslySetInnerHTML={nextSteps} />]
              ) : (
                <React.Fragment>
                  <p className='mb-2'>
                    <strong>
                      Call TAS at <a href='tel:+18665026384'>866-502-6384</a> and select option 3 <br /> or Email{' '}
                      <a href='mailto:tas@ets.org?subject=Test%20Center%20Closure'>tas@ets.org</a>.
                    </strong>
                  </p>
                  <p className='mb-2'>If you call after hours, leave a message including:</p>
                  <ul>
                    <li>Your name and phone number</li>
                    <li>Your test center code and name</li>
                    <li>Your city and state</li>
                    {closureDates[asmtEventId] ? (
                      <li>
                        Your ability to offer the makeup test on{' '}
                        {toLocaleUTCDateString(new Date(closureDates[asmtEventId]), {
                          weekday: 'long',
                          month: 'long',
                          day: 'numeric',
                          year: 'numeric',
                        })}{' '}
                      </li>
                    ) : null}
                  </ul>
                </React.Fragment>
              )
            }
          </div>
        </div>
      ) : (
        <React.Fragment>
          {showFull ? (
            <div className='row'>
              <div className='col-xs-12 col-sm-6'>
                {reasons.length > 1 ? (
                  <div className='pb-4'>
                    <Checkbox
                      id='closure-form-agreement'
                      errorMessage={localState.errorMessageAgreement}
                      key='closure-form-agreement'
                      label={`I understand that clicking “Submit” closes my test center for the SAT administration on ${toLocaleUTCDateString(
                        new Date(startDate),
                        {
                          weekday: 'long',
                          month: 'long',
                          day: 'numeric',
                          year: 'numeric',
                        }
                      )},  and cannot be undone.`}
                      onChange={handleChangeAgreement}
                      required={true}
                      value={localState.agreement || '-'}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          ) : null}
          <div className='row mt-2'>
            <div className={` ${!showFull ? 'col-sm-12' : 'col-xs-12'}`}>
              {localState.loading ? (
                <Spinner />
              ) : (
                <div className='tdtk-form-group tdtk-primary-secondary display-flex--column-mobile'>
                  {showFull ? (
                    <Link className='cb-btn cb-btn-naked cb-btn-sm' id='closure-form-link-homepage' to='/'>
                      &#60; Cancel and return to homepage
                    </Link>
                  ) : null}
                  <YellowButton
                    small
                    className={!showFull ? 'w-100' : ''}
                    disabled={
                      !localState.selectedReason ||
                      (showFull && makeupDate && !localState.canMakeup) ||
                      (showFull && !localState.agreement.length)
                    }
                    id='closure-form-button-submit'
                    type='submit'
                  >
                    {showFull ? 'Submit' : 'Report Closure'}
                  </YellowButton>
                </div>
              )}
            </div>
          </div>
        </React.Fragment>
      )}
    </form>
  );
}

ClosureForm.propTypes = {
  reasons: PropTypes.arrayOf(
    PropTypes.shape({
      eventOther: PropTypes.bool,
      makeupDay: PropTypes.number,
      makeupMonth: PropTypes.number,
      makeupYear: PropTypes.number,
      nextSteps: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  showFull: PropTypes.bool,
  staticContent: PropTypes.bool,
};

export default ClosureForm;
