import padStart from 'lodash/padStart';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';

// Loop through and find out which test book numbers we have available and populate an array.
export function getAllTestBookNumbers(bookSet = []) {
  const testBookNumbersAvailable = [];

  bookSet.forEach((range) => {
    for (let i = range.start; i <= range.end; i++) {
      testBookNumbersAvailable.push(padStart(`${i}`, 6, '0'));
    }
  });

  return uniq(testBookNumbersAvailable);
}

export function isTestNumberUnused(fullTestBookRange = [], students = []) {
  return (
    students.find((student) => {
      return (
        fullTestBookRange.find((t) => {
          // See if this student has a test book on the board.
          return t === padStart(`${student.testBookNumber}`, 6, '0');
        }) !== undefined
      );
    }) === undefined
  );
}

// Takes a range of test books, converts and pads them to strings for the UI.
export function convertTestBooksToStrings(bookSet = []) {
  return bookSet.map((range) => {
    const a = !isNaN(range.start) ? padStart(`${range.start}`, 6, '0') : '';
    const b = !isNaN(range.end) ? padStart(`${range.end}`, 6, '0') : '';
    return { start: a, end: b };
  });
}

/**
 * Returns the students assigned to a room who have not been assigned a seat in that room
 */
export function getStudentsWithoutAssignedSeats(students = []) {
  return sortBy(
    students.filter(
      (student) =>
        (!student.assignedSeat || !student.assignedSeat.x || !student.assignedSeat.y) &&
        !student.absent &&
        !student.deniedEntry
    ),
    ['candLastName', 'candFirstName', 'candMidInit'],
    'asc'
  );
}
