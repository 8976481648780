import React from 'react';
import { PropTypes } from 'prop-types';
import { formatStudentName, fuzzySearch } from '../../../../utils/common';
import { isInSchoolPSATSATEvent } from '../../../../utils/event';
import { useStateValue } from '../../../../context/AppContext';
import Select from '../../../ui/form/Select';
import Selection from '../../../ui/Selection';

type SelectionContainerProps = {
  entireTestCenter: string;
  errorMessages: any;
  handleMultiSelectChange: any;
  handleSelectChange: any;
  mergedRooms: any[];
  mergedStudents: any[];
  readOnly: boolean;
  selectedRooms: any[];
  selectedStudents: any[];
};

function SelectionContainer({
  entireTestCenter = '',
  errorMessages = {},
  handleMultiSelectChange = () => {},
  handleSelectChange = () => {},
  mergedRooms = [],
  mergedStudents = [],
  readOnly = false,
  selectedRooms = [],
  selectedStudents = [],
}: SelectionContainerProps) {
  function renderRoomName(labelKeys) {
    return function (data) {
      // Loop through available labelKeys and concat if needed.
      const newLabelKeys = labelKeys.map((option) => {
        if (data[option]) {
          return data[option];
        } else {
          return null;
        }
      });
      return newLabelKeys[0] || '';
    };
  }

  const filterOptions = (candidate, input) => {
    const escapeRegExp = (string) => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const formattedStudent = formatStudentName(candidate.data);

    if (input) {
      const escapedString = escapeRegExp(input);
      return (
        !!fuzzySearch(escapedString, formattedStudent) ||
        !!fuzzySearch(escapedString, candidate.value) ||
        !!fuzzySearch(escapedString, candidate.data.displayedRegNo)
      );
    }
    return true;
  };

  const { orgEvent, user } = useStateValue();

  return (
    <fieldset className='ir-student-selection'>
      <legend className='ir-legend mb-4'>Students Affected</legend>
      <div>
        <label htmlFor='entireTestCenter' className='cb-required cb-roboto-bold'>
          <span>Which students were involved in this irregularity? </span>
        </label>
      </div>
      {isInSchoolPSATSATEvent(orgEvent) && user.role !== 'CBAdmin' && (
        <div className='cb-roboto-italic mb-2'>
          <span id='entireTestCenter-additionalLabel' style={{ fontSize: '14px' }}>
            In the event of a widespread issue, you can select affected rooms below (instead of individual students).
          </span>
        </div>
      )}

      <Select
        disabled={readOnly}
        errorMessage={errorMessages.entireTestCenter || ''}
        id='entireTestCenter'
        name='entireTestCenter'
        onChange={(val) => {
          val = {
            target: {
              name: 'entireTestCenter',
              type: 'select',
              value: val,
            },
          };
          handleSelectChange(val);
        }}
        required={true}
        value={entireTestCenter || '-'}
        values={[
          {
            label: 'Choose one',
            value: '',
          },
          {
            label: 'Whole test center',
            value: 'entireTestCenter',
            disabled: isInSchoolPSATSATEvent(orgEvent) && user.role !== 'CBAdmin',
          },
          {
            label: 'A roomful of students',
            value: 'rooms',
          },
          {
            label: 'One or more specific students',
            value: 'students',
          },
        ]}
      />
      {entireTestCenter === 'rooms' || selectedRooms.length > 0 ? (
        mergedRooms.length > 0 ? (
          <Selection
            elements={mergedRooms}
            errorMessage={errorMessages.rooms || ''}
            handleSelectChange={handleMultiSelectChange}
            id='rooms'
            label='Choose one or more testing rooms'
            labelKeys={['title']}
            name='rooms'
            optionRenderer={renderRoomName}
            readOnly={readOnly}
            required={true}
            selectedElements={selectedRooms}
            valueKey='id'
          />
        ) : (
          <p className='cb-input-helper cb-validation-error mb-4' role='alert' aria-atomic='true'>
            No rooms have been added for this test administration.
            <br />
            Choose another option.
          </p>
        )
      ) : null}
      {entireTestCenter === 'students' || selectedStudents.length > 0 ? (
        mergedStudents.length > 0 ? (
          <Selection
            elements={mergedStudents}
            enableFilterOption={true}
            errorMessage={errorMessages.students || ''}
            filterOption={filterOptions}
            handleSelectChange={handleMultiSelectChange}
            id='students'
            label='Choose one or more students'
            labelKeys={['formattedStudentName']}
            name='irs-affected-students'
            readOnly={readOnly}
            required={true}
            selectedElements={selectedStudents}
            valueKey='id'
          />
        ) : (
          <p className='cb-input-helper cb-validation-error mb-4' role='alert' aria-atomic='true'>
            No students have been added for this test administration.
            <br />
            Choose another option.
          </p>
        )
      ) : null}
    </fieldset>
  );
}

SelectionContainer.propTypes = {
  entireTestCenter: PropTypes.string,
  errorMessages: PropTypes.object,
  handleMultiSelectChange: PropTypes.func,
  handleSelectChange: PropTypes.func,
  mergedRooms: PropTypes.array,
  mergedStudents: PropTypes.array,
  readOnly: PropTypes.bool,
  selectedRooms: PropTypes.array,
  selectedStudents: PropTypes.array,
};

export default SelectionContainer;
