import React from 'react';
import PropTypes from 'prop-types';
import padStart from 'lodash/padStart';
import sortBy from 'lodash/sortBy';
import { formatStudentName, localStateReducer } from '../../../../../utils/common';
import { getAllTestBookNumbers } from '../utils';
import Checkbox from '../../../../ui/form/Checkbox';
import Select from '../../../../ui/form/Select';
import { YellowButton } from '@cb/apricot-react';

function SeatAssignForm({
  hideModal = () => {},
  isUnusedChecked = false,
  room = {},
  selectedStudentRegId = {},
  selectedTestBookNumber = '',
  setSavingStatus = () => {},
  swapStudentSeats = () => {},
  updateRoom = () => {},
  updateStudent = () => {},
  x = -1,
  y = -1,
}) {
  function assignSerial(val) {
    const serial = val || '';

    setLocalState({
      isDirty: true,
      selectedTestBookNumber: serial,
      unused: serial !== '' ? false : isUnusedChecked,
    });
  }

  function assignStudent(val) {
    const id = val || '';

    setLocalState({
      isDirty: true,
      selectedStudentRegId: id,
      selectedTestBookNumber: id ? (selectedStudentRegId === id ? selectedTestBookNumber : '') : '',
      isUnusedChecked: id !== '' ? false : isUnusedChecked,
    });
  }

  function handleClearSeat() {
    setLocalState({
      isDirty: true,
      isUnusedChecked: false,
      selectedStudentRegId: '',
      selectedTestBookNumber: '',
    });
  }

  function handleSubmit(e) {
    e && e.preventDefault && e.preventDefault();
    /**
     * Remove or add the seat as unused based on their selection.
     */
    const unused = room.unusedSeats ? room.unusedSeats : [];
    const updatedSeats = localState.isUnusedChecked
      ? unused.filter((seat) => seat.x === x && seat.y === y).length > 0
        ? unused
        : [...unused, { x, y }]
      : unused
          .filter((seat) => seat.x !== x || seat.y !== y)
          .map((seat) => ({
            x: seat.x,
            y: seat.y,
          }));

    // Make sure our seat is properly marked as used or unused.
    /**
     * @todo: All of this can probably be refactored as a single mutation, I just don't have the strength.
     * Order of operations:
     *  - Make sure any seats are marked as "Unused" if needed first to avoid validation errors.
     *  - If we are simply adding a student to an open seat, just assign that student.
     *  - If there was previously a student in that seat, we first need to remove them or we'll get a validation error.
     */

    if (localState.isDirty) {
      // Tell the parent we're saving.
      setSavingStatus(true);

      setLocalState({
        isProcessing: true,
      });

      updateRoom({
        variables: {
          input: {
            id: room.id,
            seatingChartPDFCurrent: false,
            seatingStatus: 'drafted',
            shape: room.shape
              ? {
                  x: room.shape.x || 5,
                  y: room.shape.y || 6,
                }
              : {
                  x: 5,
                  y: 6,
                },
            unusedSeats: updatedSeats,
          },
        },
      })
        .then(() => {
          // Check if this seat had a student if it was marked as unused.
          const seatedStudent = studentList.find(
            (student) => student.assignedSeat && student.assignedSeat.x === x && student.assignedSeat.y === y
          );

          const students = [];

          // Current seated student if they exist.
          const studentA = {
            id: seatedStudent ? seatedStudent.candRegNo : null,
          };

          // Student we are adding.
          const studentB = {
            id: localState.selectedStudentRegId,
          };

          // We have to determine if a student is already sitting here.
          if (studentA.id !== studentB.id) {
            // We have two different students, so we should un-seat A, and seat B.
            studentA['assignedSeat'] = null;
            studentA['testBookNumber'] = null;

            // Student B has a different ID than the seated student, so add their coordinates.
            studentB['assignedSeat'] = {
              x,
              y,
            };
          }

          // Give the student being assigned a test number if they are given one.
          studentB['testBookNumber'] =
            localState.selectedTestBookNumber && !isNaN(parseInt(localState.selectedTestBookNumber, 10))
              ? parseInt(localState.selectedTestBookNumber, 10)
              : null;

          // Only add student A if we are replacing a seated student or if they're being updated.
          if (studentA.id && studentA.id !== studentB.id) {
            students.push(studentA);
          }

          if (studentB.id) {
            students.push(studentB);
          }

          // If we are only updating one student, do so.
          if (students.length === 1) {
            updateStudent({
              variables: {
                input: {
                  ...students[0],
                },
              },
            }).then(() => {
              setSavingStatus(false);

              // Hide the modal.
              hideModal();
            });
          } else if (students.length === 2) {
            // We are swapping.
            swapStudentSeats({
              variables: {
                studentA: {
                  ...students[0],
                },
                studentB: {
                  ...students[1],
                },
              },
            }).then(() => {
              setSavingStatus(false);

              // Hide the modal.
              hideModal();
            });
          }

          // Tell the parent we're not saving.
          setSavingStatus(false);

          // Hide the modal.
          hideModal();
        })
        .catch((e) => {
          console.error('Error saving seat: ', e);

          // Tell the parent we're not saving.
          setSavingStatus(false);

          // Hide the modal.
          hideModal();
        });
    } else {
      // Tell the parent we're not saving.
      setSavingStatus(false);

      // Hide the modal.
      hideModal();
    }
  }

  function markUnused(val) {
    const checked = val;

    setLocalState({
      isDirty: true,
      isUnusedChecked: checked,
      selectedStudentRegId: checked ? '' : selectedStudentRegId,
      selectedTestBookNumber: checked ? '' : selectedTestBookNumber,
    });
  }

  const studentList =
    room && room.students && room.students.length
      ? sortBy(
          room.students.filter((student) => !(student.absent || student.deniedEntry)),
          ['candLastName', 'candFirstName', 'candMidInit'],
          'asc'
        )
      : [];

  /**
   * Populate objects for select boxes.
   */
  const studentOptions = studentList
    ? sortBy(studentList, ['candLastName', 'candFirstName', 'candMidInit'], 'asc')
        .filter(
          (student) =>
            !student.assignedSeat ||
            (student.assignedSeat && student.assignedSeat.x === x && student.assignedSeat.y === y)
        )
        .map((student) => ({
          label: formatStudentName(student),
          title: formatStudentName(student),
          value: student.candRegNo,
        }))
    : [];

  // Only show serial numbers that aren't used.
  const serialOptions = getAllTestBookNumbers(room.testBookRanges)
    .sort()
    .filter(
      (serial) =>
        !studentList.find((student) => padStart(`${student.testBookNumber}`, 6, '0') === serial) ||
        studentList.find(
          (student) =>
            student.candRegNo === selectedStudentRegId &&
            student.testBookNumber &&
            padStart(`${student.testBookNumber}`, 6, '0') === serial
        )
    )
    .map((serial) => ({
      label: serial,
      title: serial,
      value: serial,
    }));

  const roomSeatIsUnused =
    room && room.unusedSeats && room.unusedSeats.length
      ? room.unusedSeats.find((shape) => shape.x === x && shape.y === y) !== undefined
      : false;

  // Local state.
  const [localState, setLocalState] = React.useReducer(localStateReducer, {
    isDirty: false,
    isProcessing: false,
    isUnusedChecked: isUnusedChecked,
    selectedStudentRegId,
    selectedTestBookNumber,
  });

  React.useEffect(() => {
    setLocalState({
      isDirty: false,
      isProcessing: false,
      isUnusedChecked: isUnusedChecked,
      selectedStudentRegId,
      selectedTestBookNumber,
    });
  }, [isUnusedChecked, selectedStudentRegId, selectedTestBookNumber]);

  return (
    <form onSubmit={handleSubmit}>
      {
        /**
         * Only show this if they've already marked the seat as "unused."
         * Prevents seats from becoming permanently disabled.
         */
        roomSeatIsUnused ? (
          <Checkbox
            className='mb-4'
            id='check-unused-seats'
            key='check-unused-seats'
            label='Unused'
            onChange={markUnused}
            checked={localState.isUnusedChecked}
          />
        ) : null
      }
      <div>
        {
          /**
           * @todo: Per SAT-90960, this will eventually need to be a popover.
           * Adding this for now to avoid showing dropdowns with no options.
           */

          studentOptions.length > 0 ? (
            <React.Fragment>
              <div className='row mb-4'>
                <div className='col-xs-12'>
                  <Select
                    disabled={localState.isUnusedChecked}
                    id='studentSeatAssignment'
                    label='Student Assigned'
                    name='studentSeatAssignment'
                    onChange={assignStudent}
                    value={localState.selectedStudentRegId || '-'}
                    values={[
                      {
                        label: 'Select student',
                        value: '',
                      },
                      ...studentOptions,
                    ]}
                  />
                </div>
              </div>

              {serialOptions.length > 0 ? (
                <div className='row mb-4'>
                  <div className='col-xs-12'>
                    <Select
                      disabled={localState.isUnusedChecked || !localState.selectedStudentRegId}
                      id='serialSeatAssignment'
                      label='Test Book Assigned'
                      name='serialSeatAssignment'
                      onChange={assignSerial}
                      value={localState.selectedTestBookNumber || '-'}
                      values={[
                        {
                          label: 'Select test book',
                          value: '',
                        },
                        ...serialOptions,
                      ]}
                    />
                  </div>
                </div>
              ) : (
                <React.Fragment>
                  <p className='mb-1'>
                    <strong>Test Book Assigned</strong>
                  </p>
                  <p>To assign this student a test book, add more serial numbers in step 1.</p>
                </React.Fragment>
              )}
            </React.Fragment>
          ) : (
            <p>There are no students in this room who need seats.</p>
          )
        }
      </div>
      <div className='modal-footer'>
        <div className='modal-footer-confirm-buttons'>
          {localState.isUnusedChecked || localState.selectedStudentRegId || localState.selectedTestBookNumber ? (
            <button
              className='cb-btn cb-btn-sm cb-btn-naked'
              disabled={localState.isProcessing}
              onClick={handleClearSeat}
              type='button'
            >
              Clear seat
            </button>
          ) : null}
          <YellowButton small disabled={localState.isProcessing} type='submit'>
            OK
          </YellowButton>
        </div>
      </div>
    </form>
  );
}

SeatAssignForm.propTypes = {
  hideModal: PropTypes.func,
  isUnusedChecked: PropTypes.bool,
  room: PropTypes.object,
  selectedStudentRegId: PropTypes.string,
  selectedTestBookNumber: PropTypes.string,
  setSavingStatus: PropTypes.func,
  swapStudentSeats: PropTypes.func,
  updateRoom: PropTypes.func,
  updateStudent: PropTypes.func,
  x: PropTypes.number,
  y: PropTypes.number,
};

export default SeatAssignForm;
