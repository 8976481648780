import { sortBy } from 'lodash';
import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { CBLocalNavigation } from '@cb/apricot';
import { authorizedRoutes } from '../../../AppRouter';
import { AppStateContext } from '../../../context/AppContext';
import { ResizeContext } from '../../../context/ResizeContext';
import CenterInfo from '../../nonDigital/landing/CenterInfo';
import { TourContainer } from '../../common/productTour/TourContainer';
import { TourContent } from '../../common/productTour/TourContent';
import { useTourContext } from '../../common/productTour/tourContext';
import { useTour } from '../../common/productTour/useTour';
import MenuItem from './MenuItem';
import './LocalNavigation.scss';

function LocalNav() {
  // Global App state.
  const { features, orgEvent, user } = useContext(AppStateContext);

  // Tour Context.
  const { shouldShowTour, tourOpen, onRequestClose } = useTour('home');

  const { setIsOpen } = useTourContext();

  // React Router.
  const { pathname } = useLocation();

  const localShouldShowTour = shouldShowTour && pathname === '/' && !orgEvent?.dapInd;
  const windowSize = useContext(ResizeContext);

  const localNavRef = useRef<HTMLInputElement>(null);

  const navLinks = user?.id
    ? sortBy(authorizedRoutes(features, orgEvent, user), ['weight']).filter((link) => link?.localNav)
    : [];

  const tourSteps = [
    {
      selector: `${windowSize.mobile ? '.local-menu-icon' : '[data-tour="local-nav"]'}`,
      content: function tourContent1(props) {
        return (
          <TourContent
            totalSteps={tourSteps.length}
            title='Welcome, Proctor!'
            content='Open this menu to report an irregularity, get help, or go to other pages.'
            {...props}
          />
        );
      },
    },
    {
      selector: '[data-tour="testing-rooms"]',
      content: function tourContent2(props) {
        return (
          <TourContent
            totalSteps={tourSteps.length}
            title='Navigate to Your Room'
            content='Click &ldquo;Testing Rooms&rdquo; to find your assigned room and check students in.'
            {...props}
          />
        );
      },
    },
  ];

  /**
   * @todo: close local nav using latest 4.x. TBD.
   */
  const closeOpenMobileNav = useCallback(() => {
    // triggerExternal('localNav', localNavRef.current, 'close');
  }, []);

  useEffect(() => {
    CBLocalNavigation({
      elem: document.querySelector('.cb-local-navigation'),
      mobileInteractionClose: true,
      mobileLayoutBreakpoint: 'sm',
      mainContent: document.getElementById('main-content'),
    });
    setIsOpen(false);
  }, [pathname, setIsOpen]);

  return (
    <div className='cb-local-navigation cb-tablet-local-navigation cb-white-bg cb-box-shadow-bottom' ref={localNavRef}>
      {localShouldShowTour && (
        <TourContainer
          steps={tourSteps}
          isOpen={tourOpen}
          onRequestClose={onRequestClose}
          getCurrentStep={closeOpenMobileNav}
        />
      )}
      <div className='cb-desktop-navigation container'>
        <div className='row'>
          <nav className='col-xs cb-nav-container justify-content-end' aria-label='Test Day Toolkit navigation'>
            <div className='cb-nav-items hidden-sm-down'>
              <nav className='cb-menu-list cb-menu-list-horizontal' aria-label='Test Day Toolkit site navigation'>
                <ul data-tour='local-nav'>
                  {navLinks.map((menuItem) => (
                    <MenuItem key={menuItem?.title} path={menuItem?.path} text={menuItem?.title} />
                  ))}
                </ul>
              </nav>
            </div>
            <div className='cb-mobile-trigger hidden-md-up cb-no-border'>
              <a
                aria-expanded='false'
                aria-hidden='true'
                className='cb-icon cb-menu cb-no-text-decoration cb-link-black'
                href='#0'
                id='tdtk-mobile-menu-btn'
                tabIndex={-1}
              >
                <span className='sr-only'>Display Site Navigation</span>
              </a>
            </div>
          </nav>
          {!windowSize.mobile ? (
            <div
              className={`center-info col display--flex_desktop_align-items--center ${
                orgEvent?.dapInd ? 'digital' : ''
              }`}
            >
              <CenterInfo />
            </div>
          ) : null}
        </div>
      </div>
      <div className='cb-mobile-navigation tdtk-mobile-navigation' />
    </div>
  );
}

export default LocalNav;
