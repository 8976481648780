import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { BlackButton } from '@cb/apricot-react';
import { localStateReducer, sortItems, formatStudentName } from '../../../../../utils/common';
import { UPDATE_STUDENT } from '../../../../../apollo/mutations';
import { GET_ROOMS } from '../../../../../apollo/queries';
import { useMutation } from '@apollo/client';
import { useStateValue } from '../../../../../context/AppContext';
import { ModalDispatchContext } from '../../../../ui/modal/ModalContext';
import QueryComponent from '../../../../common/network/QueryComponent';
import RoomChooser from '../../../../nonDigital/students/bulk/RoomChooser';
import TwoButtonFormWithQueryModal from '../../../../ui/modal/standard/TwoButtonFormWithQueryModal';
import OneButtonModal from '../../../../ui/modal/standard/OneButtonModal';

export default function ChangeRoomButton({ student = {} }) {
  const [localState, setLocalState] = React.useReducer(localStateReducer, {
    inProgress: false,
  });

  const dispatchModal = useContext(ModalDispatchContext);
  const [updateStudent] = useMutation(UPDATE_STUDENT);

  const { user } = useStateValue();

  const boundState = {
    onNewState: () => undefined,
    roomChoice: '',
  };

  function onWiring(fNotify) {
    boundState.onNewState = fNotify;
  }

  function onChange(newRoom) {
    boundState.roomChoice = newRoom;

    if (boundState.onNewState) {
      boundState.onNewState({
        disabled: !boundState.roomChoice,
      });
    }
  }

  function onCancel() {
    boundState.roomChoice = '';

    if (boundState.onNewState) {
      boundState.onNewState({
        disabled: !boundState.roomChoice,
      });
    }
  }

  function onSubmit() {
    setLocalState({
      inProgress: true,
    });

    updateStudent({
      variables: {
        input: {
          id: student.id,
          assignedSeat: null,
          room: boundState.roomChoice || '',
          testBookNumber: null,
        },
      },
    })
      .then(() => {
        dispatchModal(
          <OneButtonModal
            modalId='changeRoomSuccess'
            title='The selected student has changed to a new room.'
            variant='success'
          />
        );
      })
      .catch(() => {
        dispatchModal(
          <OneButtonModal modalId='changeRoomError' title='Oops. Unable to change room at this time.' variant='error' />
        );
      })
      .finally(() => {
        setLocalState({
          inProgress: false,
        });
      });
  }

  function changeRoom() {
    dispatchModal(
      <TwoButtonFormWithQueryModal
        disabled={!boundState.roomChoice}
        modalId='changeRoom'
        primaryButtonHandler={onSubmit}
        primaryButtonLabel='Change Testing Room'
        secondaryButtonHandler={onCancel}
        secondaryButtonLabel='Cancel'
        title={`Move ${formatStudentName(student, 'fml')}`}
        onWiring={onWiring}
        variant='input'
        body={
          <>
            <QueryComponent query={{ kind: 'GetRooms', specification: GET_ROOMS }}>
              {(data) => {
                let rooms = data?.viewer?.site?.rooms || [];

                if (rooms.length) {
                  rooms = sortItems(
                    rooms,
                    [
                      {
                        name: 'title',
                        order: 'asc',
                      },
                    ],
                    'natural'
                  );

                  return <RoomChooser rooms={[...rooms]} students={[student]} onChange={onChange} user={user} />;
                } else {
                  return <p>You can&rsquo;t make this change because there aren&rsquo;t enough available seats.</p>;
                }
              }}
            </QueryComponent>
          </>
        }
      />
    );
  }

  return (
    <BlackButton
      id='button-changeRoom'
      onClick={changeRoom}
      disabled={localState.inProgress}
      data-automation='button-changeRoom'
      small
      className='mb-3 w-100'
    >
      Change Testing Room
    </BlackButton>
  );
}

ChangeRoomButton.propTypes = {
  student: PropTypes.object,
};
