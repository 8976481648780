import React from 'react';
import get from 'lodash/get';
import { analyticsTracking } from '../../../../utils/analytics';
import { GET_ROOM } from '../../../../apollo/queries';
import { useParams } from 'react-router-dom';
import { useStateValue } from '../../../../context/AppContext';
import { useTakeTheTour } from '../../../common/productTour';
import QueryComponent from '../../../common/network/QueryComponent';
import RoomDetails from './RoomDetails';

function Container() {
  // Global App state.
  const { user } = useStateValue();

  // get the id of the room
  const { id } = useParams();
  const input = { id };

  React.useEffect(() => {
    analyticsTracking(user.role, '', 'room-detail');
  }, [user.role]);

  const { isUserTourValid, tourButton } = useTakeTheTour();

  return (
    <React.Fragment>
      <div className={'d-flex justify-content-space-between align-items-center'}>
        <h1 className='tdtk-h1 py-4' data-automation='heading-h1'>
          Room Details
        </h1>
        {isUserTourValid && tourButton}
      </div>
      {id !== '' ? (
        <QueryComponent query={{ kind: 'GetRoom', specification: GET_ROOM, variables: { input } }}>
          {(data) => {
            const room = get(data, 'readRoom') || {};
            if (room && room.id) {
              return <RoomDetails room={room} />;
            } else {
              return <p className='mb-4'>Room not found.</p>;
            }
          }}
        </QueryComponent>
      ) : (
        <p className='mb-4'>Room not found.</p>
      )}
    </React.Fragment>
  );
}

Container.displayName = 'RoomDetailsContainer';

export default Container;
