import React from 'react';
import get from 'lodash/get';
import { sortItems } from '../../../../utils/common';
import { GET_NON_DIGITAL_ROOMS_FORM, GET_ROOM, GET_STAFF } from '../../../../apollo/queries';
import { useStateValue } from '../../../../context/AppContext';
import QueryComponent from '../../../common/network/QueryComponent';
import RoomForm from './RoomForm';
import { useParams } from 'react-router-dom';

function Container() {
  // Get the id of the room from the url.
  const { id } = useParams();
  const input = { id };

  // Global App state.
  const { orgEvent } = useStateValue();

  return (
    <div className={orgEvent?.dapInd ? 'container' : ''}>
      <h1 className='tdtk-h1 py-4' data-automation='heading-h1'>
        Edit Room Details
      </h1>
      <QueryComponent query={{ kind: 'GetStaff', specification: GET_STAFF }}>
        {(staffData) => {
          // Get and sort the staff by last/first names.
          const staff = sortItems(get(staffData, 'viewer.site.staff', []), [
            {
              name: 'lastName',
              order: 'asc',
            },
            {
              name: 'firstName',
              order: 'asc',
            },
          ]);

          return (
            <QueryComponent query={{ kind: 'GetRoom', specification: GET_ROOM, variables: { input } }}>
              {(roomData) => {
                const room = get(roomData, 'readRoom') || {};

                if (room && room.id) {
                  return (
                    <QueryComponent
                      query={{
                        kind: 'GetNonDigitalRoomsForm',
                        specification: GET_NON_DIGITAL_ROOMS_FORM,
                      }}
                    >
                      {(studentData) => {
                        const students = get(studentData, 'viewer.site.students', []);
                        return <RoomForm room={room} staff={staff} students={students} />;
                      }}
                    </QueryComponent>
                  );
                } else {
                  return <p className='mb-4'>Room not found.</p>;
                }
              }}
            </QueryComponent>
          );
        }}
      </QueryComponent>
    </div>
  );
}

export default Container;
