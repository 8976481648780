export function parseStats(statsRaw) {
  return {
    allottedStaff: {
      description: 'Includes only proctors and room monitors assigned a room and all hall monitors.',
      number: statsRaw.allottedStaff,
      title: 'Allotted Staff',
    },
    availableProctors: {
      description: 'Staff currently unassigned.',
      number: statsRaw.availableProctors,
      title: 'Unassigned Proctors',
    },
    coordinators: {
      description: 'Staff assigned the coordinator role.',
      number: statsRaw.coordinators,
      title: 'Coordinators',
    },
    hallMonitors: {
      description: 'Staff assigned the hall monitor role.',
      number: statsRaw.hallMonitors,
      title: 'Hall Monitors',
    },
    technologyCoordinators: {
      description: 'Staff assigned the technology coordinator role.',
      number: statsRaw.technologyCoordinators,
      title: 'Technology Coordinators',
    },
    neededProctors: {
      description: 'Number of rooms that do not have proctors assigned.',
      number: statsRaw.neededProctors,
      title: 'Rooms Without Staff',
    },
    proctors: {
      description: 'Staff assigned the proctor role.',
      number: statsRaw.proctors,
      title: 'Proctors',
    },
    regStudents: {
      description: 'Registered students on your roster (not waitlist students).',
      number: statsRaw.regStudents,
      title: 'Registered Students',
    },
    roomMonitors: {
      description: 'Staff assigned the room monitor role.',
      number: statsRaw.roomMonitors,
      title: 'Room Monitors',
    },
    roomsInUse: {
      description: 'Rooms with assigned students out of rooms added.',
      number: statsRaw.roomsInUse,
      title: 'Rooms in Use',
    },
    testingGroups: {
      description: 'Groups of students who can test in the same room. Also known as group types.',
      number: statsRaw.testingGroups ? statsRaw.testingGroups.length : 0,
      title: 'Testing Groups',
      url: '/groups',
    },
    totalRooms: {
      description: 'Rooms added to this administration.',
      number: statsRaw.totalRooms,
      title: 'Total Rooms',
    },
    totalStaff: {
      description: 'Staff who have access to the toolkit.',
      number: statsRaw.totalStaff,
      title: 'Approved Staff',
    },
    totalSeats: {
      description: 'Total seats added to this administration.',
      number: statsRaw.totalSeats,
      title: 'Total Capacity',
    },
    totalStudents: {
      description: 'All students on your roster (registered and waitlisted).',
      number: statsRaw.totalStudents,
      title: 'Total Students',
    },
    unassignedStudents: {
      description: 'Registered students on your roster who need a room.',
      number: statsRaw.unassignedStudents,
      title: 'Unassigned Registered Students',
    },
    waitStudents: {
      description: 'Waitlist students on your roster (not registered students).',
      number: statsRaw.waitStudents,
      title: 'Waitlist Students',
    },
  };
}
