import React from 'react';
import PropTypes from 'prop-types';
import { CheckboxGroup as Ap4CheckboxGroup } from '@cb/apricot-react';

export default function CheckboxGroup({ additionalInstructions, errorMessage, id, ...props }) {
  return (
    <>
      {additionalInstructions && (
        <p className='mb-4' data-automation={`${id}-additional-info`}>
          {additionalInstructions}
        </p>
      )}
      <Ap4CheckboxGroup
        id={id}
        legendClassName='tdtk-checkbox-legend'
        validation={errorMessage ? 'error' : null}
        validationMsg={errorMessage ? errorMessage : ''}
        vSpacing='8'
        {...props}
      />
    </>
  );
}

CheckboxGroup.propTypes = {
  additionalInstructions: PropTypes.string,
  errorMessage: PropTypes.string,
  id: PropTypes.string.isRequired,
};
