import React from 'react';
import PropTypes from 'prop-types';
import Select from './form/Select';
import TooltipWrapper from './tooltip/TooltipWrapper';
import { YellowButton } from '@cb/apricot-react';

function BulkActionDropdown({
  defaultValue = '',
  errorMessage = '',
  handleSubmit = () => {},
  handleValueSelect = () => {},
  selectId = 'bulkOperations',
  values = [],
}) {
  function GoButton() {
    return (
      <div id={`tooltip-trigger-${selectId}`} style={{ width: 'fit-content' }}>
        {errorMessage && (
          <span className='cb-sr-only' aria-live='assertive' aria-atomic='true'>
            {' '}
            {errorMessage}
          </span>
        )}
        <YellowButton small id={`${selectId}-goButton`} disabled={Boolean(errorMessage)} type='submit'>
          Go
        </YellowButton>
      </div>
    );
  }
  // enchance options objects to have a label that we can use (based on their title)
  const transformSelectOptions = (a) => {
    return a.reduce((prev, cur) => {
      return [
        ...prev,
        {
          label: cur.title,
          ...cur,
        },
      ];
    }, []);
  };

  return (
    <form className='hidden-xs hidden-sm mb-4' onSubmit={handleSubmit}>
      <div className='row px-0 mx-0'>
        <div className='col-xs-4'>
          <Select
            id={selectId}
            label='Select a bulk operation to perform on selected elements'
            labelClassName='cb-sr-only'
            name={selectId}
            onChange={handleValueSelect}
            value={defaultValue}
            values={transformSelectOptions(values)}
          />
        </div>
        <div className='col-xs-4'>
          {errorMessage ? (
            <>
              {GoButton()}
              <TooltipWrapper light={true} trigger={`tooltip-trigger-${selectId}`} tooltipId={`tooltip-${selectId}`}>
                {errorMessage}
              </TooltipWrapper>
            </>
          ) : (
            GoButton()
          )}
        </div>
      </div>
    </form>
  );
}

BulkActionDropdown.propTypes = {
  defaultValue: PropTypes.string,
  errorMessage: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleValueSelect: PropTypes.func,
  selectId: PropTypes.string,
  values: PropTypes.array,
};

export default BulkActionDropdown;
