import { useEffect, useState } from 'react';
import { usePreviousValue } from '../../../../../constants/usePreviousValue';
import DeepDiff from 'deep-diff';

export const useBusinessLogic = ({ students = [] }) => {
  const [everyStudentHasTestBook, setEveryStudentHasTestBook] = useState(false);

  const prevStudents = usePreviousValue(students);

  useEffect(() => {
    if (DeepDiff(prevStudents, students)) {
      const studentsWithAssignedSeats = students.filter((student) => !!student.assignedSeat);
      //When invoked on an empty array, every() returns true
      const _everyStudentHasTestBook = studentsWithAssignedSeats.every((student) => !!student.testBookNumber);

      setEveryStudentHasTestBook(_everyStudentHasTestBook);
    }
  }, [students, prevStudents]);

  return { everyStudentHasTestBook };
};
