import React from 'react';
import { localStateReducer } from '../../../utils/common';
import PropTypes from 'prop-types';
import './common.scss';
import { NakedButton } from '@cb/apricot-react';

function ToggleDirections({ children, showDirectionInfo = false }) {
  // Local state.
  const [localState, setLocalState] = React.useReducer(localStateReducer, {
    // recall the user's saved preference for having the directions visible
    showDirectionInfo: sessionStorage.getItem('rosterShowDirectionInfo')?.match(/^(true|false)$/)
      ? sessionStorage.getItem('rosterShowDirectionInfo') === 'true'
        ? true
        : false
      : showDirectionInfo,
  });

  function toggleDirectionInfo() {
    sessionStorage.setItem('rosterShowDirectionInfo', !localState.showDirectionInfo);
    setLocalState({ showDirectionInfo: !localState.showDirectionInfo });
  }

  return (
    <>
      <div className='cb-gray5-bg cb-align-center cb-padding-8 cb-paragraph3-st'>
        <NakedButton
          small
          icon={localState?.showDirectionInfo ? 'see-off' : 'see-on'}
          iconDecorative
          iconLeft
          noPadding
          style={{ fontSize: '19px' }}
          aria-expanded={!!localState?.showDirectionInfo}
          id='directions-toggle'
          onClick={toggleDirectionInfo}
          aria-controls='directions-info'
        >
          {localState?.showDirectionInfo ? 'Hide Directions' : 'Show Directions'}
        </NakedButton>
      </div>
      {localState?.showDirectionInfo && <div id='directions-info'>{children}</div>}
    </>
  );
}

ToggleDirections.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  showDirectionInfo: PropTypes.bool,
};

export default ToggleDirections;
