import React from 'react';
import PropTypes from 'prop-types';
import CTA from '../../../constants/callToAction';
import Notification from './Notification';

function NotificationContainer({ cta = null }) {
  let formattedCTA = null;

  if (cta && CTA[cta.title] && CTA[cta.title].description && CTA[cta.title].description[cta.version]) {
    formattedCTA = {
      ...CTA[cta.title],
      description: CTA[cta.title].description[cta.version],
    };
  }

  return formattedCTA ? <Notification cta={formattedCTA} /> : null;
}

NotificationContainer.propTypes = {
  cta: PropTypes.object,
};

export default NotificationContainer;
