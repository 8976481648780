export function generateGroupTypeLabel(groupType, orgEvent = {}) {
  if (!groupType) {
    return '';
  }
  if (orgEvent.dapInd === true) {
    return groupType.label ? groupType.label : groupType.standard ? 'standard' : 'accommodated';
  } else {
    return groupType.standard ? 'standard' : 'accommodated';
  }
}

export function generateGroupTypeCellStr(group, student, orgEvent) {
  if (!group) {
    return `${student.groupType}`;
  }
  return group?.title
    ? `${student.groupType}: ${group?.title} (${generateGroupTypeLabel(group, orgEvent)})`
    : `${student.groupType}: ${generateGroupTypeLabel(group, orgEvent)}`;
}
