import React from 'react';
import QueryComponent from '../../../common/network/QueryComponent';
import get from 'lodash/get';
import { GET_ROOM } from '../../../../apollo/queries';
import ChartWrapper from './ChartWrapper';
import { useStateValue } from '../../../../context/AppContext';
import { TourContent, TourContainer, useTour, useTakeTheTour } from '../../../common/productTour';
import { ResizeContext } from '../../../../context/ResizeContext';
import { Link, useParams } from 'react-router-dom';
import { Icon } from '@cb/apricot-react';

function Container() {
  // Get the id of the room from the url.
  const { id } = useParams();
  const input = { id };

  // Global app state.
  const { orgEvent } = useStateValue();
  const { shouldShowTour, tourOpen, onRequestClose } = useTour('seating-chart');
  const { isUserTourValid, tourButton } = useTakeTheTour();
  const windowSize = React.useContext(ResizeContext);

  const tourSteps = [
    {
      selector: '[data-tour="test-books"]',
      content: function tourContent1(props) {
        return (
          <TourContent
            totalSteps={tourSteps.length}
            title='Add Test Books'
            content='Enter the first and last serial numbers in each range and click &ldquo;Add This Range.&rdquo;'
            {...props}
          />
        );
      },
    },
    {
      selector: '[data-tour="test-doors"]',
      content: function tourContent2(props) {
        return (
          <TourContent
            totalSteps={tourSteps.length}
            title='Add Doors'
            content='Tell us where the door is and we’ll mark it on the chart.'
            {...props}
          />
        );
      },
    },
    {
      selector: '[data-tour="adjust-grid"]',
      content: function tourContent3(props) {
        return (
          <TourContent
            totalSteps={tourSteps.length}
            title='Adjust the Chart'
            content='The default seating chart has 6 rows of 5 seats, but you can change its size and shape.'
            {...props}
          />
        );
      },
    },
    {
      selector: '[data-tour="seating-grid"]',
      content: function tourContent4(props) {
        return (
          <TourContent
            totalSteps={tourSteps.length}
            title='Assign Students'
            content='Choose an empty seat, then select a student and a test book serial number.'
            {...props}
          />
        );
      },
    },
    {
      selector: '[data-tour="submit-chart"]',
      content: function tourContent5(props) {
        return (
          <TourContent
            totalSteps={tourSteps.length}
            title='Finish Up'
            content="Submit your chart when it shows every student you checked in—we'll mark leftover seats as unused."
            {...props}
          />
        );
      },
    },
  ];

  return (
    <div id='seating-chart-bg'>
      {id !== '' ? (
        <QueryComponent query={{ kind: 'GetRoom', specification: GET_ROOM, variables: { input } }}>
          {(data) => {
            const room = get(data, 'readRoom') || {};

            if (room && room.id) {
              return (
                <React.Fragment>
                  {shouldShowTour && (
                    <TourContainer steps={tourSteps} isOpen={tourOpen} onRequestClose={onRequestClose} />
                  )}
                  <div className={'d-flex justify-content-space-between align-items-center'}>
                    <div
                      className={windowSize.prefix === 'xs' || windowSize.prefix === 'sm' ? 'mb-0 mt-2' : 'mt-4 mb-0'}
                    >
                      <Link to={`/rooms/get/${room.id}`} className='cb-palette-blue5 cb-text-icon-left'>
                        <Icon name='west' decorative />
                        <span>Back to Room Details</span>
                      </Link>
                    </div>
                    {isUserTourValid && (
                      <div className={windowSize.prefix === 'xs' || windowSize.prefix === 'sm' ? '' : 'mt-4'}>
                        {tourButton}
                      </div>
                    )}
                  </div>
                  <h1 className='tdtk-h1 mb-2' data-automation='heading-h1'>
                    {room.title}
                  </h1>
                  <h2 className='tdtk-h2 mb-4'>Seating Chart</h2>

                  <p className='mb-4'>
                    Follow the steps to create your seating chart&mdash;and we&rsquo;ll auto-save it.
                  </p>
                  <p className='mb-4'>
                    <strong>Important:</strong> When students finish testing and you complete the paper Testing Room
                    Materials Report, you can skip the seating chart section.
                  </p>

                  <ChartWrapper orgEvent={orgEvent} room={room} />
                </React.Fragment>
              );
            } else {
              return <p className='mb-4'>Room not found.</p>;
            }
          }}
        </QueryComponent>
      ) : (
        <p>Room not found.</p>
      )}
    </div>
  );
}

export default Container;
