import PropTypes from 'prop-types';
import React, { useEffect, useReducer } from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import { reducer } from '../../../utils/common';

function MenuItem({ path = '#0', text = '', type = 'promotional' }) {
  function isSelected(link) {
    return localState?.currentUrl === link;
  }

  // React Router.
  const { pathname } = useLocation();

  // Local state.
  const [localState, setLocalState] = useReducer(reducer, {
    currentUrl: pathname,
  });

  // Listen for changes in routes to see if this menu item is selected.
  useEffect(() => {
    setLocalState({
      currentUrl: pathname,
    });
  }, [pathname]);

  const automationName = () => {
    if (path === '/') {
      return 'link-home';
    }
    const splitPath = path.split('/');
    return `link-${splitPath[splitPath.length - 1]}`;
  };

  return (
    <li
      className={`cb-nav-link ${type === 'procedural' ? 'cb-procedural' : 'cb-promotional'}`}
      data-automation={automationName()}
    >
      <NavLink className={`cb-menu-link ${isSelected(path) ? 'cb-selected' : ''}`} to={path}>
        <span>{text}</span>
      </NavLink>
    </li>
  );
}

MenuItem.propTypes = {
  external: PropTypes.bool,
  path: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.string,
};

export default MenuItem;
