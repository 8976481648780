import React from 'react';
import PropTypes from 'prop-types';
import { checkIfGroupTypesCanTestTogether } from '../../../common/students/utility';
import { groupTypes } from '../../../../constants/groupTypes';
import { hasAdminAccess } from '../../../../utils/user';
import { localStateReducer, setCheckedProp, sortItems } from '../../../../utils/common';
import { mergeDeep, isEqual } from 'apollo-utilities';
import { ResizeContext } from '../../../../context/ResizeContext';
import { useStateValue } from '../../../../context/AppContext';
import { useNavigate } from 'react-router-dom';
import BulkActionDropdown from '../../../ui/BulkActionDropdown';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';
import Roster from '../../../common/Roster';
import RosterFilters from '../../../common/RosterFilters';
import StudentRosterRow from './StudentRosterRow';
import uniqBy from 'lodash/uniqBy';

function StudentRoster({ students = [] }) {
  const navigate = useNavigate();
  function filterStudents(filterObj, searchValue) {
    // Only get active filters, make a special case for the false case
    const activeFilters = Object.keys(filterObj).filter((key) => filterObj[key] || filterObj[key] === false);
    const isFiltered = !isEmpty(activeFilters);
    const searchFields = ['candLastName', 'candFirstName', 'candRegNo'];
    const upperSearchValue = searchValue ? searchValue.toUpperCase() : '';
    let filteredStudents = students;

    if (isFiltered || upperSearchValue) {
      filteredStudents = students.filter((item) => {
        let isVisible = activeFilters.every((filter) => {
          if (filter === 'status') {
            if (filterObj[filter].startsWith('waitListFlag')) {
              const flag = filterObj[filter].split(':')[1];
              return get(item, 'waitListFlag') === flag;
            } else {
              return get(item, filterObj[filter]);
            }
          } else if (filter === 'room.id' && filterObj[filter] === 'unassigned') {
            return !get(item, filter);
          } else if (filter === 'attendance') {
            // Break out any negatives.
            const attendanceFilters = filterObj[filter].split('|');

            if (attendanceFilters.length === 1) {
              // We only have the one status, just filter by that.
              return get(item, filterObj[filter]);
            } else {
              // Break out any filters from here.
              let ret = true;
              for (let i = 0, len = attendanceFilters.length; i < len; i++) {
                if (attendanceFilters[i].split(':').length > 1) {
                  // Return false if any conditions ever fail.
                  ret =
                    ret &&
                    get(item, attendanceFilters[i].split(':')[0]) === (attendanceFilters[i].split(':')[1] === 'Y');
                }
              }
              return ret;
            }
          } else {
            return get(item, filter) === filterObj[filter];
          }
        });

        if (isVisible && upperSearchValue) {
          const objFields = [];
          searchFields.forEach((searchField) => {
            if (item[searchField]) {
              objFields.push(item[searchField].toUpperCase());
            }
          });
          if (!isEmpty(objFields)) {
            isVisible = objFields.some((item) => item.indexOf(upperSearchValue) > -1);
          }
        }
        return isVisible;
      });
    }

    return filteredStudents;
  }

  function handleBulkChange(val) {
    setLocalState({
      bulkAction: val,
    });
  }

  function handleSubmit(e) {
    e && e.preventDefault && e.preventDefault();

    const isDisabled = !!generateErrorMessage();

    // Only proceed if they've selected an action, they have no errors, and they have checked staff.
    if (localState.bulkAction && !isDisabled && !isEmpty(localState.checkedStudents)) {
      navigate(`/students/bulkOperations/${localState.bulkAction}`, {
        state: {
          students: students.filter((s) => localState.checkedStudents.indexOf(s.id) > -1),
        },
      });
    }
  }

  function generateErrorMessage() {
    const { bulkAction, checkedStudents } = localState;

    if (bulkAction === '' || isEmpty(checkedStudents)) {
      return 'Select an action and at least one student.';
    }

    const selectedGroupTypes = uniqBy(
      students.filter((s) => checkedStudents.indexOf(s.id) > -1),
      'groupType'
    ).map((item) => item.groupType);
    const canTestTogether = checkIfGroupTypesCanTestTogether(selectedGroupTypes);

    return bulkAction === 'unassign' || canTestTogether
      ? ''
      : 'Select only students in the same testing group or combinable testing groups and try again.';
  }

  function bulkComponent(selectId) {
    let bulkErrorMessage;

    if (isAdmin) {
      bulkErrorMessage = generateErrorMessage();

      return (
        <BulkActionDropdown
          errorMessage={bulkErrorMessage}
          defaultValue={localState.bulkAction}
          handleSubmit={handleSubmit}
          handleValueSelect={handleBulkChange}
          selectId={selectId}
          values={bulkActions}
        />
      );
    } else {
      return null;
    }
  }

  function handleCheckStudents(studentIds = [], checkAll) {
    let index;
    const updateCheckedStudents = [...localState.checkedStudents];

    studentIds.forEach((id) => {
      index = updateCheckedStudents.indexOf(id);

      // Determine if we should un-check them based on a "check all" command.
      if (checkAll !== undefined) {
        // The ID exists, and they want to "un-check all".
        if (index > -1 && !checkAll) {
          updateCheckedStudents.splice(index, 1);
        } else if (index === -1 && checkAll) {
          // They want to check-all and this ID is not in our array.
          updateCheckedStudents.push(id);
        }
      } else {
        // They are probably just checking an individual item.
        if (index > -1) {
          // Un-check them since it's already in the array, and un-check the "check all" button.
          updateCheckedStudents.splice(index, 1);
          setCheckedProp(checkboxAllRef, false);
        } else {
          // They are not checked, so check them.
          updateCheckedStudents.push(id);
        }
      }
    });

    // Update our state to have all checked values accordingly.
    setLocalState({
      checkedStudents: updateCheckedStudents,
    });
  }

  function generateHeaders() {
    // We're passing an array for sorting multiple values in a single column header, so put them in reverse order of precedence.
    // e.g. last name will be sorted first here.
    const tableHeaders = [
      { title: 'Student', sortField: ['candMidInit', 'candFirstName', 'candLastName'] },
      { title: 'Testing Group', sortField: ['groupType'] },
      { title: 'Accommodations' },
      { title: 'Room', sortField: ['room.title'] },
      { title: 'Seat' },
      { title: 'Status', sortField: ['status.sortOrder'] },
      { title: 'Last Updated', sortField: ['updated'] },
    ];

    // Construct the data to be sent to the table. Only include checkboxes if they are an admin.
    if (isAdmin) {
      tableHeaders.unshift({ title: 'checkAll' });
    }

    return tableHeaders;
  }

  // The list of filters needed for student
  function generateFilterList() {
    // Get all the rooms that this student has
    const roomSelections = sortItems(
      students.reduce((accum, item) => {
        const roomName = get(item, 'room.title', null);
        const groupTypes = get(item, 'room.groupTypes', []);
        const id = get(item, 'room.id', '');

        if (roomName && id) {
          accum.push({
            title: `${roomName}${groupTypes && groupTypes.length > 0 ? ` | ${groupTypes.join(', ')}` : ''}`,
            value: id,
          });
        }
        return accum;
      }, []),
      [
        {
          name: 'title',
          order: 'asc',
        },
      ]
    );

    // This gets all used Group Types, and reduces it down to a single array.
    const studentGroups = students.map((student) => student.groupType);
    const uniqueGroups = [...new Set(studentGroups)].sort();

    // This ensures we only show Group Types in the filter that we're actually using in this administration.
    const groupSelections = uniqueGroups.map((item) => ({
      title: `${item}: ${groupTypes[item].title}`,
      value: item,
    }));

    return [
      {
        id: 'student-filter-room',
        label: 'Filter Students by Room',
        field: 'room.id',
        type: 'select',
        defaultValue: [localState.filterObj['room.id']],
        selectOptions: [
          { title: '-Select-', value: '' },
          { title: 'Unassigned', value: 'unassigned' },
          ...uniqBy(roomSelections, 'value'),
        ],
      },
      {
        id: 'student-filter-groupType',
        label: 'Filter Students by Testing Group',
        field: 'groupType',
        type: 'select',
        defaultValue: [localState.filterObj['groupType']],
        selectOptions: [{ title: '-Select-', value: '' }, ...groupSelections],
      },
      {
        id: 'student-filter-status',
        label: 'Filter Students by Attendance Status',
        field: 'attendance',
        type: 'select',
        defaultValue: [localState.filterObj['attendance']],
        /**
         * The following option values come from the attribute
         * value of the student object.
         * The intended use is to do get(student, <value>) to check for the filter
         * Necessary coupling to fulfill filtering by "status".
         */
        selectOptions: [
          { title: '-Select-', value: '' },
          { title: 'Not arrived', value: 'checkedInRoom:N|checkedInCenter:N|absent:N|deniedEntry:N' },
          { title: 'Checked in to center', value: 'checkedInCenter:Y|checkedInRoom:N' },
          { title: 'Checked in to room', value: 'checkedInRoom' },
          { title: 'Absent', value: 'absent' },
          { title: 'Denied entry', value: 'deniedEntry' },
        ],
      },
      {
        id: 'student-filter-waitlisted',
        label: 'Filter Students by Registration Status',
        field: 'status',
        type: 'select',
        defaultValue: [localState.filterObj['status']],
        selectOptions: [
          { title: '-Select-', value: '' },
          { title: 'Registered', value: 'waitListFlag:N' },
          { title: 'Waitlisted', value: 'waitListFlag:Y' },
        ],
      },
    ];
  }

  function generateForm() {
    // Pick a form to show based on which form they selected.
    if (localState.showFilterTableForm) {
      return (
        <RosterFilters applyFilter={toggleFilterForm} filters={generateFilterList()} updateFilter={updateFilter} />
      );
    } else {
      return null;
    }
  }

  function updateSearch(e) {
    const searchValue = e.target.value;

    // Save the object in case they navigate away we can retrieve it.
    sessionStorage.setItem('studentSearch', searchValue);

    setLocalState({
      searchValue,
    });
  }

  function updateFilter(val, e) {
    // handle variable inputs to this function for the Apricot 4 transition
    if (!e) {
      e = val;
    }

    const targetName = e.target.name;
    const targetValue = e.target.value;

    let filterObj = {};

    if (targetName !== 'resetFilters') {
      filterObj = {
        ...localState.filterObj,
        [targetName]: targetValue,
      };

      filterObj = omitBy(filterObj, (filterAttr) => {
        return filterAttr === '';
      });
    } else {
      sessionStorage.setItem('studentFilters', '');
      toggleFilterForm();
    }

    setLocalState({
      filterObj,
    });
  }

  function toggleFilterForm() {
    // Show/hide filter form.
    setLocalState({
      showFilterTableForm: !localState.showFilterTableForm,
    });

    // If they had saved the originating button, focus on it.
    if (localState.focusElement && localState.showFilterTableForm) {
      document.getElementById(localState.focusElement).focus();

      // Reset the focus element.
      setLocalState({
        focusElement: null,
      });
    }
  }

  function buildTableControlElements() {
    return [
      {
        variant: Object.keys(localState.filterObj).length ? 'yellow' : 'black',
        isExpanded: localState.showFilterTableForm,
        icon: !localState.showFilterTableForm ? 'plus' : 'minus',
        label: 'Filter Table',
        name: 'filter-student',
        onClick: () => {
          // If they are opening the accordion, focus on it.
          if (!localState.showFilterTableForm) {
            document.getElementById('tableControlForm').focus();
          }
          setLocalState({
            focusElement: 'filter-student',
          });
          toggleFilterForm();
        },
        type: 'button',
      },
      {
        defaultValue: localState.searchValue,
        icon: 'search',
        label: 'Enter part of a student’s name here to find the student',
        name: 'student-search',
        onChange: updateSearch,
        placeholder: 'Search Students',
        srOnlyLabel: true,
        suppressFormGroupClass: true,
        type: 'input',
      },
    ];
  }

  // Global App state.
  const { user } = useStateValue();
  const windowSize = React.useContext(ResizeContext);

  // Check if we're getting data from a passed filter, or the session storage.
  let savedStudentFilters;

  try {
    savedStudentFilters = sessionStorage.getItem('studentFilters')
      ? JSON.parse(sessionStorage.getItem('studentFilters'))
      : null;
  } catch (e) {
    savedStudentFilters = null;
  }

  const savedStudentSearch = sessionStorage.getItem('studentSearch') ? sessionStorage.getItem('studentSearch') : null;

  // Local state.
  const [localState, setLocalState] = React.useReducer(localStateReducer, {
    bulkAction: '',
    checkedStudents: [],
    filterObj: savedStudentFilters ? { ...savedStudentFilters } : {},
    focusElement: '',
    initialStudentList: students,
    searchValue: savedStudentSearch ? savedStudentSearch : '',
    showFilterTableForm: false,
  });

  const isAdmin = hasAdminAccess(user.rid, user.role);

  React.useEffect(() => {
    if (!isEqual(students, localState.initialStudentList)) {
      setLocalState({
        filteredStudents: [...mergeDeep(localState.filteredStudents, students)],
        initialStudentList: students,
      });
    }
  }, [localState.initialStudentList, localState.filteredStudents, students]);

  const headers = generateHeaders();
  const tableControlForm = generateForm();
  const tableControlElements = buildTableControlElements();

  const emptyMessage = !students.length ? 'Student roster information is unavailable.' : '';

  const bulkActions = [
    { value: '', title: 'Choose an action' },
    { value: 'assign', title: 'Move selected students to a room' },
    { value: 'unassign', title: 'Remove selected students from rooms' },
  ];

  const checkboxAllRef = React.useRef();

  const filteredStudents = filterStudents({ ...localState.filterObj }, localState.searchValue);

  // Set the local storage with the current filters if they exist.
  sessionStorage.setItem('studentFilters', JSON.stringify(localState.filterObj));

  return (
    <React.Fragment>
      {
        // Render the bulk component if they are allowed.
        filteredStudents.length ? bulkComponent('bulkActionStudentRosterTop') : null
      }
      <Roster
        caption='Student Roster'
        checkboxAllRef={checkboxAllRef}
        checkedItems={localState.checkedStudents}
        emptyMessage={emptyMessage}
        groupName='students'
        handleCheckItems={handleCheckStudents}
        headers={headers}
        items={filteredStudents}
        sortFields={[
          {
            name: 'status.sortOrder',
            order: 'asc',
          },
          {
            name: 'candLastName',
            order: 'asc',
          },
          {
            name: 'candFirstName',
            order: 'asc',
          },
          {
            name: 'candMidInit',
            order: 'asc',
          },
        ]}
        renderItem={(student, options) => (
          <StudentRosterRow
            key={`student_${student.id}`}
            handleCheckStudents={handleCheckStudents}
            options={{
              ...options,
              checked: localState.checkedStudents.indexOf(student.id) > -1,
            }}
            student={student}
          />
        )}
        showSelected={!windowSize.mobile}
        stickyHeaders={true}
        tableControlElements={tableControlElements}
        tableControlForm={tableControlForm}
      />
      {
        // Render the bulk component if they are allowed.
        filteredStudents.length ? bulkComponent('bulkActionStudentRosterBottom') : null
      }
    </React.Fragment>
  );
}

StudentRoster.propTypes = {
  checkboxAllRef: PropTypes.object,
  checkedStudents: PropTypes.array,
  filterObj: PropTypes.object,
  handleCheckStudents: PropTypes.func,
  searchValue: PropTypes.string,
  setFilterObj: PropTypes.func,
  setSearchValue: PropTypes.func,
  students: PropTypes.array,
};

export default StudentRoster;
