import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import padStart from 'lodash/padStart';
import find from 'lodash/find';
import Slider from 'react-slick';
import { localStateReducer, getStudentInitials, formatStudentName } from '../../../../../utils/common';
import { ModalDispatchContext } from '../../../../ui/modal/ModalContext';
import { ResizeContext } from '../../../../../context/ResizeContext';
import CustomArrow from './CustomArrow';
import { SWAP_STUDENT_SEATS, UPDATE_ROOM, UPDATE_STUDENT } from '../../../../../apollo/mutations';
import SeatFormModal from './modals/SeatFormModal';
import './Slideshow.scss';
import { Icon } from '@cb/apricot-react';

function SeatGrid({ room = {} }) {
  function handleSeatClick(x = -1, y = -1) {
    return function () {
      function hideModal() {
        dispatchModal(null);

        // Return focus to the seat that triggered the modal.
        if (x > -1 && y > -1) {
          document.getElementById(`${x}-${y}-seating-chart-seat`).focus();
        }
      }

      function setSavingStatus(isSaving = false) {
        // This will allow a child to tell the parent that it's saving and we should wait.
        setLocalState({
          isSaving,
        });
      }

      // Get the current student sitting in this seat.
      const currentStudent =
        room && room.students && room.students.length > 0
          ? room.students.find(
              (student) => student.assignedSeat && student.assignedSeat.x === x && student.assignedSeat.y === y
            ) || null
          : null;

      const currentStudentRegId = currentStudent && currentStudent.candRegNo ? currentStudent.candRegNo : '';

      // Get the currently selected testbook number for this seat.
      const selectedTestBookNumber =
        currentStudent && currentStudent.testBookNumber && !isNaN(currentStudent.testBookNumber, 10)
          ? padStart(`${currentStudent.testBookNumber}`, 6, '0')
          : '';

      const isUnusedChecked =
        room && room.unusedSeats && room.unusedSeats.length
          ? room.unusedSeats.find((shape) => shape.x === x && shape.y === y) !== undefined
          : false;

      // Update this component's state to refect the initial modal values.
      setLocalState({
        isUnusedChecked,
        selectedStudentRegId: currentStudentRegId,
        selectedTestBookNumber,
      });

      /**
       * Trigger the seat form modal.
       * Notice we're using a new prop -> showFooter: false.
       *
       * This allows us to complete the form inside of a modal with custom submit buttons.
       * Putting it in the regular "handlerFunction" we use everywhere else doesn't work here,
       * because the scope of the variables is different and doesn't update in the child form.
       */
      dispatchModal(
        <SeatFormModal
          x={x}
          y={y}
          hideModal={hideModal}
          isUnusedChecked={isUnusedChecked}
          room={room}
          currentStudentRegId={currentStudentRegId}
          selectedTestBookNumber={selectedTestBookNumber}
          swapStudentSeats={swapStudentSeats}
          updateRoom={updateRoom}
          updateStudent={updateStudent}
          setSavingStatus={setSavingStatus}
        />
      );
    };
  }
  // Global App state.
  const windowSize = useContext(ResizeContext);
  const dispatchModal = useContext(ModalDispatchContext);

  // Local state.
  const [localState, setLocalState] = React.useReducer(localStateReducer, {
    isSaving: false,
    isUnusedChecked: false,
    room,
    selectedStudentRegId: '',
    selectedTestBookNumber: '',
    slidesToShow: 1,
  });

  // Apollo.
  const [swapStudentSeats] = useMutation(SWAP_STUDENT_SEATS);
  const [updateRoom] = useMutation(UPDATE_ROOM);
  const [updateStudent] = useMutation(UPDATE_STUDENT);

  React.useEffect(() => {
    // Return the number of rows visible for any given viewport.
    function getSlideshowWidth(type) {
      const getSlides = {
        xs: function () {
          slides = 1;
        },
        sm: function () {
          slides = 1;
        },
        md: function () {
          slides = 4;
        },
        lg: function () {
          slides = room.shape.x < 6 ? room.shape.x : 6;
        },
        xl: function () {
          slides = room.shape.x < 6 ? room.shape.x : 6;
        },
        default: function () {
          slides = 6;
        },
      };

      let slides;

      // invoke it
      (getSlides[type] || getSlides['default'])();

      return slides;
    }

    setLocalState({
      slidesToShow: getSlideshowWidth(windowSize.prefix),
    });
  }, [windowSize.prefix, room.shape.x]);

  const slider = React.createRef();

  const seats = [];

  for (let x = 1; x <= room.shape.x; x++) {
    for (let y = room.shape.y; y >= 1; y--) {
      const currentStudent = find(
        room.students,
        (student) => student.assignedSeat && student.assignedSeat.y === y && student.assignedSeat.x === x
      );

      // Deterimine if the student exists, and is denied entry
      const isDenied = currentStudent && currentStudent.deniedEntry;

      // Is the room disabled?
      const isDisabled = room.unusedSeats && room.unusedSeats.filter((seat) => seat.y === y && seat.x === x).length > 0;

      seats.push(
        <div key={`Row-${y}-Seat-${x}`} className='seating-chart--seat'>
          <button
            data-row-info={`${
              (windowSize.prefix === 'xs' && y === 1) || (windowSize.prefix !== 'xs' && y === 1 && x === 1)
                ? 'Row '
                : ''
            }${windowSize.mobile || (!windowSize.mobile && x === 1) ? y : ''}`}
            data-seat-info={`${
              (windowSize.mobile && y === 1) || (!windowSize.mobile && y === 1 && x === 1) ? 'Seat ' : ''
            }${y === 1 ? x : ''}`}
            disabled={localState.isSaving}
            id={`${x}-${y}-seating-chart-seat`}
            onClick={handleSeatClick(x, y)}
            type='button'
            className={`
                ${localState.isSaving ? 'opacity--50' : ''}
                carousel-seat${currentStudent && !isDenied ? ' carousel-seat--occupied' : ''} ${
              isDisabled ? ' carousel-seat--unused' : ''
            }
            `}
          >
            {currentStudent ? (
              <React.Fragment>
                {/**
                 * The first span is cb-sr-only text that is succinct for screen readers only.
                 * The rest marked as presentation so the screen reader doesn't read it twice.
                 */}
                <span className='cb-sr-only'>
                  Row {y} Seat {x}: Seat is assigned to {formatStudentName(currentStudent, 'fml')}
                  {currentStudent.testBookNumber
                    ? `, whose test book serial number is ${padStart(`${currentStudent.testBookNumber}`, 6, '0')}`
                    : ''}
                  . Select to edit.
                </span>
                <span aria-hidden='true' role='presentation'>
                  {currentStudent.testBookNumber ? padStart(`${currentStudent.testBookNumber}`, 6, '0') : ''}
                  <br />
                </span>
                {
                  // Check if student was denied entry.
                  currentStudent.deniedEntry ? (
                    <React.Fragment>
                      {' '}
                      <Icon name='error' decorative />{' '}
                    </React.Fragment>
                  ) : null
                }{' '}
                {
                  <span aria-hidden='true' role='presentation'>
                    {getStudentInitials(currentStudent)}
                    {currentStudent.deniedEntry ? <span className='cb-sr-only'> Dismissed</span> : null}
                  </span>
                }
              </React.Fragment>
            ) : isDisabled ? (
              <span className='cb-sr-only'>
                Row {y} Seat {x}: Seat is marked as unused. Select to edit.
              </span>
            ) : (
              <span className='cb-sr-only'>
                Row {y} Seat {x}: Seat is unassigned. Select to assign a student or marked as unused.
              </span>
            )}
          </button>
        </div>
      );
    }
  }

  return (
    <div className='p-relative cb-white-bg'>
      <div data-tour='seating-grid' className={`seating-chart--table seating-chart--table__columns-${room.shape.x}`}>
        <Slider
          ref={slider}
          easing='linear'
          centerMode={false}
          centerPadding={'60px'}
          dots={false}
          infinite={false}
          nextArrow={<CustomArrow direction='right' />}
          prevArrow={<CustomArrow direction='left' />}
          rows={room.shape.y}
          slidesToShow={localState.slidesToShow}
          slidesToScroll={localState.slidesToShow > 1 ? localState.slidesToShow - 1 : 1}
          speed={300}
          swipe={false}
        >
          {seats}
        </Slider>
      </div>
    </div>
  );
}

SeatGrid.propTypes = {
  room: PropTypes.object,
};

export default SeatGrid;
