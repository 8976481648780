import React, { useReducer } from 'react';
import { PropTypes } from 'prop-types';
import { Button } from '@cb/apricot-react';
import './common.scss';
import { localStateReducer } from '../../../utils/common';

const buttonStyles = {
  primary: {
    variant: 'primary',
  },
  secondary: {
    variant: 'yellow',
  },
};

function ReloadButton({
  buttonStyle = 'primary',
  refetch = () => {},
  reloadButtonID = '',
  reloadTimeStampID = '',
  text = 'Reload',
}) {
  // Local state.
  const [localState, setLocalState] = useReducer(localStateReducer, {
    startSpinning: false,
  });

  function refreshData() {
    setLocalState({
      startSpinning: true,
    });
    refetch();
  }

  function stopSpin() {
    setLocalState({
      startSpinning: false,
    });
  }

  return (
    <Button
      aria-describedby={reloadTimeStampID}
      className={`w-100 ${localState.startSpinning ? 'refresh-icon--spin' : ''} refresh-icon`}
      icon='refresh'
      iconDecorative
      iconLeft
      id={reloadButtonID}
      onAnimationEnd={stopSpin}
      onClick={refreshData}
      small
      variant={buttonStyles[buttonStyle].variant}
    >
      <span>{text}</span>
    </Button>
  );
}

ReloadButton.propTypes = {
  buttonStyle: PropTypes.string,
  reloadButtonID: PropTypes.string,
  reloadTimeStampID: PropTypes.string,
  text: PropTypes.string,
  refetch: PropTypes.func,
};

export default ReloadButton;
