import React from 'react';
import { NumericIcon } from '@cb/apricot-react';
import TooltipWrapper from '../../../../../ui/tooltip/TooltipWrapper';

type AttendanceTooltipType = {
  linkName: string | React.ReactElement;
  descr: string | React.ReactElement;
};
type AttendanceStepType = {
  step: number;
  title: string;
  descr: string | React.ReactElement;
  tooltip?: AttendanceTooltipType;
};

const AttendanceStep: React.FC<AttendanceStepType> = ({ step, title, descr, tooltip }) => {
  const tooltipId = `directions-tooltip-${step}`;
  return (
    <>
      <div className='display-flex'>
        <div>
          <NumericIcon inverted>{step}</NumericIcon>
        </div>
        <h3 className='cb-paragraph6-st cb-margin-left-8'>{title}</h3>
      </div>
      <div className='cb-margin-top-16'>{descr}</div>
      {tooltip ? (
        <div className='cb-margin-top-8'>
          <a href='#0' id={tooltipId}>
            {tooltip.linkName}
          </a>
          <TooltipWrapper trigger={tooltipId}>{tooltip.descr}</TooltipWrapper>
        </div>
      ) : null}
    </>
  );
};

export default AttendanceStep;
