import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { localStateReducer } from '../../../../../utils/common';
import AdjustGridForm from './AdjustGridForm';
import DoorWrapper from './DoorWrapper';
import './SeatingChart.scss';
import { Icon, NumericIcon } from '@cb/apricot-react';

function SeatingChartWrapper({ room = {} }) {
  const [isAdjustSeatsVisible, setAdjustSeatsIsVisible] = useState<boolean>(false);
  const [isLegendVisible, setLegendIsVisible] = useState<boolean>(false);
  const adjustGridDivRef = useRef<HTMLDivElement>(null);
  const legendDivRef = useRef<HTMLDivElement>(null);

  function toggleAdjustSeatsVisibility() {
    setAdjustSeatsIsVisible(!isAdjustSeatsVisible);
  }
  // Refs.
  const adjustGridRef = React.createRef();

  // Local state.
  const [localState, setLocalState] = React.useReducer(localStateReducer, {
    room,
  });

  useEffect(() => {
    const handleAdjustGridOutsideClick = (event) => {
      if (adjustGridDivRef.current && !adjustGridDivRef.current.contains(event.target)) {
        setAdjustSeatsIsVisible(false);
      }
    };

    document.addEventListener('click', handleAdjustGridOutsideClick);

    return () => document.removeEventListener('click', handleAdjustGridOutsideClick);
  }, []);

  useEffect(() => {
    const handleLegendOutsideClick = (event) => {
      if (legendDivRef.current && !legendDivRef.current.contains(event.target)) {
        setLegendIsVisible(false);
      }
    };

    document.addEventListener('click', handleLegendOutsideClick);

    return () => document.removeEventListener('click', handleLegendOutsideClick);
  }, []);

  const seatingChartLegendItems = [
    {
      icon: 'disc-alt',
      color: 'green3',
      text: 'Assigned',
    },
    {
      icon: 'x-mark',
      color: 'blue1',
      text: 'Unused',
    },
    {
      icon: 'disc-alt',
      color: 'purple1',
      text: 'Door',
    },
  ];

  return (
    <React.Fragment>
      <h3 className='tdtk-h3 mb-4 display-flex align-items-center'>
        <NumericIcon inverted className='cb-margin-right-8 flex-shrink-0' decorative>
          3
        </NumericIcon>
        <span className='cb-sr-only'>Step 3</span>
        <span>Select seats in the grid to assign them to students.</span>
      </h3>

      <h3 className='tdtk-h3 mb-4 display-flex align-items-center'>
        <NumericIcon inverted className='cb-margin-right-8 flex-shrink-0' decorative>
          4
        </NumericIcon>
        <span className='cb-sr-only'>Step 4</span>
        Optional: Adjust the grid to add or remove seats.
      </h3>

      <h3 className='tdtk-h3 mb-4 display-flex align-items-center'>
        <NumericIcon inverted className='cb-margin-right-8 flex-shrink-0' decorative>
          5
        </NumericIcon>
        <span className='cb-sr-only'>Step 5</span>
        <span>Click &ldquo;Submit Chart&rdquo; when you’re finished. That’s it!</span>
      </h3>

      <div className='row mb-2'>
        <div data-tour='adjust-grid' className='col-xs-6'>
          <div className='adjust-grid-form-popover' ref={adjustGridDivRef}>
            <a
              focus='true'
              aria-expanded={localState.adjustGridFormOpen}
              className='cb-text-icon-right cb-link-black'
              data-automation='adjust-grid-form-toggle'
              href='#0'
              id='adjustGridFormToggle'
              name='adjustGridFormToggle'
              onClick={toggleAdjustSeatsVisibility}
              role='button'
            >
              <span>Adjust Seats</span>
              <Icon name={localState.adjustGridFormOpen ? 'up' : 'down'} decorative />
            </a>
            {isAdjustSeatsVisible && (
              <div className='cb-popover cb-grid-form-popover' style={{ opacity: 1 }}>
                <div className='cb-popover-inner'>
                  <div className='cb-popover-content'>
                    <div className='tdtk-adjust-grid-form-content cb-popover-content'>
                      <AdjustGridForm
                        adjustGridRef={adjustGridRef}
                        toggleGridForm={toggleAdjustSeatsVisibility}
                        room={room}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='col-xs-6'>
          <div className='tdtk-legend text-right' ref={legendDivRef}>
            <a
              focus='true'
              href='#0'
              id='seating-chart-legend'
              className='cb-text-icon-right cb-link-black'
              data-automation='seating-chart-legend-toggle'
              role='button'
              aria-expanded='false'
              onClick={() => setLegendIsVisible(!isLegendVisible)}
            >
              <span>Legend</span>
              <Icon name='down' decorative />
            </a>
            {isLegendVisible && (
              <div className='cb-popover cb-grid-form-popover' style={{ opacity: 1, right: 0 }}>
                <div className='cb-popover-inner'>
                  <div className='cb-popover-content'>
                    <div className='tdtk-legend-data'>
                      <h3 className='tdtk-h3 tdtk-legend-title cb-sr-only'>Seating Chart Legend</h3>
                      {seatingChartLegendItems && seatingChartLegendItems.length ? (
                        <ul>
                          {seatingChartLegendItems.map((item) => (
                            <li className='d-flex align-items-center' key={`legend-full-${item.text}`}>
                              <Icon name={item.icon} color={item.color} className='cb-margin-right-8' decorative />
                              <span className='pr-3'>{item.text}</span>
                            </li>
                          ))}
                        </ul>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <DoorWrapper room={room} />
    </React.Fragment>
  );
}

SeatingChartWrapper.propTypes = {
  room: PropTypes.object,
};

export default SeatingChartWrapper;
