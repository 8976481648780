import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { formatDistance } from 'date-fns';
import { localStateReducer } from '../../../utils/common';

function Timestamps({ className = '', id = '', updatedAt }) {
  // Local state.
  const [localState, setLocalState] = React.useReducer(localStateReducer, {
    updatedAt,
  });
  useEffect(() => {
    setLocalState({
      updatedAt,
    });
  }, [updatedAt]);

  let timestampRefresher;

  function startTimerRefresher() {
    timestampRefresher = setTimeout(function () {
      setLocalState({
        updatedAt,
      });
    }, 30 * 1000); // Refresh the state every 30 seconds to catch any database updates.
  }

  function stopTimerRefresher() {
    clearTimeout(timestampRefresher);
  }

  // Update the timestamp every minute so the ui shows an accurate relative time.
  useEffect(() => {
    startTimerRefresher();

    return stopTimerRefresher;
  });
  const lastUpdatedStr = `Last updated ${formatDistance(new Date(localState.updatedAt), new Date(), {
    addSuffix: true,
  })}`;
  return (
    // Don't show if we don't even have a timestamp.
    !!updatedAt && (
      <div className={className} id={id}>
        {lastUpdatedStr}
      </div>
    )
  );
}

// Validate props.
Timestamps.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  updatedAt: PropTypes.string,
};

export default Timestamps;
