import React from 'react';
import { localStateReducer } from '../../../utils/common';
import { useSocketContext } from '../../common/network/SocketProvider';
import { YellowButton } from '@cb/apricot-react';
import {
  RoomDirectoryWebsocketData,
  PhotoRostersWebsocketData,
  SiteSignInTicketsWebsocketData,
  RoomSignInTicketsWebsocketData,
  WEBSOCKET_MESSAGE_TYPE,
  StudentDataExportWebsocketData,
} from '../../common/network/types';

const REQUEST_TIMEOUT_IN_SECONDS = 5 * 60 * 1_000; // 5 minutes (ref: lambda_timeout_signintickets_and_photo_rosters)

export default function RendezvousLinkButton({
  className = '',
  icon,
  onRequest,
  onResolve,
  onFailure,
  onTimeout,
  messageType,
  resultReady,
  children,
  dataAutomation,
}: {
  messageType:
    | WEBSOCKET_MESSAGE_TYPE.SITE_SIGN_IN_TICKETS
    | WEBSOCKET_MESSAGE_TYPE.ROOM_SIGN_IN_TICKETS
    | WEBSOCKET_MESSAGE_TYPE.PHOTO_ROSTER
    | WEBSOCKET_MESSAGE_TYPE.STUDENT_DATA_EXPORT
    | WEBSOCKET_MESSAGE_TYPE.ROOM_DIRECTORY;
  className: string;
  resultReady: string;
  onRequest: () => Promise<{ rendezvous: string }>;
  onResolve: ({ rendezvous }: { rendezvous: string }) => Promise<{ resolveUrl: string; exists: boolean }>;
  onFailure: ({ rendezvous }: { rendezvous?: string }) => Promise<void>;
  onTimeout: ({ rendezvous }: { rendezvous: string }) => Promise<void>;
  children: React.ReactNode;
  dataAutomation?: string;
  icon: string;
}) {
  const [localState, setLocalState] = React.useReducer(localStateReducer, {
    resolveUrl: '',
    rendezvous: '',
    requesting: 0,
  });

  const { lastJsonMessage } = useSocketContext();

  React.useEffect(() => {
    if (lastJsonMessage) {
      const match:
        | RoomDirectoryWebsocketData
        | PhotoRostersWebsocketData
        | SiteSignInTicketsWebsocketData
        | RoomSignInTicketsWebsocketData
        | StudentDataExportWebsocketData
        | undefined = lastJsonMessage.find(
        (m) => m.t === messageType && m.d.rendezvousCode === localState.rendezvous
      ) as
        | RoomDirectoryWebsocketData
        | PhotoRostersWebsocketData
        | SiteSignInTicketsWebsocketData
        | RoomSignInTicketsWebsocketData
        | StudentDataExportWebsocketData
        | undefined;
      if (match) {
        (async ({ d: { status, rendezvousCode } }) => {
          try {
            if (status === resultReady) {
              if (onResolve) {
                const { resolveUrl, exists } = (await onResolve({ rendezvous: rendezvousCode })) || {};
                if (resolveUrl && exists) {
                  clearTimeout(localState.requesting);
                  setLocalState({
                    resolveUrl: resolveUrl,
                    rendezvous: '',
                    requesting: 0,
                  });
                  return;
                } else {
                  if (onFailure) {
                    await onFailure({ rendezvous: rendezvousCode });
                  }
                }
              }
            } else {
              if (onFailure) {
                await onFailure({ rendezvous: rendezvousCode });
              }
            }
          } catch {
          } finally {
            clearTimeout(localState.requesting);
          }
          setLocalState({
            resolveUrl: '',
            rendezvous: '',
            requesting: 0,
          });
        })(match);
      }
    }
  }, [lastJsonMessage, localState.rendezvous, localState.requesting, messageType, resultReady, onResolve, onFailure]);

  return (
    <YellowButton
      small
      className={className}
      icon={icon}
      iconDecorative
      data-automation={dataAutomation}
      onClick={async (e) => {
        e && e.preventDefault && e.preventDefault();
        try {
          if (onRequest) {
            const { rendezvous } = (await onRequest()) || {};
            setLocalState({
              requesting: setTimeout(async () => {
                try {
                  if (onResolve) {
                    const { resolveUrl, exists } = (await onResolve({ rendezvous: localState.rendezvous })) || {};
                    if (resolveUrl && exists) {
                      setLocalState({
                        resolveUrl: resolveUrl,
                        rendezvous: '',
                        requesting: 0,
                      });
                      return;
                    }
                  }
                } catch {}
                if (onTimeout) {
                  try {
                    await onTimeout({ rendezvous: localState.rendezvous });
                  } catch {}
                }
                setLocalState({
                  resolveUrl: '',
                  rendezvous: '',
                  requesting: 0,
                });
              }, REQUEST_TIMEOUT_IN_SECONDS),
              rendezvous: rendezvous,
            });
          }
        } catch {
          clearTimeout(localState.requesting);
          setLocalState({
            resolveUrl: '',
            rendezvous: '',
            requesting: 0,
          });
          if (onFailure) {
            await onFailure({});
          }
        }
      }}
    >
      {children}
    </YellowButton>
  );
}
