import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { localStateReducer, sortItems } from '../../../../../utils/common';
import { getAllTestBookNumbers, isTestNumberUnused } from '../utils';
import TestBookRange from './TestBookRange';
import { useMutation } from '@apollo/client';
import { UPDATE_ROOM } from '../../../../../apollo/mutations';
import { GET_ROOM } from '../../../../../apollo/queries';
import { ModalDispatchContext } from '../../../../ui/modal/ModalContext';
import { usePreviousValue } from '../../../../../constants/usePreviousValue';
import TestBookRangeConfirmationModal from './modals/TestBookRangeConfirmationModal';
import { NumericIcon } from '@cb/apricot-react';

function Container({ room = {} }) {
  function addSerial() {
    return async function () {
      await setLocalState({
        testBookRanges: [...localState.testBookRanges],
      });

      saveSerials();
    };
  }

  const dispatchModal = useContext(ModalDispatchContext);

  function editSerial(i, pos = 'start') {
    return async function (e) {
      // Only allow a 0-9, 6 digits precisely.
      const testValidation = new RegExp(/^[0-9]{6,6}$/);
      let enableAdd = false;
      let numberList = [];
      let val;

      if (e && e.target && i >= 0) {
        val = e.target.value || null;
        numberList =
          localState.testBookRanges && localState.testBookRanges.length ? [...localState.testBookRanges] : [];

        // Remove letters.
        val = val ? val.replace(/\D/, '') : '';

        // Only allow max 6 digits.
        if (val && val.length > 6) {
          val = val.slice(0, 6);
        }

        // Replace the array with the new value.
        numberList[i] = {
          ...numberList[i],
          [pos]: val,
          [`err${pos}`]: !testValidation.test(val) ? 'Serial number must be 6 digits.' : '',
        };

        if (
          numberList[i].start &&
          numberList[i].end &&
          parseInt(numberList[i].end, 10) < parseInt(numberList[i].start, 10)
        ) {
          // Ensure this number is greater than the previous number.
          numberList[i]['errend'] = 'Last serial number can’t be lower than the first serial number.';
        }

        if (
          numberList[i].start &&
          numberList[i].end &&
          parseInt(numberList[i].end, 10) - parseInt(numberList[i].start, 10) > 29
        ) {
          numberList[i]['errend'] = 'The difference between the two serial numbers must be 30 or less.';
        }

        if (
          numberList[i].start &&
          numberList[i].end &&
          parseInt(numberList[i].end, 10) - parseInt(numberList[i].start, 10) <= 29 &&
          parseInt(numberList[i].end, 10) - parseInt(numberList[i].start, 10) > 0 &&
          testValidation.test(numberList[i].end)
        ) {
          numberList[i]['errend'] = '';
        }

        // Enable Save button if valid.
        if (
          !numberList[i]['errstart'] &&
          !numberList[i]['errend'] &&
          numberList[i]['start'] &&
          numberList[i]['end'] &&
          !isNaN(parseInt(numberList[i]['start'], 10)) &&
          !isNaN(parseInt(numberList[i]['end'], 10)) &&
          numberList[i]['end'] - numberList[i]['start'] <= 30
        ) {
          enableAdd = true;
        }

        await setLocalState({
          enableAdd,
          testBookRanges: [...numberList],
        });

        // If they are editing, auto-save their changes only if the fields are valid.
        if (enableAdd && i !== 0) {
          saveSerials();
        }
      }
    };
  }

  function removeSerial(i) {
    return async function () {
      const numberList = [...localState.testBookRanges];

      // Get rid of whatever is up in the "Add" row.
      numberList[0] = {
        errstart: '',
        errend: '',
        start: '',
        end: '',
      };

      if (i >= 0 && i < numberList.length) {
        numberList.splice(i, 1);
      }

      await setLocalState({
        testBookRanges: [...numberList],
      });

      saveSerials();
    };
  }

  function saveSerials() {
    setLocalState({
      loading: true,
    });

    // Take the current list, format it for saving to the db.
    const serialGroup = sortItems(
      [
        ...localState.testBookRanges
          .filter(
            (tbr) =>
              tbr.start &&
              !tbr.errstart &&
              !isNaN(parseInt(tbr.start, 10)) &&
              tbr.end &&
              !tbr.errend &&
              !isNaN(parseInt(tbr.end, 10))
          )
          .map((tbr) => ({
            start: parseInt(tbr.start, 10),
            end: parseInt(tbr.end, 10),
          })),
      ],
      [
        {
          name: 'start',
          order: 'asc',
        },
      ]
    );

    try {
      updateRoom({
        variables: {
          input: {
            id: room.id,
            shape: room.shape
              ? {
                  x: room.shape.x || 5,
                  y: room.shape.y || 6,
                }
              : {
                  x: 5,
                  y: 6,
                },
            testBookRanges: serialGroup,
            seatingChartPDFCurrent: false,
            seatingStatus: 'drafted',
          },
        },
        optimisticResponse: {
          updateRoom: {
            id: room.id,
            capacity: room.capacity,
            created: new Date().getTime(),
            doors: room.doors,
            groupTypes: room.groupTypes,
            joinCode: room.joinCode,
            seatingStatus: 'drafted',
            shape: {
              ...room.shape,
              __typename: 'Shape',
            },
            startPin: room.startPin,
            status: room.status,
            staff: room.staff,
            students: room.students,
            testBookRanges: [
              ...serialGroup.map((range) => ({
                start: range.start,
                end: range.end,
                __typename: 'TestingBookRangeType',
              })),
            ],
            title: room.title,
            unusedSeats: room.unusedSeats,
            updated: new Date().getTime(),
            __typename: 'RoomType',
          },
        },
      }).then(() => {
        setLocalState({
          loading: false,
        });

        if (localState.testBookRanges.filter((a) => a.start).length > prevTestBookRanges.length) {
          dispatchModal(<TestBookRangeConfirmationModal />);
        }
      });
    } catch (e) {
      console.error('Saving serial group failed.', e);

      setLocalState({
        loading: false,
      });
    }
  }

  // Local state.
  const [localState, setLocalState] = React.useReducer(localStateReducer, {
    enableAdd: false,
    students: [...room.students],
    testBookRanges:
      room.testBookRanges && room.testBookRanges.length
        ? [
            {
              end: '',
              errend: '',
              errstart: '',
              start: '',
            },
            ...room.testBookRanges,
          ]
        : [
            {
              end: '',
              errend: '',
              errstart: '',
              start: '',
            },
          ],
  });

  const prevTestBookRanges = usePreviousValue(room.testBookRanges);

  // Apollo.
  const [updateRoom] = useMutation(UPDATE_ROOM, {
    update(cache, { data: { updateRoom } }) {
      const readRoom = cache.readQuery({
        query: GET_ROOM,
        variables: {
          input: { id: room.id },
        },
      });

      cache.writeQuery({
        query: GET_ROOM,
        data: {
          readRoom: { ...readRoom, ...updateRoom },
        },
      });
    },
  });

  React.useEffect(() => {
    setLocalState({
      enableAdd: false,
      students: [...room.students],
      testBookRanges:
        room.testBookRanges && room.testBookRanges.length
          ? [
              {
                end: '',
                errend: '',
                errstart: '',
                start: '',
              },
              ...room.testBookRanges,
            ]
          : [
              {
                end: '',
                errend: '',
                errstart: '',
                start: '',
              },
            ],
    });
  }, [room.students, room.testBookRanges]);

  return (
    <React.Fragment>
      <h3 className='tdtk-h3 pb-0 mb-0 d-flex align-items-center'>
        <NumericIcon inverted className='cb-margin-right-8 flex-shrink-0' decorative>
          1
        </NumericIcon>
        <span className='cb-sr-only'>Step 1</span>
        <span>Enter one test book range for each package of test books.</span>
      </h3>
      <p className='box-indent mb-4'>Serial numbers are 6 digits and shown at the top of the front cover.</p>
      <div className='box-card border__rounded-full shadow col-md-10 col-lg-9 box-indent mr-auto'>
        {/**
         * First field is always for adding.
         */}
        <div className='mb-4'>
          <h4 className='tdtk-h4 ml-2 pb-2'>Add a test book range:</h4>
          <TestBookRange
            addSerial={addSerial}
            editSerial={editSerial}
            enabled={localState.enableAdd}
            end={localState.testBookRanges[0].end}
            errend={localState.testBookRanges[0].errend}
            errstart={localState.testBookRanges[0].errstart}
            i={0}
            isAdd={true}
            isValid={localState.testBookRanges[0].isValid}
            key='testbook-serial-0'
            loading={localState.loading}
            removeSerial={removeSerial}
            start={localState.testBookRanges[0].start}
          />
        </div>

        <h4 className='tdtk-h4 ml-2 pb-2'>Your test book ranges:</h4>

        {localState.testBookRanges.length > 1 ? null : (
          <p className='ml-2 pb-3' id='text-book-form-empty'>
            Enter a test book range above and click &ldquo;Add This Range.&rdquo;
          </p>
        )}

        {
          /**
           * Rest of the fields are for editing.
           */
          localState.testBookRanges.slice(1).map((rangeRow, i) => {
            // Find all numbers in this particular range to compare to students assigned to book numbers.
            const fullTestBookRange = getAllTestBookNumbers([
              {
                start: localState.testBookRanges[i + 1].start,
                end: localState.testBookRanges[i + 1].end,
              },
            ]);

            const isUnused = isTestNumberUnused(
              fullTestBookRange,
              room && room.students && room.students.length ? room.students : []
            );

            return (
              <TestBookRange
                addSerial={addSerial}
                editSerial={editSerial}
                enabled={isUnused}
                end={rangeRow.end}
                errend={rangeRow.errend}
                errstart={rangeRow.errstart}
                i={i + 1}
                isAdd={false}
                isValid={rangeRow.isValid}
                key={`testbook-serial-${i + 1}`}
                loading={localState.loading}
                removeSerial={removeSerial}
                start={rangeRow.start}
              />
            );
          })
        }
      </div>
    </React.Fragment>
  );
}

Container.propTypes = {
  room: PropTypes.object,
};

Container.displayName = 'TestBookRangeContainer';

export default Container;
