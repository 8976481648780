import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import ModalDialogWrapper from '../../../../../ui/modal/ModalDialogWrapper';
import { ModalDispatchContext } from '../../../../../ui/modal/ModalContext';
import SeatAssignForm from '../SeatAssignForm';

function SeatFormModal({
  x,
  y,
  hideModal,
  isUnusedChecked,
  room,
  currentStudentRegId,
  selectedTestBookNumber,
  swapStudentSeats,
  updateRoom,
  updateStudent,
  setSavingStatus,
}) {
  function handleClose() {
    dispatchModal(null);
  }

  // Modal Context.
  const dispatchModal = useContext(ModalDispatchContext);

  return (
    <ModalDialogWrapper
      modalId='SeatFormModal'
      onClose={handleClose}
      open
      title={`Row ${y} Seat ${x}`}
      variant='success'
    >
      <SeatAssignForm
        hideModal={hideModal}
        isUnusedChecked={isUnusedChecked}
        key='seat-assign-form'
        room={room}
        selectedStudentRegId={currentStudentRegId}
        selectedTestBookNumber={selectedTestBookNumber}
        swapStudentSeats={swapStudentSeats}
        updateRoom={updateRoom}
        updateStudent={updateStudent}
        setSavingStatus={setSavingStatus}
        x={x}
        y={y}
      />
    </ModalDialogWrapper>
  );
}

SeatFormModal.propTypes = {
  currentStudentRegId: PropTypes.string,
  hideModal: PropTypes.func,
  isUnusedChecked: PropTypes.bool,
  room: PropTypes.object,
  selectedTestBookNumber: PropTypes.string,
  setSavingStatus: PropTypes.func,
  swapStudentSeats: PropTypes.func,
  updateRoom: PropTypes.func,
  updateStudent: PropTypes.func,
  x: PropTypes.number,
  y: PropTypes.number,
};

export default SeatFormModal;
