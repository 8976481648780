import React from 'react';
import { Checkbox as Ap4Checkbox } from '@cb/apricot-react';
import { ICheckboxProps } from './types';

const Checkbox: React.FC<ICheckboxProps> = ({ errorMessage, ...props }) => (
  <Ap4Checkbox
    labelClassName='tdtk-checkbox-label'
    validation={errorMessage ? 'error' : undefined}
    validationMsg={errorMessage ? errorMessage : ''}
    {...props}
  />
);

export default Checkbox;
