import React from 'react';
import { Select as Ap4Select } from '@cb/apricot-react';
import { ISelectProps } from './types';

const Select: React.FC<ISelectProps> = ({ additionalInstructions, errorMessage = '', id, name, ...props }) => (
  <>
    {additionalInstructions && (
      <p className='mb-0' id={`${name}-additionalLabel`}>
        {additionalInstructions}
      </p>
    )}
    <Ap4Select
      id={id}
      name={name}
      selectId={id}
      validation={errorMessage ? 'error' : undefined}
      validationMsg={errorMessage ? errorMessage : ''}
      {...props}
    />
  </>
);

export default Select;
