// React polyfills.
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import '@cb/apricot/CBBase';
import '@cb/apricot/CBGrid';
import '@cb/apricot/CBCard';

import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import '@cbw/widgets';
import '@cb/athena-widgets-types';

import './styles/variables.scss';
import './styles/bootstrap-4-utilities.scss';
import './styles/index.scss';

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.Fragment>
    <cbw-header
      app-id='378'
      program-type='corporate'
      site-title='Test Day Toolkit'
      use-icon-logo='false'
      skip-target-id='main-content'
      use-cb-logo-link='false'
      use-global-navigation='false'
      use-search-widget='false'
      site-type='testday'
    />
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.Fragment>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
