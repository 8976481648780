import { useState } from 'react';
import { getAuthTokens } from './authUtil';

export const getAuthSession = () => window.cb?.core?.iam?.getAuthSession();

export const getCatapultId = () => window.cb?.core?.iam?.getTempAWSCredsSubject();

export const parseJWTToken = () => {
  const { authorization } = getAuthTokens();
  if (!authorization) {
    return null;
  }
  const base64Url = authorization.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
};

export const useAuth = () => {
  const bus = window.cb?.core?.iam?.getEventBus();
  const { Logout, AWSLogin, AWSLoginFailed } = window.cb?.core?.iam?.events || {};
  const hasLoginCookie = document.cookie.includes('cb_login');
  const hasAWSCreds = Boolean(getCatapultId());
  const [auth, setAuth] = useState({
    loading: hasLoginCookie && !hasAWSCreds, // user is logged in, but temp-aws-creds is still fetching
    loggedIn: hasAWSCreds,
    loggedOut: !hasLoginCookie && !hasAWSCreds,
    error: false,
  });

  bus.on(Logout, () => setAuth({ loggedIn: false, loggedOut: true, error: false, loading: false }));
  bus.on(AWSLogin, () => setAuth({ loading: false, error: false, loggedIn: true, loggedOut: false }));
  bus.on(AWSLoginFailed, () => setAuth({ loading: false, loggedIn: true, error: true, loggedOut: false }));

  return auth;
};
