import { gql } from 'graphql-tag';
import { SirMutationFragment } from './fragments';

export const AUTO_ASSIGN = gql`
  mutation AutoAssignMutation {
    autoAssign
  }
`;

export const BULK_ASSIGN = gql`
  mutation BulkAssignStudentMutation($input: BulkUpdateStudentInput!) {
    bulkUpdateStudent(input: $input)
  }
`;

export const BULK_UNASSIGN = gql`
  mutation BulkUnassignStudentMutation($input: BulkUpdateStudentInput!) {
    bulkUpdateStudent(input: $input)
  }
`;

export const CREATE_SIR = gql`
  mutation createSir($input: CreateSirInput!) {
    createSir(input: $input) {
      ...SirMutationFragment
    }
  }
  ${SirMutationFragment}
`;

export const DELETE_SIR = gql`
  mutation deleteSir($input: DeleteSirInput!) {
    deleteSir(input: $input)
  }
`;

export const UPDATE_SIR = gql`
  mutation updateSir($input: UpdateSirInput!) {
    updateSir(input: $input) {
      ...SirMutationFragment
    }
  }
  ${SirMutationFragment}
`;

export const CREATE_ROOM = gql`
  mutation createRoom($input: CreateRoomInput!) {
    createRoom(input: $input) {
      capacity
      created
      groupTypes
      id
      staff {
        id
      }
      status
      students {
        id
      }
      title
      updated
    }
  }
`;

export const CREATE_ROOMS = gql`
  mutation bulkCreateRoom($input: [bulkCreateRoomInput!]!) {
    bulkCreateRoom(input: $input)
  }
`;

export const UPDATE_ROOM = gql`
  mutation UpdateRoomFormMutation($input: UpdateRoomInput!) {
    updateRoom(input: $input) {
      id
      capacity
      created
      doors
      groupTypes
      joinCode
      seatingStatus
      shape {
        x
        y
      }
      status
      staff {
        active
        confirmed
        email
        firstName
        id
        lastName
        phone
        role
        welcomed
      }
      startPin
      students {
        absent
        adultTestTakerFlag
        assignedSeat {
          x
          y
        }
        candDOB
        candFirstName
        candGender
        candLastName
        candMidInit
        candRegNo
        checkedInCenter
        checkedInRoom
        dapExamStatus {
          name
          timestamp
        }
        dapTestStatus {
          name
          payload {
            ... on DAPSectionStartTime {
              sectionName
            }
          }
          timestamp
        }
        deniedEntry
        grade8OrLowerFlag
        groupType
        id
        joinCode
        origGroupType
        photoRequiredFlag
        room {
          id
          groupTypes
          title
        }
        satIIListening
        accommodationsList {
          code
          supplementalInfo
        }
        startPin
        testBookNumber
        updated
        waitListFlag
      }
      testBookRanges {
        start
        end
      }
      title
      unusedSeats {
        x
        y
      }
      updated
    }
  }
`;

export const DELETE_ROOM = gql`
  mutation DeleteRoomFormMutation($input: DeleteRoomInput!) {
    deleteRoom(input: $input) {
      id
    }
  }
`;

export const CREATE_USER = gql`
  mutation createUser($input: CreateUserInput!) {
    createUser(input: $input) {
      active
      firstName
      lastName
      confirmed
      welcomed
      email
      id
      phone
      role
      room {
        id
        title
      }
      username
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      active
      confirmed
      welcomed
      email
      firstName
      id
      lastName
      phone
      role
      room {
        id
      }
      lastLogonDatetime
    }
  }
`;

export const BULK_STATUS = gql`
  mutation BulkUpdateUser($input: BulkUpdateUserInput!) {
    bulkUpdateUser(input: $input) {
      ids
      operation
    }
  }
`;

export const UPDATE_USERS = gql`
  mutation updateUsers($input: UpdateUsersInput!) {
    updateUsers(input: $input) {
      active
      confirmed
      welcomed
      email
      firstName
      id
      lastName
      phone
      role
      room {
        id
      }
      lastLogonDatetime
    }
  }
`;

export const UPDATE_STUDENT = gql`
  mutation updateStudent($input: UpdateStudentInput!) {
    updateStudent(input: $input) {
      id
      assignedSeat {
        x
        y
      }
      checkedInCenter
      checkedInRoom
      absent
      deniedEntry
      joinCode
      room {
        id
      }
      specialUseOnly
      startPin
      testBookNumber
    }
  }
`;

export const SWAP_STUDENT_SEATS = gql`
  mutation swapStudentSeats($studentA: UpdateStudentInput!, $studentB: UpdateStudentInput!) {
    studentA: updateStudent(input: $studentA) {
      id
      assignedSeat {
        x
        y
      }
      checkedInCenter
      checkedInRoom
      absent
      deniedEntry
      room {
        id
      }
      testBookNumber
    }
    studentB: updateStudent(input: $studentB) {
      id
      assignedSeat {
        x
        y
      }
      checkedInCenter
      checkedInRoom
      absent
      deniedEntry
      room {
        id
      }
      testBookNumber
    }
  }
`;

export const UPDATE_STUDENTS = gql`
  mutation updateStudents($input: UpdateStudentsInput!) {
    updateStudents(input: $input) {
      id
      assignedSeat {
        x
        y
      }
      checkedInCenter
      checkedInRoom
      absent
      deniedEntry
      room {
        id
      }
      testBookNumber
    }
  }
`;

export const UPDATE_STUDENT_ESSAY = gql`
  mutation UpdateStudentEssayMutation($input: UpdateStudentInput!) {
    updateStudent(input: $input) {
      id
      groupType
      room {
        id
      }
    }
  }
`;

export const RESEND_EMAIL = gql`
  mutation ResendEmail($input: ResendEmailUserInput!) {
    resendEmailUser(input: $input)
  }
`;

export const BULK_IMPORT_USERS = gql`
  mutation bulkImportUsers($input: bulkCopyUserInput!) {
    bulkCopyUser(input: $input)
  }
`;

export const BULK_REMOVE_USERS = gql`
  mutation bulkRemoveUser($input: bulkRemoveUserInput!) {
    bulkRemoveUser(input: $input)
  }
`;

export const RESET_SITE = gql`
  mutation resetSite($input: ResetSiteInput!) {
    resetSite(input: $input)
  }
`;

export const GENERATE_PDF = gql`
  mutation generatePDF($input: PDFGenerateInput!) {
    generatePDF(input: $input)
  }
`;

export const GENERATE_SIGN_IN_TICKET = gql`
  mutation generateSignInTicket($input: SignInTicketGenerateInput!) {
    generateSignInTicket(input: $input)
  }
`;

export const CREATE_CLOSURE = gql`
  mutation createClosure($input: ClosureTypeInput!) {
    createClosure(input: $input) {
      id
    }
  }
`;

export const TRIGGER_PHOTO_ROSTER_GENERATOR = gql`
  mutation triggerPhotoRosterGenerator {
    triggerPhotoRosterGenerator {
      rendezvousCode
    }
  }
`;

export const RETRIEVE_PHOTO_ROSTER = gql`
  mutation retrievePhotoRoster($input: RetrievePhotoRosterInput!) {
    retrievePhotoRoster(input: $input) {
      rosterSignedUrl
      exists
    }
  }
`;

export const TRIGGER_SIGN_IN_TICKETS = gql`
  mutation triggerSignInTickets($input: SignInTicketGenerateInput!) {
    triggerSignInTickets(input: $input) {
      rendezvousCode
      fileName
    }
  }
`;

export const RETRIEVE_SIGN_IN_TICKETS = gql`
  mutation retrieveSignInTickets($input: RetrieveSignInTicketsInput!) {
    retrieveSignInTickets(input: $input) {
      rosterSignedUrl
      exists
    }
  }
`;

export const TRIGGER_DATA_EXPORT = gql`
  mutation triggerDataExport($input: DataExportInput!) {
    triggerDataExport(input: $input) {
      rendezvousCode
      fileName
    }
  }
`;

export const RETRIEVE_DATA_EXPORT = gql`
  mutation retrieveDataExport($input: RetrieveDataExportInput!) {
    retrieveDataExport(input: $input) {
      rosterSignedUrl
      exists
    }
  }
`;

export const TRIGGER_GENERATE_ROOM_DIRECTORY = gql`
  mutation triggerRoomDirectory($input: RoomDirectoryInput!) {
    triggerRoomDirectory(input: $input) {
      rendezvousCode
      fileName
    }
  }
`;

export const RETRIEVE_GENERATE_ROOM_DIRECTORY = gql`
  mutation retrieveRoomDirectory($input: RetrieveRoomDirectoryInput!) {
    retrieveRoomDirectory(input: $input) {
      roomDirectorySignedUrl
      exists
    }
  }
`;

export const CREATE_VOUCHER = gql`
  mutation createVoucher($input: CreateVoucherInput!) {
    createVoucher(input: $input) {
      id
      voucherSubmissionStatus
      updated
    }
  }
`;

export const UPDATE_VOUCHER = gql`
  mutation updateVoucher($input: UpdateVoucherInput!) {
    updateVoucher(input: $input) {
      id
      voucherSubmissionStatus
      updated
    }
  }
`;
