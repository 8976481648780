// Reference global cb library.
export const cb = window.cb || {
  apricot: {
    utils: {
      viewport: () => ({
        prefix: 'lg',
      }),
    },
  },
  core: {
    iam: {
      getAuthenticationToken: () => null,
      getAuthorizationToken: () => null,
      getAuthSession: () => ({ expireTimeInMS: 0 }),
      getEventBus: () => ({ _events: [] }),
    },
  },
  widgets: {
    GlobalHeader: () => null,
    Identity: () => null,
  },
};
