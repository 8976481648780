import React from 'react';
import PropTypes from 'prop-types';
import Accommodations from '../../../digital/students/roster/Accommodations';
import { AccommodationLookup } from '../../../common/accommodations/lookup';
import { formatStudentName } from '../../../../utils/common';
import { generateStatus } from '../../../common/students/utility';
import { groupTypes } from '../../../../constants/groupTypes';
import { hasAdminAccess } from '../../../../utils/user';
import { languageListeningTable } from '../../../../constants/languageListeningTable';
import { Link } from 'react-router-dom';
import { ResizeContext } from '../../../../context/ResizeContext';
import { useStateValue } from '../../../../context/AppContext';
import { generateGroupTypeCellStr } from '../../../../utils/groupTypeLabels';
import moment from 'moment';
import { Icon } from '@cb/apricot-react';
import IconStatus from '../../../ui/icons/IconStatus';

function StudentRosterRow({ handleCheckStudents = () => {}, options = {}, student = {} }) {
  // Global App state.
  const { orgEvent, user } = useStateValue();
  const windowSize = React.useContext(ResizeContext);
  const room = student.room || {};
  const { hiddenFields = {} } = options;
  const group = groupTypes[student.groupType];

  const statusClasses = generateStatusClasses(student);
  const accommodations = generateAccommodationList(student.accommodationsList);
  const checkId = `bulk-check-${student.id}`;
  const checkName = `bulk-check[${student.id}]`;
  const linkId = `student-details-${student.id}`;

  function checkStudent(studentId) {
    return function () {
      handleCheckStudents([studentId]);
    };
  }

  function generateStatusClasses(student) {
    let statusColor = undefined;
    let statusBackground = '';
    // Determine which class to use for Student Status.
    if (student.checkedInRoom) {
      statusBackground = 'status--checked-in-to-room';
      statusColor = 'green3';
    } else if (student.checkedInCenter) {
      statusBackground = 'status--checked-in-to-center';
      statusColor = 'orange1';
    } else if (student.absent) {
      statusColor = 'red1';
    } else if (student.deniedEntry) {
      statusBackground = 'status--denied-entry';
      statusColor = 'gray2';
    }
    return {
      statusBackground,
      statusColor,
    };
  }

  function generateAccommodationList(accommodations = []) {
    return AccommodationLookup(accommodations, 'cb').map((item, index) => {
      return (
        <li key={`accommodations_${index}`}>
          <Accommodations item={item} studentId={student?.id} />
        </li>
      );
    });
  }

  function mobileComponent() {
    return (
      <li className={statusClasses.statusBackground}>
        <Link id={linkId} to={'/students/get/' + student.id} style={{ textDecoration: 'none' }}>
          <div className='tdtk-item-text'>
            <div className='tdtk-item-title cb-roboto-bold' style={{ textDecoration: 'none' }}>
              <div className='row'>
                <div className='col-xs-7'>
                  <div className='row'>{formatStudentName(student)}</div>
                  <div className='row text--color-black text--weight-normal'>
                    {student.id}
                    {student.waitListFlag === 'Y' && (
                      <React.Fragment>
                        <br />
                        Waitlisted
                      </React.Fragment>
                    )}
                  </div>
                </div>
                <div className='col-xs-5'>
                  <IconStatus color={statusClasses.statusColor} text={generateStatus(student).statusText} />
                </div>
              </div>
            </div>
          </div>
        </Link>
      </li>
    );
  }

  function desktopComponent() {
    return (
      <tr className={statusClasses.statusBackground}>
        {hasAdminAccess(user.rid, user.role) ? (
          <td>
            <label htmlFor={checkId}>
              <input
                checked={options.checked ? 'checked' : ''}
                id={checkId}
                name={checkName}
                onChange={checkStudent(student.id)}
                type='checkbox'
                value={student.id}
              />
              <span className='cb-sr-only'>Select {formatStudentName(student)} for a bulk operation</span>
            </label>
          </td>
        ) : null}
        <th scope='row'>
          <strong>
            <Link to={'/students/get/' + student.id}>{formatStudentName(student)}</Link>
          </strong>
          <br />
          {student?.candRegNo && <span> Reg. no.: {student?.candRegNo}</span>}
          {student.waitListFlag === 'Y' && (
            <React.Fragment>
              <br />
              <strong>Waitlisted</strong>
            </React.Fragment>
          )}
        </th>
        <td>
          {generateGroupTypeCellStr(group, student, orgEvent)}
          {student.groupType && student.satIIListening && languageListeningTable[student.satIIListening] && (
            <>
              <br />
              {`${languageListeningTable[student.satIIListening]}`}
            </>
          )}
        </td>
        <td>
          {accommodations.length > 0 ? (
            <ul
              className='cb-resource'
              style={{
                listStyle: 'disc',
                padding: '0 1rem 0 1rem',
              }}
            >
              {accommodations}
            </ul>
          ) : (
            'None'
          )}
        </td>
        {hiddenFields?.room ? null : (
          <td>{room.title ? <Link to={`/rooms/get/${room.id}`}>{room.title}</Link> : 'Unassigned'}</td>
        )}
        {hiddenFields?.seat ? null : (
          <td>
            {student.assignedSeat && student.assignedSeat.x && student.assignedSeat.y ? (
              <React.Fragment>
                Row {student.assignedSeat.y}
                <br />
                Seat {student.assignedSeat.x}
              </React.Fragment>
            ) : (
              'Unassigned'
            )}
          </td>
        )}
        <td>
          <IconStatus
            showIcon={student.status && student.status.statusText !== 'Not arrived'}
            color={statusClasses.statusColor}
            text={generateStatus(student).statusText}
          />
        </td>
        {hiddenFields?.updated ? null : (
          <td>
            {moment(student.updated || undefined)
              .local()
              .format('lll')}
          </td>
        )}
        {
          /**
           * @todo: MAKE DYNAMIC IF THIS IS DIGITAL.
           */
          student?.testing ? <td>Not started</td> : null
        }
      </tr>
    );
  }

  return windowSize.mobile ? mobileComponent() : desktopComponent();
}

StudentRosterRow.propTypes = {
  handleCheckItems: PropTypes.func,
  options: PropTypes.object,
  student: PropTypes.object,
};

export default StudentRosterRow;
