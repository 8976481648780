import {
  blueboxSectionNameFormatter,
  getDynamicBlueboxTestStatuses,
  getUniqueSectionNames,
  sectionRegex,
} from '../../../../constants/blueboxTestStatus';
import { DAP_STUDENT_TEST_STATUS } from '../../../../constants/bluebox';

function addOrIncrement(sectionName, dapTestStatuses) {
  return dapTestStatuses[sectionName] ? dapTestStatuses[sectionName] + 1 : 1;
}

/**
 * Find only the unique statuses for students for a particular room
 */
export function getUniqueStatuses(students, tdtkAsmtType) {
  const blueboxTestStatuses = getDynamicBlueboxTestStatuses(getUniqueSectionNames(students), tdtkAsmtType);
  return students.reduce((accum, student) => {
    const matchingEnum = blueboxTestStatuses.find((stat) => stat?.name === blueboxSectionNameFormatter(student));
    let sectionName = '';
    if (
      student?.dapTestStatus?.name === DAP_STUDENT_TEST_STATUS.SECTION_START_TIME &&
      student?.dapTestStatus?.payload?.sectionName?.match(sectionRegex)
    ) {
      sectionName = `${DAP_STUDENT_TEST_STATUS.SECTION_START_TIME}-${
        student?.dapTestStatus?.payload?.sectionName?.match(sectionRegex)[0]
      }`;
    } else {
      sectionName = matchingEnum?.sectionName ? matchingEnum?.sectionName : matchingEnum?.name;
    }

    if (sectionName) {
      // bin "app checkin" and "waiting-room-arrival" into "not started"
      if (
        sectionName === DAP_STUDENT_TEST_STATUS.EXAM_CHECKIN_STARTED ||
        sectionName === DAP_STUDENT_TEST_STATUS.WAITING_ROOM_ARRIVAL
      ) {
        accum[DAP_STUDENT_TEST_STATUS.NONE] = addOrIncrement(DAP_STUDENT_TEST_STATUS.NONE, accum);
      } else {
        accum[sectionName] = addOrIncrement(sectionName, accum);
      }
    } else if (student?.dapTestStatus && !sectionName) {
      // We have a student dapTestStatus, but we don't know what it is or the name is null.
      console.warn(`Unknown student status name, or blank: ${student?.candRegNo} `, student?.dapTestStatus);
    }
    return accum;
  }, {});
}
