export const appErrors = {
  'rooms:capacity_too_low': {
    message: 'The room capacity cannot be lower than the current number of assigned test takers for the room.',
    field: 'capacity',
  },
  'user:role_selection_invalid': {
    message: 'Test Day Toolkit does not allow someone with your role to make this selection.',
    field: 'role',
  },
  'user:too_many_proctors': {
    message: 'The room you chose already has a proctor.',
    field: 'role',
  },
};
