import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import ModalDialogWrapper from '../../../ui/modal/ModalDialogWrapper';
import { ModalDispatchContext } from '../../../ui/modal/ModalContext';
import ModalFooter from '../../../ui/modal/ModalFooter';
import { useNavigate } from 'react-router-dom';

export default function ConfirmationOfBulkAssign({ title = '' }) {
  const navigate = useNavigate();
  function handleClose() {
    dispatchModal(null);

    navigate(-1);
  }

  // Modal Context.
  const dispatchModal = useContext(ModalDispatchContext);

  return (
    <ModalDialogWrapper modalId='ConfirmationOfBulkAssign' onClose={handleClose} open title={title} variant='success'>
      <ModalFooter
        buttons={[
          {
            handler: handleClose,
            id: 'close-bulk-assign',
            style: 'yellow',
            text: 'Close',
          },
        ]}
      />
    </ModalDialogWrapper>
  );
}

ConfirmationOfBulkAssign.propTypes = {
  title: PropTypes.string,
};
