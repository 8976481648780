import { groupTypes } from '../../../constants/groupTypes';
import { formatter } from './formatter';
import { tableGenerator } from '../../../utils/pdfUtils';
import { formatStudentName } from '../../../utils/common';
import { groupTypesCheck } from '../../../utils/getGroupTypes';
import { formattedDate } from '../../../utils/date';

const _zebra = {
  fillColor: (rowIndex) => {
    return rowIndex % 2 === 0 ? '#CCCCCC' : null;
  },
};

// function _processGroupType(studentGroupType, display) {
function _processGroupType(studentGroupType, display) {
  if (!studentGroupType) {
    // Kill it right away if we have an issue with Group Type.
    return 'Unassigned';
  }
  const formattedStudentGroupType = {};

  // If groupTypes[studentGroupType] is undefined, create a formatted obj for groupTypesCheck fn
  const isInvalidGroupType = groupTypes[studentGroupType] === undefined;

  if (isInvalidGroupType) {
    formattedStudentGroupType[studentGroupType] = undefined;
  } else {
    formattedStudentGroupType[studentGroupType] = groupTypes[studentGroupType];
  }
  // Format the group type text based on the display.
  const checkedGroupType = groupTypesCheck(formattedStudentGroupType)[0];
  switch (display) {
    case 'long':
      return `${checkedGroupType.label} ${checkedGroupType.standard ? ' (standard)' : ' (accommodated)'}`;
    case 'short':
      if (isInvalidGroupType) {
        return `${checkedGroupType.label}`;
      }
      return `${checkedGroupType.label} ${groupTypes[studentGroupType]?.standard ? ' (standard)' : ' (accommodated)'}`;
    default:
      return 'Unassigned';
  }
}

function _processTestingGroups(studentGroupType) {
  const groupList = [];
  if (!studentGroupType) {
    // Kill it right away if we have an issue with Group Type.
    return 'Unassigned';
  }
  const isInvalidGroupType = groupTypes[studentGroupType] === undefined;

  studentGroupType.forEach(function (e) {
    if (isInvalidGroupType) {
      return groupList.push(e);
    }
    return groupList.push(
      `${groupTypes[e].title} ${groupTypes[e].standard ? ' (standard)' : ' (accommodated)'}${
        groupTypes[e].description ? '; ' : ''
      }${groupTypes[e].description}`
    );
  });
  return groupList.join('\n');
}

function _processAttendance(checkedInRoom, deniedEntry, absent) {
  let ret = '';
  /**
   * P = Admitted
   * X = Denied entry
   * A = Absent
   */
  if (checkedInRoom && !deniedEntry && !absent) {
    // If they checked into the room, they're most likely present.
    ret = 'P';
  } else if (deniedEntry) {
    // If they were denied entry.
    ret = 'X';
  } else if (absent) {
    // If they were absent.
    ret = 'A';
  }

  return ret;
}

function _processAccommodations(accommodations) {
  let ret = [];

  // Add accommodation numbers if needed.
  if (
    accommodations &&
    accommodations !== null &&
    Array.isArray(accommodations) &&
    typeof accommodations[0] === 'object' &&
    accommodations.length > 0
  ) {
    ret = accommodations?.map((item) => item.code).join(', ');
  } else {
    ret = 'None';
  }

  return ret;
}

export function generateStudentRoster(students) {
  let localStudents = [];
  const rows = [];
  const tables = [];

  // Let's sort it by Last Name and push it to a table.
  localStudents = formatter(students, 'student_roster_alphabetical');

  localStudents.forEach((student, index) => {
    rows.push([
      index + 1,
      formatStudentName(student),
      student.room && student.room.title !== 'Unassigned' ? student.room.title : 'Unassigned',
      student.waitListFlag && student.waitListFlag === 'Y' ? 'Y' : 'N',
    ]);
  });

  // Generate the room table.
  tables.push({
    layout: _zebra,
    style: 'table',
    table: {
      body: tableGenerator(
        // Headers.
        ['Count', 'Name', 'Room', 'Waitlisted'],
        // Rows.
        rows,
        // Specify bigger font.
        true
      ),
      dontBreakRows: true,
      headerRows: 1,
      widths: ['auto', '*', 'auto', 'auto'],
    },
  });

  return tables;
}

export const generateRoomRoster = (students) => {
  let dob = '';
  let filteredStudents = [];
  let localStudents = [];
  let roomGroupType = '';
  let roomId = '';
  let roomTitle = '';
  let rows = [];
  const tables = [];
  const usedRooms = {};

  // Sort the array by Room Title, etc.
  localStudents = formatter(students, 'student_roster_room');

  /**
   * Student roster tables.
   */
  localStudents.forEach((outerStudent) => {
    // The name of the current room.
    if (outerStudent.room) {
      roomId = outerStudent.room.id;
      roomTitle = outerStudent.room.title;
      roomGroupType = outerStudent.room.groupTypes;
    } else {
      roomId = 'Unassigned';
      roomTitle = 'Unassigned';
      roomGroupType = null;
    }

    // Only add this if we haven't done this room yet.
    if (usedRooms[roomId] === undefined) {
      // Let's filter by this room since we haven't done it yet.
      filteredStudents = localStudents.filter((item) => {
        return item.room ? item.room.id === roomId : roomId === 'Unassigned';
      });

      // Loop through students and prepare for PDF.
      filteredStudents.forEach((student, index) => {
        dob = formattedDate(student.candDOB);
        rows.push([
          // Count.
          index + 1,
          // Name.
          formatStudentName(student, 'l'),
          formatStudentName(student, 'fm'),
          // Registration #
          student.candRegNo || '-',
          // Group Type
          student.groupType || '-',
          // Date of Birth
          dob,
          // Gender
          student.candGender || '',
          // Photo Required
          student.photoRequiredFlag || '',
          // Grade 8 or Below
          student.grade8OrLowerFlag || '',
          // 21 or Over
          student.adultTestTakerFlag || '',
          // Accommodations
          _processAccommodations(student.accommodationsList),
          // Waitlisted
          student.waitListFlag,
          // Attendance
          _processAttendance(student.checkedInRoom, student.deniedEntry, student.absent),
          // Changed Essay
          // (student.origGroupType !== null) ? 'Y' : 'N',
        ]);
      });

      // Generate the room table.
      tables.push(
        {
          pageBreak: 'before',
          pageOrientation: 'landscape',
          style: 'h2',
          text: `Room: ${roomTitle}`,
        },
        {
          style: 'h2',
          columns: [
            {
              width: 90,
              text: 'Testing Groups:',
            },
            {
              width: 'auto',
              text: _processTestingGroups(roomGroupType),
            },
          ],
        },
        {
          layout: _zebra,
          style: 'table',
          table: {
            body: tableGenerator(
              // Headers.
              [
                '',
                'Last',
                'First M.I.',
                'Reg. no.',
                'Testing Group',
                'DOB',
                'Gender',
                'Photo Req.',
                'Grade 8 or Below',
                '21 or Over',
                'Accomms.',
                'Waitlisted',
                'Attendance',
              ],
              // Rows.
              rows
            ),
            dontBreakRows: true,
            headerRows: 1,
            widths: [
              'auto',
              'auto',
              '*',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
            ],
          },
        }
      );

      rows = [];
      usedRooms[roomId] = true;
    }
  });

  return tables;
};

export const generateFullRoster = (students) => {
  let dob = '';
  let localStudents = [];
  const rows = [];
  const tables = [];

  // Sort the array by Room Title, etc.
  localStudents = formatter(students, 'student_roster_full');

  /**
   * Student roster tables.
   */

  // Loop through students and prepare for PDF.
  localStudents.forEach((student, index) => {
    dob = formattedDate(student.candDOB);
    rows.push([
      // Count.
      index + 1,
      // Name.
      formatStudentName(student, 'l'),
      formatStudentName(student, 'fm'),
      // Registration #
      student.candRegNo || '-',
      // Room
      student.room ? student.room.title : 'Unassigned',
      // Group Type
      _processGroupType(student.groupType, 'short'),
      // Date of Birth
      dob,
      // Gender
      student.candGender || '',
      // Photo Required
      student.photoRequiredFlag || '',
      // Grade 8 or Below
      student.grade8OrLowerFlag || '',
      // 21 or Over
      student.adultTestTakerFlag || '',
      // Accommodations
      _processAccommodations(student.accommodationsList),
      // Waitlisted
      student.waitListFlag,
      // Attendance
      _processAttendance(student.checkedInRoom, student.deniedEntry, student.absent),
      // Changed Essay
      // (student.origGroupType !== null) ? 'Y' : 'N',
    ]);
  });

  // Generate the room table.
  tables.push({
    layout: _zebra,
    pageBreak: 'before',
    pageOrientation: 'landscape',
    style: 'table',
    table: {
      body: tableGenerator(
        // Headers.
        [
          '',
          'Last',
          'First M.I.',
          'Reg. no.',
          'Room',
          'Testing Group',
          'DOB',
          'Gender',
          'Photo Req.',
          'Grade 8 or Below',
          '21 or Over',
          'Accomms.',
          'Waitlisted',
          'Attendance',
        ],
        // Rows.
        rows
      ),
      dontBreakRows: true,
      headerRows: 1,
      widths: [
        'auto',
        'auto',
        '*',
        'auto',
        'auto',
        'auto',
        'auto',
        'auto',
        'auto',
        'auto',
        'auto',
        'auto',
        'auto',
        'auto',
      ],
    },
  });
  return tables;
};
