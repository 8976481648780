import React from 'react';
import { GET_STAFF_DETAIL, GET_STAFF } from '../../../../apollo/queries';
import QueryComponent from '../../../common/network/QueryComponent';
import StaffDetails from './StaffDetails';
import get from 'lodash/get';
import { ResizeProvider } from '../../../../context/ResizeContext';
import { useStateValue } from '../../../../context/AppContext';
import { useParams } from 'react-router-dom';

function Container() {
  // Global App state.
  const { orgEvent } = useStateValue();

  // local state
  const { id } = useParams();
  const input = { id };

  return (
    <div className={orgEvent?.dapInd ? 'container' : ''}>
      <h1 className='tdtk-h1 py-4' data-automation='heading-h1'>
        Test Day Staff
      </h1>

      <QueryComponent query={{ kind: 'GetStaffDetail', specification: GET_STAFF_DETAIL, variables: { input } }}>
        {(data) => {
          const staff = get(data, 'readUser') || {};

          if (staff && staff.id) {
            return (
              <ResizeProvider>
                <QueryComponent query={{ kind: 'GetStaff', specification: GET_STAFF }}>
                  {(staffData) => {
                    const allStaff = get(staffData, 'viewer.site.staff', []);
                    return <StaffDetails staff={staff} allStaff={allStaff} />;
                  }}
                </QueryComponent>
              </ResizeProvider>
            );
          } else {
            return <p className='mb-4'>Staff not found.</p>;
          }
        }}
      </QueryComponent>
    </div>
  );
}

Container.displayName = 'StaffDetails';

export default Container;
