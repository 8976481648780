import React from 'react';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import IrregularityRoster from './IrregularityRoster';
import { GET_SIRS } from '../../../../apollo/queries';
import QueryComponent from '../../network/QueryComponent';
import { useTitle } from '../../../../constants/useTitle';
import { useStateValue } from '../../../../context/AppContext';

function Container() {
  useTitle('Irregularities');
  const {
    orgEvent: { dapInd },
  } = useStateValue();

  return (
    <div className={`${dapInd ? 'container' : ''}`}>
      <h1 className='tdtk-h1 py-4' data-automation='heading-h1'>
        Irregularities
      </h1>

      <div className='row'>
        <div className='col-xs-12'>
          <p className='mb-4'>
            If something goes wrong, let us know what happened by submitting an irregularity report. We rely on your
            reports to make decisions&mdash;and we appreciate your help.
          </p>

          <p className='mb-4'>
            Anyone can create, save, and review reports, but only coordinators can submit them to the College Board.
          </p>

          <div className='mb-4'>
            <Link id='irs-add-report' to='/irregularities/add' className='cb-btn cb-btn-sm cb-btn-yellow'>
              Add Report
            </Link>
          </div>
        </div>
      </div>
      <QueryComponent query={{ kind: 'GetSirs', specification: GET_SIRS }}>
        {(data) => {
          const sirs = get(data, 'viewer.site.sirs');

          if (sirs) {
            return <IrregularityRoster irregularities={sirs} />;
          } else {
            return (
              <p className='mb-4'>No one at your test center has saved or submitted reports for this test date.</p>
            );
          }
        }}
      </QueryComponent>
    </div>
  );
}

export default Container;
