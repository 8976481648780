import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import Messaging from '../../../context/Messaging';
import { hasAdminAccess } from '../../../utils/user';
import { analyticsTracking } from '../../../utils/analytics';
import { UPDATE_USER } from '../../../apollo/mutations';
import { useStateValue } from '../../../context/AppContext';
import ClosureForm from '../centerClosure/Container';
import ClosureFormCompleted from '../centerClosure/ClosureFormCompleted';
import { useTakeTheTour } from '../../common/productTour';
import { ModalDispatchContext } from '../../ui/modal/ModalContext';
import OneButtonModal from '../../ui/modal/standard/OneButtonModal';

function Home() {
  const navigate = useNavigate();

  function handleShowAgain(e) {
    // If they don't want to see the modal, set the state to indicate it.
    showAgain = !e.target.checked;
    setShowAgainLocal(!e.target.checked);
  }

  function handleWelcomeState(urlToPush = '') {
    const input = {
      id: user.id,
      welcomed: true,
    };

    // Don't show the modal again this session.
    sessionStorage.setItem('wasWelcomed', true);

    // Only save their welcomed state if they checked "Don't Show Again"
    if (!showAgain) {
      updateUser({
        variables: { input },
      })
        .then(() => {
          setShowAgainLocal({
            showAgain: false,
          });
          // If they want to push to another URL, do so.
          if (urlToPush) {
            navigate(urlToPush);
          }
        })
        .catch((e) => {
          console.error('Unable to save user welcome.', e);
        });
    } else {
      // If they want to push to another URL, do so.
      if (urlToPush) {
        navigate(urlToPush);
      }
    }
  }

  // Global App state.
  const { orgEvent, user } = useStateValue();
  const dispatchModal = useContext(ModalDispatchContext);

  // Local State.
  const [showAgainLocal, setShowAgainLocal] = React.useState(true);

  // Apollo.
  const [updateUser] = useMutation(UPDATE_USER);

  // Use session storage to determine if they'd seen the welcome modal yet, if not, check their db record.
  const wasWelcomed = sessionStorage.getItem('wasWelcomed') ? sessionStorage.getItem('wasWelcomed') : user.welcomed;

  let showAgain = true;

  // Show modal only for coordinators or CBAdmin.
  if (hasAdminAccess(user.rid, user.role) && !wasWelcomed && showAgainLocal) {
    dispatchModal(
      <OneButtonModal
        body={
          <div>
            <div>
              <Messaging key='welcomeModal' section='welcomeModal' />
            </div>
            <div key='dontShowAgainDiv' style={{ marginLeft: '1rem', minHeight: '2rem' }}>
              <p className='mb-4'>
                <label htmlFor='dontShowAgain' key='dontShowAgainLabel' className='checkbox'>
                  <input key='dontShowAgain' id='dontShowAgain' type='checkbox' value='1' onClick={handleShowAgain} />{' '}
                  Don&rsquo;t show this to me again.
                </label>
              </p>
            </div>
          </div>
        }
        modalId='welcomeGetStarted'
        onClose={() => {
          handleWelcomeState();
        }}
        title={'How Test Day Toolkit Helps You'}
        buttonLabel='Get Started'
        variant='info'
      />
    );
  }

  const isAdmin = hasAdminAccess(user.rid, user.role);
  const mainBlockClasses = 'py-4 h-100 box-card shadow border__rounded-full border__gray';

  React.useEffect(() => {
    analyticsTracking(user.role, '', 'dashboard');
  }, [user.role]);

  // Check if they've already submitted a closure.
  const testCenterClosed = !!orgEvent?.closure?.id;
  const { isUserTourValid, tourButton } = useTakeTheTour();

  return isAdmin ? (
    <React.Fragment>
      <h2 className='tdtk-h2 py-4' id='test-admin-tools'>
        Test Administration Tools
      </h2>
      <div className='row'>
        <div className='col-md-8'>
          <div className='row '>
            <div className='col-md-12'>
              <div className='row'>
                <div className='col-md-6 hidden-md-up p-2'>
                  <div className={`${mainBlockClasses}`}>
                    <h2 className='tdtk-h4 mb-4'>
                      <Link className='cb-palette-blue2' to='/students'>
                        <strong>Check-In and Student Roster</strong>
                      </Link>
                    </h2>
                    <p>Check students in. View room assignments, accommodations, and other details.</p>
                  </div>
                </div>
                <div data-tour='testing-rooms' className='col-md-6 p-2'>
                  <div className={`${mainBlockClasses}`}>
                    <h2 className='tdtk-h4 mb-4'>
                      <Link className='cb-palette-blue2' to='/rooms' data-automation='testing-rooms-tile-link'>
                        <strong>Testing Rooms</strong>
                      </Link>
                    </h2>
                    <p>Add and edit rooms. View and adjust the list of assigned students.</p>
                  </div>
                </div>
                <div className='col-md-6 p-2'>
                  <div className={`${mainBlockClasses}`}>
                    <h2 className='tdtk-h4 mb-4'>
                      <Link className='cb-palette-blue2' to='/staff'>
                        <strong>Test Day Staff</strong>
                      </Link>
                    </h2>
                    <p>
                      Learn what your staff needs are, assign roles and rooms, and grant staff access to the toolkit.
                    </p>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-6 hidden-xs hidden-sm p-2'>
                  <div className={`${mainBlockClasses}`}>
                    <h2 className='tdtk-h4 mb-4'>
                      <Link className='cb-palette-blue2' to='/students'>
                        <strong>Student Roster and Check-In</strong>
                      </Link>
                    </h2>
                    <p>Check students in. View room assignments, accommodations, and other details.</p>
                  </div>
                </div>
                <div className='col-md-6 p-2'>
                  <div className={`${mainBlockClasses}`}>
                    <h2 className='tdtk-h4 mb-4'>
                      <Link className='cb-palette-blue2' to='/irregularities'>
                        <strong>Irregularities</strong>
                      </Link>
                    </h2>
                    <p>If something goes wrong, let us know what happened.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <h2 className='tdtk-h2 py-4'>Help and Support Tools</h2>

          <div className='row'>
            <div className='col-md-12'>
              <div className='row'>
                <div className='col-md-6 p-2'>
                  <div className={`${mainBlockClasses}`}>
                    <h2 className='tdtk-h4 mb-4'>
                      <Link className='cb-palette-blue2' to='/printable-rosters'>
                        <strong>Printable Student Rosters</strong>
                      </Link>
                    </h2>
                    <p>
                      Print room rosters for proctors, the final roster to return with your shipment, and a directory
                      for students.
                    </p>
                  </div>
                </div>
                <div className='col-md-6 p-2'>
                  <div className={`${mainBlockClasses}`}>
                    <h2 className='tdtk-h4 mb-4'>
                      <Link className='cb-palette-blue2' to='/vouchers'>
                        <strong>Voucher Data</strong>
                      </Link>
                    </h2>
                    <p>View the data you need to create ETS payment vouchers.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              <div className='row'>
                <div className='col-md-6 p-2'>
                  <div className={`${mainBlockClasses}`}>
                    <h2 className='tdtk-h4 mb-4'>
                      <Link className='cb-palette-blue2' to='/help'>
                        <strong>Help and Contact Info</strong>
                      </Link>
                    </h2>
                    <p>Find the answers you need.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className='col-md-4 p-2'
          style={{
            alignSelf: 'flex-start',
          }}
        >
          <div className={`${mainBlockClasses} border__corporate`}>
            {!testCenterClosed ? (
              <ClosureForm showFull={false} />
            ) : (
              <ClosureFormCompleted
                canSupportMakeup={orgEvent?.closure?.canSupportMakeup || ''}
                confirmationId={orgEvent?.closure?.confirmationId || ''}
                created={orgEvent?.closure?.created || ''}
                makeupDate={orgEvent?.closure?.makeupDate || ''}
                reason={orgEvent?.closure?.reason || ''}
                showFull={false}
              />
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <div className='cb-sr-only'>
        <h1 className='tdtk-h1 py-4' data-automation='heading-h1'>
          Home
        </h1>
      </div>

      {isUserTourValid && <div className='mt-3 text-right'>{tourButton}</div>}

      <div className='pt-2 row'>
        <div className='col-md-6 hidden-md-up p-2'>
          <div className={`${mainBlockClasses}`}>
            <h2 className='tdtk-h4 mb-4'>
              <Link className='cb-palette-blue2' to='/students'>
                <strong>Check-In and Student Roster</strong>
              </Link>
            </h2>
            <p>Check students in. View room assignments, accommodations, and other details.</p>
          </div>
        </div>
        <div data-tour='testing-rooms' className='col-md-6 p-2'>
          <div className={`${mainBlockClasses}`}>
            <h2 className='tdtk-h4 mb-4'>
              <Link className='cb-palette-blue2' to='/rooms' data-automation='testing-rooms-tile-link'>
                <strong>Testing Rooms</strong>
              </Link>
            </h2>
            <p>Add and edit rooms. View and adjust the list of assigned students.</p>
          </div>
        </div>
        <div className='col-md-6 hidden-xs hidden-sm p-2'>
          <div className={`${mainBlockClasses}`}>
            <h2 className='tdtk-h4 mb-4'>
              <Link className='cb-palette-blue2' to='/students'>
                <strong>Student Roster and Check-In</strong>
              </Link>
            </h2>
            <p>Check students in. View room assignments, accommodations, and other details.</p>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-6 p-2'>
          <div className={`${mainBlockClasses}`}>
            <h2 className='tdtk-h4 mb-4'>
              <Link className='cb-palette-blue2' to='/irregularities'>
                <strong>Irregularities</strong>
              </Link>
            </h2>
            <p>If something goes wrong, let us know what happened.</p>
          </div>
        </div>
        <div className='col-md-6 p-2'>
          <div className={`${mainBlockClasses}`}>
            <h2 className='tdtk-h4 mb-4'>
              <Link className='cb-palette-blue2' to='/help'>
                <strong>Help and Contact Info</strong>
              </Link>
            </h2>
            <p>Find the answers you need.</p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Home;
