import PropTypes from 'prop-types';
import { React, useState } from 'react';
import { ModalDispatchContext } from './ModalContext';

function ModalProvider({ children }) {
  function ModalWrapper(currentModal) {
    return (
      <ModalDispatchContext.Provider key='modal-wrapper' value={dispatchModal}>
        {children}
        {currentModal}
      </ModalDispatchContext.Provider>
    );
  }

  // Local state.
  const [currentModal, dispatchModal] = useState(null);

  return ModalWrapper(currentModal);
}

ModalProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.object, PropTypes.array]),
};

export default ModalProvider;
