import React from 'react';
import ToggleDirections from '../../../../../common/ToggleDirections';

function MonitorStudentDirectionsAP() {
  return (
    <ToggleDirections>
      <div className='cb-white-bg'>
        <h3 className='tdtk-h3 how-to-Monitor-Testing'>How to Monitor Testing</h3>
        <div className='row px-0 mx-0 py-3 cb-border-top' role='region' aria-label='directions'>
          <div className='col-md-4 cb-margin-sm-down-bottom-16'>
            <h4 className='cb-paragraph6-st'>Reminders</h4>
            <ul className='pl-4 ml-2'>
              <li>Walk around often.</li>
              <li>Tell students with closed or covered devices to open or uncover them immediately.</li>
              <li>Make sure students resume testing after breaks.</li>
              <li>Watch for raised hands.</li>
              <li>Minimize distractions.</li>
            </ul>
          </div>
          <div className='col-md-4 cb-margin-sm-down-bottom-16'>
            <h4 className='cb-paragraph6-st'>Statuses</h4>
            <ul className='pl-4 ml-2'>
              <li>
                A student&rsquo;s status could be inaccurate if they&rsquo;re testing offline and can&rsquo;t send
                updates.
              </li>
              <li>No action is required if students are testing smoothly.</li>
            </ul>
          </div>
          <div className='col-md-4'>
            <h4 className='cb-paragraph6-st'>Watch for Security Violations</h4>
            <ul className='pl-4 ml-2'>
              <li>Working on another student&rsquo;s device. </li>
              <li>Angling a device so another student can see it.</li>
              <li>Viewing nontest content or using other applications.</li>
              <li>Taking screenshots.</li>
            </ul>
          </div>
        </div>
      </div>
    </ToggleDirections>
  );
}

export default MonitorStudentDirectionsAP;
