export enum WEBSOCKET_MESSAGE_TYPE {
  HEARTBEAT = 0,
  ROOM_CHECKIN = 1,
  STUDENT_TELEMETRY = 2,
  PHOTO_ROSTER = 3,
  SITE_SIGN_IN_TICKETS = 4,
  ROOM_SIGN_IN_TICKETS = 5,
  STUDENT_DATA_EXPORT = 6,
  ROOM_DIRECTORY = 7,
}

export type WebsocketMessage =
  | HeartbeatWebsocketData
  | PhotoRostersWebsocketData
  | RoomCheckinWebsocketData
  | RoomDirectoryWebsocketData
  | RoomSignInTicketsWebsocketData
  | SiteSignInTicketsWebsocketData
  | StudentDataExportWebsocketData
  | StudentTelemetryWebsocketData;

/**
 * The base websocket message specification
 */
export interface WebsocketData {
  /** timestamp */
  ts: string;
}

/**
 * A heartbeat message specification
 *
 * NOTE: This message format just needs to conform
 * to what we tell react-use-websocket to consume.
 * It needs to be a static message without timestamps
 */
export interface HeartbeatWebsocketData {
  /** type */
  t: WEBSOCKET_MESSAGE_TYPE.HEARTBEAT;
}

/**
 * A room checkin message specification
 */
export interface RoomCheckinWebsocketData extends WebsocketData {
  /** type */
  t: WEBSOCKET_MESSAGE_TYPE.ROOM_CHECKIN;
  /** data */
  d: RoomCheckinWebsocketDataBody[];
}

export interface RoomCheckinWebsocketDataBody {
  /** student id */
  id: string;
  /** student attributes */
  [key: string]: any;
}

/**
 * A student telemetry message specification
 */
export interface StudentTelemetryWebsocketData extends WebsocketData {
  /** type */
  t: WEBSOCKET_MESSAGE_TYPE.STUDENT_TELEMETRY;
  /** data */
  d: StudentTelemetryWebsocketDataBody[];
}

export interface StudentTelemetryWebsocketDataBody {
  /** student id */
  id: string;
  /** dapExamStatus */
  des?: {
    /** name */
    n: string;
    /** timestamp */
    ts: string;
  };
  /** dapTestStatus */
  dts?: {
    /** name */
    n: string;
    /** timestamp */
    ts: string;
    /** payload */
    p?: object;
  };
}

/**
 * A photo roster message specification
 */
export interface PhotoRostersWebsocketData extends WebsocketData {
  /** type */
  t: WEBSOCKET_MESSAGE_TYPE.PHOTO_ROSTER;
  /** data */
  d: {
    rendezvousCode: string;
    /** Signals if the pdf generation failed or succeeded */
    status: string;
  };
}

/**
 * A Site sign in tickets message specification
 */
export interface SiteSignInTicketsWebsocketData extends WebsocketData {
  /** type */
  t: WEBSOCKET_MESSAGE_TYPE.SITE_SIGN_IN_TICKETS;
  /** data */
  d: {
    rendezvousCode: string;
    /** Signals if the pdf generation failed or succeeded */
    status: string;
  };
}

/**
 * A generate room directory message specification
 */
export interface RoomDirectoryWebsocketData extends WebsocketData {
  /** type */
  t: WEBSOCKET_MESSAGE_TYPE.ROOM_DIRECTORY;
  /** data */
  d: {
    rendezvousCode: string;
    /** Signals if the pdf generation failed or succeeded */
    status: string;
  };
}

/**
 * A room sign in tickets message specification
 */
export interface RoomSignInTicketsWebsocketData extends WebsocketData {
  /** type */
  t: WEBSOCKET_MESSAGE_TYPE.ROOM_SIGN_IN_TICKETS;
  /** data */
  d: {
    rendezvousCode: string;
    /** Signals if the pdf generation failed or succeeded */
    status: string;
  };
}

/**
 * A room sign in tickets message specification
 */
export interface StudentDataExportWebsocketData extends WebsocketData {
  /** type */
  t: WEBSOCKET_MESSAGE_TYPE.STUDENT_DATA_EXPORT;
  /** data */
  d: {
    rendezvousCode: string;
    /** Signals if the pdf generation failed or succeeded */
    status: string;
  };
}
