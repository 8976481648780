import React from 'react';
import PropTypes from 'prop-types';
import sortBy from 'lodash/sortBy';
import QueryComponent from '../../common/network/QueryComponent';
import { GET_CLOSURE_REASONS } from '../../../apollo/queries';
import { useStateValue } from '../../../context/AppContext';
import ClosureForm from './ClosureForm';
import ClosureFormCompleted from './ClosureFormCompleted';
import './ClosureForm.scss';

function Container({ showFull = true }) {
  // Global App state.
  const { orgEvent } = useStateValue();

  // Check if they've already submitted a closure.
  const testCenterClosed = !!orgEvent?.closure?.id;
  const isSundayTC = orgEvent?.testCenterTypeCd === 'R' ? true : false;

  return testCenterClosed ? (
    <ClosureFormCompleted
      canSupportMakeup={orgEvent?.closure?.canSupportMakeup || ''}
      confirmationId={orgEvent?.closure?.confirmationId || ''}
      created={orgEvent?.closure?.created || ''}
      makeupDate={orgEvent?.closure?.makeupDate || ''}
      reason={orgEvent?.closure?.reason || ''}
      showFull={true}
    />
  ) : (
    <QueryComponent query={{ kind: 'GetClosureReasons', specification: GET_CLOSURE_REASONS }}>
      {(data) => {
        // Determine if this event has closure reasons. Put "Others" at the bottom.
        let reasons =
          sortBy(
            data?.getClosureEvents?.closures.map((reason) => ({
              eventOther: reason?.event_other === 'true' || false,
              makeupDay: reason?.makeup_day || -1,
              makeupMonth: reason?.makeup_month || -1,
              makeupYear: reason?.makeup_year || -1,
              nextSteps: reason?.next_steps || '',
              title: reason?.title || '',
              isSunday: reason?.isSunday || false,
            })),
            ['eventOther', 'title'],
            'asc'
          ) || [];

        // Determine if a custom "ETS Other" has been marked, if not, add the default as the last "reason."
        if (reasons && reasons.length > 1) {
          reasons = reasons.filter((r) => r.isSunday === isSundayTC);
        }
        if (reasons.find((reason) => reason.eventOther) === undefined) {
          reasons.push({
            eventOther: true,
            title: 'Other',
          });
        }

        return <ClosureForm reasons={reasons} showFull={showFull} />;
      }}
    </QueryComponent>
  );
}

Container.propTypes = {
  showFull: PropTypes.bool,
};

export default Container;
