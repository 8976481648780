import React from 'react';
import { Spinner } from '@cb/apricot-react';
import './Spinner.scss';

export default function SpinnerWrapper() {
  return (
    <span className='centerLoader'>
      <Spinner palette='blue2' size='64' />
    </span>
  );
}
