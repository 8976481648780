import React from 'react';
import PropTypes from 'prop-types';
import { ErrorNotification as Ap4ErrorNotification } from '@cb/apricot-react';

function ErrorNotification({ title = '', ...props }) {
  return <Ap4ErrorNotification title={title} {...props} />;
}

ErrorNotification.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
};

export default ErrorNotification;
