import { useState, useCallback, useEffect } from 'react';
import { useTourCheck } from './useTourCheck';
import { useTourContext } from './tourContext';

export const useTour = (page) => {
  const { isOpen: overRideOpen, setIsOpen: setOverRideOpen } = useTourContext();
  const [tourOpen, setTourOpen] = useState(true);
  const { isUserTourValid, setTourCookie, getTourCookie } = useTourCheck();
  const tourCookie = getTourCookie(page);
  const shouldShowTour = (isUserTourValid && !tourCookie) || (isUserTourValid && overRideOpen);

  useEffect(() => {
    if (overRideOpen) {
      setTourOpen(true);
    }
  }, [overRideOpen]);

  const onRequestClose = useCallback(() => {
    setTourOpen(false);
    setOverRideOpen(false);
    setTourCookie(page);
  }, [setTourCookie, page, setOverRideOpen]);

  return { shouldShowTour, tourOpen, setTourOpen, onRequestClose };
};
