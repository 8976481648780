import React from 'react';
import { Button, Icon } from '@cb/apricot-react';

type Props = {
  content?: string;
  goTo(step: number): void;
  step: number;
  title?: string;
  totalSteps: number;
};

export const TourContent = ({ step, goTo, title = 'TITLE', content = 'CONTENT', totalSteps }: Props) => {
  return (
    <React.Fragment>
      <h3 className='tdtk-h3' data-automation='tour-title'>
        {title}
      </h3>
      <p className='m-0'>{content}</p>
      <p className='mt-2'>
        {step !== 1 && (
          <button
            aria-label='Back to previous stop'
            className='cb-btn cb-btn-sm mr-2'
            data-automation='tour-arrow-left'
            onClick={() => goTo(step - 2)}
          >
            {' '}
            <Icon name='west' className='cb-no-margin' decorative />
          </button>
        )}
        <span aria-label={`${step} of ${totalSteps}`}>
          {step} of {totalSteps}
        </span>
        {step !== totalSteps && (
          <Button
            aria-label='Forward to next stop'
            className='cb-btn cb-btn-sm ml-2'
            data-automation='tour-arrow-right'
            onClick={() => goTo(step)}
          >
            <Icon name='east' className='cb-no-margin' decorative />
          </Button>
        )}
      </p>
    </React.Fragment>
  );
};
