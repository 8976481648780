/* eslint no-case-declarations: 0 */

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';
import { useNavigate } from 'react-router-dom';
import { DELETE_ROOM, UPDATE_ROOM, UPDATE_STUDENTS } from '../../../../apollo/mutations';
import { hasAdminAccess } from '../../../../utils/user';
import { formatStudentName, sortItems } from '../../../../utils/common';
import TooltipWrapper from '../../../ui/tooltip/TooltipWrapper';
import { useStateValue } from '../../../../context/AppContext';
import { ModalDispatchContext } from '../../../ui/modal/ModalContext';
import OneButtonModal from '../../../ui/modal/standard/OneButtonModal';
import TwoButtonModal from '../../../ui/modal/standard/TwoButtonModal';
import { Button } from '@cb/apricot-react';

function RoomDetailsButtons({ room = {} }) {
  const navigate = useNavigate();

  function GenerateButtons(refsArray) {
    const { staff = [], status = 'none', students = [] } = room;
    const deleteDisabled = room.status === 'started' || room.status === 'ended';
    const startTestingDisabledMessage =
      students.length < 1 || staff.length < 1
        ? 'Assign a proctor and at least one student.'
        : room.status === 'ended'
        ? 'Testing has ended.'
        : '';

    const buttons = [];

    // Only push buttons that are available to proper people.
    buttons.push(
      renderButton(
        {
          buttonRef: refButtonChangeStatus,
          disabled: startTestingDisabledMessage !== '',
          handler: 'changeStatus',
          id: 'button-changeStatus',
          popover: startTestingDisabledMessage,
          primary: true,
          title: status === 'none' ? 'Start Testing' : 'End Testing',
        },
        refsArray[0]
      )
    );

    if (hasAdminAccess(user.rid, user.role)) {
      buttons.push(
        renderButton(
          {
            handler: 'editRoom',
            id: 'button-editRoom',
            title: 'Edit',
          },
          refsArray[1]
        )
      );
    }

    buttons.push(
      renderButton(
        {
          handler: 'manageSeatingChart',
          id: 'button-seatingChart',
          title: 'Manage Seating Chart',
        },
        refsArray[2]
      )
    );

    if (hasAdminAccess(user.rid, user.role)) {
      buttons.push(
        renderButton(
          {
            buttonRef: refButtonDelete,
            disabled: deleteDisabled,
            handler: 'deleteRoom',
            id: 'button-deleteRoom',
            popover: deleteDisabled ? 'You can’t delete a room after testing has started.' : '',
            title: 'Delete Room',
          },
          refsArray[3]
        )
      );
    }

    return buttons;
  }

  function renderButton(btn = {}, buttonRef) {
    const tourProps = {};
    if (btn.title === 'Start Testing' || btn.title === 'End Testing') {
      tourProps['data-tour'] = 'start-testing';
    } else if (btn.title === 'Manage Seating Chart') {
      tourProps['data-tour'] = 'manage-chart';
    }
    function buttonTemplate() {
      return (
        <Button
          variant={btn.primary ? 'yellow' : 'black'}
          small
          disabled={btn.disabled}
          {...tourProps}
          className='w-100 mb-4'
          data-automation={btn.id}
          id={btn.id}
          onClick={updateRoomHandler(btn.handler)}
          ref={btn.buttonRef}
          type='button'
        >
          {btn.title}
        </Button>
      );
    }

    return (
      <div key={btn.id}>
        {!isEmpty(btn.popover) && btn.popover !== '' ? (
          <>
            {buttonTemplate()}
            <TooltipWrapper light={true} trigger={btn.id}>
              {btn.popover}
            </TooltipWrapper>
          </>
        ) : (
          buttonTemplate()
        )}
      </div>
    );
  }

  function updateRoomHandler(handler) {
    return function () {
      switch (handler) {
        case 'editRoom':
          editRoom();
          break;
        case 'changeStatus':
          changeRoomStatus();
          break;
        case 'deleteRoom':
          handleDelete();
          break;
        case 'manageSeatingChart':
          manageSeatingChart();
          break;
        default:
          console.error('No Proper Action Specified');
      }
    };
  }

  function editRoom() {
    navigate(`/rooms/edit/${room.id}`);
  }

  function manageSeatingChart() {
    navigate(`/rooms/seating-chart/edit/${room.id}`);
  }

  function changeRoomStatus() {
    const { students = {} } = room;

    let body = [];
    let input = {};
    let numberOfStudentsNotCheckedIntoRoom = 0;
    let status = '';
    let title = '';
    let warningStudents = [];

    if (!room.status || room.status === 'none') {
      warningStudents = students.filter((student) => {
        const isNotCheckedInRoom = !student.absent && !student.deniedEntry && !student.checkedInRoom;
        isNotCheckedInRoom && ++numberOfStudentsNotCheckedIntoRoom;
        return isNotCheckedInRoom;
      });

      warningStudents = sortItems(warningStudents, [
        {
          name: 'candLastName',
          order: 'asc',
        },
        {
          name: 'candFirstName',
          order: 'asc',
        },
      ]);

      switch (numberOfStudentsNotCheckedIntoRoom) {
        case 0:
          title = 'Are you sure you want to start testing?';
          body = (
            <p className='mb-4' key='1'>
              Click <b key='1.1'>Confirm</b> if you’ve read students the directions in your script and you’re ready to
              start timed testing.
            </p>
          );
          break;
        case 1:
          title = 'One student has not checked in';
          body = (
            <div key='one-student-checked'>
              <p className='mb-4' key='1'>
                {formatStudentName(warningStudents[0], 'fml')} will be marked absent if you click{' '}
                <b key='1.1'>Confirm</b>. You can change student statuses later if you need to.
              </p>
              <p className='mb-4' key='2'>
                Don&rsquo;t click <b key='2.1'>Confirm</b> unless you&rsquo;ve read students the directions in your
                script and are ready to start timed testing.
              </p>
            </div>
          );
          break;
        default:
          title = 'Some students have not checked in';
          const listStudents = warningStudents.map((item) => {
            return <li key={item.id}>{formatStudentName(item)}</li>;
          });
          body = (
            <div key='will-mark-absent'>
              <p className='mb-4' key='1'>
                {' '}
                These students will be marked absent if you click <b key='1.1'>Confirm</b>:
              </p>
              <ul key='2'>{listStudents}</ul>
              <p className='mb-4' key='3'>
                You can change student statuses later if you need to.
              </p>
              <p className='mb-4' key='4'>
                Don&rsquo;t click <b key='4.1'>Confirm</b> unless you&rsquo;ve read students the directions in your
                script and are ready to start timed testing.
              </p>
            </div>
          );
      }
    } else {
      title = 'Are you sure you want to end testing?';
      body = ['You’re about to end testing in this room.'];
    }

    if (!room.status || room.status === 'none') {
      status = 'started';
    } else if (room.status === 'started') {
      status = 'ended';
    }

    input = {
      id: room.id,
      status,
    };

    // Update the modal state.
    dispatchModal(
      <TwoButtonModal
        body={body}
        primaryButtonHandler={() => {
          return updateRoom({
            variables: { input },
          }).then(() => {
            const showSuccessfulModal = () => {
              if (status === 'started') {
                dispatchModal(
                  <OneButtonModal
                    body={
                      <div key='successModalTimedTesting'>
                        <p>It&lsquo;s time for you to:</p>
                        <ol>
                          <li>Update student statuses on your room roster if you need to.</li>
                          <li>
                            Select the <strong>Manage Seating Chart</strong> button to show where each student is
                            sitting.
                          </li>
                        </ol>
                        <p>Accurate seating charts and up-to-date rosters really help your coordinator.</p>
                      </div>
                    }
                    buttonLabel='Got It'
                    title='Congrats on starting timed testing!'
                    modalId='successfully-starteds-timed-testing'
                    variant='success'
                  />
                );
              }
            };

            if (!isEmpty(warningStudents)) {
              const updatedStudents = warningStudents.map((item) => {
                return {
                  id: item.id,
                  absent: true,
                  checkedInCenter: false,
                  checkedInRoom: false,
                  deniedEntry: false,
                };
              });

              updateStudents({
                variables: {
                  input: {
                    students: updatedStudents,
                  },
                },
              }).then(() => {
                showSuccessfulModal();
              });
            } else {
              showSuccessfulModal();
            }
          });
        }}
        primaryButtonLabel='Confirm'
        title={title}
        modalId='room-start-modal'
        variant='error'
      />
    );
  }

  function handleDelete() {
    const input = {
      id: room.id,
    };

    if (some(room.students, { checkedInRoom: true })) {
      dispatchModal(
        <OneButtonModal
          body='You can’t delete a room with assigned students. Remove the students from the room and try again.'
          buttonLabel='OK'
          title='Students are assigned to this room.'
          modalId='assigned-students-warning'
          variant='error'
        />
      );

      // Kick the user out of this function before continuing.
      return;
    }

    // Render a modal indicating their intention to delete this room.
    dispatchModal(
      <TwoButtonModal
        body={
          isEmpty(room.students) && isEmpty(room.staff)
            ? 'You’re about to delete this room.'
            : 'If you delete this room, students and staff will need to be reassigned.'
        }
        primaryButtonHandler={() =>
          deleteRoom({
            variables: { input },
            optimisticResponse: {
              deleteRoom: {
                id: input.id,
                __typename: 'RoomType',
              },
            },
          })
            .then(() => {
              navigate('/rooms');
            })
            .catch((e) => {
              if (e && Array.isArray(e.graphQLErrors)) {
                console.error('error', e);
              }
            })
        }
        modalId='delete-room-warning'
        variant='error'
      />
    );
  }

  // Global App state.
  const { user } = useStateValue();
  const dispatchModal = useContext(ModalDispatchContext);

  // Apollo.
  const [deleteRoom] = useMutation(DELETE_ROOM);
  const [updateRoom] = useMutation(UPDATE_ROOM);
  const [updateStudents] = useMutation(UPDATE_STUDENTS);

  // Refs.
  const refButtonChangeStatus = React.useRef();
  const refButtonDelete = React.useRef();

  // We need refs for popovers used in button generator.
  const buttonRefArray = [React.useRef(), React.useRef(), React.useRef(), React.useRef()];

  return <React.Fragment>{GenerateButtons(buttonRefArray)}</React.Fragment>;
}

RoomDetailsButtons.propTypes = {
  room: PropTypes.object,
};

export default RoomDetailsButtons;
