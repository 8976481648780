import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

export default function RosterLink({ linkName = '', roomId = '' }) {
  return (
    <div className='wizard-panel-RosterLink'>
      <Link
        data-automation='skipTo-student-roster-link'
        className='skipTo-student-roster cb-palette-blue5'
        to={`/rooms/get/${roomId}/flow/attendance/step/1`}
      >
        {linkName}
      </Link>
    </div>
  );
}

RosterLink.propTypes = {
  linkName: PropTypes.string,
  roomId: PropTypes.string,
};
