import React from 'react';
import PropTypes from 'prop-types';
import { roles } from '../../../../../../constants/roles';
import { getRoomStaffByRole } from '../../../../../../utils/room';

export default function Staff({ room }) {
  function generateReadOnlyStaffList(room) {
    const currentRoomStaff = [...room.staff];
    const roomStaffList = [];
    const { admin, proctor, roomMonitors } = getRoomStaffByRole(currentRoomStaff);

    if (admin && admin.length > 0) {
      // coordinators
      admin.forEach((adm) => {
        roomStaffList.push(mapUserToDisplay(adm));
      });
    }

    if (proctor && proctor.length > 0) {
      // proctors
      proctor.forEach((p) => {
        roomStaffList.push(mapUserToDisplay(p));
      });
    }

    // room monitors
    if (roomMonitors && roomMonitors.length > 0) {
      roomMonitors.forEach((rmProc) => {
        roomStaffList.push(mapUserToDisplay(rmProc));
      });
    }

    return roomStaffList.join(', ');
  }

  // format staff display
  function mapUserToDisplay(user) {
    const role = roles[user.role] ? ` (${roles[user.role]})` : ' ';
    return `${user.firstName} ${user.lastName ? user.lastName : ''}${role}`;
  }

  return <span>{generateReadOnlyStaffList(room) || 'none'}</span>;
}

Staff.propTypes = {
  room: PropTypes.object,
};
