import { Icon } from '@cb/apricot-react';
import React from 'react';

/*
 * @param {Object} params - the params for the function
 * @param {boolean} params.forceUser=true - If the force user option is checked
 * @param {string} params.username='' - The username of the person being added
 * @param {boolean} params.checkUserName=false - If the username entered has come back as valid
 * @returns {boolean} - If the button should be disabled or not
 */
export function getSubmitButtonDisabled({
  forceUser = false,
  username = '',
  checkUserName = false,
  duplicateUserName = false,
  siteStaffList = false,
}) {
  if (forceUser) {
    if (username && !username.trim().length) {
      return true;
    }
    if (!checkUserName && checkUserName !== null) {
      return true;
    }
    if (duplicateUserName || siteStaffList) return true;
  }
  return false;
}

/*
 * @param {Object} params - the params for the function
 * @param {string} params.username='' - The username of the person being added
 * @param {boolean} params.checkUserName=null - If the username entered has come back as valid
 * @param {boolean} params.formError=null - If there is a form error message for username
 * @returns {string|null} -  The appropriate string or null
 */
export function getUserNameCheckMsg({
  checkUserName = null,
  duplicateUserName = false,
  siteStaffList = false,
  username = '',
  formError = '',
}) {
  if (!username) return;
  if (duplicateUserName) {
    return (
      <p id='user-current-administration-error' className='cb-input-helper cb-validation-error mb-0'>
        This user is already part of this administration.
      </p>
    );
  }
  if (siteStaffList) {
    return (
      <p id='user-previous-administration-error' className='cb-input-helper cb-validation-error mb-0'>
        This user was added for a previous administration. You can select them from your staff list instead.
      </p>
    );
  }

  if (checkUserName !== null) {
    return checkUserName && username.trim().length && !formError.length ? (
      <div className='d-flex align-items-center'>
        <Icon name='check-fill' color='green3' className='mr-2 mt-2' decorative />
        <span className='cb-input-helper'>Username is valid.</span>
      </div>
    ) : (
      !formError.length && !!username.length && (
        <p className='cb-input-helper cb-validation-error mb-0'>
          This username is invalid. Please confirm that the user has a valid CB Professional user account.
        </p>
      )
    );
  }
}

/*
 * @param {function=} cb - function to call
 * @returns {function} -  function to pass to event handler
 */
export function handleUserNameCheck(isCBAdmin = false, cb = () => {}) {
  return function (e) {
    if (!isCBAdmin) {
      return;
    }
    if (e.target.value.trim().length) {
      cb({ variables: { userName: e.target.value } });
    }
  };
}

/*
 * @param {Object} previousRole - The update role the user will receive
 * @param {Object} updatedRole - The update role the user will receive
 * @returns {boolean} - True when at least one coordinator is in the administration
 */
export function atLeastOneCoordinator(previousRole = '', updatedRole = '', allStaff = []) {
  if (previousRole === 'admin' && updatedRole !== 'admin') {
    const coordinatorCount = allStaff.reduce((accum, item) => {
      if (item.role === 'admin') {
        accum += 1;
      }
      return accum;
    }, 0);
    if (coordinatorCount <= 1) {
      return false;
    }
  }
  return true;
}
