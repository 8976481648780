import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import ModalDialogWrapper from '../../../ui/modal/ModalDialogWrapper';
import { ModalDispatchContext } from '../../../ui/modal/ModalContext';
import ModalFooter from '../../../ui/modal/ModalFooter';
import { useNavigate } from 'react-router-dom';

export default function ConfirmationTestInProgress({ handlerFunction = () => {}, isPlural = false }) {
  const navigate = useNavigate();
  function handleClose() {
    dispatchModal(null);

    navigate(-1);
  }

  function handleConfirm() {
    dispatchModal(null);

    handlerFunction();
  }

  // Modal Context.
  const dispatchModal = useContext(ModalDispatchContext);

  return (
    <ModalDialogWrapper
      modalId='ConfirmationTestInProgress'
      onClose={handleClose}
      open
      title='Are you sure?'
      variant='error'
    >
      {`You’re about to move ${isPlural ? 'these students' : 'this student'} to a room that started testing.`}
      <ModalFooter
        buttons={[
          {
            handler: handleClose,
            id: 'cancel-bulk-assign',
            style: 'black',
            text: 'Cancel',
          },
          {
            handler: handleConfirm,
            id: 'confirm-bulk-assign',
            style: 'yellow',
            text: 'Continue',
          },
        ]}
      />
    </ModalDialogWrapper>
  );
}

ConfirmationTestInProgress.propTypes = {
  handlerFunction: PropTypes.func,
  isPlural: PropTypes.bool,
};
