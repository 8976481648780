import React from 'react';
import PropTypes from 'prop-types';
import { createCleanMarkup, stripBrs } from '../../../utils/common';
import { RadioButtonGroup as Ap4RadioButtonGroup } from '@cb/apricot-react';
import { unescape } from 'lodash';
import './RadioButtonGroup.scss';

export default function RadioButtonGroup({
  additionalInstructions,
  errorMessage,
  id,
  legend,
  required = false,
  useAdditionalInstructionsAsLegend = false,
  ...props
}) {
  // if useAdditionalInstructionsAsLegend is true, we build a custom legend using the legend and additionalInstructions props
  if (additionalInstructions) {
    additionalInstructions = (
      <div
        className='my-2'
        data-automation={`${id}-additional-info`}
        dangerouslySetInnerHTML={createCleanMarkup(unescape(stripBrs(additionalInstructions)))}
      />
    );

    if (useAdditionalInstructionsAsLegend) {
      const requiredClass = required ? 'cb-required' : '';
      legend = (
        <div>
          <div className={requiredClass}>{legend}</div>
          <div className='radio-group-additional-instructions'>{additionalInstructions}</div>
        </div>
      );
    }
  }

  const legendClasses = ['tdtk-radio-legend'];
  useAdditionalInstructionsAsLegend && legendClasses.push('radio-group-with-additional-info');

  return (
    <>
      {useAdditionalInstructionsAsLegend ? null : additionalInstructions}
      <Ap4RadioButtonGroup
        id={id}
        legend={legend}
        legendClassName={legendClasses.join(' ')}
        required={required}
        validation={errorMessage ? 'error' : null}
        validationMsg={errorMessage ? errorMessage : ''}
        vSpacing='8'
        {...props}
      />
    </>
  );
}

RadioButtonGroup.propTypes = {
  additionalInstructions: PropTypes.string,
  errorMessage: PropTypes.string,
  id: PropTypes.string.isRequired,
  legend: PropTypes.string,
  required: PropTypes.bool,
  useAdditionalInstructionsAsLegend: PropTypes.bool,
};
