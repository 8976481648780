import React, { useContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@cb/apricot-react';
import TooltipWrapper from '../../../../../ui/tooltip/TooltipWrapper';
import { localStateReducer } from '../../../../../../utils/common';
import { ResizeContext } from '../../../../../../context/ResizeContext';

const section3ToolTipHelpText = 'Section 3 is the SAT Essay. Used in CO, DE, IL, MI, NH state administrations only.';
const section3Id = 'section-start-time-Section 3';

function DigitalStudentFilterButton({
  blueboxTestStatuses = [],
  count = 0,
  handleClick = () => {},
  isActive = false,
  section,
  tooltipId = '',
}) {
  const windowSize = useContext(ResizeContext);

  const [localState, setLocalState] = useReducer(localStateReducer, {
    isMobile: windowSize.mobile,
  });

  useEffect(() => {
    setLocalState({
      isMobile: windowSize.mobile,
    });
  }, [windowSize.mobile]);

  const statusObject = blueboxTestStatuses.find((status) => status?.name === section);
  const displayName = statusObject?.filterDisplayName || statusObject?.displayName;

  return (
    <button
      aria-label={`${displayName}: ${count} student${count !== 1 ? 's' : ''}`}
      aria-pressed={isActive}
      className={`
        ${isActive ? 'active' : ''}
        ${section === 'needsAttention' ? 'needsAttention' : ''}
        digital-student-filters--btn
      `}
      onClick={handleClick}
      id={tooltipId}
    >
      <span className='digital-student-filters--btn_text' data-automation={section}>
        {displayName}
      </span>
      {section === section3Id && !localState.isMobile && (
        <>
          <div className='digital-student-filters-tooltip'>
            <span className='cb-sr-only' data-automation='section-3-tooltip-text'>
              {section3ToolTipHelpText}
            </span>
            <Icon decorative name='question-fill' className='ml-2' color={isActive ? 'white' : 'blue5'} />
          </div>
          <TooltipWrapper tooltipId='section3ToolTipHelpTextText' trigger='section3ToolTipHelpText'>
            <p>{section3ToolTipHelpText}</p>
          </TooltipWrapper>
        </>
      )}
      <span className='digital-student-filters--btn_count' data-automation={`${section}-count`}>
        {count}
      </span>
    </button>
  );
}

DigitalStudentFilterButton.propTypes = {
  blueboxTestStatuses: PropTypes.array,
  count: PropTypes.number,
  handleClick: PropTypes.func,
  isActive: PropTypes.bool,
  section: PropTypes.string,
  tooltipId: PropTypes.string,
};

export default DigitalStudentFilterButton;
