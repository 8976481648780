import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ModalDispatchContext } from '../ModalContext';
import ModalDialogWrapper from '../ModalDialogWrapper';
import ModalFooter from '../ModalFooter';

export default function OneButtonModal({
  body = '',
  buttonLabel = 'Close',
  buttonStyle = 'yellow',
  modalId,
  onClose = () => {},
  title = '',
  trigger = '',
  variant = 'error',
  clickOverlayToClose = true,
}) {
  // Modal Context.
  const dispatchModal = useContext(ModalDispatchContext);

  function closeModal() {
    dispatchModal(null);
    onClose();
  }

  return (
    <ModalDialogWrapper
      modalId={`modal-${modalId}`}
      onClose={closeModal}
      open
      title={title}
      trigger={trigger}
      variant={variant}
      clickOverlayToClose={clickOverlayToClose}
    >
      <>
        {body}
        <ModalFooter
          buttons={[
            {
              handler: closeModal,
              id: `btn-close-${modalId}`,
              style: buttonStyle,
              text: buttonLabel,
            },
          ]}
        />
      </>
    </ModalDialogWrapper>
  );
}

OneButtonModal.propTypes = {
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  buttonLabel: PropTypes.string,
  buttonStyle: PropTypes.string,
  clickOverlayToClose: PropTypes.bool,
  modalId: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  title: PropTypes.string,
  trigger: PropTypes.string,
  variant: PropTypes.oneOf(['error', 'info', 'input', 'success']).isRequired,
};
