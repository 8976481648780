import React from 'react';
import isArray from 'lodash/isArray';
import mergeWith from 'lodash/mergeWith';
import { initialAppState } from './initialState';

/**
 * App Context.
 */
export const AppStateContext = React.createContext(initialAppState);
export const AppStateDispatchContext = React.createContext<React.Dispatch<any>>(() => {});

export const useStateDispatch = () => React.useContext<React.Dispatch<any>>(AppStateDispatchContext);
export const useStateValue = () => React.useContext(AppStateContext);

// Reducer for the App state.
export const reducer = (state, action) => {
  // Exception for empty arrays not replacing arrays.
  function customizer(objValue, srcValue) {
    if (isArray(objValue) && isArray(srcValue) && !srcValue.length) {
      return [];
    }
  }

  const deepCopy = {
    ...mergeWith(state, action, customizer),
  };

  if (action === 'reset') {
    return {
      ...initialAppState,
    };
  } else {
    return deepCopy;
  }
};

/**
 * Modal Context.
 */
export const ModalContext = React.createContext();
export const useModalContext = () => React.useContext(ModalContext);

/**
 * Feature Flag Context.
 * How to use:
 *
 * import { useFeatureFlag } from '../../../context/AppContext';
 * const hasSeatingChart = useFeatureFlag('seating-charts'); // Returns true or false.
 */
export const useFeatureFlag = (name = []) => {
  const {
    orgEvent: { featureFlags },
  } = useStateValue();
  return Array.isArray(featureFlags) && featureFlags.includes(name);
};
