import { useRef, useCallback } from 'react';
import { useStateValue } from '../../../context/AppContext';
import set from 'lodash/set';
import get from 'lodash/get';

const STORE_NAME = 'tdtkTour';

const getAndSetTourCookie = (data = {}) => {
  if (data.getPage && data.user?.id) {
    let tour;

    try {
      tour = JSON.parse(localStorage.getItem(STORE_NAME));
      if (tour) {
        const { getPage, user } = data;
        return get(tour, `${user.id}.${getPage}`);
      } else {
        return undefined;
      }
    } catch (error) {
      return undefined;
    }
  }

  const { page, user } = data;
  if (page && user) {
    let tour;
    try {
      tour = JSON.parse(localStorage.getItem(STORE_NAME)) || {};
      set(tour, `${user.id}.${page}`, true);
      localStorage.setItem(STORE_NAME, JSON.stringify(tour));
    } catch (error) {
      // do nothing
    }
  }
};

export const useTourCheck = () => {
  const { orgEvent, user } = useStateValue();

  const isUserTourValid = useRef(user.role === 'proctor' && !!user.room && !orgEvent?.dapInd);

  const setTourCookie = useCallback(
    (page) => {
      getAndSetTourCookie({ page: page, user });
    },
    [user]
  );

  const getTourCookie = useCallback(
    (page) => {
      return getAndSetTourCookie({ getPage: page, user });
    },
    [user]
  );

  return { isUserTourValid: isUserTourValid.current, setTourCookie, getTourCookie };
};
