import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Link, useLocation } from 'react-router-dom';
import { calculateStats } from './calculateStats';
import { useStateValue } from '../../../context/AppContext';
import { Icon } from '@cb/apricot-react';

function Statistics({ stat = {} }) {
  const { pathname } = useLocation();
  const {
    orgEvent: { dapInd },
  } = useStateValue();

  function buildStatObject(stat) {
    return stat.url ? (
      <Link className='p-0 text--color-white' to={stat.url}>
        <span className='statistics--number' key={stat.title}>
          {stat.number}
        </span>
        <span className='statistics--text'>
          {stat.title} <Icon name='east' decorative />
        </span>
      </Link>
    ) : (
      <React.Fragment>
        <span className='statistics--number' key={stat.title}>
          {stat.number}
        </span>
        <span className='statistics--text'>{stat.title}</span>
      </React.Fragment>
    );
  }
  let showStats = false;
  let selector = 'site';

  switch (pathname) {
    case '/students':
      showStats = true;
      selector = 'student';
      break;
    case '/staff':
      showStats = true;
      selector = 'staff';
      break;
    case '/rooms':
      showStats = true;
      selector = 'room';
      break;
    case '/':
      showStats = true;
      selector = 'site';
      break;
    default:
      showStats = false;
  }

  if (!showStats) {
    return null;
  }

  const statistics = !isEmpty(stat) ? calculateStats(selector, stat, dapInd) : null;

  return statistics && statistics.length ? (
    <div className='statistics container' data-automation='statistics-container'>
      <div className='text-center cb-panel-blue'>
        <div className='stats-panel-heading d-flex'>
          {statistics.map((stat) => {
            return (
              <p
                data-automation={stat.title.split(' ').join('-').toLowerCase()}
                className='statistics--stat row display--flex_desktop-1 text--color-white cb-roboto-medium'
                key={stat.title}
              >
                {buildStatObject(stat)}
              </p>
            );
          })}
        </div>
      </div>
    </div>
  ) : null;
}

Statistics.propTypes = {
  stat: PropTypes.object,
};

export default Statistics;
