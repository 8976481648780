import React from 'react';
import './common.scss';
import { useNavigate } from 'react-router-dom';
import { PrimaryButton } from '@cb/apricot-react';

function HelpNav() {
  const navigate = useNavigate();

  return (
    <div className='cb-width-100 z-index-90 position-fixed position-left position-bottom cb-margin-bottom-72'>
      <div className='container cb-padding-bottom-48'>
        <div className='row'>
          <div className='col-xs-12 cb-align-right'>
            <PrimaryButton
              small
              id='wizard-navigation-help-btn'
              onClick={() => navigate('/help')}
              icon='question-fill'
              iconDecorative
              iconLeft
              className='cb-white-border'
            >
              Help
            </PrimaryButton>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HelpNav;
