import React, { Suspense, useContext } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { getRoutes } from './utils/route';
import { useStateValue } from './context/AppContext';
import { ModalDispatchContext } from './components/ui/modal/ModalContext';

// non-digital assessments
import BulkOperations from './components/nonDigital/students/bulk/Container';
import ClosureForm from './components/nonDigital/centerClosure/Container';
import ClosureFormCompleted from './components/nonDigital/centerClosure/ClosureFormCompleted';
import ErrorPage from './components/nonDigital/error/ErrorPage';
import GroupsInfo from './components/nonDigital/groupsInfo/Container';
import Help from './components/common/help/Help';
import Home from './components/nonDigital/landing/Home';
import IrregularityCategories from './components/common/irregularities/categories/Container';
import IrregularityFormContainer from './components/common/irregularities/form/Container';
import IrregularityRoster from './components/common/irregularities/roster/Container';
import PrintStudentRoster from './components/nonDigital/printable/Container';
import RoomDetails from './components/nonDigital/rooms/details/Container';
import RoomForm from './components/nonDigital/rooms/form/Container';
import Rooms from './components/nonDigital/rooms/roster/Container';
import SeatingChartsForm from './components/nonDigital/rooms/seating-charts/Container';
import StaffDetails from './components/nonDigital/staff/details/Container';
import StaffForm from './components/nonDigital/staff/form/Container';
import StaffRoster from './components/nonDigital/staff/roster/Container';
import StudentDetails from './components/nonDigital/students/details/Container';
import Students from './components/nonDigital/students/roster/Container';
import VoucherInfo from './components/nonDigital/voucherInfo/Container';

// digital assessments
const DigitalBulkOperations = React.lazy(() => import('./components/digital/students/bulk/Container'));
const DigitalErrorPage = React.lazy(() => import('./components/digital/error/ErrorPage'));
const DigitalGroupsInfo = React.lazy(() => import('./components/digital/groupsInfo/Container'));
const DigitalHome = React.lazy(() => import('./components/digital/landing/Home'));
const DigitalRooms = React.lazy(() => import('./components/digital/weekend/rooms/roster/Container'));
const DigitalRoomDetails = React.lazy(() => import('./components/digital/weekend/rooms/details/Container'));
const DigitalStudentDetails = React.lazy(() => import('./components/digital/students/details/Container'));
const DigitalStudents = React.lazy(() => import('./components/digital/students/roster/Container'));
const Downloads = React.lazy(() => import('./components/digital/downloads/Container'));
const Vouchers = React.lazy(() => import('./components/digital/vouchers/Container'));

// digital school based assessments
import DigitalInSchoolRoomDetails from './components/digital/inSchool/rooms/details/Container';

import Spinner from './components/ui/loading/SpinnerWrapper';
import SocketProvider from './components/common/network/SocketProvider';

type Route = {
  component: any;
  path: string;
  localNav?: boolean;
  multiline?: boolean;
  title?: string;
  weight?: number;
};

function authorizedRoutes(features: any, orgEvent: any, user: any) {
  const routes: Record<string, Route[]> = {};
  routes.elevated = [
    {
      component: <StaffRoster />,
      localNav: true,
      path: '/staff',
      title: 'Staff',
      weight: 2,
    },
    {
      component: <ClosureForm />,
      path: '/test-center-closure/add',
    },
    {
      component: <ClosureFormCompleted />,
      path: '/test-center-closure/confirmation',
    },
    {
      component: <ClosureForm />,
      path: '/test-center-closure/add/:selectedReason',
    },
    {
      component: <RoomForm />,
      path: '/rooms/edit/:id',
    },
    {
      component: <StaffForm />,
      path: '/staff/add',
    },
    {
      component: <StaffDetails />,
      path: '/staff/get/:id',
    },
    {
      component: <StaffForm />,
      path: '/staff/edit/:id',
    },
    {
      component: <VoucherInfo />,
      localNav: true,
      path: '/vouchers',
      title: 'Voucher Data',
      weight: 6,
    },
  ];

  routes.digitalElevated = [
    {
      component: <StaffRoster />,
      localNav: true,
      path: '/staff',
      title: 'Staff',
      weight: 2,
    },
    {
      component: <RoomForm />,
      path: '/rooms/edit/:id',
    },
    {
      component: <StaffForm />,
      path: '/staff/add',
    },
    {
      component: <StaffDetails />,
      path: '/staff/get/:id',
    },
    {
      component: <StaffForm />,
      path: '/staff/edit/:id',
    },
  ];

  routes.digitalInSchoolHomeWithRoom = [
    {
      component: <DigitalInSchoolRoomDetails />,
      localNav: true,
      path: '/',
      title: 'My Room',
      weight: 0,
    },
  ];

  routes.DigitalHomeWithRoom = [
    {
      component: <DigitalRoomDetails />,
      localNav: true,
      path: '/',
      title: 'My Room',
      weight: 0,
    },
  ];

  routes.digitalHome = [
    {
      component: <DigitalHome />,
      localNav: true,
      path: '/',
      title: 'Home',
      weight: 0,
    },
  ];

  routes.digitalIrregularities = [
    {
      component: <IrregularityRoster />,
      localNav: true,
      path: '/irregularities',
      title: 'Irregularities',
      weight: 7,
    },
    {
      component: <IrregularityCategories />,
      path: '/irregularities/add',
    },
    {
      component: <IrregularityCategories />,
      path: '/irregularities/add/:irregularityCategoryId/:irregularityId',
    },
    {
      component: <IrregularityFormContainer />,
      path: '/irregularities/get/:id',
    },
  ];

  routes.digitalVouchers = [
    {
      component: <Vouchers />,
      localNav: true,
      path: '/digital-vouchers',
      title: 'Vouchers',
      weight: 8,
    },
  ];

  routes.digitalInSchool = [
    {
      component: <RoomForm />,
      localNav: false,
      path: '/rooms/edit/:id',
    },
    {
      component: <DigitalStudentDetails />,
      path: '/students/get/:id',
    },
    {
      component: <DigitalBulkOperations />,
      path: '/students/bulkOperations/:operation',
    },
    {
      component: <DigitalRooms />,
      localNav: true,
      path: '/rooms',
      title: 'All Rooms',
      weight: 3,
    },
    {
      component: <DigitalStudents />,
      localNav: true,
      path: '/students',
      title: 'All Students',
      weight: 4,
    },
  ];

  routes.digitalInSchoolRoomDetails = [
    {
      component: <DigitalInSchoolRoomDetails />,
      path: '/rooms/get/:id',
    },
    {
      component: <DigitalInSchoolRoomDetails />,
      path: '/rooms/get/:id/flow/:flowName/step/:stepNum',
      title: 'My Room',
    },
  ];

  routes.digitalHelp = [
    {
      component: <Help />,
      localNav: true,
      path: '/help',
      title: 'Help',
      weight: 2,
    },
  ];

  routes.weekendDigitalRoomDetails = [
    {
      component: <DigitalRoomDetails />,
      path: '/rooms/get/:id',
    },
    {
      component: <DigitalRoomDetails />,
      path: '/rooms/get/:id/flow/:flowName/step/:stepNum',
      title: 'My Room',
    },
  ];

  routes.digitalCommon = [
    {
      component: <DigitalGroupsInfo />,
      path: '/groups',
    },
    {
      component: <DigitalErrorPage />,
      path: '/error',
    },
    {
      component: <DigitalRooms />,
      localNav: true,
      path: '/rooms',
      title: 'All Rooms',
      weight: 2,
    },
    {
      component: <SeatingChartsForm />,
      path: '/rooms/seating-chart/edit/:id',
    },
    {
      component: <DigitalStudents />,
      localNav: true,
      path: '/students',
      title: 'All Students',
      weight: 3,
    },
    {
      component: <DigitalStudentDetails />,
      path: '/students/get/:id',
    },
    {
      component: <DigitalBulkOperations />,
      path: '/students/bulkOperations/:operation',
    },
  ];

  routes.irregularities = [
    {
      component: <IrregularityRoster />,
      localNav: true,
      path: '/irregularities',
      title: 'Irregularities',
      weight: 5,
    },
    {
      component: <IrregularityCategories />,
      path: '/irregularities/add',
    },
    {
      component: <IrregularityCategories />,
      path: '/irregularities/add/:irregularityCategoryId/:irregularityId',
    },
    {
      component: <IrregularityFormContainer />,
      path: '/irregularities/get/:id',
    },
  ];

  routes.common = [
    {
      // Show the user's room if this is a digital test and they are NOT an admin.
      component: <Home />,
      localNav: true,
      path: '/',
      title: 'Home',
      weight: 0,
    },
    {
      component: <Rooms />,
      localNav: true,
      path: '/rooms',
      title: 'Rooms',
      weight: 1,
    },
    {
      component: <SeatingChartsForm />,
      path: '/rooms/seating-chart/edit/:id',
    },
    {
      component: <RoomDetails />,
      path: '/rooms/get/:id',
    },
    {
      component: <Students />,
      localNav: true,
      path: '/students',
      title: 'Students',
      weight: 3,
    },
    {
      component: <StudentDetails />,
      path: '/students/get/:id',
    },
    {
      component: <BulkOperations />,
      path: '/students/bulkOperations/:operation',
    },
    {
      component: <PrintStudentRoster />,
      localNav: true,
      multiline: true,
      path: '/printable-rosters',
      title: 'Printable Student Rosters',
      weight: 4,
    },
    {
      component: <Help />,
      localNav: true,
      path: '/help',
      title: 'Help',
      weight: 7,
    },
    {
      component: <GroupsInfo />,
      path: '/groups',
    },
    {
      component: <ErrorPage />,
      path: '/error',
    },
  ];

  routes.downloads = [
    {
      component: (
        <SocketProvider>
          <Downloads />
        </SocketProvider>
      ),
      localNav: true,
      path: '/downloads',
      title: 'Downloads',
      weight: 6,
    },
  ];

  return getRoutes(features, orgEvent, user).reduce((prev, cur) => {
    if (routes[cur]) {
      return [...prev, ...routes[cur]];
    } else {
      return [...prev];
    }
  }, [] as Route[]);
}

function AppRouter() {
  // Global App state.
  const { features, orgEvent, user } = useStateValue();
  const { pathname, hash } = useLocation();

  const dispatchModal = useContext(ModalDispatchContext);

  /**
   * Close Modal and scroll to the top on a page refresh unless they have a specific hash to jump to.
   */
  React.useEffect(() => {
    if (!hash) {
      window.scrollTo(0, 0);
    }
    dispatchModal(null);
  }, [hash, pathname, dispatchModal]);

  return (
    <Routes>
      {authorizedRoutes(features, orgEvent, user).map((routeObj) => (
        <Route
          key={routeObj.path}
          path={routeObj.path}
          element={<Suspense fallback={<Spinner />}>{routeObj.component}</Suspense>}
        />
      ))}
      {/** 404 Catch All */}
      <Route element={<ErrorPage errorCode='404' />} />
    </Routes>
  );
}

export { authorizedRoutes };

export default AppRouter;
