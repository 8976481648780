import React from 'react';
import PropTypes from 'prop-types';
import { createCleanMarkup } from '../../../utils/common';
import { AnchorMenu } from '@cb/apricot-react';

export default function DynamicAnchorMenu({ registerNewContentListener = () => {}, label = 'On This Page' }) {
  const [anchorMenuItems, setAnchorMenuItems] = React.useState(null);

  // If container is binding to dynamic content, we need to know once it
  // loads. They need to pass a function to pick up our content parsing
  // menu binder.

  registerNewContentListener((content) => {
    if (content) {
      // If valid content, we're parsing out all the h2 tags that have
      // id attributes. We use the id values as local anchor tag bookmarks
      // and take the header's inner text as our menu item entry.

      setAnchorMenuItems(
        Array.from(createCleanMarkup(content).__html.matchAll(/<h2 id=[^<]*/g), (m) => m[0]).map((h) => {
          return {
            label: h.split('>')[1],
            bookmark: h.match(/(id=")([^"]*)/)[2],
          };
        })
      );
    }
  });

  // Reactively return a menu if and only if we have something to show,
  // otherwise render null.

  if (anchorMenuItems?.length) {
    return <AnchorMenu anchorLabel={label} offsetTop={120} items={anchorMenuItems} close={true} />;
  }

  return null;
}

DynamicAnchorMenu.propTypes = {
  registerNewContentListener: PropTypes.func,
  label: PropTypes.string,
};
