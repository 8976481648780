import React from 'react';
import Tour from 'reactour';
import { TOUR_PROPS } from './constants';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

const enableBody = (target) => enableBodyScroll(target);

export const TourContainer = (props) => {
  const tourContent = React.useRef();

  React.useEffect(() => {
    return () => {
      enableBodyScroll(tourContent.current);
    };
  }, []);

  const disableBody = React.useCallback((target) => {
    tourContent.current = target;
    disableBodyScroll(target);
  }, []);

  return <Tour {...TOUR_PROPS} onAfterOpen={disableBody} onBeforeClose={enableBody} {...props} />;
};
