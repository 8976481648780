import React from 'react';

type TimerPropType = { startTime: number; timerCallback?: (d: boolean) => void };

function Timer({ startTime = 180, timerCallback }: TimerPropType) {
  function formatTime(time: number) {
    const minutes = Math.floor(time / 60);
    const evaluatedMinutes = minutes < 10 ? '0' + minutes : minutes;
    const seconds = Math.floor(time % 60);
    const evaluatedSeconds = seconds < 10 ? '0' + seconds : seconds;

    return timer > 0 ? `${evaluatedMinutes}:${evaluatedSeconds}` : '00:00';
  }
  const [timer, setTimer] = React.useState(startTime);

  // Run any callback functions if we hit zero.
  React.useEffect(() => {
    if (timer < 1) {
      timerCallback?.(true);
    }
  }, [timer, timerCallback]);

  React.useEffect(() => {
    let duration = timer;

    const countdown = setInterval(function () {
      if (--duration < 0) {
        duration = 0;
      }
      setTimer(duration);
    }, 1000);

    return () => {
      clearInterval(countdown);
    };
  }, [timer]);

  return (
    <span
      aria-atomic='true'
      aria-live='polite'
      role='timer'
      style={{
        background: '#F0F0F0',
        padding: '0.5rem',
      }}
    >
      {formatTime(timer)}
    </span>
  );
}

export default Timer;
