import React, { useEffect } from 'react';
import { CBInputMethod } from '@cb/apricot';
import { Tabs } from '@cb/apricot-react';

export function TabsWrapper(props) {
  useEffect(() => {
    CBInputMethod();
  }, []);

  return <Tabs {...props} />;
}
