import React from 'react';
import PropTypes from 'prop-types';
import { generateReadOnlyStaffList } from '../../../../utils/staff';
import { generateStatus } from '../../../common/students/utility';
import { groupTypes } from '../../../../constants/groupTypes';
import { hasAdminAccess } from '../../../../utils/user';
import { localStateReducer, seatsAvailable, setCheckedProp } from '../../../../utils/common';
import { ResizeContext } from '../../../../context/ResizeContext';
import { roomStatus, seatingStatus } from '../../../../constants/roomConstants';
import { TourContent, TourContainer, useTour } from '../../../common/productTour';
import { useStateValue } from '../../../../context/AppContext';
import { useTitle } from '../../../../constants/useTitle';
import BulkActionDropdown from '../../../ui/BulkActionDropdown';
import isEmpty from 'lodash/isEmpty';
import RoomDetailsButtons from './RoomDetailsButtons';
import Roster from '../../../common/Roster';
import SimpleList from '../../../ui/list/SimpleList';
import StudentRosterRow from '../../students/roster/StudentRosterRow';
import { useNavigate } from 'react-router-dom';

function RoomDetails({ room = {} }) {
  useTitle(room?.title);
  const navigate = useNavigate();
  function generateErrorMessage() {
    const { bulkAction, checkedStudents } = localState;

    if (bulkAction === '' || isEmpty(checkedStudents)) {
      return 'Select an action and at least one student.';
    }

    return '';
  }

  function bulkComponent(selectId) {
    let bulkErrorMessage;

    if (hasAdminAccess(user.rid, user.role)) {
      bulkErrorMessage = generateErrorMessage();

      return (
        <BulkActionDropdown
          errorMessage={bulkErrorMessage}
          defaultValue={localState.bulkAction}
          handleSubmit={handleBulkSubmit}
          handleValueSelect={handleBulkChange}
          selectId={selectId}
          values={bulkActions}
        />
      );
    } else {
      return null;
    }
  }

  function handleBulkSubmit(e) {
    e && e.preventDefault && e.preventDefault();

    const isDisabled = !!generateErrorMessage();

    // Only proceed if they've selected an action, they have no errors, and they have checked staff.
    if (localState.bulkAction && !isDisabled && !isEmpty(localState.checkedStudents)) {
      navigate(`/students/bulkOperations/${localState.bulkAction}`, {
        state: {
          students: room.students.filter((s) => localState.checkedStudents.indexOf(s.id) > -1),
        },
      });
    }
  }

  function setScreenItems(screenItems = []) {
    setLocalState({
      screenItems,
    });
  }

  function handleCheckStudents(studentIds = [], checkAll) {
    let index;
    const updateCheckedStudents = [...localState.checkedStudents];

    studentIds.forEach((id) => {
      index = updateCheckedStudents.indexOf(id);

      // Determine if we should un-check them based on a "check all" command.
      if (checkAll !== undefined) {
        // The ID exists, and they want to "un-check all".
        if (index > -1 && !checkAll) {
          updateCheckedStudents.splice(index, 1);
        } else if (index === -1 && checkAll) {
          // They want to check-all and this ID is not in our array.
          updateCheckedStudents.push(id);
        }
      } else {
        // They are probably just checking an individual item.
        if (index > -1) {
          // Un-check them since it's already in the array, and un-check the "check all" button.
          updateCheckedStudents.splice(index, 1);
          setCheckedProp(checkboxAllRef, false);
        } else {
          // They are not checked, so check them.
          updateCheckedStudents.push(id);
        }
      }
    });

    // Update our state to have all checked values accordingly.
    setLocalState({
      checkedStudents: updateCheckedStudents,
    });
  }

  function getStudentRoomStats(students) {
    return (students || []).reduce(
      (accum, student) => {
        const newAccum = { ...accum };
        // These are the people we have no idea where they are...
        // By having the following statuses, we have marked them as "arrived".
        if (!(student.absent || student.deniedEntry || student.checkedInCenter || student.checkedInRoom)) {
          newAccum['notArrived'] = accum['notArrived'] + 1;
        }
        if (student.checkedInCenter && !student.checkedInRoom) {
          newAccum['checkedInCenter'] = accum['checkedInCenter'] + 1;
        }
        if (student.checkedInRoom) {
          newAccum['checkedInRoom'] = accum['checkedInRoom'] + 1;
        }
        return newAccum;
      },
      { notArrived: 0, checkedInCenter: 0, checkedInRoom: 0 }
    );
  }

  function generateDetailsList() {
    const availableSeats = seatsAvailable(room);
    const studentsPerGroupType = {};

    // Get the total number of students per group type.
    if (updatedStudents && updatedStudents.length) {
      updatedStudents.forEach((student) => {
        if (studentsPerGroupType[student.groupType]) {
          studentsPerGroupType[student.groupType] = ++studentsPerGroupType[student.groupType];
        } else {
          studentsPerGroupType[student.groupType] = 1;
        }
      });
    }

    // Loop through the array of group types.
    const groupText =
      room.groupTypes && room.groupTypes.length > 0 ? (
        <ul>
          {room.groupTypes.map((groupType) => {
            const groupTitle = groupTypes[groupType] ? groupTypes[groupType].title : '';
            const groupStandard = groupTypes[groupType] && groupTypes[groupType].standard ? 'standard' : 'accommodated';
            const groupDescription =
              groupTypes[groupType] && groupTypes[groupType].description ? ` ${groupTypes[groupType].description}` : '';
            const groupText =
              groupType && groupTypes[groupType] ? `${groupType}: ${groupTitle} (${groupStandard})` : '-';

            // Return the number of students in this group type.
            const studentsHere = studentsPerGroupType[groupType] ? studentsPerGroupType[groupType] : 0;

            return (
              <li key={groupText} style={{ border: 'none' }}>
                <b>{groupText + groupDescription}</b> &mdash; {studentsHere}{' '}
                {studentsHere === 1 ? 'student' : 'students'}
              </li>
            );
          })}
        </ul>
      ) : (
        '-'
      );

    const seatsAvailableText = `${availableSeats} seat${availableSeats === 1 ? '' : 's'} available`;
    const roomStats = getStudentRoomStats(room.students);

    return [
      { id: 'room-detail-li-capacity', title: 'Capacity', value: `${room.capacity || 0} (${seatsAvailableText})` },
      {
        id: 'room-detail-li-not-arrived',
        title: 'Not Arrived',
        value: `${roomStats.notArrived} student${roomStats.notArrived === 1 ? '' : 's'}`,
      },
      {
        id: 'room-detail-li-checked-center',
        title: 'Checked In to Center',
        value: `${roomStats.checkedInCenter} student${roomStats.checkedInCenter === 1 ? '' : 's'}`,
      },
      {
        id: 'room-detail-li-checked-room',
        title: 'Checked In to Room',
        value: `${roomStats.checkedInRoom} student${roomStats.checkedInRoom === 1 ? '' : 's'}`,
      },
      { id: 'room-detail-li-testing-groups', title: 'Testing Groups', value: groupText },
      { id: 'room-detail-li-staff', title: 'Staff', value: generateReadOnlyStaffList(room) },
      {
        id: 'room-detail-li-seating-status',
        title: 'Seating Chart',
        value: seatingStatus[room.seatingStatus || 'drafted'].statusText,
      },
      { id: 'room-detail-li-status', title: 'Room Status', value: roomStatus[room.status || 'none'].statusText },
    ];
  }

  function generateHeaders() {
    // We're passing an array for sorting multiple values in a single column header, so put them in reverse order of precedence.
    // e.g. last name will be sorted first here.
    const tableHeaders = [
      { title: 'checkAll' },
      { title: 'Student', sortField: ['candMidInit', 'candFirstName', 'candLastName'] },
      { title: 'Testing Group', sortField: ['groupType'] },
      { title: 'Accommodations' },
      { title: 'Seat' },
      { title: 'Status', sortField: ['status.sortOrder'] },
      { title: 'Last Updated', sortField: ['updated'] },
    ];

    return tableHeaders;
  }

  function renderDetail(detail = {}) {
    const tourProps = {};

    if (detail && detail.title === 'Testing Groups') {
      tourProps['data-tour'] = 'testing-groups';
    }

    return detail?.title || false ? (
      <li {...tourProps} key={detail.title}>
        <div className='tdtk-item-text row mx-0 px-0' id={detail.id ? detail.id : null}>
          <span className='col-xs-4' data-automation='list-title'>
            {detail.title}:
          </span>
          <span className='col-xs-8' data-automation='list-value'>
            {detail.title === 'Staff' || detail.title === 'Testing Groups' ? (
              detail.value
            ) : (
              <strong>{detail.value}</strong>
            )}

            {
              // Add the additional description if needed.
              detail.description ? (
                <React.Fragment>
                  <br />
                  {detail.description}
                </React.Fragment>
              ) : null
            }
          </span>
        </div>
      </li>
    ) : null;
  }

  function handleBulkChange(val) {
    setLocalState({
      bulkAction: val,
    });
  }

  // Global App state.
  const { user } = useStateValue();
  const windowSize = React.useContext(ResizeContext);

  // Local state.
  const [localState, setLocalState] = React.useReducer(localStateReducer, {
    bulkAction: '',
    checkedStudents: [],
    screenItems: [],
  });

  const checkboxAllRef = React.useRef();

  const bulkActions = [
    { value: '', title: 'Choose an action' },
    { value: 'assign', title: 'Move selected students to a room' },
    { value: 'unassign', title: 'Remove selected students from rooms' },
  ];

  const { students = [], title = '' } = room;

  const emptyMessage = students.length ? '' : 'No students are assigned to this room.';

  const updatedStudents = students.map((item) => {
    return {
      ...item,
      status: generateStatus(item),
    };
  });

  const { shouldShowTour, tourOpen, onRequestClose } = useTour('roomDetails');

  const tourSteps = [
    {
      selector: '[data-tour="room-details"]',
      content: function tourContent1(props) {
        return (
          <TourContent
            totalSteps={tourSteps.length}
            title='Room Details'
            content='Find out how many students are assigned to your room and how many checked in.'
            {...props}
          />
        );
      },
    },
    {
      selector: '[data-tour="testing-groups"]',
      content: function tourContent2(props) {
        return (
          <TourContent
            totalSteps={tourSteps.length}
            title='Test Type'
            content="Find out which test you're giving and whether you're proctoring an accommodated room."
            {...props}
          />
        );
      },
    },
    {
      selector: '[data-tour="start-testing"]',
      content: function tourContent3(props) {
        return (
          <TourContent
            totalSteps={tourSteps.length}
            title='Starting the Test'
            content='Click here when timed testing starts. Students not checked in will be marked absent.'
            {...props}
          />
        );
      },
    },
    {
      selector: '[data-tour="manage-chart"]',
      content: function tourContent4(props) {
        return (
          <TourContent
            totalSteps={tourSteps.length}
            title='Seating Chart'
            content='Complete your seating chart while students are testing.'
            {...props}
          />
        );
      },
    },
  ];

  if (students.length) {
    tourSteps.push({
      selector: '[data-tour="students"]',
      content: function tourContent5(props) {
        return (
          <TourContent
            totalSteps={tourSteps.length}
            title='Your Roster'
            content='When students come to your room, click their name to check them in.'
            {...props}
          />
        );
      },
    });
  }

  return (
    <React.Fragment>
      {shouldShowTour && <TourContainer steps={tourSteps} isOpen={tourOpen} onRequestClose={onRequestClose} />}
      <div className='row'>
        <div className='col-md-9'>
          <h3 className='tdtk-h3 text-break mb-4' data-automation='room-details-heading-h3'>
            {title}
          </h3>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-9'>
          <div className='mb-4' data-tour='room-details'>
            <SimpleList items={generateDetailsList()} renderItem={renderDetail} />
          </div>
        </div>

        <div className='col-md-3'>
          <RoomDetailsButtons room={room} />
        </div>
      </div>
      <div>
        {updatedStudents.length ? (
          <React.Fragment>
            {
              // Render the bulk component if they are allowed.
              bulkComponent('bulkActionStudentRosterTop')
            }
            <div data-tour='students'>
              <Roster
                caption='Students for This Room'
                checkboxAllRef={checkboxAllRef}
                checkedItems={localState.checkedStudents}
                groupName='students'
                headers={generateHeaders()}
                handleCheckItems={handleCheckStudents}
                items={updatedStudents}
                sortFields={[
                  {
                    name: 'status.sortOrder',
                    order: 'asc',
                  },
                  {
                    name: 'candLastName',
                    order: 'asc',
                  },
                  {
                    name: 'candFirstName',
                    order: 'asc',
                  },
                  {
                    name: 'candMidInit',
                    order: 'asc',
                  },
                ]}
                emptyMessage={emptyMessage}
                renderItem={(student, options) => {
                  return (
                    <StudentRosterRow
                      handleCheckStudents={handleCheckStudents}
                      key={`student_${student.id}`}
                      options={{
                        ...options,
                        checked: localState.checkedStudents.indexOf(student.id) > -1,
                        hiddenFields: { room: true },
                      }}
                      student={student}
                    />
                  );
                }}
                setScreenItems={setScreenItems}
                showSelected={!windowSize.mobile}
                stickyHeaders={true}
              />
            </div>
            {bulkComponent('bulkActionStudentRosterBottom')}
          </React.Fragment>
        ) : (
          <div className='col-xs-12'>
            <p>No students are assigned to this room.</p>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

RoomDetails.propTypes = {
  room: PropTypes.object,
};

export default RoomDetails;
