export const roomStatus = {
  none: {
    sortOrder: 1,
    statusText: 'Ready',
  },
  started: {
    sortOrder: 2,
    statusText: 'Test in progress',
  },
  ended: {
    sortOrder: 3,
    statusText: 'Test ended',
  },
};

export const seatingStatus = {
  incomplete: {
    sortOrder: 1,
    statusText: 'Not started',
  },
  drafted: {
    sortOrder: 2,
    statusText: 'In progress',
  },
  complete: {
    sortOrder: 3,
    statusText: 'Submitted',
  },
};

export const roomOptions = {
  'Ready': 'Ready',
  'Test ended': 'Test ended',
  'Test in progress': 'Test in progress',
};

export const roomAssignmentStatus = {
  assigned: 'Students assigned',
  full: 'Full',
  unassigned: 'Unassigned',
};
