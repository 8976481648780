import { groupTypes } from '../../../constants/groupTypes';
import { getGroupTypes } from '../../../utils/getGroupTypes';
import { isWeekendEvent } from '../../../utils/event';
import uniq from 'lodash/uniq';

export const generateStatus = (student) => {
  let status = {
    sortOrder: 1,
    statusText: 'Not arrived',
  };

  if (student?.checkedInRoom) {
    status = {
      sortOrder: 5,
      statusText: 'Checked in to room',
    };
  } else if (student?.checkedInCenter) {
    status = {
      sortOrder: 1,
      statusText: 'Checked in to center',
    };
  } else if (student?.absent) {
    status = {
      sortOrder: 1,
      statusText: 'Absent',
    };
  } else if (student?.deniedEntry) {
    status = {
      sortOrder: 1,
      statusText: 'Denied entry',
    };
  }

  return status;
};

export const checkIfGroupTypesCanTestTogether = (inputTypes = [], orgEvent = {}) => {
  let eventGroupTypes = groupTypes;
  if (!isWeekendEvent(orgEvent) && !orgEvent?.dapInd) {
    eventGroupTypes = getGroupTypes(orgEvent);
  }
  const selectedGroupTypes = uniq(inputTypes);
  if (selectedGroupTypes.length > 2) {
    return false;
  }
  for (const i of selectedGroupTypes) {
    const canCombineWith = (eventGroupTypes[i] || {}).canCombineWith || [];
    for (const j of selectedGroupTypes) {
      // If firstGroupType and secondGroup type are different
      // AND cannot combine together, return false
      if (!(i === j || canCombineWith.includes(j))) {
        return false;
      }
    }
    return true;
  }
  return false;
};
