import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@cb/apricot-react';

function CustomArrow({ className, direction, onClick, style }) {
  return (
    <div className={`p-absolute h-100 seat-grid--arrow-${direction}`} style={{ ...style, top: 0 }}>
      <button
        className={`${className} seating-chart--slideshow-arrow h-100 d-flex flex--align-center flex-1 flex-direction--column seating-chart--column__vertical`}
        onClick={onClick}
      >
        <Icon name={direction} decorative />
        <span className='cb-sr-only'>Slide {direction} in the room grid slideshow</span>
      </button>
    </div>
  );
}

CustomArrow.propTypes = {
  className: PropTypes.string,
  direction: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.object,
};

export default CustomArrow;
