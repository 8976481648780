import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { BlackButton, PrimaryButton } from '@cb/apricot-react';
import { UPDATE_STUDENT } from '../../../../../apollo/mutations';
import { seatsAvailable } from '../../../../../utils/common';
import { ModalDispatchContext } from '../../../../ui/modal/ModalContext';
import OneButtonModal from '../../../../ui/modal/standard/OneButtonModal';
import TwoButtonModal from '../../../../ui/modal/standard/TwoButtonModal';
import { useNavigate } from 'react-router-dom';

function DenyEntryButton({ student = {} }) {
  // Global Modal context.
  const dispatchModal = useContext(ModalDispatchContext);
  // Apollo.
  const [updateStudent] = useMutation(UPDATE_STUDENT);

  const navigate = useNavigate();

  function changeRoom() {
    dispatchModal(null);

    navigate('/students/bulkOperations/assign', {
      state: {
        students: [student],
      },
    });
  }

  function handleDenyEntryContinueFromModal() {
    const input = {
      absent: false,
      assignedSeat: null,
      checkedInCenter: false,
      checkedInRoom: false,
      deniedEntry: !student.deniedEntry,
      id: student.id,
      testBookNumber: null,
    };

    updateStudent({ variables: { input } })
      .then(() => {
        dispatchModal(
          <OneButtonModal
            body={student.deniedEntry ? 'You’ll still need to check the student in.' : ''}
            modalId='StudentDenyEntryCompleted'
            title={student.deniedEntry ? 'You’ve approved entry of this student.' : 'You’ve denied this student entry.'}
            variant='success'
          />
        );
      })
      .catch((e) => {
        // Some server error.
        console.error('student deny entry failed', e);
      });
  }

  function denyEntry() {
    const availableSeats = seatsAvailable(student.room);

    // Check available seats to ensure they are capable of being added back in.
    if (availableSeats < 1 && student.deniedEntry && student.room) {
      return dispatchModal(
        <TwoButtonModal
          body='Move the student to a new room. There are no seats available in their original room.'
          modalId='studentChangeTestingRoom'
          primaryButtonHandler={changeRoom}
          primaryButtonLabel='Change Testing Room'
          title='Approve Entry'
          variant='error'
        />
      );
    } else {
      return dispatchModal(
        <TwoButtonModal
          body={`You’re about to ${student.deniedEntry ? 'approve entry of this student' : 'deny this student entry'}.`}
          modalId='studentDenyEntryConfirmation'
          primaryButtonHandler={handleDenyEntryContinueFromModal}
          variant='error'
        />
      );
    }
  }

  const buttonProps = {
    'data-automation': 'button-denyEntry',
    'id': 'button-denyEntry',
    'onClick': denyEntry,
    'small': true,
    'className': 'mb-3 w-100',
  };

  if (student.deniedEntry) {
    return (
      <PrimaryButton {...buttonProps} className={`${buttonProps.className} cb-palette-blue2`}>
        Undo Deny Entry
      </PrimaryButton>
    );
  }

  return <BlackButton {...buttonProps}>Deny Entry</BlackButton>;
}

DenyEntryButton.propTypes = {
  student: PropTypes.object,
};

export default DenyEntryButton;
