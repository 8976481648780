import { Button } from '@cb/apricot-react';
import React from 'react';

export type ModalButtonStyle = 'yellow' | 'black' | 'naked' | 'default';

type Props = {
  buttons?: {
    id: string;
    handler(e: React.SyntheticEvent): void;
    style: ModalButtonStyle;
    text: string;
    disabled?: boolean;
  }[];
};

/**
 * Returns a nice list of footer buttons for your modal.
 */
function ModalFooter({ buttons = [] }: Props) {
  return (
    <div className='display-flex justify-content-end py-2'>
      {buttons.map((button) => (
        <Button
          variant={button.style}
          className={'cb-margin-left-16'}
          disabled={button?.disabled}
          key={button?.id}
          id={button?.id}
          onClick={button?.handler}
          small
        >
          {button?.text}
        </Button>
      ))}
    </div>
  );
}

export default ModalFooter;
