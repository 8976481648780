import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';
import { cb_table } from '../../../constants/accommodations/cb';
import { cbLogo } from '../../../constants/cbLogo';
import { formatter } from './formatter';
import { generateStudentRoster, generateFullRoster, generateRoomRoster } from './generators';
import { GET_NON_DIGITAL_PRINTABLE } from '../../../apollo/queries';
import { ModalDispatchContext } from '../../ui/modal/ModalContext';
import { TRIGGER_PHOTO_ROSTER_GENERATOR, RETRIEVE_PHOTO_ROSTER } from '../../../apollo/mutations';
import { localStateReducer, parseDateFormat } from '../../../utils/common';
import { TabWrapper as Tab } from '../../ui/tab/TabWrapper';
import { TabsWrapper as Tabs } from '../../ui/tab/TabsWrapper';
import { tableGenerator } from '../../../utils/pdfUtils';
import { useStateValue } from '../../../context/AppContext';
import { useTitle } from '../../../constants/useTitle';
import events from '../../../constants/events';
import get from 'lodash/get';
import moment from 'moment';
import OneButtonModal from '../../ui/modal/standard/OneButtonModal';
import pdfFonts from './vfs_fonts';
import pdfMake from 'pdfmake/build/pdfmake';
import QueryComponent from '../../common/network/QueryComponent';
import Spinner from '../../ui/loading/SpinnerWrapper';
import RendezvousLinkButton from './RendezvousLinkButton';
import { YellowButton } from '@cb/apricot-react';
import { WEBSOCKET_MESSAGE_TYPE } from '../../common/network/types';

// Add the fonts to the library.
pdfMake.vfs = pdfFonts;

pdfMake.fonts = {
  Roboto: {
    normal: 'Roboto-Regular.ttf',
    bold: 'Roboto-Medium.ttf',
    italics: 'Roboto-Italic.ttf',
    bolditalics: 'Roboto-MediumItalic.ttf',
  },
  robotoSlab: {
    bold: 'robotoSlab-regular.ttf',
    bolditalics: 'robotoSlab-regular.ttf',
    italics: 'robotoSlab-regular.ttf',
    normal: 'robotoSlab-regular.ttf',
  },
};

function Container() {
  function handleClick(printType, students, refetch) {
    return async function (e) {
      e && e.preventDefault && e.preventDefault();

      if (refetch) {
        await refetch();
      }

      setLocalState({
        disabled: true,
        printType,
        students,
      });
    };
  }

  function handlePrint(printType, students) {
    const fileTimestamp = moment().format('MM_DD_YYYY_h:mm:ss_a');
    const formattedEventDate = parseDateFormat(startDate, endDate);
    const timestamp = moment().format('MMMM DD, YYYY [at] h:mm:ss a');
    const _zebra = {
      fillColor: (rowIndex) => {
        return rowIndex % 2 === 0 ? '#f0f0f0' : null;
      },
    };

    let filename = `roster_${fileTimestamp}.pdf`;
    const pdfHeader = [
      {
        columns: [
          {
            image: cbLogo,
            width: 150,
            height: 38,
            margin: [40, 40, 40, 10],
          },
          {
            alignment: 'right',
            margin: [40, 44, 40, 10],
            style: 'h2',
            text: `${testEvent}: ${formattedEventDate}
            ${centerName} (Test center code: ${testCenterCd})`,
          },
        ],
      },
    ];
    let pdfContent = [];
    let pdfPageMargins = [40, 80];
    const startingOrientation = 'portrait';

    // Call the formatter function to sort and organize our array.
    students = formatter(students, printType);

    switch (printType) {
      case 'student_roster_alphabetical':
        // Set the filename.
        filename = `room_directory_${fileTimestamp}.pdf`;
        pdfPageMargins = [40, 120, 40, 80];
        pdfHeader.push({
          margin: [40, 0],
          style: 'h1',
          text: 'Room Directory for Students',
        });

        // Public student rosters.
        pdfContent = [generateStudentRoster(students).map((room) => room)];
        break;

      case 'student_roster_full':
        // Set the filename.
        filename = `test_center_roster_full_${fileTimestamp}.pdf`;

        pdfPageMargins = [40, 120, 40, 80];

        pdfHeader.push({
          margin: [40, 0],
          style: 'h1',
          text: 'Test Center Roster',
        });

        // Generate the content for the Room Rosters.
        pdfContent = [
          {
            style: 'p',
            text: 'These rosters list the students assigned to each testing room. Registered and waitlisted students who are not assigned to a testing room are listed at the end.',
          },

          {
            style: 'h2',
            text: 'Test Day Changes',
          },

          {
            style: 'p',
            text: 'If your test center has enough staff, seats, and test materials, registered students under 21 can make the following changes on test day:',
          },

          {
            // to treat a paragraph as a bulleted list, set an array of items under the ul key
            ul: [
              'Change from standard to accommodated testing if they have a College Board SSD eligibility letter.',
              'Change from accommodated to standard testing if they’re 18 or older or they have a signed permission letter from their guardian.',
            ],
            style: 'list',
          },

          {
            style: 'p',
            text: 'Waitlisted students and test takers who are 21 or over may not change their registration in any way on test day.',
          },

          {
            style: 'h2',
            text: 'How to Validate Documents at Check-In',
          },

          {
            style: 'p',
            text: 'Before admitting a student, make sure each of these statements is true:',
          },

          {
            ol: [
              {
                text: [
                  'The ',
                  { text: 'name ', bold: true },
                  'on the roster matches the name on the photo ID and the admission ticket exactly (see the Coordinator Manual for exceptions).',
                ],
              },
              {
                text: [
                  'The ',
                  { text: 'date of birth ', bold: true },
                  'and ',
                  { text: 'gender ', bold: true },
                  'on the roster matches those on the photo ID (if present) and the admission ticket exactly.',
                ],
              },
              {
                text: [
                  'The following information on the roster matches the information on the admission ticket exactly:',
                ],
              },

              {
                ul: ['Test type', 'Test center number', 'Test date', 'Registration number'],
                style: 'list',
              },
            ],
            style: 'list',
          },

          {
            bold: true,
            style: 'p',
            text: 'Note:',
          },
          {
            ul: [
              'If any statement is untrue, deny the student entry. Exception: Students who identify with a gender not listed on their ID can test if their appearance on test day matches their photo ID and admission ticket.',
              'If the student is not on any roster, call us at the number listed in your manual.',
              'Photos are required unless an exemption is noted on the roster and the admission ticket.',
            ],
            style: 'list',
          },

          {
            pageBreak: 'before',
            style: 'h2',
            text: 'Accommodation Codes',
          },

          {
            style: 'p',
            text: 'Use the table below to look up the accommodation codes used in your rosters. If the code you’re looking for is not listed, call Services for Students with Disabilities at 844-255-7728.',
          },

          {
            layout: _zebra,
            table: {
              body: tableGenerator(
                // Headers.
                ['Code', 'Accommodation Description'],
                // Rows.
                Object.keys(cb_table)
                  .map((key) => {
                    return [key, cb_table[key]];
                  })
                  .sort((a, b) => {
                    // Sort the accommodation codes smallest to biggest.
                    return parseInt(a[0], 10) - parseInt(b[0], 10);
                  })
              ),
              dontBreakRows: true,
              headerRows: 1,
              style: 'table',
              widths: ['auto', '*'],
            },
          },

          // Generate PDF content.
          generateFullRoster(students, formattedEventDate).map((studentTable) => studentTable),
        ];
        break;

      case 'student_roster_room':
        // Set the filename.
        filename = `room_rosters_${fileTimestamp}.pdf`;
        pdfPageMargins = [40, 120, 40, 80];
        pdfHeader.push({
          margin: [40, 0],
          style: 'h1',
          text: 'Room Rosters',
        });

        // Generate the content for the Room Rosters.
        pdfContent = [
          {
            style: 'p',
            text: 'These rosters list the students assigned to each testing room. Registered and waitlisted students who are not assigned to a testing room are listed at the end.',
          },

          {
            style: 'h2',
            text: 'Test Day Changes',
          },

          {
            style: 'p',
            text: 'If your test center has enough staff, seats, and test materials, registered students under 21 can make the following changes on test day:',
          },

          {
            // to treat a paragraph as a bulleted list, set an array of items under the ul key
            ul: [
              'Change from standard to accommodated testing if they have a College Board SSD eligibility letter.',
              'Change from accommodated to standard testing if they’re 18 or older or they have a signed permission letter from their guardian.',
            ],
            style: 'list',
          },

          {
            style: 'p',
            text: 'Waitlisted students and test takers who are 21 or over may not change their registration in any way on test day.',
          },

          {
            style: 'h2',
            text: 'How to Validate Documents at Check-In',
          },

          {
            style: 'p',
            text: 'Before admitting a student, make sure each of these statements is true:',
          },

          {
            ol: [
              {
                text: [
                  'The ',
                  { text: 'name ', bold: true },
                  'on the roster matches the name on the photo ID and the admission ticket exactly (see the Coordinator Manual for exceptions).',
                ],
              },
              {
                text: [
                  'The ',
                  { text: 'date of birth ', bold: true },
                  'and ',
                  { text: 'gender ', bold: true },
                  'on the roster matches those on the photo ID (if present) and the admission ticket exactly.',
                ],
              },
              {
                text: [
                  'The following information on the roster matches the information on the admission ticket exactly:',
                ],
              },

              {
                ul: ['Test type', 'Test center number', 'Test date', 'Registration number'],
                style: 'list',
              },
            ],
            style: 'list',
          },

          {
            bold: true,
            style: 'p',
            text: 'Note:',
          },

          {
            ul: [
              'If any statement is untrue, deny the student entry. Exception: Students who identify with a gender not listed on their ID can test if their appearance on test day matches their photo ID and admission ticket.',
              'If the student is not on any roster, call us at the number listed in your manual.',
              'Photos are required unless an exemption is noted on the roster and the admission ticket.',
            ],
            style: 'list',
          },

          {
            style: 'h2',
            text: 'How to Mark the Roster',
          },

          {
            style: 'p',
            text: 'If you were unable to check students in with Test Day Toolkit, you’ll need to mark the roster by hand before sending it to the College Board.',
          },
          {
            ol: [
              {
                style: 'p',
                stack: [
                  'For each student, complete the Attendance column with the appropriate letter:',
                  {
                    ul: ['Admitted: P', 'Denied entry: X', 'Absent: A'],
                    style: 'list',
                  },
                ],
              },
              // {
              //   style: 'p',
              //   text: 'If a student adds or removes the SAT Essay, mark an X in the Changed Essay column.',
              // },
            ],
            style: 'list',
          },
          {
            pageBreak: 'before',
            style: 'h2',
            text: 'Accommodation Codes',
          },

          {
            style: 'p',
            text: 'Use the table below to look up the accommodation codes used in your rosters. If the code you’re looking for is not listed, call Services for Students with Disabilities at 844-255-7728.',
          },

          {
            layout: _zebra,
            table: {
              body: tableGenerator(
                // Headers.
                ['Code', 'Accommodation Description'],
                // Rows.
                Object.keys(cb_table)
                  .map((key) => {
                    return [key, cb_table[key]];
                  })
                  .sort((a, b) => {
                    // Sort the accommodation codes smallest to biggest.
                    return parseInt(a[0], 10) - parseInt(b[0], 10);
                  })
              ),
              dontBreakRows: true,
              headerRows: 1,
              style: 'table',
              widths: ['auto', '*'],
            },
          },

          // Generate PDF content.
          generateRoomRoster(students, formattedEventDate).map((room) => room),
        ];
        break;

      default:
        break;
    }

    // Generate the PDF.
    const docDefinition = {
      pageOrientation: startingOrientation,
      content: pdfContent,
      footer: (currentPage, pageCount) => {
        const currentYear = new Date().getFullYear();
        return {
          columns: [
            {
              style: 'p',
              text: `Page ${currentPage} of ${pageCount}
              Last updated: ${timestamp}`,
              margin: [40, 20, 0, 20],
            },
            {
              style: 'p',
              text: `
                © ${currentYear} The College Board.`,
              alignment: 'right',
              margin: [0, 20, 40, 20],
            },
          ],
        };
      },
      header: pdfHeader,
      pageMargins: pdfPageMargins,
      pageSize: 'LETTER',
      styles: {
        h1: {
          bold: true,
          font: 'robotoSlab',
          fontSize: 16,
          margin: [0, 0, 0, 4],
        },
        h2: {
          bold: true,
          font: 'robotoSlab',
          fontSize: 12,
          margin: [0, 2, 0, 2],
        },
        p: {
          fontSize: 10,
          margin: [0, 0, 0, 4],
        },
        list: {
          fontSize: 10,
          margin: [10, 0, 0, 4],
        },
        tableCell: {
          fontSize: 9,
        },
        tableCellLg: {
          fontSize: 14,
        },
        tableHeader: {
          bold: true,
          fontSize: 9,
          fillColor: '#006298',
          color: '#ffffff',
        },
        tableHeaderLg: {
          bold: true,
          fontSize: 14,
          fillColor: '#006298',
          color: '#ffffff',
        },
      },
    };

    // Generate the PDF and reset the state.
    pdfMake.createPdf(docDefinition).download(filename, () => {
      setLocalState({
        disabled: false,
        printType: '',
      });
    });
  }

  React.useEffect(() => {
    if (localState.disabled && localState.printType) {
      handlePrint(localState.printType, localState.students);
    }
  });

  // Global App state.
  const { orgEvent, user } = useStateValue();
  const dispatchModal = useContext(ModalDispatchContext);

  const centerName = orgEvent.title;
  const endDate = orgEvent.eventEndDt;
  const startDate = orgEvent.eventStartDt;
  const testCenterCd = orgEvent.testCenterCd;
  const testEvent = events[orgEvent['testEvent']];
  const role = user.role;

  // Local state.
  const [localState, setLocalState] = React.useReducer(localStateReducer, {
    disabled: false,
    printType: '',
    students: [],
  });

  useTitle('Printable Student Rosters');

  const [triggerPhotoRosterGenerator] = useMutation(TRIGGER_PHOTO_ROSTER_GENERATOR);
  const [retrievePhotoRoster] = useMutation(RETRIEVE_PHOTO_ROSTER);

  return (
    <React.Fragment>
      <h1 className='tdtk-h1 py-4' data-automation='heading-h1'>
        Printable Student Rosters
      </h1>
      <QueryComponent
        query={{
          kind: 'GetNonDigitalPrintable',
          specification: GET_NON_DIGITAL_PRINTABLE,
        }}
      >
        {(data, refetch) => {
          const students = get(data, 'viewer.site.students') || [];

          if (students.length > 0) {
            return (
              <div className='box-card border border__gray--left border__gray--right border__gray--bottom shadow'>
                <Tabs>
                  <Tab label='Room Directory for Students' panelId='guidelines' selected>
                    <h2 className='tdtk-h2 mb-4'>Room Directory for Students</h2>
                    <p className='mb-4'>This roster directs students to the right room on test day.</p>
                    <ul className='mb-4'>
                      <li>Post the room directory where students can see it.</li>
                      <li>
                        Registered and waitlisted students are listed alphabetically and their room assignments are
                        displayed.
                      </li>
                      <li>No sensitive student information is shared.</li>
                    </ul>
                    {localState.disabled ? (
                      <Spinner />
                    ) : (
                      <YellowButton
                        small
                        disabled={localState.disabled}
                        dynamic-asset-link-track='test_center_roster.pdf'
                        onClick={handleClick('student_roster_alphabetical', students, refetch)}
                        icon='print'
                        iconDecorative
                      >
                        Print Room Directory
                      </YellowButton>
                    )}
                  </Tab>
                  <Tab
                    label={<span data-automation='tab-test-center-roster'>Test Center Roster</span>}
                    panelId='fundamentals'
                  >
                    <h2 className='tdtk-h2 mb-4' data-automation='tabheader-test-center-roster'>
                      Test Center Roster
                    </h2>
                    <p className='mb-4'>
                      The test center roster lists all registered and waitlisted students alphabetically. Print it
                      before the test and use it to check students in if you can&rsquo;t access Test Day Toolkit.
                    </p>
                    <ul className='mb-4'>
                      <li>
                        Print the test center roster right before test day so it&rsquo;s as up to date as possible.
                      </li>
                      <li>Do not show this roster to students since it includes sensitive student information.</li>
                      <li>Give copies to staff who check students into the test center.</li>
                    </ul>
                    {localState.disabled ? (
                      <Spinner />
                    ) : (
                      <>
                        <YellowButton
                          small
                          disabled={localState.disabled}
                          dynamic-asset-link-track='student_roster_full.pdf'
                          name='btn-printStudentRosterFull'
                          onClick={handleClick('student_roster_full', students, refetch)}
                          icon='print'
                          iconDecorative
                        >
                          Print Test Center Roster
                        </YellowButton>
                        {(role === 'admin' && moment(orgEvent.eventStartDt).diff(moment(), 'days') <= 4) ||
                        role === 'CBAdmin' ? (
                          <RendezvousLinkButton
                            icon='user'
                            className='ml-2'
                            messageType={WEBSOCKET_MESSAGE_TYPE.PHOTO_ROSTER}
                            resultReady='Ready'
                            dataAutomation='photo-roster-submit-button'
                            onRequest={async () => {
                              const { rendezvousCode } =
                                (await triggerPhotoRosterGenerator({}))?.data?.triggerPhotoRosterGenerator || {};
                              dispatchModal(
                                <OneButtonModal
                                  title='Generating Student Photo Roster'
                                  modalId='photoRosterRequest'
                                  variant='info'
                                  clickOverlayToClose={false}
                                  body={
                                    <span data-automation='photo-roster-request'>
                                      <Spinner />
                                    </span>
                                  }
                                />
                              );
                              return {
                                rendezvous: rendezvousCode,
                              };
                            }}
                            onResolve={async ({ rendezvous }) => {
                              const { rosterSignedUrl, exists } =
                                (
                                  await retrievePhotoRoster({
                                    variables: {
                                      input: {
                                        rendezvousCode: rendezvous,
                                      },
                                    },
                                  })
                                )?.data?.retrievePhotoRoster || {};
                              if (rosterSignedUrl && exists) {
                                dispatchModal(
                                  <OneButtonModal
                                    title='Generating Student Photo Roster'
                                    modalId='photoRosterResolve'
                                    variant='success'
                                    clickOverlayToClose={false}
                                    body={
                                      <span data-automation='photo-roster-resolve'>
                                        Click the link to download your student photo roster:{' '}
                                        <a href={rosterSignedUrl}>Photo Roster.pdf</a>
                                      </span>
                                    }
                                  />
                                );
                              }
                              return {
                                resolveUrl: rosterSignedUrl,
                                exists,
                              };
                            }}
                            onTimeout={async () => {
                              dispatchModal(
                                <OneButtonModal
                                  title='Generating Student Photo Roster'
                                  modalId='photoRosterTimeout'
                                  variant='error'
                                  body={
                                    <span data-automation='photo-roster-timeout'>
                                      Sorry, but we are unable to complete generating your student photo roster. Please
                                      click the &quot;Close&quot; button below and try again.
                                    </span>
                                  }
                                />
                              );
                            }}
                            onFailure={async () => {
                              dispatchModal(
                                <OneButtonModal
                                  title='Generating Student Photo Roster'
                                  modalId='photoRosterFailure'
                                  variant='error'
                                  body={
                                    <span data-automation='photo-roster-failure'>
                                      Sorry, but there was an error generating your student photo roster. Please click
                                      the &quot;Close&quot; button below and try again.
                                    </span>
                                  }
                                />
                              );
                            }}
                          >
                            Print with Student Photos
                          </RendezvousLinkButton>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </Tab>
                  <Tab label='Room Rosters' panelId='visual'>
                    <h2 className='tdtk-h2 mb-4'>Room Rosters</h2>
                    <p className='mb-4'>Room rosters list the students assigned to each testing room separately.</p>
                    <p className='mb-4'>
                      Print them before and after the test. Use them to check students in if you can&rsquo;t access Test
                      Day Toolkit. After the test, print and submit them as your final rosters. Keep copies for six
                      months.
                    </p>
                    <ul className='mb-4'>
                      <li>Print room rosters right before test day so they&rsquo;re as up to date as possible.</li>
                      <li>Do not show these rosters to students since they include sensitive student information.</li>
                      <li>Give each proctor their room roster.</li>
                    </ul>
                    {localState.disabled ? (
                      <Spinner />
                    ) : (
                      <YellowButton
                        small
                        disabled={localState.disabled}
                        dynamic-asset-link-track='room_rosters.pdf'
                        name='btn-printRoomRoster'
                        onClick={handleClick('student_roster_room', students, refetch)}
                        icon='print'
                        iconDecorative
                      >
                        Print Room Rosters
                      </YellowButton>
                    )}
                  </Tab>
                </Tabs>
              </div>
            );
          } else {
            return <p> No students have been assigned to this site. </p>;
          }
        }}
      </QueryComponent>
    </React.Fragment>
  );
}

Container.displayName = 'PrintableStudentRostersContainer';

export default Container;
