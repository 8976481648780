import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@cb/apricot-react';
import Input from '../form/Input';
import TooltipWrapper from '../tooltip/TooltipWrapper';
import './TableControls.scss';

function TableControls({ tableControlElements = [], tableControlForm = null }) {
  function buildControlElement(element) {
    const controlElementTemplate = {
      // Render a Button.
      button: (
        <Button
          variant={element.variant || 'black'}
          aria-controls='tableControlForm'
          aria-expanded={element.isExpanded}
          className={element.classes}
          data-automation={`button-${element.name}`}
          id={element.name}
          key={element.name}
          onClick={element.onClick}
          icon={element.icon}
          iconDecorative
          small
        >
          {element.label}
        </Button>
      ),
      buttonWithPopover: (
        <>
          <Button
            variant={element.variant || 'black'}
            aria-controls='tableControlForm'
            aria-expanded={element.isExpanded}
            className={element.classes}
            data-automation={`button-${element.name}`}
            id={element.name}
            icon={element.icon}
            key={element.name}
            onClick={element.onClick}
            type='button'
            small
          >
            {element.label}
          </Button>
          <TooltipWrapper light={true} trigger={element.name}>
            <>
              <div>{element.popoverTitle}</div>
              <div>{element.popoverText}</div>
            </>
          </TooltipWrapper>
        </>
      ),
      // Render a text input.
      input: (
        <Input
          data-automation='input-roster-search'
          defaultValue={element.defaultValue}
          icon={element.icon}
          id={element.name}
          key={element.name}
          label={element.label}
          labelClassName='cb-sr-only'
          name={element.name}
          onChange={element.onChange}
          placeholder={element.placeholder}
          mainClassName={element.className}
        />
      ),
    };

    return controlElementTemplate[element.type];
  }

  const tableControlElementsRendered = tableControlElements.map((tableControl) => (
    <div className={`ml-3 ${tableControl.type === 'input' ? 'flex-grow-1' : 'flex-shrink-0'}`} key={tableControl.name}>
      {buildControlElement(tableControl)}
    </div>
  ));

  if (tableControlElements.length > 0) {
    return (
      <React.Fragment>
        <div className='display-flex justify-content-between align-items-center py-3 pr-3 cb-border shadow hide-from-print'>
          {tableControlElementsRendered}
        </div>

        <div className='hide-from-print' id='tableControlForm' role='region' tabIndex='-1'>
          {tableControlForm}
        </div>
      </React.Fragment>
    );
  } else {
    return null;
  }
}

TableControls.propTypes = {
  tableControlElements: PropTypes.array,
  tableControlForm: PropTypes.element,
};

export default TableControls;
