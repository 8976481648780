import React, { useState } from 'react';
import PropTypes from 'prop-types';

function RolloverImage({ src, hoverSrc, alt, title }) {
  const [srcAttrib, setSrcAttrib] = useState(src);

  return (
    <img
      className='photo--img'
      src={srcAttrib}
      onFocus={() => setSrcAttrib(hoverSrc)}
      onBlur={() => setSrcAttrib(src)}
      onMouseOver={() => setSrcAttrib(hoverSrc)}
      onMouseOut={() => setSrcAttrib(src)}
      alt={alt}
      title={title}
    />
  );
}

RolloverImage.propTypes = {
  src: PropTypes.string,
  hoverSrc: PropTypes.string,
  alt: PropTypes.string,
  title: PropTypes.string,
};

export default RolloverImage;
