import React from 'react';
import PropTypes from 'prop-types';
import { hasAdminAccess } from '../../../utils/user';
import { useStateValue } from '../../../context/AppContext';

function Table({
  checkboxAllRef = {},
  handleCheckAll = () => {},
  headers = [],
  id = '',
  items = [],
  options = {},
  renderHeader,
  renderItem,
  stickyHeaders = false,
  addStaff = false,
}) {
  // Global App state.
  const { user } = useStateValue();

  return (
    <div className='box-card border__rounded-full p-0 mb-4 shadow'>
      <table className={`cb-table cb-table-striped mb-0 ${addStaff ? 'cb-table-add-staff' : ''}`}>
        {options.caption ? <caption className='cb-sr-only'>{options.caption}</caption> : null}
        <thead>
          <tr>
            {headers.length && headers[0] && headers[0].title === 'checkAll' && hasAdminAccess(user.rid, user.role) ? (
              <th scope='col' className={stickyHeaders ? 'sticky' : ''}>
                <label htmlFor='check-all-items'>
                  <input id='check-all-items' onClick={handleCheckAll(items)} ref={checkboxAllRef} type='checkbox' />
                  <span className='cb-sr-only'> Select all items in this table</span>
                </label>
              </th>
            ) : null}
            {headers.filter((h) => h.title !== 'checkAll').map((header, index) => renderHeader(header, index))}
          </tr>
        </thead>
        <tbody>{items.map((item) => renderItem(item, options))}</tbody>
      </table>
    </div>
  );
}

Table.propTypes = {
  checkboxAllRef: PropTypes.object,
  handleCheckAll: PropTypes.func,
  headers: PropTypes.array,
  id: PropTypes.string,
  items: PropTypes.array,
  options: PropTypes.object,
  renderHeader: PropTypes.func,
  renderItem: PropTypes.func,
  stickyHeaders: PropTypes.bool,
  addStaff: PropTypes.bool,
};

export default Table;
