import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const RoomContext = createContext();

export const RoomContextProvider = ({ children }) => {
  const [startedButNotSubmitted, setStartedButNotSubmitted] = useState(0);

  return (
    <RoomContext.Provider value={{ startedButNotSubmitted, setStartedButNotSubmitted }}>
      {children}
    </RoomContext.Provider>
  );
};

RoomContextProvider.propTypes = {
  children: PropTypes.any,
};
