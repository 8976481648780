import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { logUserOut } from '../../../utils/user';
import { ModalDispatchContext } from '../../ui/modal/ModalContext';
import TwoButtonModal from '../../ui/modal/standard/TwoButtonModal';
import { getAuthSession } from '../../../utils/auth';
import { removeSignupCookies } from './utility';

function LogoutModal() {
  const navigate = useNavigate();
  const authSession = getAuthSession();
  const dispatchModal = useContext(ModalDispatchContext);

  useEffect(() => {
    dispatchModal(
      <TwoButtonModal
        body={
          <>
            <p>You&rsquo;re signed in to College Board as {authSession?.basicProfile?.userName || ''}.</p>
            <p>To access Test Day Toolkit for the first time:</p>
            <ol>
              <li>Save your work in all open College Board sessions.</li>
              <li>
                Click the <strong>Sign Out</strong> button below.
              </li>
              <li>When this modal closes, stay at this URL and sign back in.</li>
            </ol>
          </>
        }
        modalId='logoutForSignupModal'
        primaryButtonHandler={logUserOut}
        primaryButtonLabel='Sign Out'
        secondaryButtonHandler={() => {
          navigate('/');
          removeSignupCookies();
        }}
        secondaryButtonLabel='Stay signed in and go to the toolkit later.'
        title='Sign out of College Board to continue.'
        variant='error'
      />
    );
  }, [authSession?.basicProfile?.userName, navigate, dispatchModal]);

  return null;
}

export default LogoutModal;
