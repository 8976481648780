import React, { useContext } from 'react';
import { BULK_REMOVE_USERS, BULK_STATUS, RESEND_EMAIL } from '../../../../apollo/mutations';
import { GET_STAFF, GET_STAFF_AND_ROOMS } from '../../../../apollo/queries';
import { hasAdminAccess } from '../../../../utils/user';
import { localStateReducer, setCheckedProp } from '../../../../utils/common';
import { ModalDispatchContext } from '../../../ui/modal/ModalContext';
import { useMutation } from '@apollo/client';
import { useStateValue } from '../../../../context/AppContext';
import { useTitle } from '../../../../constants/useTitle';
import contentStrings from '../../../../constants/contentStrings';
import BulkActionDropdown from '../../../ui/BulkActionDropdown';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import OneButtonModal from '../../../ui/modal/standard/OneButtonModal';
import QueryComponent from '../../../common/network/QueryComponent';
import sortBy from 'lodash/sortBy';
import StaffRoster from './StaffRoster';
import TwoButtonModal from '../../../ui/modal/standard/TwoButtonModal';
import { ROLES_MAP } from '../../../../constants/roles';

function Container() {
  function generateErrorMessage() {
    const { bulkAction = '', checkedStaff = [] } = localState;

    if (bulkAction === '' || !checkedStaff.length) {
      return 'Select an action and at least one staff member.';
    }

    const loggedInUser = staffList.find((s) => s.username === user.username);
    const checkedStaffUsers = staffList.filter((s) => checkedStaff.includes(s.id));

    if (
      checkedStaff.includes(user.id) ||
      (loggedInUser && checkedStaffUsers.some((s) => s.username === user.username))
    ) {
      return 'Uncheck the box next to your name and try again.';
    }

    let activatedStaffs = 0;
    let deactivatedStaffs = 0;
    let staffRoleError;
    let adminCount = staffList.filter((s) => s.role === ROLES_MAP.ADMIN).length;

    checkedStaff.forEach((id) => {
      const item = staffList.find((s) => s.id === id);
      // Send a failure if they've tried to de-activate/remove the last admin
      if ((bulkAction === 'deactivate' || bulkAction === 'remove') && item.role === ROLES_MAP.ADMIN) {
        if (adminCount <= 1) {
          staffRoleError = contentStrings.staff.warn.coordinator;
        } else {
          adminCount--;
        }
      }
      if (item && item.active) {
        activatedStaffs++;
      } else {
        deactivatedStaffs++;
      }
    });

    if (staffRoleError) {
      return staffRoleError;
    }

    if (bulkAction === 'activate' && deactivatedStaffs === 0) {
      return 'Select at least one staff member without toolkit access.';
    }

    if (bulkAction === 'deactivate' && activatedStaffs === 0) {
      return 'Select at least one staff member with toolkit access.';
    }

    return null;
  }

  // Bulk delete from event.
  function handleDelete(input) {
    // Get the main staff details so we don't have to query again.
    const selectedStaffDetails = sortBy(
      staffList.filter((staff) => input.ids.includes(staff.id)),
      ['lastName', 'firstName']
    );
    const staffUl =
      selectedStaffDetails.length > 0 ? (
        <ul key='selectedStaffList'>
          {selectedStaffDetails.map((staff) => (
            <li key={staff.id}>{`${staff.lastName ? staff.lastName + ', ' : ''}${staff.firstName}`}</li>
          ))}
        </ul>
      ) : (
        ''
      );

    // Ensure they're not including themselves.
    if (input.ids.includes(user.id)) {
      return dispatchModal(
        <OneButtonModal buttonLabel='OK' modalId='bulkError' title='You can’t remove yourself.' variant='error' />
      );
    }

    function handleDeleteConfirm() {
      bulkRemove({
        variables: { input },
        refetchQueries: ['inventory', 'getStaff', 'getSiteStats'],
        update: (cache, { data: { bulkUsers } }) => {
          try {
            // Read the existing data from the local cache using the GET_STAFF query
            const { viewer } = cache.readQuery({ query: GET_STAFF }) || { viewer: { site: { staff: [] } } };
            const updatedStaff = Array.isArray(bulkUsers) ? bulkUsers : [];

            // Create a new object newData by spreading the existing viewer data and updating the staff array
            const newData = {
              viewer: {
                id: viewer.id,
                site: {
                  id: viewer.site.id,
                  staff: [...viewer.site.staff, ...updatedStaff],
                },
              },
            };

            // Write the updated data back to the local cache using the GET_STAFF query
            cache.writeQuery({
              query: GET_STAFF,
              data: newData,
            });
          } catch (error) {
            // Log an error message if there's an issue updating the cache
            console.error('Error updating cache:', error);
          }
        },
      }).then(() => {
        setLocalState({
          checkedStaff: [],
        });

        setCheckedProp(checkboxAllRef, false);

        dispatchModal(
          <OneButtonModal
            modalId='bulkStaffSuccess'
            title={'You’ve removed selected staff from this test administration.'}
            variant='success'
          />
        );
      });
    }

    dispatchModal(
      <TwoButtonModal
        body={
          <>
            <p>They’ll still be on your test center staff list.</p>
            <br />
            {staffUl}
          </>
        }
        modalId='bulkStaffConfirmation'
        primaryButtonHandler={handleDeleteConfirm}
        title='You’re about to remove the selected staff from this administration.'
        variant='error'
      />
    );
  }

  function sendBulkAction(action = '', staff = []) {
    const input = {
      ids: staff,
    };

    let staffStatusTotal = 0;

    switch (action) {
      case 'resendEmail':
        resendEmail({
          variables: { input },
        })
          .then(() => {
            dispatchModal(
              <OneButtonModal
                modalId='bulkStaffSuccess'
                onClose={() => {
                  setLocalState({
                    checkedStaff: [],
                  });
                  setCheckedProp(checkboxAllRef, false);
                }}
                title={'We’ve sent access emails to selected staff members.'}
                variant='success'
              />
            );
          })
          .catch(() => {
            return dispatchModal(
              <OneButtonModal
                body={'Please try again later.'}
                modalId='bulkError'
                onClose={() => {
                  setLocalState({
                    checkedStaff: [],
                  });
                  setCheckedProp(checkboxAllRef, false);
                }}
                title={'We were unable to send access emails to one or more staff members.'}
                variant='error'
              />
            );
          });
        break;
      case 'remove':
        handleDelete(input);
        break;
      case 'activate':
        staffStatusTotal = staffList.filter((s) => staff.find((cs) => cs === s.id && !s.active)).length;

        dispatchModal(
          <TwoButtonModal
            body={
              <p key={1}>
                Staff with Test Day Toolkit access see sensitive student information.
                <br key='2' />
                <br key='3' />
                Tip: Wait until test day to grant access.
              </p>
            }
            modalId='bulkStaffConfirmation'
            primaryButtonHandler={() => {
              bulkUsersStatus(action);
            }}
            title={`You're about to grant toolkit access to ${staffStatusTotal} staff member${
              staffStatusTotal === 1 ? '' : 's'
            }.`}
            variant='error'
          />
        );
        break;
      case 'deactivate':
        staffStatusTotal = staffList.filter((s) => staff.find((cs) => cs === s.id && s.active)).length;

        dispatchModal(
          <TwoButtonModal
            body={<p key={1}>Staff without Test Day Toolkit access can’t help out on test day.</p>}
            modalId='bulkStaffConfirmation'
            primaryButtonHandler={() => {
              bulkUsersStatus(action);
            }}
            title={`You’re about to revoke toolkit access of ${staffStatusTotal} staff member${
              staffStatusTotal === 1 ? '' : 's'
            }.`}
            variant='error'
          />
        );
        break;
      default:
        break;
    }
  }

  function handleSubmit(e) {
    e && e.preventDefault && e.preventDefault();

    const { bulkAction, checkedStaff = [] } = localState;

    const isDisabled = !!generateErrorMessage();

    // Only proceed if they've selected an action, they have no errors, and they have checked staff.
    if (bulkAction && !isDisabled && !isEmpty(checkedStaff)) {
      const nonSelfCheckedUsers = [];

      checkedStaff.forEach((id) => {
        const item = staffList.find((s) => s.id === id);
        if (item) {
          // Don't perform any bulk actions on these roles.
          if (item.id !== user.id && item.username !== user.username) {
            nonSelfCheckedUsers.push(item.id);
          }
        }
      });

      if (nonSelfCheckedUsers.length > 0) {
        sendBulkAction(bulkAction, nonSelfCheckedUsers);
      } else {
        // They really shouldn't ever get to this point, but have a fallback just in case.
        return dispatchModal(
          <OneButtonModal
            buttonLabel='Continue'
            modalId='bulkError'
            title='Uncheck the box next to your name and try again.'
            variant='error'
          />
        );
      }
    }
  }

  function bulkUsersStatus(bulkAction) {
    const input = {
      ids: [...localState.checkedStaff],
      operation: bulkAction,
    };

    bulkUpdate({
      variables: { input },
      refetchQueries: ['getStaff', 'getSiteStats'],
      update: (cache, { data: { bulkUsers } }) => {
        try {
          // Read the existing data from the local cache using the GET_STAFF query
          const { viewer } = cache.readQuery({ query: GET_STAFF }) || { viewer: { site: { staff: [] } } };
          const updatedStaff = Array.isArray(bulkUsers) ? bulkUsers : [];

          // Create a new object newData by spreading the existing viewer data and updating the staff array
          const newData = {
            viewer: {
              id: viewer.id,
              site: {
                id: viewer.site.id,
                staff: [...viewer.site.staff, ...updatedStaff],
              },
            },
          };

          // Write the updated data back to the local cache using the GET_STAFF query
          cache.writeQuery({
            query: GET_STAFF,
            data: newData,
          });
        } catch (error) {
          // Log an error message if there's an issue updating the cache
          console.error('Error updating cache:', error);
        }
      },
    })
      .then(() => {
        setLocalState({
          checkedStaff: [],
        });

        setCheckedProp(checkboxAllRef, false);

        dispatchModal(
          <OneButtonModal
            modalId='bulkStaffSuccess'
            title={
              bulkAction === 'activate'
                ? 'You’ve granted access to the selected staff members.'
                : 'You’ve revoked the Toolkit access of selected staff.'
            }
            variant='success'
          />
        );
      })
      .catch((e) => {
        console.error('bulk update user status failed', e);
      });
  }

  function handleBulkChange(val) {
    setLocalState({
      bulkAction: val,
    });
  }

  function bulkComponent(selectId) {
    let bulkErrorMessage;

    if (hasAdminAccess(user.rid, user.role)) {
      bulkErrorMessage = generateErrorMessage();

      return (
        <BulkActionDropdown
          errorMessage={bulkErrorMessage}
          defaultValue={localState.bulkAction}
          handleSubmit={handleSubmit}
          handleValueSelect={handleBulkChange}
          selectId={selectId}
          values={bulkActions}
        />
      );
    } else {
      return null;
    }
  }

  function handleCheckStaff(staffIds = [], checkAll) {
    let index;
    const updatedCheckedStaff = [...localState.checkedStaff];

    staffIds.forEach((id) => {
      index = updatedCheckedStaff.indexOf(id);

      // Determine if we should un-check them based on a "check all" command.
      if (checkAll !== undefined) {
        // The ID exists, and they want to "un-check all".
        if (index > -1 && !checkAll) {
          updatedCheckedStaff.splice(index, 1);
        } else if (index === -1 && checkAll) {
          // They want to check-all and this ID is not in our array.
          updatedCheckedStaff.push(id);
        }
      } else {
        // They are probably just checking an individual item.
        if (index > -1) {
          // Un-check them since it's already in the array, and un-check the "check all" button.
          updatedCheckedStaff.splice(index, 1);

          // un-check the "Check All" box.
          setCheckedProp(checkboxAllRef, false);
        } else {
          // They are not checked, so check them.
          updatedCheckedStaff.push(id);
        }
      }
    });

    // Update our state to have all checked values accordingly.
    setLocalState({
      checkedStaff: updatedCheckedStaff,
    });
  }

  // Global App state.
  const { orgEvent, user } = useStateValue();
  const dispatchModal = useContext(ModalDispatchContext);

  // Local state.
  const [localState, setLocalState] = React.useReducer(localStateReducer, {
    bulkAction: '',
    checkedStaff: [],
    searchValue: '',
  });

  // Apollo.
  const [bulkRemove] = useMutation(BULK_REMOVE_USERS);
  const [bulkUpdate] = useMutation(BULK_STATUS);
  const [resendEmail] = useMutation(RESEND_EMAIL);

  const bulkActions = [
    { value: '', title: 'Choose an action' },
    { value: 'activate', title: 'Grant toolkit access' },
    { value: 'deactivate', title: 'Revoke toolkit access' },
    { value: 'resendEmail', title: 'Send access email' },
    { value: 'remove', title: 'Remove from administration' },
  ];

  const checkboxAllRef = React.useRef();

  let roomList;
  let staffList;
  useTitle('Staff');
  return (
    <div className={orgEvent?.dapInd ? 'container' : ''}>
      <h1 className='tdtk-h1 py-4' data-automation='heading-h1'>
        Test Day Staff
      </h1>

      <QueryComponent query={{ kind: 'GetStaffAndRooms', specification: GET_STAFF_AND_ROOMS }}>
        {(staffData) => {
          roomList = get(staffData, 'viewer.site.rooms', []);
          staffList = get(staffData, 'viewer.site.staff', []);

          if (staffList) {
            return (
              <React.Fragment>
                {
                  // Render the bulk component if they are allowed.
                  staffList.length > 0 && bulkComponent('bulkActionStaffTop')
                }

                <StaffRoster
                  checkboxAllRef={checkboxAllRef}
                  checkedStaff={localState.checkedStaff}
                  handleCheckStaff={handleCheckStaff}
                  roomList={roomList}
                  staffList={staffList}
                />

                {
                  // Render the bulk component if they are allowed.
                  staffList.length > 0 && bulkComponent('bulkActionStaffBottom')
                }
              </React.Fragment>
            );
          } else {
            return <p className='mb-4'>You haven&rsquo;t added any staff for this test date yet.</p>;
          }
        }}
      </QueryComponent>
    </div>
  );
}

Container.displayName = 'StaffContainer';

export default Container;
