import { sortItems } from '../../../utils/common';

/**
 * Returns printer-friendly data.
 */
export function formatter(students, printType) {
  // This ensures we're not mutating the original array.
  let localStudents = JSON.parse(JSON.stringify(students));

  switch (printType) {
    case 'student_roster_alphabetical':
    case 'student_roster_full':
      // All students, alphabetically.
      localStudents = sortItems(
        localStudents,
        [
          {
            name: 'candLastName',
            order: 'asc',
          },
          {
            name: 'candFirstName',
            order: 'asc',
          },
          {
            name: 'candMidInit',
            order: 'asc',
          },
          {
            name: 'groupType',
            order: 'asc',
          },
        ],
        'natural'
      );

      break;

    case 'student_roster_room':
      // Defaulting to 'zzzzzzzzzz' to put undefined values at the bottom, like Unassigned students.
      localStudents = sortItems(
        localStudents,
        [
          {
            name: 'room.title',
            order: 'asc',
          },
          {
            name: 'groupType',
            order: 'asc',
          },
          {
            name: 'candLastName',
            order: 'asc',
          },
          {
            name: 'candFirstName',
            order: 'asc',
          },
          {
            name: 'candMidInit',
            order: 'asc',
          },
        ],
        'natural'
      );

      break;

    default:
      break;
  }
  return localStudents;
}
