import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';

export const nest = function (seq, keys) {
  if (!keys.length) {
    return seq;
  }
  const first = keys[0];
  const rest = keys.slice(1);
  return mapValues(groupBy(seq, first), function (value) {
    return nest(value, rest);
  });
};
