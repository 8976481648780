import React, { useContext } from 'react';
import { ModalDispatchContext } from '../ModalContext';
import ModalDialogWrapper from '../ModalDialogWrapper';
import ModalFooter, { ModalButtonStyle } from '../ModalFooter';

type Props = {
  modalId: string;
  variant: 'error' | 'info' | 'input' | 'success';
  body?: string | object;
  clickOverlayToClose?: boolean;
  primaryButtonHandler?(): void;
  primaryButtonLabel?: string;
  primaryButtonStyle?: ModalButtonStyle;
  secondaryButtonHandler?(): void;
  secondaryButtonLabel?: string;
  secondaryButtonStyle?: ModalButtonStyle;
  title?: string;
  trigger?: string;
};

export default function TwoButtonModal({
  body = '',
  modalId,
  primaryButtonHandler = () => {},
  primaryButtonLabel = 'Continue',
  primaryButtonStyle = 'yellow',
  secondaryButtonHandler = () => {},
  secondaryButtonLabel = 'Cancel',
  secondaryButtonStyle = 'black',
  title = 'Are you sure?',
  trigger = '',
  variant = 'success',
  clickOverlayToClose = false,
}: Props) {
  // Modal Context.
  const dispatchModal = useContext(ModalDispatchContext);

  function closeModal() {
    dispatchModal(null);
    secondaryButtonHandler();
  }

  function handlePrimaryButton() {
    dispatchModal(null);
    primaryButtonHandler();
  }

  return (
    <ModalDialogWrapper
      modalId={`modal-${modalId}`}
      onClose={closeModal}
      open
      title={title}
      trigger={trigger}
      variant={variant}
      clickOverlayToClose={clickOverlayToClose}
    >
      <>
        {body}
        <ModalFooter
          buttons={[
            {
              handler: closeModal,
              id: `btn-secondary-${modalId}`,
              style: secondaryButtonStyle,
              text: secondaryButtonLabel,
            },
            {
              handler: handlePrimaryButton,
              id: `btn-primary-${modalId}`,
              style: primaryButtonStyle,
              text: primaryButtonLabel,
            },
          ]}
        />
      </>
    </ModalDialogWrapper>
  );
}
