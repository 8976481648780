import React from 'react';
import PropTypes from 'prop-types';
import './common.scss';

function WizardNav({ prevButton, nextButton }) {
  return (
    <nav
      role='navigation'
      aria-label='Test Day Proctor Wizard Steps'
      id='wizard-navigation'
      className='wizard-nav-container'
    >
      <div className='wizard-nav d-flex container'>
        {prevButton}
        {nextButton}
      </div>
    </nav>
  );
}

WizardNav.propTypes = {
  prevButton: PropTypes.object,
  nextButton: PropTypes.object,
};

export default WizardNav;
