import React from 'react';
import ToggleDirections from '../../../../../common/ToggleDirections';
import AttendanceStep from '../AttendanceStep';

function StudentRosterDirectionsAP({ showDirectionInfo = false }) {
  return (
    <ToggleDirections showDirectionInfo={showDirectionInfo}>
      <div className='row p-3' role='region' aria-label='directions'>
        <div className='col-md-4 px-4 cb-margin-sm-down-bottom-32'>
          <AttendanceStep
            step={1}
            title='Ask students to check in.'
            descr={
              <p>
                Tell students to follow the instructions you posted. You&rsquo;ll see their names in the{' '}
                <strong>Entered Room Code</strong> list when they enter the room code.
              </p>
            }
            tooltip={{
              linkName: 'What if no students appear in the  "Assigned" list?',
              descr: (
                <>
                  If you don&rsquo;t see names in the <strong>Assigned</strong> list, students will be assigned to your
                  room when they enter the room code.
                </>
              ),
            }}
          />
        </div>
        <div className='col-md-4 px-4 cb-margin-sm-down-bottom-32'>
          <AttendanceStep
            step={2}
            title='Mark students present.'
            descr={
              <p>
                Before you mark a student present, make sure you see them in your room. If students are present but not
                shown in the <strong>Entered Room Code</strong> list, prompt them to enter the room code.
              </p>
            }
            tooltip={{
              linkName: <>What if you don&rsquo;t know the student?</>,
              descr: (
                <>
                  If you&rsquo;re not sure who a student is, click their name and follow the instructions to check their
                  ID.
                </>
              ),
            }}
          />
        </div>
        <div className='col-md-4 px-4'>
          <AttendanceStep
            step={3}
            title='Check student progress.'
            descr={
              <p>
                Students are listed as <strong>Ready to Test</strong> when they complete app check-in. If everyone you
                marked present is ready to test, click <strong>Next Step</strong> and read the script.
              </p>
            }
            tooltip={{
              linkName: 'What if some students are behind?',
              descr: (
                <>
                  Remind students to complete check-in. If they can&rsquo;t do it quickly, continue to the next step so
                  they don&rsquo;t delay everyone. You can help them get started later.
                  <br />
                  <br />
                  NOTE: Students can&rsquo;t start testing until you mark them present.
                </>
              ),
            }}
          />
        </div>
      </div>
    </ToggleDirections>
  );
}

export default StudentRosterDirectionsAP;
