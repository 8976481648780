import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

function shortenString(s = '', len = 10, suffix = '') {
  if (!len || len < 1) return [false, s];
  if (!s || s?.length < len) return [false, s];
  return [true, `${s.substring(0, len - 1)}${suffix}`];
}

function Accommodations({ item = {}, studentId = '', studentName = '' }) {
  const { pathname } = useLocation();
  const supplementalTextMaxLength = 15;
  const [textWasShortened, supplementalInfo] = shortenString(item?.supplementalInfo, supplementalTextMaxLength);

  return (
    <>
      {`${item?.title} - ${item?.description}`}
      {supplementalInfo ? (
        <span data-automation={`${item?.title}-supplementalInfo`}>
          <> - </>
          {supplementalInfo}
          {textWasShortened ? (
            <Link
              aria-label={`more accomodations for ${studentName}`}
              to={{
                hash: '#accomm-list',
                pathname: `/students/get/${studentId}`,
              }}
              state={{
                from: pathname,
              }}
            >
              ...more
            </Link>
          ) : (
            ''
          )}
        </span>
      ) : (
        ''
      )}
    </>
  );
}

Accommodations.propTypes = {
  item: PropTypes.object,
  studentId: PropTypes.string,
  studentName: PropTypes.string,
};

export default Accommodations;
