import React from 'react';
import PropTypes from 'prop-types';
import { Mutation } from '@apollo/client/react/components';
import Messaging from '../../../context/Messaging';
import { UPDATE_USER } from '../../../apollo/mutations';
import { analyticsTracking } from '../../../utils/analytics';
import { useStateDispatch, useStateValue } from '../../../context/AppContext';
import './Legal.scss';
import { YellowButton } from '@cb/apricot-react';

function Legal() {
  function userApproved() {
    dispatch({
      user: {
        confirmed: true,
      },
    });
  }

  // Global App state.
  const { user } = useStateValue();
  const dispatch = useStateDispatch();

  const input = {
    id: user.id,
    confirmed: true,
  };

  React.useEffect(() => {
    analyticsTracking(user.role, '', 'terms');
  }, [user.role]);

  return (
    <Mutation mutation={UPDATE_USER} variables={{ input }} onCompleted={userApproved}>
      {(updateUser) => (
        <React.Fragment>
          <h1 className='tdtk-h1 py-4' data-automation='heading-h1'>
            Test Day Toolkit
          </h1>
          <div className='legal box-card shadow' data-automation='mou-container'>
            <Messaging dynamic={true} section='mou' />
          </div>
          <p className='text-center mb-4'>
            <YellowButton small data-automation='submit-agreement' onClick={updateUser}>
              Submit
            </YellowButton>
          </p>
        </React.Fragment>
      )}
    </Mutation>
  );
}

Legal.propTypes = {
  legalAgreementType: PropTypes.string,
};

export default Legal;
