import React from 'react';
import PropTypes from 'prop-types';

export default function RoomCode({ code = '' }) {
  return <>{code}</>;
}

RoomCode.propTypes = {
  code: PropTypes.string,
};
