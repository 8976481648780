import { parseStats } from './parseStats';

export function calculateStats(selector, statsSource = {}, dapInd) {
  const { roomsInUse = 0, testingGroups = {}, totalRooms = {} } = statsSource;

  const roomsInUseCount = Object.keys(roomsInUse).length;

  const roomIds = Object.keys(totalRooms);
  const totalSeats = roomIds.reduce((acc, val) => {
    const room = totalRooms[val];
    return acc + room.capacity;
  }, 0);

  const totalRoomsCount = roomIds.length;

  const formattedStats = parseStats({
    ...statsSource,
    roomsInUse: `${roomsInUseCount}/${totalRoomsCount}`,
    testingGroups: Object.keys(testingGroups) || 0,
    totalRooms: totalRoomsCount,
    totalSeats,
  });

  switch (selector) {
    case 'room':
      return [
        formattedStats['totalRooms'],
        formattedStats['totalSeats'],
        formattedStats['regStudents'],
        formattedStats['roomsInUse'],
        formattedStats['testingGroups'],
      ];
    case 'site':
      return [
        formattedStats['regStudents'],
        formattedStats['testingGroups'],
        formattedStats['totalRooms'],
        formattedStats['totalSeats'],
        formattedStats['totalStaff'],
      ];
    case 'staff':
      return [
        formattedStats['totalStaff'],
        formattedStats['allottedStaff'],
        formattedStats['proctors'],
        formattedStats['roomMonitors'],
        formattedStats['hallMonitors'],
        ...(dapInd ? [formattedStats['technologyCoordinators']] : []),
      ];
    case 'student':
      return [
        formattedStats['totalStudents'],
        formattedStats['regStudents'],
        formattedStats['waitStudents'],
        formattedStats['unassignedStudents'],
      ];
    default:
      console.error('Unknown selector type');
      return [];
  }
}
