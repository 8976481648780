import React from 'react';
import PropTypes from 'prop-types';

function ListTable({ callerName = '', clickHandler = () => {}, list = [] }) {
  return (
    <div className='tdtk-item-list tdtk-item-list-simple'>
      <ul>
        {list.map((listItem) => (
          <li key={listItem.id}>
            <div className='tdtk-item-text'>
              <a
                className='cb-blue5-color'
                data-automation={`listtable-item-${callerName}`}
                href={listItem.url}
                onClick={(e) => {
                  clickHandler(e, listItem.url);
                }}
              >
                <p
                  className='tdtk-item-title cb-roboto-bold mb-4'
                  data-automation={`${callerName}-${listItem.label.split(' ').join('-').toLowerCase()}`}
                >
                  {listItem.label}
                </p>
                {listItem.description ? (
                  <p className='mb-4 cb-gray1-color'>{listItem.description.replace(/<[^>]*>?/gm, '')}</p>
                ) : null}
              </a>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

ListTable.propTypes = {
  callerName: PropTypes.string,
  clickHandler: PropTypes.func,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      url: PropTypes.string,
    })
  ).isRequired,
};

export default ListTable;
