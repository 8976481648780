import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import GroupsInfo from './GroupsInfo';
import { GET_NON_DIGITAL_GROUPS_INFO } from '../../../apollo/queries';
import QueryComponent from '../../common/network/QueryComponent';

function Container({ description = [], showHeading1 = true, showHeading2 = false, usePopovers = false }) {
  return (
    <QueryComponent
      query={{
        kind: 'GetNonDigitalGroupsInfo',
        specification: GET_NON_DIGITAL_GROUPS_INFO,
      }}
    >
      {(data) => {
        const students = get(data, 'viewer.site.students') || [];
        if (students.length) {
          return (
            <GroupsInfo
              description={description}
              showHeading1={showHeading1}
              showHeading2={showHeading2}
              students={students}
              usePopovers={usePopovers}
            />
          );
        } else {
          return showHeading1 ? (
            <React.Fragment>
              <h1 className='tdtk-h1 mb-4'>Testing Groups</h1>
              <p>Student roster information is unavailable.</p>
            </React.Fragment>
          ) : null;
        }
      }}
    </QueryComponent>
  );
}

Container.propTypes = {
  description: PropTypes.array,
  showHeading1: PropTypes.bool,
  showHeading2: PropTypes.bool,
  usePopovers: PropTypes.bool,
};

export default Container;
