import React from 'react';
import PropTypes from 'prop-types';
import { getTitle } from '../../../../constants/roles';
import Selection from '../../../ui/Selection';
import { displayItemValue } from '../../../../utils/staff';

function AddStaffSelection({
  callback = () => {},
  hallMonitorsNeeded = 0,
  hallMonitorsSelected = [],
  proctorSelected = [],
  roomMonitorsNeeded = 0,
  roomMonitorsSelected = [],
  staff = [],
}) {
  function generateStaffOptions(staffCandidates) {
    return [...staffCandidates.map((user) => displayItemValue(user))];
  }

  function findMatchingStaff(list = [], value) {
    return list.find((user) => {
      return user ? user.value === value : false;
    });
  }

  function renderStaffName() {
    return function (data) {
      const { role, room, title, value } = data;

      let displayName = title;

      if (!findMatchingStaff(proctorSelected, value) && !findMatchingStaff(roomMonitorsSelected, value)) {
        // Not currently selected item.  Add more information to be displayed on menu selection
        if (role && !room) {
          displayName += ` (${getTitle(role)})`;
        } else if (!role && room) {
          displayName += ` (${room.title})`;
        } else if (role && room) {
          displayName += ` (${getTitle(role)} in ${room.title})`;
        }
      }

      return displayName;
    };
  }

  function handleStaffSelection(id) {
    return function (selectedValues) {
      const selectedObj = {
        [id]: selectedValues,
      };

      // Ensure the values are not null.
      if (selectedValues === null) {
        selectedObj[id] = [];
      } else if (!Array.isArray(selectedValues)) {
        selectedObj[id] = [selectedValues];
      }

      // Update the local state so our Select boxes update so we don't duplicate users between two roles.
      if (id === 'proctorSelected') {
        setProctorSelectedLocal(selectedObj[id]);
      } else if (id === 'roomMonitorsSelected') {
        setRoomMonitorsSelectedLocal(selectedObj[id]);
      }

      // Send the selection to our parent.
      callback(selectedObj);
    };
  }

  /**
   * Ensure a user doesn't appear in both lists.
   */

  // Room proctor candidates.
  const [proctorSelectedLocal, setProctorSelectedLocal] = React.useState(proctorSelected);

  // Room monitor candidates.
  const [roomMonitorsSelectedLocal, setRoomMonitorsSelectedLocal] = React.useState(roomMonitorsSelected);

  const roomMonitorCandidates = staff.filter((s) => {
    return proctorSelectedLocal.find((a) => a.value === s.id) === undefined;
  });

  const proctorCandidates = staff.filter((s) => {
    return roomMonitorsSelectedLocal.find((a) => a.value === s.id) === undefined;
  });

  // Hall monitor candidates. Should exclude admins.
  const hallMonitorCandidates = [...staff];

  return (
    <React.Fragment>
      {
        // Show the hall monitor selection if that's what they're editing.
        hallMonitorsNeeded ? (
          <div className='item'>
            <Selection
              additionalInstructions={
                hallMonitorsNeeded >= hallMonitorsSelected.length
                  ? null
                  : 'Note: You’re adding more hall monitors than we recommend.'
              }
              elements={generateStaffOptions(hallMonitorCandidates)}
              handleSelectChange={handleStaffSelection}
              id='hallMonitorsSelected'
              key='hallMonitorsSelected'
              isMulti={true}
              label={`Hall Monitor (${hallMonitorsNeeded} recommended)`}
              labelKeys={['title']}
              name='hallMonitorsSelected'
              optionRenderer={renderStaffName}
              placeholder={'Choose a Hall Monitor'}
              readOnly={false}
              required={false}
              selectedElements={hallMonitorsSelected}
              valueKey='value'
            />
          </div>
        ) : (
          <React.Fragment>
            <div className='item mb-4'>
              <Selection
                elements={generateStaffOptions(proctorCandidates)}
                handleSelectChange={handleStaffSelection}
                id='proctorSelected'
                key='proctorSelected'
                isMulti={proctorSelected.length > 1}
                label={'Proctor (1 required)'}
                labelKeys={['title']}
                name='proctorSelected'
                optionRenderer={renderStaffName}
                placeholder={'Choose a Proctor'}
                readOnly={false}
                required={false}
                selectedElements={proctorSelected}
                valueKey='value'
              />
            </div>

            <div className='item'>
              <Selection
                additionalInstructions={
                  roomMonitorsNeeded >= roomMonitorsSelected.length
                    ? null
                    : 'Note: You’re adding more room monitors than we recommend.'
                }
                elements={generateStaffOptions(roomMonitorCandidates)}
                handleSelectChange={handleStaffSelection}
                id='roomMonitorsSelected'
                key='roomMonitorsSelected'
                isMulti={true}
                label={`Room Monitor (${roomMonitorsNeeded} recommended)`}
                labelKeys={['title']}
                name='roomMonitorsSelected'
                optionRenderer={renderStaffName}
                placeholder={'Choose a Room Monitor'}
                readOnly={false}
                required={false}
                selectedElements={roomMonitorsSelected}
                valueKey='value'
              />
            </div>
          </React.Fragment>
        )
      }
    </React.Fragment>
  );
}

AddStaffSelection.propTypes = {
  callback: PropTypes.func,
  hallMonitorsNeeded: PropTypes.number,
  hallMonitorsSelected: PropTypes.array,
  proctorSelected: PropTypes.array,
  roomMonitorsNeeded: PropTypes.number,
  roomMonitorsSelected: PropTypes.array,
  staff: PropTypes.array,
};

export default AddStaffSelection;
