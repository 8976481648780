export const cb_table = {
  '001': 'Reading: time and one-half (+50%)',
  '002': 'Reading: double time (+100%)',
  '003': 'Reading: more than double time (>+100%)',
  '004': 'Writing: time and one-half (+50%)',
  '005': 'Writing: double time (+100%)',
  '006': 'Writing: more than double time (>+100%)',
  '007': 'Math: time and one-half (+50%)',
  '008': 'Math: double time (+100%)',
  '009': 'Math: more than double time (>+100%)',
  '010': 'Listening: time and one-half (+50%)',
  '011': 'Listening: double time (+100%)',
  '012': 'Listening: more than 100% (>+100%)',
  '013': 'Speaking: time and one-half (+50%)',
  '014': 'Speaking: double time (+100%)',
  '015': 'Speaking: more than double time (>+100%)',
  '016': 'Extra Breaks',
  '017': 'Extended Breaks',
  '018': 'Breaks as needed',
  '019': 'Permission to test blood sugar',
  '020': 'Other (breaks)',
  '021': 'Large print test book: other',
  '022': 'Large print test book: 14 point',
  '023': 'Large print test book: 20 point',
  '024': 'Large print (large block) answer sheet',
  '025': 'Human reader (assistive technology can be used)',
  '026': 'MP3 (assistive technology can be used)',
  '027': 'Braille',
  '028': 'Magnification device (non-electronic)',
  '029': 'Magnification device (electronic)',
  '030': 'Braille (assistive Technology can be used)',
  '031': 'Other',
  '032': 'Large print (large block) answer sheet',
  '033': 'Computer (word processor ONLY for essays ONLY)',
  '034': 'Writer/scribe (assistive technology can be used)',
  '035': 'Braille Writer',
  '036': 'Record answers in Test book',
  '037': 'Other',
  '038': 'Preferential seating',
  '039': 'Small group testing',
  '040': 'School-based testing',
  '041': 'One-to-one testing',
  '042': 'Food/drink/medication',
  '043': 'Printed copy of verbal instructions',
  '044': 'Sign language interpreter for oral instructions ONLY',
  '045': 'Other',
  '046': 'Limited Time Testing',
  '047': 'Home / hospital testing',
  '048': 'Wheelchair accessibility',
  '049': 'Other',
  '050': 'Assistive technology',
  '051': 'Auditory amplification / FM system',
  '083': 'Other Center Based',
  '128': 'ATC form (Assistive technology can be used)',
  '129': '4-function calculator',
  '145': 'Colored overlay',
  '149': 'EL time and one-half (+50%)',
  '200': 'Paper Test for digital assessments',
  '201': 'Screen reader/text-to-speech',
  '202': 'Dictation for digital tests (speech-to-text)',
  '203': 'Braille, UEB Technical',
  '204': 'Human Reader for digital tests',
  '205': 'Scribe for digital tests',
  '300': 'SE - Paper test for digital assessments',
};

export const digital_cb_table = {
  '001': 'Reading: Time and one-half (+50%)',
  '002': 'Reading: Double time (+100%)',
  '003': 'Reading: More than double time (>+100%)',
  '004': 'Writing: Time and one-half (+50%)',
  '005': 'Writing: Double time (+100%)',
  '006': 'Writing: More than double time (>+100%)',
  '007': 'Math: Time and one-half (+50%)',
  '008': 'Math: Double time (+100%)',
  '009': 'Math: More than double time (>+100%)',
  '016': 'Extra Breaks',
  '017': 'Extended Breaks',
  '018': 'Breaks as needed',
  '019': 'Permission to test blood sugar',
  '020': 'Other (breaks)',
  '021': 'Large Print Test Book - Other',
  '022': 'Large Print Test Book - 14 Pt',
  '023': 'Large Print Test Book - 20 Pt',
  '024': 'Large Print (Large Block) answer sheet (No bubbles)',
  '025': 'Human reader for paper tests',
  '026': 'Pre-recorded audio (tests with screen reader)',
  '027': 'Braille',
  '028': 'Magnification device (non-electronic)',
  '029': 'Magnification device (electronic)',
  '030': 'Raised Line Drawings',
  '031': 'Other (reading/seeing text)',
  '032': 'Large Print (Large Block) answer Sheet (No bubbles)',
  '033': 'Computer (Word Processor ONLY for essays ONLY)',
  '034': 'Writer/Scribe for paper tests',
  '035': 'Braille Writer',
  '036': 'Record answers in test book',
  '037': 'Other (recording responses)',
  '038': 'Preferential seating',
  '039': 'Small group testing',
  '040': 'School-based Testing',
  '041': 'One-to-one testing',
  '042': 'Food/drink/medication',
  '043': 'Copy of verbal instructions',
  '044': 'Sign language interpreter for oral instructions ONLY',
  '045': 'Other',
  '046': 'Limited Time Testing',
  '047': 'Home / hospital testing',
  '048': 'Wheelchair Accessibility',
  '049': 'Other (modified setting)',
  '050': 'Assistive technology',
  '051': 'Auditory amplification / FM system',
  '083': 'Other Center-based',
  '087': 'State-Allowed: Breaks: Extra',
  '092': 'State-Allowed: One-to-One Testing',
  '093': 'State-Allowed: Small Group Testing',
  '094': 'State-Allowed: Human Reader for digital tests',
  '095': 'State-Allowed: Sign Language Interpreter',
  '096': 'State-Allowed: Limited Time Testing',
  '097': 'State-Allowed: Assistive Technology',
  '103': 'State-Allowed: Writer/Scribe for digital tests',
  '104': 'State-Allowed: Magnification device (electronic/non-electronic)',
  '105': 'State-Allowed: Use of Math Aids (e.g. Manipulatives, Ruler, Abacus, etc.)',
  '109': 'State Allowed: Permission for Food/Drinks/Medication',
  '110': 'State-Allowed: Clarification of Directions',
  '111': 'State-Allowed: Use of Non-Electronic Reading/Writing Aids',
  '112': 'State-Allowed: Auditory Amplification / FM System',
  '115': 'State-Allowed: Reading content & questions in native language',
  '117': 'State-Allowed: Sign language interpreter for test content and/or responses',
  '123': 'State-Allowed: Reading - Time and one-half (+50%)',
  '124': 'State-Allowed: Reading - Double time (+100%)',
  '125': 'State-Allowed: Reading - More than double time (>+100%)',
  '126': 'State-Allowed: Essay: Time and one-half (+50%)',
  '127': 'State-Allowed: Essay: Double time (+100%)',
  '128': 'Assistive technology compatible test form (ATC)',
  '129': '4-function Calculator',
  '130': 'State-Allowed: Essay More than double time (>+100%)',
  '131': 'State-Allowed: Math - Time and one-half (+50%)',
  '132': 'State-Allowed: Math - Double time (+100%)',
  '133': 'State-Allowed: Math - More than double time (>+100%)',
  '134': 'State-Allowed: Home/Hospital',
  '135': 'State-Allowed: Other Accommodation',
  '140': 'State-Allowed: Raised Line Drawings',
  '145': 'Colored overlay/color contrast',
  '146': 'State-Allowed: EL - Math Only Testing',
  '147': 'State-Allowed: EL - Oral Translation of Math',
  '149': 'EL time and one-half (+50%)',
  '150': 'State-Allowed: Screen reader for digital tests',
  '151': 'State-Allowed: Dictation for digital tests',
  '153': 'State-Allowed: Breaks: Extended',
  '154': 'State-Allowed: Breaks as Needed',
  '200': 'Paper Test for digital assessments',
  '201': 'Screen reader/text-to-speech',
  '202': 'Dictation for digital tests (speech-to-text)',
  '203': 'Braille, UEB Technical',
  '204': 'Human reader for digital tests',
  '205': 'Scribe for digital tests',
  '206': 'Extra Breaks Math Only',
  '207': 'Extra Breaks Essay Only',
  '208': 'Nutrition break',
  '300': 'SE - Paper test for digital assessments',
};
