import React from 'react';
import PropTypes from 'prop-types';
import { hasAdminAccess } from '../../../utils/user';
import { useStateValue } from '../../../context/AppContext';

function Table({
  checkboxAllRef = {},
  handleCheckAll = () => {},
  headers = [],
  items = [],
  options = {},
  renderHeader,
  renderItem,
  stickyHeaders = false,
}) {
  // Global App state.
  const { user } = useStateValue();

  return (
    <div
      aria-describedby={options.caption ? 'aria-table-desc' : ''}
      aria-label='Students'
      className='student-div-table'
      role='table'
    >
      {options.caption ? (
        <div className='cb-sr-only' id='aria-table-desc'>
          {options.caption}
        </div>
      ) : null}
      <span className='cb-sr-only'>Column headers with buttons are sortable.</span>
      {/* table headers */}
      <div className={`px-0 mx-0 student-table-rowgroup cb-gray5-bg ${stickyHeaders ? 'sticky' : ''}`} role='rowgroup'>
        <div className='row student-table-header mx-0' role='row'>
          {headers.length && headers[0] && headers[0].title === 'checkAll' && hasAdminAccess(user.rid, user.role) ? (
            <div>
              <label htmlFor='check-all-items'>
                <input id='check-all-items' onClick={handleCheckAll(items)} ref={checkboxAllRef} type='checkbox' />
                <span className='cb-sr-only'> Select all items in this table</span>
              </label>
            </div>
          ) : null}
          {headers.filter((h) => h.title !== 'checkAll').map((header, index) => renderHeader(header, index))}
        </div>
      </div>
      {/* table items */}
      <div className='px-0 mx-0 student-table-rowgroup monitor-student-table-list-wrapper' role='rowgroup'>
        {items.map((item) => renderItem(item, options))}
      </div>
    </div>
  );
}

Table.propTypes = {
  checkboxAllRef: PropTypes.object,
  handleCheckAll: PropTypes.func,
  headers: PropTypes.array,
  items: PropTypes.array,
  options: PropTypes.object,
  renderHeader: PropTypes.func,
  renderItem: PropTypes.func,
  stickyHeaders: PropTypes.bool,
};

export default Table;
