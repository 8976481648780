import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import omitBy from 'lodash/omitBy';
import uniqBy from 'lodash/uniqBy';
import { AddStaff } from './add-staff/AddStaff';
import { BULK_IMPORT_USERS, RESEND_EMAIL } from '../../../../apollo/mutations';
import { GET_STAFF, GET_STAFF_INVENTORY } from '../../../../apollo/queries';
import { isEqual } from 'apollo-utilities';
import { localStateReducer, sortItems } from '../../../../utils/common';
import { ModalDispatchContext } from '../../../ui/modal/ModalContext';
import { ResizeProvider, ResizeContext } from '../../../../context/ResizeContext';
import { roles } from '../../../../constants/roles';
import { useLocation } from 'react-router-dom';
import { useStateValue } from '../../../../context/AppContext';
import OneButtonModal from '../../../ui/modal/standard/OneButtonModal';
import QueryComponent from '../../../common/network/QueryComponent';
import Roster from '../../../common/Roster';
import RosterFilters from '../../../common/RosterFilters';
import StaffForm from '../form/StaffForm';
import StaffRosterRow from './StaffRosterRow';
import TwoButtonModal from '../../../ui/modal/standard/TwoButtonModal';
import { YellowButton } from '@cb/apricot-react';

function StaffRoster({
  checkboxAllRef = {},
  checkedStaff = [],
  handleCheckStaff = () => {},
  roomList = [],
  staffList = [],
}) {
  function applyFilters(filterObj = {}, searchValue = '', searchFields = ['lastName', 'firstName', 'username']) {
    // Only get active filters, make a special case for the false case
    const activeFilters = Object.keys(filterObj).filter((key) => filterObj[key] || filterObj[key] === false);
    const isFiltered = !isEmpty(activeFilters);
    let filteredStaff = staffList;
    let isVisible = true;

    if (!isEmpty(searchValue)) {
      searchValue = searchValue.toUpperCase();
    }

    if (isFiltered || searchValue) {
      filteredStaff = staffList.filter((item) => {
        isVisible = activeFilters.every((filter) => {
          // Check if they are unassigned.
          if ((filter === 'role' || filter === 'room.id') && filterObj[filter] === 'unassigned') {
            return !get(item, filter);
          } else if (filter === 'confirmed' && filterObj[filter] === 'all') {
            return true;
          } else if (filter === 'confirmed' && filterObj[filter] === false) {
            return !get(item, filter);
          } else {
            return get(item, filter) === filterObj[filter];
          }
        });

        if (isVisible && searchValue) {
          const objFields = [];
          searchFields.forEach((searchField) => {
            if (item[searchField]) {
              objFields.push(item[searchField].toUpperCase());
            }
          });
          if (!isEmpty(objFields)) {
            isVisible = objFields.some((item) => item.indexOf(searchValue) > -1);
          }
        }
        return isVisible;
      });
    }

    const newChange = {
      isFiltered,
      filteredStaff,
    };

    newChange.search = searchValue;
    newChange.filterObj = filterObj;

    setLocalState({
      ...newChange,
    });
  }

  // The list of filters needed for staff
  function generateFilterList() {
    const { filterObj } = localState;

    // Get all the rooms that this staff has
    const roomSelections = sortItems(
      staffList.reduce((accum, item) => {
        if (get(item, 'room.title') && get(item, 'room.title')) {
          accum.push({
            title: item.room.title,
            value: item.room.id,
          });
        }
        return accum;
      }, []),
      [
        {
          name: 'title',
          order: 'asc',
        },
      ]
    );

    return [
      {
        defaultValue: [filterObj['role']],
        field: 'role',
        id: 'staff-filter-role',
        label: 'Filter Staff By Role',
        type: 'select',
        selectOptions: [
          { title: '-Select-', value: '' },
          { title: 'Unassigned', value: 'unassigned' },
          ...Object.keys(roles).map((key) => ({
            title: roles[key],
            value: key,
          })),
        ],
      },
      {
        defaultValue: [filterObj['room.id']],
        field: 'room.id',
        id: 'staff-filter-room',
        label: 'Filter Staff By Room',
        type: 'select',
        selectOptions: [
          { title: '-Select-', value: '' },
          { title: 'Unassigned', value: 'unassigned' },
          ...uniqBy(roomSelections, 'value'),
        ],
      },
      {
        defaultValue: filterObj['confirmed'],
        field: 'confirmed',
        id: 'staff-filter-confirmation-status',
        label: 'Filter Staff by Sign-In Success Status',
        required: false,
        type: 'radio',
        selectOptions: {
          all: 'All',
          true: 'Confirmed',
          false: 'Unconfirmed',
        },
      },
    ];
  }

  function generateHeaders() {
    return [
      { title: 'checkAll' },
      { title: 'Staff Name', sortField: ['firstName', 'lastName'] },
      { title: 'Phone', sortField: ['phone'] },
      { title: 'Role', sortField: ['role'] },
      { title: 'Room', sortField: ['room.title'] },
      { title: 'Sign-In Success', sortField: ['confirmed'] },
      { title: 'Toolkit Access', sortField: ['active'] },
    ];
  }

  function toggleForm(toggle) {
    // If they had saved the originating button, focus on it.
    if (localState.saveFocusElement && localState.saveFocusElement.current) {
      localState.saveFocusElement.current.focus();
    }

    // Reset the focus element.
    setLocalState({
      saveFocusElement: null,
      showAddForm: toggle,
    });
  }

  function updateSearch(e) {
    const search = e.target.value;

    applyFilters(localState.filterObj, search);
  }

  function updateFilter(e) {
    const name = get(e, 'target.name', 'confirmed');
    let value = get(e, 'target.value');

    let filterObj = {};

    // Add exception for "confirmed" since they're strings.
    if (name === 'confirmed') {
      if (value !== 'all') {
        value = value ? value === 'true' : value;
      }
    }

    if (name !== 'resetFilters') {
      filterObj = {
        ...localState.filterObj,
        [name]: value,
      };

      filterObj = omitBy(filterObj, (filterAttr) => {
        return filterAttr === '';
      });
    } else {
      toggleFilterForm();
    }

    applyFilters(filterObj, localState.search);
  }

  function toggleFilterForm() {
    setLocalState({
      showAddForm: false,
      showEditDataForm: false,
      showFilterTableForm: !localState.showFilterTableForm,
    });

    if (localState.saveFocusElement && localState.showFilterTableForm) {
      localState.saveFocusElement.current?.focus();

      // Reset the focus element.
      setLocalState({
        saveFocusElement: null,
      });
    }
  }

  let sendBulkEmailCheckboxValue = true;

  function handleImportSubmit(selectedStaff = [], inventoryStaffById = {}) {
    return function (e) {
      e && e.preventDefault && e.preventDefault();
      // Send the IDs to the service.
      const input = {
        ids: selectedStaff,
      };

      const collectedNames = selectedStaff.map((staffId) => {
        const user = inventoryStaffById[staffId];
        return `${user?.lastName || ''}, ${user?.firstName || ''}`;
      });

      if (selectedStaff.length > 0) {
        setLocalState({
          importStaffSubmitDisabled: true,
        });

        bulkImportUsers({
          variables: { input },
          refetchQueries: ['getStaff', 'getSiteStats'],
          update: (cache, { data: { bulkImportUsers } }) => {
            try {
              // Read the existing data from the local cache using the GET_STAFF query
              const { viewer } = cache.readQuery({ query: GET_STAFF }) || { viewer: { site: { staff: [] } } };

              const importedStaff = Array.isArray(bulkImportUsers) ? bulkImportUsers : [];

              // Create a new object newData by spreading the existing viewer data and updating the staff array
              const newData = {
                viewer: {
                  site: {
                    staff: [...viewer.site.staff, ...importedStaff],
                  },
                },
              };

              // Write the updated data back to the local cache using the GET_STAFF query
              cache.writeQuery({
                query: GET_STAFF,
                data: newData,
              });
            } catch (error) {
              // Log an error message if there's an issue updating the cache
              console.error('Error updating cache:', error);
            }
          },
        })
          .then(() => {
            const afterAction = () => {
              // Close the accordion.
              setLocalState({
                importStaffSelected: [],
                saveFocusElement: addStaffRef,
                showAddForm: !localState.showAddForm,
                showEditDataForm: false,
                showFilterTableForm: false,
              });

              toggleForm();
            };

            function handleContinueFromModal() {
              if (sendBulkEmailCheckboxValue) {
                resendEmail({
                  variables: { input },
                });
              }
              afterAction();
            }

            dispatchModal(
              <TwoButtonModal
                body={
                  <>
                    <ul key='imported-staff'>
                      {collectedNames.map((selectedStaff) => (
                        <li key={selectedStaff}>{selectedStaff}</li>
                      ))}
                    </ul>
                    <label key='email-imported-staff' htmlFor='email-imported-staff'>
                      <input
                        id='email-imported-staff'
                        onChange={(e) => {
                          sendBulkEmailCheckboxValue = e.target.checked;
                        }}
                        defaultChecked='true'
                        type='checkbox'
                      />
                      &nbsp; Send notification email to added staff?
                    </label>
                  </>
                }
                modalId='bulkImportConfirm'
                primaryButtonHandler={handleContinueFromModal}
                secondaryButtonHandler={afterAction}
                title={'You’ve added the following staff to this test administration'}
                variant='success'
              />
            );
          })
          .catch((e) => {
            console.error('bulkImportUsers failed', e);

            dispatchModal(
              <OneButtonModal
                body='Please try again later.'
                modalId='bulkStaffError'
                title='We were unable to add these users.'
                variant='error'
              />
            );
          });
      }
    };
  }

  const addUserHandler = React.useCallback((importStaffSelected) => {
    setLocalState({
      importStaffSelected,
      importStaffSubmitDisabled: !importStaffSelected || importStaffSelected.length === 0,
    });
  }, []);

  function generateForm() {
    const { importStaffSelected, importStaffSubmitDisabled, showAddForm, showFilterTableForm } = localState;

    // Pick a form to show based on which form they selected.
    if (showAddForm) {
      return (
        <div className='box-card p-3 shadow'>
          <QueryComponent query={{ kind: 'GetStaffInventory', specification: GET_STAFF_INVENTORY }}>
            {(data) => {
              const inventoryStaff = get(data, 'viewer.site.inventory') || [];
              const inventoryStaffById = keyBy(inventoryStaff, 'id');
              let importStaffInventory = null;

              if (inventoryStaff.length > 0) {
                // Create an import selectbox if needed.importStaffInventory
                importStaffInventory = (
                  <div className='col-md-6 pr-5'>
                    <form
                      id='bulkImportStaffForm'
                      onSubmit={handleImportSubmit(importStaffSelected, inventoryStaffById)}
                    >
                      <h2 className='tdtk-h2 mb-4' data-automation='heading-h2'>
                        Select from Your Staff List
                      </h2>
                      <React.Fragment>
                        <p className='mb-4'>Start by assigning returning staff to this administration.</p>

                        <AddStaff items={inventoryStaff} {...{ addUserHandler }} />
                        <div style={{ clear: 'both' }} className='text-right my-4'>
                          <YellowButton
                            className='mt-0'
                            data-automation={'button-import-staff'}
                            disabled={importStaffSubmitDisabled || !importStaffSelected.length}
                            type='submit'
                          >
                            Add Selected Staff
                          </YellowButton>
                        </div>
                      </React.Fragment>
                    </form>
                  </div>
                );
              }

              return (
                <div className='row'>
                  {
                    // Should return null if we got no results.
                    importStaffInventory
                  }
                  <div className='col-md-6 pl-2'>
                    <h2 className='tdtk-h2 mb-4' data-automation='heading-h2' id='staff-header'>
                      Add New Staff to Test Day Toolkit
                    </h2>
                    <StaffForm rooms={roomList} toggleForm={toggleForm} />
                  </div>
                </div>
              );
            }}
          </QueryComponent>
        </div>
      );
    } else if (showFilterTableForm) {
      return (
        <RosterFilters applyFilter={toggleFilterForm} filters={generateFilterList()} updateFilter={updateFilter} />
      );
    } else {
      return null;
    }
  }

  function buildTableControlElements(isFiltered) {
    // Build a button.
    const buildAddStaffButton = {
      isExpanded: localState.showAddForm,
      icon: !localState.showAddForm ? 'plus' : 'minus',
      label: 'Add Staff',
      name: 'add-staff',
      onClick: () => {
        // If they are opening the accordion, focus on it.
        if (!localState.showAddForm) {
          document.getElementById('tableControlForm').focus();
        }
        setLocalState({
          saveFocusElement: addStaffRef,
          showAddForm: !localState.showAddForm,
          showEditDataForm: false,
          showFilterTableForm: false,
        });
      },
      ref: addStaffRef,
      type: 'button',
    };

    // Filter out any coordinators in here, just see if they're missing staff.
    const nonAdminStaff = staffList.filter((p) => p.role !== 'admin');

    // If they have no staff, we should show a helpful popup.
    if (!nonAdminStaff || nonAdminStaff.length === 0) {
      // Add the popover.
      buildAddStaffButton.popoverTitle = 'Start Here';
      buildAddStaffButton.popoverText = 'Search for staff you’ve already added or add new staff.';
      buildAddStaffButton.showPopover = true;
      buildAddStaffButton.type = 'buttonWithPopover';
    }

    return [
      {
        defaultValue: localState.search,
        icon: 'search',
        label: 'Enter part of a staff member’s name here to find the staff member',
        name: 'staff-search',
        onChange: updateSearch,
        placeholder: 'Search assigned staff',
        srOnlyLabel: true,
        suppressFormGroupClass: true,
        type: 'input',
      },
      buildAddStaffButton,
      {
        isExpanded: localState.showFilterTableForm,
        icon: !localState.showFilterTableForm ? 'plus' : 'minus',
        label: 'Filter Table',
        name: 'filter-staff',
        onClick: () => {
          // If they are opening the accordion, focus on it.
          if (!localState.showFilterTableForm) {
            document.getElementById('tableControlForm').focus();
          }
          // If they close the form, save the element that triggered it in the first place.
          setLocalState({
            saveFocusElement: filterStaffRef,
          });

          toggleFilterForm();
        },
        ref: filterStaffRef,
        variant: isFiltered ? 'yellow' : 'black',
        type: 'button',
      },
    ];
  }

  // Global App state.
  const { orgEvent } = useStateValue();
  const dispatchModal = useContext(ModalDispatchContext);
  const windowSize = React.useContext(ResizeContext);
  const location = useLocation();

  const addStaffRef = React.useRef();
  const filterStaffRef = React.useRef();

  const { hash } = location;

  // Local state.
  const [localState, setLocalState] = React.useReducer(localStateReducer, {
    filteredStaff: staffList,
    filterObj: {},
    importStaffSelected: [],
    importStaffSubmitDisabled: true,
    initialStaffList: staffList,
    isFiltered: false,
    saveFocusElement: null,
    showAddForm: hash === '#add',
    showEditDataForm: false,
    showFilterTableForm: false,
  });

  // Update the filtered staff whenever we get new props.
  React.useEffect(() => {
    if (!isEqual(localState.initialStaffList, staffList)) {
      setLocalState({
        initialStaffList: staffList,
      });

      applyFilters(localState.filterObj, localState.search);
    }
  });

  // Apollo.
  const [bulkImportUsers] = useMutation(BULK_IMPORT_USERS);
  const [resendEmail] = useMutation(RESEND_EMAIL);
  const headers = generateHeaders();
  const tableControlForm = generateForm();
  const tableControlElements = buildTableControlElements(localState.isFiltered);
  const emptyMessage = isEmpty(staffList) ? 'You haven’t added any staff for this test date yet.' : '';

  return (
    <ResizeProvider>
      <div className={orgEvent?.dapInd ? 'mobile-striping' : ''}>
        <Roster
          caption='Test Center Staff'
          checkboxAllRef={checkboxAllRef}
          checkedItems={checkedStaff}
          emptyMessage={emptyMessage}
          groupName='staff'
          handleCheckItems={handleCheckStaff}
          headers={headers}
          id='staff-table'
          items={[...localState.filteredStaff]}
          sortFields={[
            {
              name: 'lastName',
              order: 'asc',
            },
            {
              name: 'firstName',
              order: 'asc',
            },
          ]}
          renderItem={(staffUser, options) => (
            <StaffRosterRow
              handleCheckItems={handleCheckStaff}
              key={`staff_${staffUser.id}`}
              options={{
                ...options,
                checked: checkedStaff.indexOf(staffUser.id) > -1,
              }}
              staff={staffUser}
            />
          )}
          showSelected={!windowSize.mobile}
          stickyHeaders={true}
          tableControlElements={tableControlElements}
          tableControlForm={tableControlForm}
          totalCount={localState.filteredStaff.length}
        />
      </div>
    </ResizeProvider>
  );
}

StaffRoster.propTypes = {
  checkboxAllRef: PropTypes.object,
  checkedStaff: PropTypes.array,
  handleCheckStaff: PropTypes.func,
  roomList: PropTypes.array,
  staffList: PropTypes.array,
};

export default StaffRoster;
