import React from 'react';
import PropTypes from 'prop-types';
import SeatGrid from './SeatGrid';
import { ResizeContext } from '../../../../../context/ResizeContext';
import { localStateReducer } from '../../../../../utils/common';

function Container({ room = {} }) {
  const windowSize = React.useContext(ResizeContext);

  // Local state.
  const [localState, setLocalState] = React.useReducer(localStateReducer, {
    isMobile: windowSize.mobile,
  });

  React.useEffect(() => {
    setLocalState({
      isMobile: windowSize.mobile,
    });
  }, [windowSize.mobile]);

  return (
    <div className='d-flex w-100 p-relative cb-white-bg seating-chart--inner-container'>
      {!localState.isMobile ? (
        <div
          className='
              d-flex
              flex--align-center
              flex-1
              flex-direction--column
            '
        >
          <span className='rotate--270'>Left</span>
        </div>
      ) : null}

      <div className='flex-1'>
        <p
          className='
            m-0
            p-absolute
            seating-chart--room-label__top
            text-center
            w-100
          '
          style={{ top: 0, left: '50%' }}
        >
          Back
        </p>

        <div className='w-100 h-100'>
          <SeatGrid room={room} />
        </div>

        <p
          className='
            m-0
            p-absolute
            seating-chart--room-label__bottom
            text-center
            w-100
          '
          style={{ bottom: 0, left: '50%' }}
        >
          Front of Room
          <br /> <small>(students face this direction)</small>
        </p>
      </div>
      {!localState.isMobile ? (
        <div
          className='
              d-flex
              flex--align-center
              flex-1
              flex-direction--column
            '
        >
          <span className='rotate--90'>Right</span>
        </div>
      ) : null}
    </div>
  );
}

Container.propTypes = {
  room: PropTypes.object,
};

Container.displayName = 'SeatWrapper';

export default Container;
