import React, { useEffect } from 'react';
import { GET_ROOM, GET_CMS_MULTI_STEPPER } from '../../../../../apollo/queries';
import { RoomContextProvider } from '../../../common/context/RoomContext';
import { SocketProvider } from '../../../../common/network/SocketProvider';
import { useStateValue } from '../../../../../context/AppContext';
import Attendance from './Attendance/Attendance';
import MonitorStudentsContainer from './Monitor/MonitorStudentsContainer';
import QueryComponent from '../../../../common/network/QueryComponent';
import Setup from './Setup/Setup';
import TestCompletion from './TestCompletion';
import { isInSchoolAPEvent } from '../../../../../utils/event';
import { useParams } from 'react-router-dom';

function Container() {
  // Global App state.
  const { user, orgEvent } = useStateValue();

  // get the id of the room from the user to automatically load.
  let { id, flowName, stepNum } = useParams();
  if (!id) {
    id = user?.room?.id || '';
  }
  const input = { id: id as string };

  // get the flow name from the route
  flowName = flowName || 'setup';
  stepNum = stepNum || '0';

  // get the step number parameter from the route
  const stepNumVal = parseInt(stepNum, 10) || 0;

  const flowData = {
    flowName,
    stepNum: stepNumVal,
  };

  useEffect(() => {
    const selectInSchoolAp = isInSchoolAPEvent(orgEvent);
    const lastAttendanceStepNum = selectInSchoolAp ? 8 : 10;
    // Make the background blue tint color just when this page is loaded.
    if (
      (flowName === 'setup' && stepNumVal === 7) ||
      (flowName === 'attendance' && stepNumVal === lastAttendanceStepNum) ||
      (flowName === 'completion' && stepNumVal === 3)
    ) {
      document.body.classList.add('cb-blue5-bg');
    } else {
      document.body.classList.add('cb-blue5-tint-1');
    }

    setTimeout(function () {
      if (flowName && document.getElementById('wizard-main-heading')) {
        document?.getElementById('wizard-main-heading')?.focus();
      }
    }, 50);

    return () => {
      document.body.classList.remove('cb-blue5-tint-1');
      document.body.classList.remove('cb-blue5-bg');
    };
  }, [flowName, stepNum]);

  return (
    <div className='wizard-container container' id='Proctor-wizard-container' tabIndex={-1}>
      {id !== '' ? (
        <SocketProvider>
          <QueryComponent query={{ kind: 'GetRoom', specification: GET_ROOM, variables: { input } }}>
            {(data, refetch) => {
              const room = data?.readRoom || {};

              if (room && room?.id) {
                return (
                  <QueryComponent query={{ kind: 'GetCMSMultiStepper', specification: GET_CMS_MULTI_STEPPER }}>
                    {(data) => {
                      const setUpCMSTemplates = (data?.getMultiStepperContent?.proctorSteps || []).find((rec) =>
                        rec?.section_name?.includes('Setup')
                      );
                      const attendanceCMSTemplates = (data?.getMultiStepperContent?.proctorSteps || []).find((rec) =>
                        rec?.section_name?.includes('Attendance')
                      );
                      const monitorCMSTemplates = (data?.getMultiStepperContent?.proctorSteps || []).find((rec) =>
                        rec?.section_name?.includes('Monitor')
                      );
                      const completionCMSTemplates = (data?.getMultiStepperContent?.proctorSteps || []).find((rec) =>
                        rec?.section_name?.includes('Completion')
                      );
                      return (
                        <RoomContextProvider>
                          {flowName === 'setup' && (
                            <Setup cmsContent={setUpCMSTemplates} flowData={flowData} room={room} />
                          )}
                          {flowName === 'attendance' && (
                            <Attendance
                              cmsContent={attendanceCMSTemplates}
                              flowData={flowData}
                              lastUpdated={new Date().toISOString()}
                              refetch={refetch}
                              room={room}
                            />
                          )}
                          {flowName === 'monitor' && (
                            <MonitorStudentsContainer
                              cmsContent={monitorCMSTemplates}
                              flowData={flowData}
                              lastUpdated={new Date().toISOString()}
                              refetch={refetch}
                              room={room}
                            />
                          )}
                          {flowName === 'completion' && (
                            <TestCompletion cmsContent={completionCMSTemplates} flowData={flowData} room={room} />
                          )}
                        </RoomContextProvider>
                      );
                    }}
                  </QueryComponent>
                );
              } else {
                return <p>Room not found.</p>;
              }
            }}
          </QueryComponent>
        </SocketProvider>
      ) : (
        <p>Room not found.</p>
      )}
    </div>
  );
}

Container.displayName = 'RoomDetailsContainer';

export default Container;
