import React from 'react';
import PropTypes from 'prop-types';
import { Expandable } from '@cb/apricot-react';

function ExpandableWrapper(props) {
  const { title } = props;
  return (
    <Expandable {...{ ...props, title: undefined, openLabel: title, closedLabel: title }}>{props.children}</Expandable>
  );
}

ExpandableWrapper.propTypes = {
  children: PropTypes.any,
  title: PropTypes.any,
};

export default ExpandableWrapper;
