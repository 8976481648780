import Accordion from '../../ui/accordion/AccordionWrapper';
import AccordionItem from '../../ui/accordion/AccordionItemWrapper';
import DOMPurify from 'dompurify';
import QueryComponent from '../network/QueryComponent';
import React from 'react';
import capitalize from 'lodash/capitalize';
import get from 'lodash/get';
import { GET_NOTIFICATIONS } from '../../../apollo/queries';
import './Notification.scss';
import { Icon } from '@cb/apricot-react';

function EmergencyNotification() {
  function createCleanMarkup(dangerousHtml) {
    // This should purify any content coming from the CMS.
    return { __html: DOMPurify.sanitize(dangerousHtml) };
  }

  function generateMessage(priority, header, content, showExpanded) {
    return {
      title: (
        <React.Fragment>
          <Icon name='exclamation-circle' size='24' decorative />
          <span className='tdtk-notification-header'>
            {header ? header : `${capitalize(priority)} Priority Notification`}
          </span>
        </React.Fragment>
      ),
      body: [
        <div key='emergency-notification-body' className='tdtk-notification-body' id='emergency-notification-body'>
          <div className='container'>
            <div className='pl-2'>{content ? <div dangerouslySetInnerHTML={createCleanMarkup(content)} /> : null}</div>
          </div>
        </div>,
      ],
      showExpanded,
    };
  }

  function getContainerColorCode(priority) {
    switch (priority) {
      case 'Low':
        return 'tdtk-notification-low';
      case 'Medium':
        return 'tdtk-notification-med';
      case 'Urgent':
        return 'tdtk-notification-urgent';
      default:
        return 'tdtk-notification-low';
    }
  }

  return (
    <QueryComponent query={{ kind: 'GetNotifications', specification: GET_NOTIFICATIONS }}>
      {(data) => {
        const getNotifications = get(data, 'getNotifications');
        let emergencyAccordionItems;
        if (getNotifications) {
          emergencyAccordionItems = [
            generateMessage(
              getNotifications.alert_level,
              getNotifications.header,
              getNotifications.body,
              getNotifications.show_accordion
            ),
          ];
        }

        return getNotifications ? (
          <div
            className={getContainerColorCode(capitalize(getNotifications.alert_level))}
            style={{ backgroundColor: getContainerColorCode(capitalize(getNotifications.alert_level)) }}
          >
            <div className='container tdtk-notification' id='tdtk-emergency-notification'>
              <Accordion>
                {emergencyAccordionItems.map((panel) => (
                  <AccordionItem
                    key={panel.key || panel.title}
                    title={panel.title}
                    glyphIcon={panel.glyphIcon}
                    open={panel.showExpanded}
                  >
                    {panel.body}
                  </AccordionItem>
                ))}
              </Accordion>
            </div>
          </div>
        ) : null;
      }}
    </QueryComponent>
  );
}

export default EmergencyNotification;
