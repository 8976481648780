const contentStrings = {
  staff: {
    warn: {
      coordinator: 'You can’t remove a coordinator.',
    },
  },
  downloadsContent: {
    directStudents: 'Let students know which room they’re testing in.',
    postDirectory: 'Post the room directory where students can see it.',
    studentAssignments: 'Students are listed alphabetically along with their room assignments.',
    noStudentInfo: 'No sensitive student information is shared.',
    noStudentsFound: 'No students have been assigned to this site.',
    printRoomDirectoryBtn: 'Print Room Directory',
    printRoomDirectoryModalTitle: 'Generating Room Directory',
  },
};

export default contentStrings;
