/**
 * PDF Table Generator.
 */
const _generateHeaders = (headers, largeFont) => {
  return headers.map((header) => {
    return {
      style: largeFont ? 'tableHeaderLg' : 'tableHeader',
      border: [false, false, false, false],
      text: header,
    };
  });
};

const _generateRow = (rows, largeFont) => {
  return rows.map((rowItem) => {
    return {
      style: largeFont ? 'tableCellLg' : 'tableCell',
      border: [false, true, false, false],
      text: rowItem,
    };
  });
};

// Table Generator.
export const tableGenerator = (headers, rows, largeFont) => {
  const compiled = [];

  // Set the headers.
  compiled.push(_generateHeaders(headers, largeFont));

  // Loop through the rows and generate each row.
  rows.forEach((row) => {
    compiled.push(_generateRow(row, largeFont));
  });
  return compiled;
};
