import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { UPDATE_ROOM, UPDATE_STUDENTS, GENERATE_PDF } from '../../../../../apollo/mutations';
import { GET_ROOM } from '../../../../../apollo/queries';
import Spinner from '../../../../ui/loading/SpinnerWrapper';
import TooltipWrapper from '../../../../ui/tooltip/TooltipWrapper';
import { localStateReducer, clickHandler } from '../../../../../utils/common';
import { getStudentsWithoutAssignedSeats } from '../utils';
import b64toBlob from 'b64-to-blob';
import { saveAs } from 'file-saver';
import { useBusinessLogic } from './useBusinessLogic';
import { filter } from 'graphql-anywhere';
import { GetRoomFragment } from '../../../../../apollo/fragments';
import ClearAllSeatingChartConfirmationModal from './modals/ClearAllSeatingChartConfirmationModal';
import SaveSeatingChartConfirmationModal from './modals/SaveSeatingChartConfirmationModal';
import SaveSeatingChartModal from './modals/SaveSeatingChartModal';
import { ModalDispatchContext } from '../../../../ui/modal/ModalContext';
import { Icon, YellowButton } from '@cb/apricot-react';

function SaveAndFinalize({ orgEvent = {}, room = {} }) {
  function handleClearSettings() {
    dispatchModal(
      <ClearAllSeatingChartConfirmationModal
        room={room}
        setLocalState={setLocalState}
        updateStudents={updateStudents}
        updateRoom={updateRoom}
      />
    );
  }

  function handleSaveSeatingChartModalFunction(unusedSeats) {
    return () => {
      setLocalState({
        loading: true,
      });

      updateRoom({
        variables: {
          input: {
            id: room.id,
            seatingStatus: 'complete',
            shape: room.shape
              ? {
                  x: room.shape.x || 5,
                  y: room.shape.y || 6,
                }
              : {
                  x: 5,
                  y: 6,
                },
            unusedSeats,
          },
        },
      }).then(() => {
        // Generate the PDF after the seats were updated!
        generatePDF({
          variables: {
            input: {
              eventId: orgEvent.asmtEventId,
              roomIds: [room.id],
            },
          },
        })
          .then(() => {
            setLocalState({
              loading: false,
            });
          })
          .catch((e) => {
            //TODO HANDLE ERROR
            console.error('Problem generating PDF: ', e);

            setLocalState({
              loading: false,
            });
          });

        dispatchModal(<SaveSeatingChartConfirmationModal />);

        setLocalState({
          loading: false,
          savedButtonText: 'Submitted',
        });
      });
    };
  }

  function handleSubmit() {
    // Mark all un-used seats as 'unused.'
    if (
      room &&
      room.students &&
      room.students.length &&
      room.shape &&
      room.shape.x &&
      room.shape.y &&
      !isNaN(parseInt(room.shape.x, 10)) &&
      !isNaN(parseInt(room.shape.y, 10))
    ) {
      const unusedSeats = [];

      // Loop through the seats, find the ones that are used.
      for (let i = 1; i <= room.shape.y; i++) {
        for (let j = 1; j <= room.shape.x; j++) {
          if (
            room.students.find(
              (student) => student.assignedSeat && student.assignedSeat.x === j && student.assignedSeat.y === i
            )
          ) {
            // This seat is used.
          } else {
            unusedSeats.push({ x: j, y: i });
          }
        }
      }

      const studentsWithoutAssignedSeats = getStudentsWithoutAssignedSeats(room.students);

      // Confirm if there are any students that have been checked in but haven't been seated.
      if (studentsWithoutAssignedSeats.length) {
        dispatchModal(
          <SaveSeatingChartModal
            handlerFunction={handleSaveSeatingChartModalFunction(unusedSeats)}
            studentsWithoutAssignedSeats={studentsWithoutAssignedSeats}
          />
        );
      } else {
        handleSaveSeatingChartModalFunction(unusedSeats)();
      }
    }
  }

  // Global App state.
  const dispatchModal = useContext(ModalDispatchContext);

  // Local state.
  const [localState, setLocalState] = React.useReducer(localStateReducer, {
    loading: false,
  });

  // Apollo.
  const [updateRoom] = useMutation(UPDATE_ROOM, {
    update(cache, { data: { updateRoom } }) {
      const readRoom = cache.readQuery({
        query: GET_ROOM,
        variables: {
          input: { id: room.id },
        },
      });

      cache.writeQuery({
        query: GET_ROOM,
        data: {
          readRoom: { ...readRoom, ...updateRoom },
        },
      });
    },
  });

  const [updateStudents] = useMutation(UPDATE_STUDENTS);

  const [generatePDF] = useMutation(GENERATE_PDF);
  const [, { data: pdfData }] = useMutation(GENERATE_PDF);

  React.useEffect(() => {
    if (pdfData && pdfData.generatePDF && pdfData.generatePDF.length === 2) {
      const [fileName, base64] = pdfData.generatePDF;
      const blob = b64toBlob(base64, 'application/pdf');
      saveAs(blob, fileName);
    }
  }, [pdfData]);

  // Update the submit button based on room info. Disable if we're lacking students.
  React.useEffect(() => {
    setLocalState({
      savedButtonText:
        room && room.students && room.students.length
          ? room && room.seatingStatus === 'complete'
            ? 'Submitted'
            : ''
          : 'Submit Chart',
    });
  }, [room]);

  const submitRef = React.useRef();
  const { everyStudentHasTestBook } = useBusinessLogic(filter(GetRoomFragment, room));

  return localState.loading ? (
    <div className='row'>
      <div className='col-xs-12'>
        <Spinner />
      </div>
    </div>
  ) : (
    <React.Fragment>
      <div className='seating-charts-submit-container'>
        <div className='d-flex my-3 mr-auto-mobile '>
          <button
            className='mt-0 cb-btn cb-btn-sm cb-btn-naked cb-no-padding'
            onClick={handleClearSettings}
            type='button'
          >
            Clear settings
          </button>
        </div>
        <div className='d-flex align-items-center' style={{ paddingLeft: '40px' }}>
          <YellowButton
            small
            data-tour='submit-chart'
            className='mr-2 w-35--mobile px-0--mobile'
            disabled={
              (room && room.seatingStatus === 'complete') || localState.savedButtonText || !everyStudentHasTestBook
                ? true
                : false
            }
            id='saveChart'
            name='saveChart'
            onClick={handleSubmit}
          >
            {localState.savedButtonText ? localState.savedButtonText : 'Submit Chart'}
          </YellowButton>
          {room && room.seatingStatus !== 'complete' && everyStudentHasTestBook ? (
            <div className='mx-2'>
              <a
                data-automation='seating-chart-tooltip-trigger'
                href='#0'
                id='tooltip-trigger-seating-chart'
                role='button'
                ref={submitRef}
                onClick={clickHandler}
              >
                <span className='cb-sr-only'>tooltip for submitting a seating chart</span>
                <Icon circular circleSize='24' name='question' decorative />
              </a>
              <TooltipWrapper light={true} trigger='tooltip-trigger-seating-chart'>
                {'When every student has a seat, click “Submit Chart” to mark leftover seats as unused.'}
              </TooltipWrapper>
            </div>
          ) : null}
          {room && room.seatingStatus !== 'complete' && !everyStudentHasTestBook ? (
            <div className='mx-2'>
              <a href='#0' id='tooltip-trigger-serial-numbers' role='button' ref={submitRef} onClick={clickHandler}>
                <span className='cb-sr-only'>tooltip for submitting a seating chart</span>
                <Icon circular circleSize='24' name='question' decorative />
              </a>
              <TooltipWrapper light={true} trigger='tooltip-trigger-serial-numbers'>
                {'Add serial numbers to every assigned seat and try again.'}
              </TooltipWrapper>
            </div>
          ) : null}
        </div>
      </div>
    </React.Fragment>
  );
}

SaveAndFinalize.propTypes = {
  orgEvent: PropTypes.object,
  room: PropTypes.object,
};

export default SaveAndFinalize;
