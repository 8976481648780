import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import { localStateReducer } from '../../../../../utils/common';
import DoorForm from './DoorForm';
import { useMutation } from '@apollo/client';
import { UPDATE_ROOM } from '../../../../../apollo/mutations';
import { GET_ROOM } from '../../../../../apollo/queries';
import { ResizeContext } from '../../../../../context/ResizeContext';
import { usePreviousValue } from '../../../../../constants/usePreviousValue';
import { ModalDispatchContext } from '../../../../ui/modal/ModalContext';
import DoorAdditionConfirmationModal from './modals/DoorAdditionConfirmationModal';
import { NumericIcon } from '@cb/apricot-react';

function Container({ room = {} }) {
  const dispatchModal = useContext(ModalDispatchContext);
  const prevDoors = usePreviousValue(room.doors);

  function editDoor(i, pos = 'start') {
    return async function (val) {
      let doorList = [];
      let enableAdd = false;
      let ret;

      if (val && i >= 0) {
        doorList = [...localState.doors];

        ret = {
          [pos]: val,
        };

        // Reset the second dropdown any time the first one is changed.
        if (pos === 'wall' && doorList[i] && doorList[i].wall !== val) {
          ret['position'] = '';
        }

        // Replace the array with the new value.
        doorList[i] = {
          ...doorList[i],
          ...ret,
        };

        // Auto-save if the fields are valid.
        if (doorList[i]['wall'] && doorList[i]['position']) {
          enableAdd = true;
        }

        await setLocalState({
          enableAdd,
          doors: [...doorList],
        });

        // If they are editing, auto-save their changes only if the fields are valid.
        if (enableAdd && i !== 0) {
          saveDoors();
        }
      }
    };
  }

  function removeDoor(i) {
    return async function () {
      const numberList = [...localState.doors];

      if (i >= 0 && i < numberList.length) {
        numberList.splice(i, 1);
      }

      await setLocalState({
        doors: [...numberList],
      });

      saveDoors();
    };
  }

  function saveDoors() {
    // The service expects an enum string: e.g. 'front_center'.
    const localDoors = localState.doors
      ? [...localState.doors.filter((door) => door.wall && door.position).map((arr) => `${arr.wall}_${arr.position}`)]
      : [];

    /**
     * Selectboxes are weird right now, using tab to go through them
     * triggers an onChange event. So we need to make sure we only save if something changed.
     */
    if (!isEqual(room.doors, localDoors)) {
      setLocalState({
        loading: true,
      });

      try {
        updateRoom({
          variables: {
            input: {
              id: room.id,
              doors: localDoors,
              seatingChartPDFCurrent: false,
              seatingStatus: 'drafted',
              shape: room.shape
                ? {
                    x: room.shape.x || 5,
                    y: room.shape.y || 6,
                  }
                : {
                    x: 5,
                    y: 6,
                  },
            },
          },
          optimisticResponse: {
            updateRoom: {
              id: room.id,
              capacity: room.capacity,
              created: new Date().getTime(),
              doors: localDoors,
              groupTypes: room.groupTypes,
              shape: {
                ...room.shape,
                __typename: 'Shape',
              },
              status: room.status,
              staff: room.staff,
              students: room.students,
              testBookRanges: [
                ...room.testBookRanges.map((t) => ({
                  start: t.start,
                  end: t.end,
                  __typename: 'TestingBookRangeType',
                })),
              ],
              title: room.title,
              unusedSeats: room.unusedSeats,
              updated: new Date().getTime(),
              __typename: 'RoomType',
            },
          },
        }).then(() => {
          setLocalState({
            loading: false,
          });

          if (localDoors.length > prevDoors.length) {
            dispatchModal(<DoorAdditionConfirmationModal />);
          }
        });
      } catch (e) {
        console.error('Saving doors failed.', e);

        setLocalState({
          loading: false,
        });
      }
    }
  }

  // Global app state.
  const windowSize = React.useContext(ResizeContext);

  // Local state.
  const [localState, setLocalState] = React.useReducer(localStateReducer, {
    enableAdd: false,
    doors:
      room.doors && room.doors.length
        ? [
            {
              position: '',
              wall: '',
            },
            ...room.doors.map((door) => ({
              wall: door.split('_')[0],
              position: door.split('_')[1],
            })),
          ]
        : [
            {
              position: '',
              wall: '',
            },
          ],
  });

  // Apollo.
  const [updateRoom] = useMutation(UPDATE_ROOM, {
    update(cache, { data: { updateRoom } }) {
      const readRoom = cache.readQuery({
        query: GET_ROOM,
        variables: {
          input: { id: room.id },
        },
      });

      cache.writeQuery({
        query: GET_ROOM,
        data: {
          readRoom: { ...readRoom, ...updateRoom },
        },
      });
    },
  });

  React.useEffect(() => {
    setLocalState({
      doors:
        room.doors && room.doors.length
          ? [
              {
                position: '',
                wall: '',
              },
              ...room.doors.map((door) => ({
                wall: door.split('_')[0],
                position: door.split('_')[1],
              })),
            ]
          : [
              {
                position: '',
                wall: '',
              },
            ],
    });
  }, [room.doors]);

  return (
    <React.Fragment>
      <h3 className='tdtk-h3 mb-0 pb-0 d-flex align-items-center'>
        <NumericIcon inverted className='cb-margin-right-8 flex-shrink-0' decorative>
          2
        </NumericIcon>
        <span className='cb-sr-only'>Step 2</span>
        <span>Tell us where the door is.</span>
      </h3>
      <p className='box-indent mb-4'>
        Select <strong>left</strong> if the wall or door is on your left as you face students.
        <br />
        If it’s on your right, select <strong>right.</strong>
      </p>
      <div className='box-card border__rounded-full shadow col-md-10 col-lg-9 box-indent mr-auto'>
        {/**
         * First field is always for adding.
         */}
        {
          // Only show if they have doors left to add.
          localState.doors.length <= 12 ? (
            <div>
              {windowSize.prefix !== 'xs' ? (
                <div className='row'>
                  <span className='tdtk-h5 cb-roboto-bold col-xs-4' role='presentation' aria-hidden='true'>
                    What wall is the door on?
                  </span>
                  <span className='tdtk-h5 cb-roboto-bold col-xs-5' role='presentation' aria-hidden='true'>
                    What position is the door?
                  </span>
                </div>
              ) : null}
              <DoorForm
                addDoor={saveDoors}
                doors={localState.doors}
                editDoor={editDoor}
                i={0}
                isAdd={true}
                key='door-list-0'
                loading={localState.loading}
                position={localState.doors[0].position}
                removeDoor={removeDoor}
                wall={localState.doors[0].wall}
              />
            </div>
          ) : null
        }

        {<h4 className='tdtk-h4 pb-2 my-4'>Your doors:</h4>}
        {localState.doors.length > 1 ? null : (
          <p className='pb-2' id='doors-form-empty'>
            Tell us where the door is and click &ldquo;Add This Door&rdquo; above.
          </p>
        )}
        {
          /**
           * Rest of the fields are for editing.
           */
          localState.doors.slice(1).map((door, i) => (
            <DoorForm
              addDoor={saveDoors}
              doors={localState.doors}
              editDoor={editDoor}
              i={i + 1}
              isAdd={false}
              key={`door-field-${i + 1}`}
              loading={localState.loading}
              position={door.position || ''}
              removeDoor={removeDoor}
              wall={door.wall || ''}
            />
          ))
        }
      </div>
    </React.Fragment>
  );
}

Container.propTypes = {
  room: PropTypes.object,
};

Container.displayName = 'DoorFormContainer';

export default Container;
