import React from 'react';

export const sat_national = {
  digitalProctorScript: <p className='mb-4'>Start code not found. Your test coordinator should call us.</p>,
  legal: (
    <React.Fragment>
      <p className='mb-4'>
        You&rsquo;re almost there&mdash;we just need you to read and accept (1) the SAT Testing Staff Agreement and (2)
        the Test Day Toolkit Agreement.
      </p>

      <h2 className='tdtk-h2 text-center text-uppercase' data-automation='heading-h2'>
        (1) <u>SAT TESTING STAFF AGREEMENT</u>
      </h2>

      <p className='mb-4'>As a testing staff person, I affirm that I:</p>
      <ul>
        <li>
          Have read and agree to all rules and guidelines set forth in the applicable SAT manual, including, but not
          limited to, the pages setting forth conditions under which the SAT must be administered and the information on
          staff qualifications and conflict of interest restrictions.
        </li>
        <li>
          Am a high school graduate, at least 18 years old, speak English fluently, possess the same level of
          unquestionable integrity and maturity expected of a member of the school staff, and act in a fair, courteous,
          nondiscriminatory, and professional manner.
        </li>
        <li>
          Understand and agree that all SAT tests, and all the questions contained in them, are the property of College
          Board and protected by copyright law, and that no one other than the student or authorized reader for College
          Board&ndash;approved accommodations can open a test book and see the test content.
        </li>
        <li>
          Understand and agree that copying, reproducing, or removing any materials from any SAT test book or digitally
          reproduced test form is prohibited.
        </li>
        <li>Am not prohibited by any law or regulation from working with minors or on school property.</li>
        <li>
          Am not engaged in any private SAT test preparation conducted outside the auspices of my school or district for
          compensation and will not engage in such activity for the remainder of the current school year. (The teaching
          and normal review of course content, including test familiarization, that is part of regularly scheduled
          school coursework is acceptable.)
        </li>
        <li>Have not taken any SAT test within 180 days of the test date.</li>
        <li>
          Do not have a member of my household or immediate family (&ldquo;related student&rdquo;) taking the SAT on the
          test date at any testing site. (Immediate family includes parents, siblings, children, grandparents, and
          spouse. This applies even if they don&rsquo;t reside in the same household.) In such instances, the related
          student&rsquo;s scores are subject to cancellation.
        </li>
        <li>
          Understand and agree that providing any assistance or coaching to students on the SAT (including directing or
          changing answers) prior to, during, or after the administration is prohibited. In such instances, any impacted
          students&rsquo; scores are subject to cancellation, and any staff involved may be referred to
          school/district/state administration or to law enforcement, as appropriate.
        </li>
        <li>
          Will act with integrity and will refrain from any illegal or unethical acts, including those that attempt to
          provide an unfair advantage for one or more test takers.
        </li>
      </ul>

      <p className='mb-4'>
        All staff, including test coordinators, proctors, room monitors, and hall monitors, must accept these provisions
        by clicking the <strong>Submit</strong> button below. College Board has the right, in its sole discretion, to
        remove or bar staff who violate these terms from administering future College Board tests.
      </p>

      <p className='mb-4'>
        <strong>IMPORTANT:</strong> Test coordinators will ensure that all testing staff are properly trained to
        administer a secure administration. Test coordinators will not discuss details about test policies or procedures
        (e.g., handling test materials, training, and packaging for return) with anyone other than their staff,
        including the media or through social media. Test coordinators will refer all media questions to the customer
        support phone number as listed in your manual.
      </p>

      <h2 className='tdtk-h2 text-center text-uppercase' data-automation='heading-h2'>
        (2) <u>SAT TEST DAY TOOLKIT AGREEMENT</u>
      </h2>

      <p className='mb-4'>
        This AGREEMENT (this &ldquo;<strong>Agreement</strong>&rdquo;) is made by and between College Board, a
        not-for-profit education corporation organized under the laws of the State of New York (the &ldquo;
        <strong>College Board</strong>&rdquo;), and the person receiving this Agreement (&ldquo;You&rdquo; or
        &ldquo;Your&rdquo;), as of the date of execution by both parties.
      </p>

      <h3 className='tdtk-h3 text-center legal__title-small' data-automation='heading-h3'>
        Recitals
      </h3>

      <p className='mb-4'>
        College Board will provide a web-based toolkit (&ldquo;Test Day Toolkit&rdquo;) that allows SAT testing staff to
        perform certain test day&ndash;related functions online. Test Day Toolkit is further described in Attachment A
        to this Agreement.
      </p>

      <h3 className='tdtk-h3 text-center legal__title-small' data-automation='heading-h3'>
        Agreement
      </h3>

      <p className='mb-4'>
        In consideration of the mutual covenants and promises herein contained, the adequacy of which is hereby
        acknowledged, You and College Board agree as follows:
      </p>

      <ol>
        <li>
          <u>Right to Use</u>. College Board grants to You a nonexclusive, nontransferable, revocable right to access
          and use Test Day Toolkit solely for the purposes set forth herein and in accordance with the SAT Staffing
          Agreement. You agree to use Test Day Toolkit only in connection your role as SAT staff. You understand that
          College Board is not granting to You any license to the underlying technologies, calculations, and
          methodologies that compose Test Day Toolkit or the supporting services.
        </li>
        <li>
          <u>Ownership</u>. You understand and acknowledge that all title, proprietary and intellectual property rights
          in and to Test Day Toolkit are owned solely by College Board, including copyright, trade secrets, and
          trademarks. You acknowledge and agree that Test Day Toolkit is protected under U.S. laws and international
          treaties. You have a limited right to use Test Day Toolkit in accordance with the terms of this Agreement. You
          agree not to change, alter, or modify Test Day Toolkit or any related materials, and not to disassemble,
          decompile, reverse-engineer, or otherwise translate any software incorporated in Test Day Toolkit or any
          related materials.
        </li>
        <li>
          <u>Feedback</u>. You agree to allow College Board to send You an optional survey to provide You an opportunity
          to give College Board reasonable suggestions, comments, and feedback, including, but not limited to,
          usability, bug reports, and test results, with respect to Your use of Test Day Toolkit (collectively, &ldquo;
          <strong>feedback</strong>&rdquo;). You agree that we can use Your quotes or testimonials, in a deidentified
          way, in promotional or other materials. You acknowledge and agree that College Board may freely use, disclose,
          reproduce, license, distribute, and otherwise commercialize any feedback, whether oral or written, provided to
          College Board by You with respect to the Service.
        </li>
        <li>
          <u>Confidentiality and Data Security</u>. In connection with Test Day Toolkit, You agree not to disclose to
          third parties: (i) any information received from College Board; and (ii) information pertaining to Test Day
          Toolkit. You also agree: (i) to keep Your mobile device with You during testing; (ii) to notify College Board
          should Your mobile device become missing during the test administration; (iii) not to disclose test taker
          personal information to anyone other than testing staff for purposes of completing the test administration;
          and (iv) not to misuse, transfer, or copy test taker personal information.
        </li>
        <li>
          <u>GENERAL DISCLAIMER</u>. COLLEGE BOARD DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING,
          WITHOUT LIMITATION, ANY IMPLIED REPRESENTATIONS OR WARRANTIES OF MERCHANTABILITY, OR FITNESS FOR A PARTICULAR
          PURPOSE. COLLEGE BOARD DOES NOT REPRESENT OR WARRANT THAT TEST DAY TOOLKIT WILL BE UNINTERRUPTED OR
          ERROR-FREE, OR THAT ALL DEFICIENCIES OR ERRORS ARE CAPABLE OF BEING CORRECTED.
        </li>
        <li>
          <u>LIMITATION OF LIABILITY</u>. EXCEPT TO THE EXTENT FINALLY DETERMINED TO BE PROHIBITED BY LAW, IN NO EVENT
          WILL COLLEGE BOARD OR ITS AFFILIATES HAVE ANY LIABILITY WHATSOEVER, INCLUDING, BUT NOT LIMITED TO, DIRECT,
          INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES (EVEN IF WE HAVE BEEN ADVISED OF THE
          POSSIBILITY OF SUCH DAMAGES) IN CONTACT, TORT, OR OTHERWISE TO YOU OR ANY THIRD PARTY ARISING FROM, RELATING
          TO, OR IN CONNECTION WITH THIS AGREEMENT.
        </li>
        <li>
          <u>Publicity</u>. You may not use College Board name or trademarks for any reason, including, without
          limitation, for marketing or publicity purposes.
        </li>
        <li>
          <u>Governing Law</u>. This Agreement shall be governed in all respects by the laws of the State of New York
          without regard to principles in respect of conflict of law.
        </li>
        <li>
          <u>No Third-Party Beneficiaries</u>. Each party intends that this Agreement shall not benefit, or create any
          right or cause of action in or on behalf of, any person or entity other than College Board and You.
        </li>
      </ol>

      <h3 className='tdtk-h3 text-center legal__title-small' data-automation='heading-h3'>
        ATTACHMENT A TO SAT TEST DAY TOOLKIT AGREEMENT
      </h3>

      <p className='mb-4'>
        <u>What</u>: College Board is providing Test Day Toolkit, a web-based tool accessible via mobile phone, tablet,
        and desktop computer that allows test center staff (test center supervisors/coordinators, associate
        supervisors/proctors, and monitors) administering the SAT to, among other items,:
      </p>

      <ul>
        <li>Manage test center staff</li>
        <li>Assign staff and students to testing rooms</li>
        <li>Check in students on test day</li>
        <li>Manage student rosters</li>
        <li>Generate final student rosters</li>
        <li>Capture/collect test day irregularities (student- and group-level)</li>
      </ul>
      <p className='mb-4'>
        <u>Support and Training</u>: College Board will provide users with helpful resources within Test Day Toolkit.
        Educational Testing Service (&ldquo;ETS&rdquo;) will answer any questions about the tool.
      </p>
      <p className='mb-4'>
        By clicking <strong>Submit</strong>, I acknowledge that I have read and accept the terms of (1) the SAT Testing
        Staff Agreement and (2) the SAT Test Day Toolkit Agreement.
      </p>
    </React.Fragment>
  ),
  homeIntroHelpText: (
    <React.Fragment>
      <h2 className='tdtk-h2 py-4' id='do-you-need-help-header'>
        Do you need help?
      </h2>
      <p className='mb-2'>
        To sign in for the first time, you need a personalized access email. If you didn’t get an email:
      </p>
      <ul className='mb-4'>
        <li>Check your junk email folder.</li>
        <li>Ask your test coordinator for help.</li>
        <li>If you are a coordinator, use the information in your manual to contact us.</li>
      </ul>
      <p className='mb-4'>
        Step-by-step instructions and more troubleshooting tips are available on{' '}
        <a
          href='https://bluebook.collegeboard.org/test-admin/test-day-toolkit-access/'
          rel='noopener noreferrer'
          target='_blank'
        >
          First-Time Access to Test Day Toolkit
        </a>
        .
      </p>
    </React.Fragment>
  ),
  helpPage: (
    <React.Fragment>
      <h2 id='whats-new'>Toolkit Features</h2>

      <p>Make sure you use everything Test Day Toolkit has to offer. Features for coordinators:</p>

      <ul>
        <li>
          <strong>Auto-setup </strong>assigns students to rooms, makes the best use of your space, and estimates staff
          needs with the click of a button.
        </li>
        <li>
          <strong>Printable rosters </strong>include an accurate final roster to return with your shipment, room rosters
          for proctors, and a room directory you can post for students.
        </li>
        <li>
          <strong>Voucher data </strong>does the math for you when you create ETS<sup>®</sup> payment vouchers.
        </li>
        <li>
          <strong>Test center closure reporting </strong>is available on the homepage and could save you a phone call.
        </li>
      </ul>

      <p>Features for all users:</p>

      <ul>
        <li>
          <strong>Student check-in </strong>has all the student info you need to check IDs and admission tickets.
        </li>
        <li>
          <strong>Digital seating charts </strong>are easily customized and hard to get wrong. Proctors can skip the
          seating chart when they complete the paper Testing Room Materials Report.
        </li>
        <li>
          <strong>Online irregularity reports </strong>replace the paper form. Submit them to quickly let us know if
          something goes wrong.
        </li>
      </ul>

      <h2 id='how-tos'>Coordinator Tips and How-Tos</h2>

      <h3>How to Give Staff Access</h3>

      <p>
        When you add new staff to Test Day Toolkit, you’ll provide their email address and personal phone number and
        we’ll send them an access email with instructions. Staff who have used the toolkit for past administrations can
        sign in without an access email.
      </p>

      <p>
        Share{' '}
        <a href='/pdf/Staff-Access-Test-Day-Toolkit.pdf' target='_blank'>
          Staff Access to Test Day Toolkit (pdf)
        </a>{' '}
        for detailed help and troubleshooting tips.
      </p>

      <h3>How to Set Up Your Test Center</h3>

      <p>
        The toolkit makes it easier for coordinators to get ready for test day by figuring out where to seat each
        student and by generating room rosters.
      </p>

      <p>Status messages at the top of your screen walk you through the process:</p>

      <ol>
        <li>
          Add rooms: Just name your rooms and tell us how many students they can seat. If you’ve used the toolkit
          before, you can import rooms from a previous test day instead.
        </li>
        <li>
          Auto-setup: You’ll see this button when you have enough rooms to seat all registered students in each testing
          group. Select it and the toolkit assigns students to rooms.
        </li>
        <li>
          Add staff: New staff are automatically sent an access email so they can use the toolkit on test day. Don’t
          create new records for returning staff; import them into the new administration instead.
        </li>
        <li>Assign staff: When you have enough staff, assign them to rooms.</li>
      </ol>

      <p>
        You’re all set! Watch the status panel to find out if your roster changes and you need to move students or seat
        new ones.
      </p>

      <h3 id='how-to-close'>How to Close Your Test Center</h3>

      <p>To close your test center, follow the steps on the homepage. Don’t call TAS unless you’re instructed to.</p>

      <p>If you’ve already reported your closure, here’s what you need to do next:</p>

      <ul>
        <li>Notify local media (as you do for emergency school closings).</li>
        <li>Post the closure on your school website.</li>
        <li>Have someone on-site in case students show up. If that’s not possible, put up a sign.</li>
        <li>Return all test materials immediately or store them securely until you can return them.</li>
        <li>
          If you’re not sure you can offer a makeup test, find out if it’s possible and contact TAS &nbsp;at{' '}
          <a href='mailto:testadminsupport@collegeboard.org?subject=Test%20Center%20Closure'>tas@ets.org</a> or call{' '}
          <a href='tel:+18665026384'>866-502-6384</a> on the Monday or Tuesday after test day.
        </li>
        <li>
          If you need to close any upcoming&nbsp;administrations, contact TAS at{' '}
          <a href='mailto:testadminsupport@collegeboard.org?subject=Test%20Center%20Closure'>tas@ets.org</a> or call{' '}
          <a href='tel:+18665026384'>866-502-6384</a>.
        </li>
      </ul>

      <p>And here’s what we’ll do:</p>

      <ul>
        <li>
          If you reported your closure before 8 p.m. ET on the Friday before test day, we’ll email students telling them
          not to report to your test center.
        </li>
        <li>
          We’ll post test center closures on{' '}
          <a
            href='https://satsuite.collegeboard.org/sat/test-center-closings'
            target='_blank'
            rel='noopener noreferrer'
            title='sat.org/test-center-closings'
          >
            sat.org/test-center-closings
          </a>{' '}
          as quickly as possible.
        </li>
        <li>We’ll contact all students about makeup tests, cancellations, and refunds.</li>
      </ul>

      <h3>Coordinator Test Day Tips</h3>

      <ul>
        <li>If you want students to find their assigned room on their own, print and post a room directory.</li>
        <li>If students arrive late, go to Testing Rooms to find out which rooms haven’t started testing yet.</li>
        <li>
          Later, return to Testing Rooms to find out which rooms have ended testing and if all seating charts were
          submitted to College Board.
        </li>
        <li>
          Make sure your attendance data are up to date—and that all students who took the test are checked in to a
          room—before you print your final roster or use the voucher data to complete your ETS voucher form.
        </li>
      </ul>

      <h2 id='proctor-workflow'>Proctor Workflow</h2>

      <p>As a proctor, you’ll use Test Day Toolkit throughout test day. Watch this video to find out what it’s like:</p>

      <h4>
        <a href='https://youtu.be/cjUcwn2EK9w' target='_blank' rel='noopener noreferrer'>
          Proctoring with Test Day Toolkit{' '}
        </a>
      </h4>

      <p>Before students arrive:</p>

      <ul>
        <li>Go to Testing Rooms to find out which room you’re assigned to.</li>
        <li>Click your room name to access the list of students assigned to it.</li>
      </ul>

      <p>When students come to your room:</p>

      <ul>
        <li>Click their names to compare their IDs and admission tickets to their roster photos and details.</li>
        <li>
          Click <strong>Room Check-In</strong>.
        </li>
      </ul>

      <p>After you read the first set of instructions and start timed testing:</p>

      <ul>
        <li>
          Click <strong>Start Testing</strong>.
        </li>
        <li>
          Click <strong>Manage Seating Chart</strong> to select a student name and test book serial number for each
          seat.
        </li>
        <li>If something goes wrong, submit an irregularity report to your coordinator.</li>
        <li>
          When timed testing is done for the day, click <strong>End Testing</strong>.
        </li>
      </ul>

      <h2 id='student-check-in'>Student Check-In</h2>

      <p>
        Some test centers check students in to the building before they check them in to their room. Others use room
        check-in only. Test Day Toolkit supports both options.
      </p>

      <p>
        Either way, keep the SAT fair and secure by checking students in one at a time. That’s the only way you can
        compare student IDs and admission tickets to roster photos and details.
      </p>

      <h3>Test Center Check-In</h3>

      <p>To check students in to the test center:</p>

      <ol>
        <li>Navigate to the student roster and search for a student.</li>
        <li>Click their name to view their roster photo and details.</li>
        <li>
          Click <strong>Center Check-In</strong> and direct the student to their room.
        </li>
      </ol>

      <h3>Room Check-In</h3>

      <p>To check students in to their room:</p>

      <ol>
        <li>Go to Testing Rooms to find out which room you’re assigned to.</li>
        <li>Click your room name to access the list of students assigned to it.</li>
        <li>Click the student’s name to view their roster photo and details.</li>
        <li>
          Click <strong>Room Check-In </strong>and direct the student to their seat.
        </li>
      </ol>

      <h2 id='seating-charts'>Seating Chart Tips</h2>

      <ul>
        <li>
          The seating chart works best if you use Test Day Toolkit to check students in to your room and click{' '}
          <strong>Start Testing </strong>first.
        </li>
        <li>During check-in keep track of where you’re seating each student.</li>
        <li>
          Click <strong>Adjust Seats</strong> to change the dimensions of the grid, which defaults to 6 rows of 5.
        </li>
        <li>Click a seat in the grid to select a student and test book serial number.</li>
        <li>
          Leave the seating chart in the paper Testing Room Materials Report blank, but remember to complete the rest of
          the form.
        </li>
      </ul>

      <h2 id='faqs'>FAQs</h2>

      <h3>Do I have to use the link in my access email every time I sign in?</h3>

      <p>No, after you sign in to Test Day Toolkit the first time, you won’t need it again.</p>

      <h3>Can I check in multiple students at once?</h3>

      <p>
        Students must be checked in one at a time so that you compare student IDs and admission tickets to roster photos
        and details—and keep the SAT fair and secure.
      </p>

      <h3>Should I use the toolkit to check students out for breaks or at the end of the day?</h3>

      <p>No, but you can use Test Day Toolkit to check their identity when they return from a break.</p>

      <h3>When should I click Start Testing and End Testing?</h3>

      <p>
        Click each button only once. Click <strong>Start Testing</strong> after you read the first set of instructions
        and start timed testing. Click <strong>End Testing</strong> when testing is done for the day.
      </p>

      <p>When you click these buttons, your coordinator can see your room’s status at a glance.</p>

      <p>
        Clicking <strong>Start Testing</strong> also marks no-show students absent automatically, updates your roster,
        and makes it easier to complete the seating chart.
      </p>

      <h3>What should I do if I click Start Testing or End Testing by mistake?</h3>

      <p>
        If you didn’t mean to click the <strong>Start Testing</strong> button:
      </p>

      <ol>
        <li>Ask your coordinator to reset the room status to “Ready” by editing your room details.</li>
        <li>
          Click <strong>Undo Mark as Absent</strong> when you check arriving students in to your room.
        </li>
      </ol>

      <p>
        If you clicked <strong>End Testing</strong> by mistake, ask your coordinator to reset the room status to “Test
        in progress” by editing your room details.
      </p>

      <h3>How can I save time adding rooms and staff?</h3>

      <p>
        If you’ve used Test Day Toolkit before, import rooms and returning staff from previous administrations. Just
        click <strong>Add Rooms</strong> on the Rooms page or <strong>Add Staff </strong>on the Staff page and follow
        the instructions.
      </p>

      <h3>My voucher data are inaccurate. How can I fix it?</h3>

      <p>
        Make sure your attendance data are up to date. All students who took the test should be checked in to a room but
        sometimes students are accidentally checked out or marked absent.
      </p>

      <h3>What materials do I have to include in the return shipment?</h3>

      <p>
        Coordinators need to return all materials listed in the manual, except for paper reports of irregularities
        submitted in Test Day Toolkit.
      </p>

      <p>
        Don’t forget to return the paper Testing Room Materials Reports. Proctors can leave the seating charts blank
        since they used the toolkit to submit them.
      </p>

      <p>You can print your final roster from the toolkit and include it with your shipment.</p>

      <h2 id='resources'>Resources</h2>

      <p>For coordinators:</p>

      <ul>
        <li>
          <a href='/pdf/SAT_Weekend_Coordinator_Manual.pdf' target='_blank'>
            SAT™ Coordinator Manual (.pdf)
          </a>
        </li>
        <li>
          <a href='/pdf/SAT_Weekend_Coordinator_Manual_Intl.pdf' target='-blank'>
            SAT™ Coordinator Manual - International (.pdf)
          </a>
        </li>
        <li>
          <a href='/pdf/Coordinator-Access-Test-Day-Toolkit.pdf' target='_blank'>
            Coordinator Access to Test Day Toolkit (.pdf)
          </a>
        </li>
        <li>
          <a href='/pdf/Coordinator_Test_Day_Tips.pdf' target='_blank'>
            Coordinator’s Test Day Tips (.pdf)
          </a>
        </li>
      </ul>

      <p>For staff:</p>

      <ul>
        <li>
          <a href='/pdf/Staff-Access-Test-Day-Toolkit.pdf' target='_blank'>
            Staff Access to Test Day Toolkit (pdf)
          </a>
        </li>
        <li>
          <a href='/pdf/Test_Day_How-To_Guide.pdf' target='_blank'>
            Test Day How-To Guide (.pdf)
          </a>
        </li>
      </ul>

      <h2 id='contact-tas'>Contact Test Administration (TA) Support</h2>

      <p>
        If you’re a coordinator, TA Support can answer your questions and help you troubleshoot issues. Staff should ask
        their coordinator for help.
      </p>

      <h3>Email</h3>

      <p>Include your name and title (SAT coordinator) and your SAT test center number and location.</p>

      <p>
        <a href='mailto:testadminsupport@collegeboard.org?subject=Test%20Center%20Closure'>
          testadminsupport@collegeboard.org
        </a>
      </p>

      <h3>Call</h3>

      <p>
        <a href='tel:+18665026384'>866-502-6384</a> (domestic US) or <a href='tel:+12125208570'>+1-212-520-8570</a>{' '}
        (international)
      </p>

      <p>Regular Hours: 8 a.m. to 5 p.m. ET, Monday through Friday</p>

      <p>Test Day Hours: Friday 8 a.m. to Saturday 6 p.m. ET and Sunday 8 a.m. to 4 p.m. ET</p>
    </React.Fragment>
  ),
  voucherIntro: (
    <React.Fragment>
      <p className='mb-4'>
        Use the information shown here to create payment vouchers at{' '}
        <a href='https://supervisor.ets.org/Supervisor/' rel='noopener noreferrer' target='_blank'>
          ets.org/supervisor
        </a>{' '}
        after testing.
      </p>
      <p className='mb-4'>
        <strong>IMPORTANT:</strong> Make sure your roster is updated with attendance data first.
      </p>
    </React.Fragment>
  ),
  voucherStandardStudentIntro: (
    <React.Fragment>
      <h2 className='tdtk-h2 py-4'>Standard Attendance Information</h2>
      <p className='mb-4'>Use this table to complete step 2 of creating a standard ETS voucher.</p>
    </React.Fragment>
  ),
  voucherStandardStaffIntro: (
    <React.Fragment>
      <h2 className='tdtk-h2 py-4'>Standard Staff Role Information</h2>
      <p className='mb-4'>Use this table to complete step 4 of creating a standard ETS voucher.</p>
    </React.Fragment>
  ),
  voucherAccommodatedStudentIntro: (
    <React.Fragment>
      <h2 className='tdtk-h2 py-4'>Accommodated Attendance Information</h2>
      <p className='mb-4'>Use this table to complete step 2 of creating an accommodated ETS voucher.</p>
    </React.Fragment>
  ),
  voucherAccommodatedStaffIntro: (
    <React.Fragment>
      <h2 className='tdtk-h2 py-4'>Accommodated Staff Role Information</h2>
      <p className='mb-4'>Use this table to complete step 4 of creating an accommodated ETS voucher.</p>
    </React.Fragment>
  ),
  voucherSubjectTestsMessage: (
    <td colSpan={4}>
      <p className='mb-4'>
        <strong>Important:</strong> If you had any accommodated rooms for SAT Subject Tests with Listening, subtract
        those numbers from the totals shown below and report them separately on the ETS voucher.
      </p>
    </td>
  ),
  welcomeModal: (
    <div className='videoWrapper mb-4'>
      <iframe
        allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
        allowFullScreen
        frameBorder='0'
        height='315'
        src='https://www.youtube.com/embed/bC-nsGegSng?rel=0&ref=0&modestbranding=1'
        title='YouTube - College Board Test Day Toolkit First Time Access'
        width='560'
      ></iframe>
    </div>
  ),
};
