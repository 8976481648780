import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from '@cb/apricot-react';

function AmountCheckedDisplay({
  amountSelected = 0,
  endDisplayIndex = 0,
  itemsPerPage = 30,
  showSelected = false,
  startDisplayIndex = 0,
  tableName = '',
  totalCount = 0,
  loading = false,
}) {
  return (
    <span
      id='amount-checked'
      style={{ fontSize: '1rem' }}
      aria-atomic='true'
      aria-live='polite'
      className='cb-float-left display-flex align-items-center'
    >
      {loading ? <Spinner size='16' className='cb-margin-right-8' /> : null}
      <span className='cb-sr-only'>{tableName} table currently </span>
      Displaying{' '}
      {totalCount < itemsPerPage
        ? totalCount
        : `${
            startDisplayIndex === endDisplayIndex ? 1 : `${startDisplayIndex}-${endDisplayIndex}`
          } of ${totalCount}`}{' '}
      result{totalCount === 1 ? '' : 's'}
      {showSelected ? ` (${amountSelected} selected)` : ''}
    </span>
  );
}

AmountCheckedDisplay.propTypes = {
  amountSelected: PropTypes.number,
  endDisplayIndex: PropTypes.number,
  itemsPerPage: PropTypes.number,
  showSelected: PropTypes.bool,
  startDisplayIndex: PropTypes.number,
  tableName: PropTypes.string,
  totalCount: PropTypes.number,
};

export default AmountCheckedDisplay;
