import React from 'react';
import { BackToTop } from '@cb/apricot-react';
import { useStateValue } from '../../../context/AppContext';
import { useTitle } from '../../../constants/useTitle';
import DynamicAnchorMenu from '../../ui/anchormenu/DynamicAnchorMenu';
import Messaging from '../../../context/Messaging';
import './Help.scss';
import { scrollTo } from '../../../utils/common';

function Help() {
  // Global App state.
  const { orgEvent } = useStateValue();

  useTitle('Help');

  // Define a flag to indicate if the CMS content has loaded
  const [cmsContentLoaded, setCmsContentLoaded] = React.useState(false);

  let updateMenuContent = () => {};

  function onCmsContentLoaded(content) {
    updateMenuContent(content);
    setCmsContentLoaded(true);
  }

  React.useEffect(() => {
    const id = window.location.hash.split('#')[1]; // Extract the ID from the URL
    // Scroll to the element when both CMS content and ID are available
    if (id && cmsContentLoaded) {
      try {
        const element = document.getElementById(id);
        if (element) {
          scrollTo(id);
        } else {
          throw new Error(`Element with ID ${id} not found on the page.`);
        }
      } catch (error) {
        console.error(`Error scrolling to element with ID ${id}: ${error.message}`);
      }
    }
  }, [cmsContentLoaded]);

  return (
    <div className={orgEvent?.dapInd ? 'container' : ''}>
      <div className='help-heading'>
        <h1 className='tdtk-h1 py-4' id='top-of-page' data-automation='heading-h1'>
          Help
        </h1>
        <DynamicAnchorMenu
          label='On This Page'
          registerNewContentListener={(f) => (updateMenuContent = f)}
        ></DynamicAnchorMenu>
      </div>
      <div className='formatted-content'>
        <Messaging dynamic={true} cmsContentLoaded={onCmsContentLoaded} section='help'></Messaging>
        <BackToTop topOfPageAnchorId='top-of-page' animationTime={100}></BackToTop>
      </div>
    </div>
  );
}

export default Help;
