import moment from 'moment';

const FORMAT_STRING = 'MM/DD/YYYY';

export function formattedDate(dob: string) {
  // if the format is YYYY-MM-DD or ISO 8601
  if (typeof dob === 'string') {
    if (dob?.match(/^(\d{4})-(\d{2})-(\d{2})/)) {
      return moment.utc(dob).format(FORMAT_STRING);
      // if moment knows how to process the date
    } else if (moment.utc(dob, 'x').format(FORMAT_STRING) !== 'Invalid date') {
      return moment.utc(dob, 'x').format(FORMAT_STRING);
    }
  }
  return dob;
}
