import { GraphQLError } from 'graphql';

/**
 * An error that occurs within the business logic of the application.
 *
 * @class AppError
 * @extends {GraphQLError}
 */
class AppError extends GraphQLError {
  constructor(error) {
    super('app_error');

    this.payload = error;
  }
}

export default AppError;
