import { gql } from 'graphql-tag';

export const SirFragment = gql`
  fragment SirFragment on SirType {
    id
    reportId
    irregularityCategory {
      label
      order
      irregularity {
        id
        label
      }
    }
    students {
      candDOB
      candFirstName
      candLastName
      candMidInit
      displayedRegNo
      groupType
      id
      multiDayInd
      regId
      roomName
      ssdAccommodations
      testPackageCount
      testPackageSeq
    }
    rooms {
      id
      title
    }
    entireTestCenter
    irregCreatedBy {
      id
      firstName
      lastName
    }
    statusText
    updated
  }
`;

export const SirMutationFragment = gql`
  fragment SirMutationFragment on SirType {
    id
    reportId
    students {
      candDOB
      candFirstName
      candLastName
      candMidInit
      displayedRegNo
      groupType
      id
      multiDayInd
      regId
      roomName
      ssdAccommodations
      testPackageCount
      testPackageSeq
    }
    rooms {
      id
      title
    }
    entireTestCenter
    statusText
    irregCreatedBy {
      id
      lastName
      firstName
    }
    irregUpdatedBy {
      id
      lastName
      firstName
    }
    irregularityCategory {
      label
      order
      irregularity {
        id
        description
        instructions {
          id
          body
          glyphicon
          showExpanded
          title
          weight
        }
        label
        order
        promptCategoryList {
          label
          order
          promptList {
            description
            id
            label
            order
            required
            responseList
            responseType
            validation_maxlength
            validation_minlength
            validation_regex
            validation_regex_message
          }
        }
      }
    }
    userResponse
    updated
  }
`;

export const GetRoomFragment = gql`
  fragment GetRoomFragment on RoomType {
    students {
      assignedSeat {
        x
        y
      }
      testBookNumber
    }
  }
`;
