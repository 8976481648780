import React from 'react';
import { scrollTo } from '../../../utils/common';
import { ErrorNotification } from '@cb/apricot-react';
import { IErrorMessagesProps } from './types';

const ErrorMessages: React.FC<IErrorMessagesProps> = ({ errorMessages = [] }) => {
  if (!errorMessages.length) {
    return null;
  }

  function clickHandler(e: React.MouseEvent<HTMLElement>) {
    e && e.preventDefault && e.preventDefault();

    const id = window.location.hash.split('#')[1];

    if (id) {
      scrollTo(id);
    }
  }

  return (
    <ErrorNotification title='Submission Error' notificationId='generalInfoFormErrorBlock'>
      <>
        <p>List of Errors:</p>
        <ul className='cb-list-style'>
          {[...errorMessages]
            .filter((item) => item.errorMessage)
            .map(({ key, uiName, errorMessage }, index) => (
              <li key={`${key}-${index}-link`} className='tdtk-error-link'>
                <a href={`#${uiName}`} id={`${key}-${index}-link`} onClick={clickHandler}>
                  {errorMessage}
                </a>
              </li>
            ))}
        </ul>
      </>
    </ErrorNotification>
  );
};

export default ErrorMessages;
