import React from 'react';
import get from 'lodash/get';
import RoomRoster from './RoomRoster';
import { roomStatus, seatingStatus } from '../../../../constants/roomConstants';
import { GET_ROOMS } from '../../../../apollo/queries';
import QueryComponent from '../../../common/network/QueryComponent';
import { seatsAvailable } from '../../../../utils/common';
import { getStaffingPriority } from '../../../../utils/room';
import { useTakeTheTour } from '../../../common/productTour';
import { useTitle } from '../../../../constants/useTitle';
import { useStateValue } from '../../../../context/AppContext';

function Container() {
  const { isUserTourValid, tourButton } = useTakeTheTour();
  useTitle('Rooms');
  const { orgEvent } = useStateValue();
  return (
    <React.Fragment>
      <div className={'d-flex justify-content-space-between align-items-center'}>
        <h1 className='tdtk-h1 py-4' data-automation='heading-h1'>
          Testing Rooms
        </h1>
        {isUserTourValid && tourButton}
      </div>
      <QueryComponent query={{ kind: 'GetRooms', specification: GET_ROOMS }}>
        {(data) => {
          const rooms = get(data, 'viewer.site.rooms') || [];
          let updatedRooms = [];
          // Update their status.
          updatedRooms = rooms.map((item) => {
            const availableSeats = seatsAvailable(item);
            return {
              ...item,
              availableSeats,
              staffingPriority: getStaffingPriority(availableSeats, item, item.staff, orgEvent),
              seatingStatus: seatingStatus[item.seatingStatus],
              status: roomStatus[item.status],
            };
          });

          return <RoomRoster rooms={updatedRooms} />;
        }}
      </QueryComponent>
    </React.Fragment>
  );
}

Container.displayName = 'RoomRosterContainer';

export default Container;
