import React from 'react';
import get from 'lodash/get';
import { STAFF_FORM } from '../../../../apollo/queries';
import QueryComponent from '../../../common/network/QueryComponent';
import { useStateValue } from '../../../../context/AppContext';
import StaffForm from './StaffForm';
import { useParams } from 'react-router-dom';

function Container() {
  let { id } = useParams();
  if (!id) {
    id = '';
  }
  const input = { id };

  // Global App state.
  const { orgEvent } = useStateValue();

  return (
    <div className={orgEvent?.dapInd ? 'container' : ''}>
      <h1 className='tdtk-h1 py-4' data-automation='heading-h1'>
        Test Day Staff
      </h1>

      <QueryComponent query={{ kind: 'StaffForm', specification: STAFF_FORM, variables: { input } }}>
        {(data) => {
          const staff = get(data, 'readUser') || {};
          const rooms = get(data, 'viewer.site.rooms') || [];
          const allStaff = get(data, 'viewer.site.staff') || [];

          if (staff && staff.id) {
            return <StaffForm rooms={rooms} staff={staff} allStaff={allStaff} />;
          } else {
            return <p className='mb-4'>Staff not found.</p>;
          }
        }}
      </QueryComponent>
    </div>
  );
}

export default Container;
