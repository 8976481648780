import React from 'react';
import PropTypes from 'prop-types';
import { getTitle } from '../../../../../constants/roles';

export const AddStaffRow = ({ handleCheckItems = () => {}, options = {}, staff = {} }) => {
  const checkId = `add-bulk-check-${staff.id}`;
  const staffTitle = `${staff.lastName ? staff.lastName + ', ' : ''}${staff.firstName}`;

  function checkStaff(staffId) {
    return function () {
      handleCheckItems([staffId]);
    };
  }

  return (
    <tr>
      <td>
        <label htmlFor={checkId}>
          <input
            checked={options.checked ? 'checked' : ''}
            id={checkId}
            name={`add-bulk-check[${staff.id}]`}
            onChange={checkStaff(staff.id)}
            type='checkbox'
            value={staff.id}
          />
          <span className='cb-sr-only'>Select {staffTitle} for a bulk operation</span>
        </label>
      </td>
      <th scope='row'>
        {staffTitle}
        {!!staff.role && ` (${getTitle(staff.role)})`}
        <br />
        <span className='cb-sr-only'>Email: </span>
        {staff.email}
      </th>
    </tr>
  );
};

AddStaffRow.propTypes = {
  staff: PropTypes.object,
  options: PropTypes.object,
  handleCheckItems: PropTypes.func,
};
