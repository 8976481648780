import React, { createRef, useContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { CloseButton, Icon, Button, NakedButton } from '@cb/apricot-react';
import {
  compareSortOrder,
  getDynamicBlueboxTestStatuses,
  getUniqueSectionNames,
} from '../../../../../../constants/blueboxTestStatus';
import { DAP_STUDENT_TEST_STATUS } from '../../../../../../constants/bluebox';
import { getStudentDisplayData } from '../../../students/roster/rosterProcessing';
import { getUniqueStatuses } from '../../../students/status';
import { localStateReducer, trapKeyboardFocus } from '../../../../../../utils/common';
import { ResizeContext } from '../../../../../../context/ResizeContext';
import { RoomContext } from '../../../context/RoomContext';
import { useStateValue } from '../../../../../../context/AppContext';
import { useTitle } from '../../../../../../constants/useTitle';
import DigitalStudentFilterButton from './DigitalStudentFilterButton';
import ReloadButton from '../../../ReloadButton';
import RosterII from '../../../../../common/RosterII';

import './Monitor.scss';

const SECTION_3_NAME = 'Section 3';
const SECTION_START_TIME_SECTION_3 = `${DAP_STUDENT_TEST_STATUS.SECTION_START_TIME}-${SECTION_3_NAME}`;
const SECTION_3_ACTIVE_FITLER_DESCRIPTION =
  'Students listed here are in section 3, the SAT Essay. Used in CO, DE, IL, MI, NH state administrations only.';

function Dashboard({ generateHeaders = () => {}, refetch = () => {}, room = {}, StudentRosterRow = () => {} }) {
  // Global App state.
  const { orgEvent, user } = useStateValue();
  const tdtkAsmtType = orgEvent?.tdtkAsmtType;
  const windowSize = useContext(ResizeContext);

  useTitle(room?.title);

  // shared room state
  const { setStartedButNotSubmitted } = useContext(RoomContext);

  function setScreenItems(screenItems = []) {
    setLocalState({
      screenItems,
    });
  }

  function selectFilter(activeFilter, activeFilterDescription, activeFilterDisplayName) {
    setLocalState({
      activeFilter: activeFilter === localState.activeFilter ? '' : activeFilter,
      activeFilterDescription: activeFilter === localState.activeFilter ? '' : activeFilterDescription,
      activeFilterDisplayName: activeFilter === localState.activeFilter ? '' : activeFilterDisplayName,
    });
  }

  function clearFilters() {
    setLocalState({
      activeFilter: '',
      activeFilterDescription: '',
      activeFilterDisplayName: '',
    });

    filterRef.current.focus();
  }

  // Local state.
  const [localState, setLocalState] = useReducer(localStateReducer, {
    activeFilter: '',
    activeFilterDescription: '',
    activeFilterDisplayName: '',
    bulkAction: '',
    checkedStudents: [],
    monitorButtonDisabled: false,
    screenItems: [],
    showFiltersMobile: false,
  });

  const { students = [] } = room;

  const emptyMessage = students.length ? '' : 'No students are assigned to this room.';

  const blueboxTestStatuses = getDynamicBlueboxTestStatuses(getUniqueSectionNames(students), tdtkAsmtType);
  const updatedStudents = getStudentDisplayData(students, tdtkAsmtType).filter(
    (student) => !localState.activeFilter || student?.dapTestStatus?.name === localState?.activeFilter
  );

  const filterRef = createRef();

  // Find only the unique statuses for students in this room.
  const dapTestStatuses = getUniqueStatuses(students, tdtkAsmtType);

  // Toggle Filters code
  function handleEscPress(event) {
    if (event.key === 'Escape') {
      toggleFiltersMobile();
    }
  }

  function toggleFiltersMobile() {
    if (localState.showFiltersMobile) {
      setLocalState({ showFiltersMobile: false });
      setTimeout(function () {
        document.getElementById('mobile-testing-filters-btn').focus();
      }, 50);
    } else {
      setLocalState({ showFiltersMobile: true });
      setTimeout(function () {
        trapKeyboardFocus('digital-testing-status-filters');
      }, 100);
      window.addEventListener('keydown', handleEscPress);
    }
  }

  // generate an array and sort by the sortOrder property of each status
  const availableStudentFilters = blueboxTestStatuses
    .filter((section) => section.displayName !== '')
    .filter((section) => section.name !== DAP_STUDENT_TEST_STATUS.OFFLINE)
    .filter((section) => section.name !== 'not-arrived')
    .filter((section) => section.name !== DAP_STUDENT_TEST_STATUS.EXAM_CHECKIN_STARTED) // this status is part of "not started" now, so don't show it as a filter option
    .filter((section) => section.name !== DAP_STUDENT_TEST_STATUS.WAITING_ROOM_ARRIVAL) // this status is part of "not started" now, so don't show it as a filter option
    .map((section) => {
      return {
        blueBoxTestStatusName: section.name, //This includes the section and module name and is being used to look up the details in blueboxTestStatuses
        count: dapTestStatuses[section.name],
        description: section.description,
        displayName: section.displayName,
        name: section.name, //This is the filter name. In the cases of test in progress with section-start-time, it only shows the section number (without the module number) for filtering
        sortOrder: section.sortOrder,
      };
    })
    .sort(compareSortOrder);

  useEffect(() => {
    // Un-set the filter if no students match an already selected filter.
    if (
      !availableStudentFilters.some((availableFilter) => availableFilter?.name === localState.activeFilter) &&
      localState.activeFilter !== ''
    ) {
      setLocalState({
        activeFilter: '',
        activeFilterDescription: '',
        activeFilterDisplayName: '',
      });
    }
  }, [availableStudentFilters, localState.activeFilter]);

  useEffect(() => {
    const totalStudentCount = students.length;
    const notStartedCount =
      availableStudentFilters.filter((stat) => stat?.name === DAP_STUDENT_TEST_STATUS.NONE)?.[0]?.count || 0;
    const submittedCount =
      availableStudentFilters.filter((stat) => stat?.name === DAP_STUDENT_TEST_STATUS.EXAM_SUBMISSION_COMPLETE)?.[0]
        ?.count || 0;

    setStartedButNotSubmitted(totalStudentCount - notStartedCount - submittedCount);
  }, [availableStudentFilters, setStartedButNotSubmitted, students]);

  const renderNoStudentOrMatchingFilterText = () => {
    if (localState.activeFilterDisplayName === SECTION_3_NAME) {
      return (
        <div className='section-3-no-student-text-ctn'>
          <div className='section-3-no-student-text-item' data-automation='section-3-no-student-item'>
            <div className='digital-heading--h3'>Colorado (State Administrations)</div>
            <p className='section-3-no-student-text'>
              If any students in your room are taking SAT with Essay, they’ll be listed here when they begin Section 3.
              If they’re not, their answers will be submitted at the end of Section 2.
            </p>
          </div>
          <div className='section-3-no-student-text-item' data-automation='section-3-no-student-item'>
            <div className='digital-heading--h3'>
              Delaware, Illinois, Michigan, New Hampshire (State Administrations)
            </div>
            <p className='section-3-no-student-text'>
              If your school is participating in a state administration, students in your room will be listed here when
              they start section 3, the SAT Essay.
            </p>
          </div>
          <div className='section-3-no-student-text-item' data-automation='section-3-no-student-item'>
            <div className='digital-heading--h3'>All Other Administrations</div>
            <p className='section-3-no-student-text'>
              Your students do not complete section 3. Their answers will be submitted at the end of Section 2.
            </p>
          </div>
        </div>
      );
    }
    if (localState?.activeFilter) {
      return (
        <p data-automation='active-filter-noMacth' className='px-3 mb-4'>
          No students match the selected filter.
        </p>
      );
    }

    return (
      <p data-automation='monitor-student-list-empty' className='px-3 mb-4'>
        No students are assigned to this room.
      </p>
    );
  };

  return (
    <>
      <div className='cb-black-corporate-color' id='digital-student-filters'>
        <div className='display-flex justify-content-between align-items-center flex-sm-column-reverse-only flex-xs-column-reverse-only'>
          <div
            className='tdtk-h4 cb-padding-left-16 cb-padding-sm-down-bottom-24'
            data-automation='monitor-dashboard-header'
          >
            Filter by testing status to see which students might need attention, and then check their screen to see if
            they&apos;re testing smoothly or not.
          </div>
          <div className='cb-padding-16 cb-width-sm-100-only cb-width-xs-100-only'>
            <ReloadButton refetch={refetch} reloadButtonID='StdRosterReloadData' />
          </div>
        </div>
        <hr className='hr--solid filter-hr hidden-xs hidden-sm' />
        <div className='row row-eq-height mx-0'>
          <div
            className={`col-xs-12 col-md-3 mx-0 pt-3 px-0 filters-mobile-layout ${
              localState?.showFiltersMobile ? '' : 'hide-filters-mobile-layout'
            }`}
          >
            <div
              aria-labelledby='testing-status-filters-modal-header'
              className={`digital-testing-status-filters-wrapper ${localState?.showFiltersMobile ? 'slide-open' : ''}`}
              id='digital-testing-status-filters'
              tabIndex='-1'
            >
              <CloseButton
                greyscale
                className='hidden-md-up mt-3 mb-1 ml-1'
                label='Close student status filters'
                onClick={toggleFiltersMobile}
                id='close-student-filters-btn'
                aria-controls='digital-testing-status-filters'
              />
              <div>
                <div className='d-flex px-3'>
                  <h3
                    className='tdtk-h3 mb-0 d-flex'
                    id='testing-status-filters-modal-header'
                    data-automation='testing-status-filters-header'
                  >
                    <Icon name='filter' decorative className='cb-margin-right-4 cb-margin-top-2' />
                    <span>Testing Status Filters</span>
                  </h3>
                </div>
                <div className='mt-2 pt-3'>
                  <div className='px-3 d-flex'>
                    <span data-automation='testing-status-filters-status' className='digital-heading--h4'>
                      Status
                    </span>
                    <span
                      data-automation='testing-status-filters-count'
                      className='digital-heading--h4 text-right ml-auto'
                    >
                      Count
                    </span>
                  </div>
                  <div className='px-3'>
                    <hr className='hr--solid-black my-1' />
                  </div>
                  <div className='digital-student-filters'>
                    {availableStudentFilters
                      .filter(
                        (stat) =>
                          stat?.name !== DAP_STUDENT_TEST_STATUS.OFFLINE &&
                          stat.name !== DAP_STUDENT_TEST_STATUS.EXIT_CONFIRMED &&
                          stat.name !== DAP_STUDENT_TEST_STATUS.EXAM_SUBMISSION_COMPLETE &&
                          stat.name !== DAP_STUDENT_TEST_STATUS.EXAM_SUBMISSION_PENDING
                      )
                      .filter((stat) => stat.name !== SECTION_START_TIME_SECTION_3)
                      .map((stat) => (
                        <DigitalStudentFilterButton
                          blueboxTestStatuses={blueboxTestStatuses}
                          count={stat?.count}
                          handleClick={() => selectFilter(stat?.name, stat?.description, stat?.displayName)}
                          isActive={localState.activeFilter === stat?.name}
                          key={`digital-student-filter-${stat?.name}`}
                          section={stat?.blueBoxTestStatusName}
                        />
                      ))}
                  </div>

                  {availableStudentFilters
                    .filter((stat) => stat.name === SECTION_START_TIME_SECTION_3)
                    .filter(() => orgEvent?.enableEssaySection === true)
                    .map((stat) => (
                      <div key={`digital-student-filter-limited-${stat?.name}`}>
                        <div className='px-3'>
                          <hr />
                        </div>
                        <div className='px-3 d-flex'>
                          <span data-automation='limited-use-only-header' className='digital-heading--h4'>
                            Limited Use Only
                          </span>
                        </div>
                        <div className='digital-student-filters'>
                          <DigitalStudentFilterButton
                            blueboxTestStatuses={blueboxTestStatuses}
                            count={stat?.count}
                            handleClick={() => selectFilter(stat?.name, stat?.description, stat?.displayName)}
                            isActive={localState.activeFilter === stat?.name}
                            section={stat?.blueBoxTestStatusName}
                            tooltipId={'section3ToolTipHelpText'}
                          />
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              <div className='py-4 mt-auto'>
                <div className='d-flex align-items-center px-3'>
                  <h3 className='tdtk-h3 mb-0' data-automation='testing-needs-attn-header'>
                    <Icon name='exclamation-fill' color='red1' className='cb-margin-right-4' />
                    Needs Attention
                  </h3>
                </div>
                <div className='mt-2 pt-2 px-3 d-flex'>
                  <span data-automation='testing-needs-attn-status' className='digital-heading--h4'>
                    Status
                  </span>
                  <span data-automation='testing-needs-attn-count' className='digital-heading--h4 text-right  ml-auto'>
                    Count
                  </span>
                </div>
                <div className='px-3'>
                  <hr className='hr--solid-black my-1' />
                </div>
                <div className='digital-student-filters'>
                  {availableStudentFilters
                    .filter(
                      (stat) =>
                        stat?.name === DAP_STUDENT_TEST_STATUS.OFFLINE ||
                        stat.name === DAP_STUDENT_TEST_STATUS.EXIT_CONFIRMED ||
                        stat.name === DAP_STUDENT_TEST_STATUS.EXAM_SUBMISSION_PENDING
                    )
                    .map((stat) => (
                      <DigitalStudentFilterButton
                        blueboxTestStatuses={blueboxTestStatuses}
                        count={stat?.count}
                        handleClick={() => selectFilter(stat?.name, stat?.description, stat?.displayName)}
                        isActive={localState.activeFilter === stat?.name}
                        key={`digital-student-filter-${stat?.name}`}
                        section={stat?.blueBoxTestStatusName}
                      />
                    ))}
                </div>
              </div>
              <div className='py-3 cb-blue5-tint-2'>
                <div className='px-3'>
                  <h3 className='tdtk-h3 mb-0' data-automation='testing-ready-to-dismiss-header'>
                    Ready to Dismiss
                  </h3>
                  <p className='pt-2' data-automation='testing-ready-to-dismiss-help'>
                    Dismiss students with a “Submitted” status before helping others.{' '}
                    <a
                      className='ready-to-dismiss-help-link cb-blue5-color'
                      data-automation='testing-ready-to-dismiss-help-link'
                      href='/help#answer-submission-dismissal'
                    >
                      Follow the dismissal instructions on the “Help” page.
                    </a>
                  </p>
                </div>
                <div className='mt-2 pt-2 px-3 d-flex'>
                  <span data-automation='testing-ready-to-dismiss-status' className='digital-heading--h4'>
                    Status
                  </span>
                  <span
                    data-automation='testing-ready-to-dismiss-count'
                    className='digital-heading--h4 text-right ml-auto'
                  >
                    Count
                  </span>
                </div>
                <div className='px-3'>
                  <hr className='hr--solid-black my-1' />
                </div>
                <div className='digital-student-filters'>
                  {availableStudentFilters
                    .filter((stat) => stat?.name === DAP_STUDENT_TEST_STATUS.EXAM_SUBMISSION_COMPLETE)
                    .map((stat) => (
                      <DigitalStudentFilterButton
                        blueboxTestStatuses={blueboxTestStatuses}
                        count={stat?.count}
                        handleClick={() => selectFilter(stat?.name, stat?.description, stat?.displayName)}
                        isActive={localState.activeFilter === stat?.name}
                        key={`digital-student-filter-${stat?.name}`}
                        section={stat?.blueBoxTestStatusName}
                      />
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className='col-xs-12 col-md-9 mx-0 px-0 dashboard-Student-list-wrapper cb-gray5-bg'>
            <div className='cb-white-bg py-4'>
              <div className='display-flex cb-border-sm-down-top cb-border-sm-down-bottom justify-content-between'>
                <div className='student-list-wrapper d-flex' ref={filterRef} tabIndex='-1' role='status'>
                  <div
                    role='heading'
                    aria-level='3'
                    className='digital-heading--h3 student-list-heading d-flex mr-1'
                    data-automation='monitor-student-list-header'
                    ref={filterRef}
                    tabIndex='-1'
                  >
                    Student List:
                  </div>
                  <div className='filters-selected-clear'>
                    <span
                      data-automation='monitor-filter-status-count'
                      role='status'
                      className='digital-heading--h3 mr-1'
                    >
                      {localState.activeFilterDisplayName || 'All Students'} ({updatedStudents.length || 0})
                    </span>
                    {
                      // Show Clear Filters link if a filter is active.
                      localState?.activeFilter ? (
                        <NakedButton small noPadding id='clear-filters-btn' onClick={clearFilters}>
                          Clear Filters
                        </NakedButton>
                      ) : null
                    }
                  </div>
                </div>
                <div className='cb-border-sm-down-left display-flex'>
                  <Button
                    variant={localState?.activeFilterDisplayName ? 'primary' : 'naked'}
                    aria-expanded={localState?.showFiltersMobile}
                    aria-label='Open filters'
                    aria-controls='digital-testing-status-filters'
                    className={'flex-column tdtk-h3 cb-no-border-radius cb-hidden-md-up'}
                    id='mobile-testing-filters-btn'
                    onClick={toggleFiltersMobile}
                  >
                    <Icon name='filter' decorative className='cb-no-margin' />
                    Filters
                  </Button>
                </div>
              </div>
              <div className='px-3'>
                <p data-automation='monitor-student-active-filter-desc' className='cb-margin-sm-down-top-8'>
                  {localState.activeFilterDisplayName !== SECTION_3_NAME
                    ? localState.activeFilterDescription
                    : SECTION_3_ACTIVE_FITLER_DESCRIPTION}
                </p>
              </div>
            </div>
            <div className='p-3 monitor-students-table-container'>
              <div tabIndex='-1' name='digital-student-list' id='digital-student-list' />
              {updatedStudents.length ? (
                <div data-automation='monitor-students-list-table'>
                  <RosterII
                    caption='Students for This Room'
                    emptyMessage={emptyMessage}
                    groupName='students'
                    headers={generateHeaders(user)}
                    items={updatedStudents}
                    renderItem={(student, options) => {
                      return (
                        <StudentRosterRow
                          key={`student_${student.id}`}
                          options={{
                            ...options,
                            checked: localState.checkedStudents.indexOf(student.id) > -1,
                            hiddenFields: { checkAll: true, room: true, seat: true },
                          }}
                          room={room}
                          student={student}
                        />
                      );
                    }}
                    showChecked={false}
                    sortFields={[
                      {
                        name: 'status.sortOrder',
                        order: 'asc',
                      },
                      {
                        name: 'dapTestStatus.sortOrder',
                        order: 'asc',
                        sortStrategy: 'alphanumeric',
                      },
                      {
                        name: 'candLastName',
                        order: 'asc',
                      },
                      {
                        name: 'candFirstName',
                        order: 'asc',
                      },
                      {
                        name: 'candMidInit',
                        order: 'asc',
                      },
                    ]}
                    setScreenItems={setScreenItems}
                    showSelected={!windowSize.mobile}
                  />
                </div>
              ) : (
                renderNoStudentOrMatchingFilterText()
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

Dashboard.propTypes = {
  generateHeaders: PropTypes.func,
  history: PropTypes.object,
  refetch: PropTypes.func,
  room: PropTypes.object,
  StudentRosterRow: PropTypes.func,
};

export default Dashboard;
