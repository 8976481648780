import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ModalDispatchContext } from '../../../ui/modal/ModalContext';
import ModalDialogWrapper from '../../../ui/modal/ModalDialogWrapper';
import ModalFooter from '../../../ui/modal/ModalFooter';

function SubmissionPendingModal({ proceedToNextStep = () => {}, refetch = () => {}, startedButNotSubmitted = 0 }) {
  // Modal Context.
  const dispatchModal = useContext(ModalDispatchContext);

  const modalId = 'modal-submissionPending';
  const modalTitle =
    startedButNotSubmitted !== 1
      ? `${startedButNotSubmitted} students have unsubmitted answers.`
      : `${startedButNotSubmitted} student has unsubmitted answers.`;

  function nextButtonFocusableElement() {
    const nextStepButton = document?.querySelectorAll('[data-automation="nav-nextStep-Button"]')?.[0];
    const nextStepLink = document?.querySelectorAll('[data-automation="nav-nextStep-Link"]')?.[0];
    if (typeof nextStepButton?.focus === 'function') {
      return nextStepButton;
    }
    if (typeof nextStepLink?.focus === 'function') {
      return nextStepLink;
    }
  }

  function handlePrimaryButton() {
    dispatchModal(null);
    refetch();
    nextButtonFocusableElement()?.focus();
  }

  function handleSecondaryButton() {
    dispatchModal(null);
    proceedToNextStep();
  }

  return (
    <ModalDialogWrapper
      modalId={modalId}
      onClose={handlePrimaryButton}
      open
      title={modalTitle}
      variant='error'
      clickOverlayToClose={false}
    >
      <>
        {
          <React.Fragment key='submissionPendingModalBody'>
            <ul>
              <li>Help them before they leave your room.</li>
              <li>
                Follow the dismissal instructions on the <strong>Help</strong> page.
              </li>
            </ul>
          </React.Fragment>
        }
        <ModalFooter
          buttons={[
            {
              handler: handleSecondaryButton,
              id: `btn-secondary-${modalId}`,
              style: 'black',
              text: 'Next Page',
            },
            {
              handler: handlePrimaryButton,
              id: `btn-primary-${modalId}`,
              style: 'yellow',
              text: 'Close',
            },
          ]}
        />
      </>
    </ModalDialogWrapper>
  );
}

SubmissionPendingModal.propTypes = {
  proceedToNextStep: PropTypes.func,
  refetch: PropTypes.func,
  startedButNotSubmitted: PropTypes.number,
};

export default SubmissionPendingModal;
