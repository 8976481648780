import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import TooltipWrapper from '../../ui/tooltip/TooltipWrapper';
import { ResizeContext } from '../../../context/ResizeContext';
import { useStateValue } from '../../../context/AppContext';
import { generateGroupTypeLabel } from '../../../utils/groupTypeLabels';

function GroupInfoRow({ groupInfo = {}, usePopovers = false }) {
  const { orgEvent } = useStateValue();

  function clickHandler(e) {
    e && e.preventDefault && e.preventDefault();
  }

  function handleClick(data) {
    return function () {
      sessionStorage.setItem('studentFilters', JSON.stringify(data));
    };
  }

  function mobileComponent() {
    return (
      <li>
        <Link onClick={handleClick({ groupType: `${groupInfo.groupType}` })} to='/students'>
          <div className='tdtk-item-text'>
            <div className='tdtk-item-title cb-roboto-bold'>
              <div className='row'>
                {groupInfo?.title
                  ? `${groupInfo.groupType}: ${groupInfo.title} (${generateGroupTypeLabel(groupInfo, orgEvent)})`
                  : `${groupInfo.groupType}: ${generateGroupTypeLabel(groupInfo, orgEvent)}`}
              </div>
            </div>
          </div>
        </Link>
      </li>
    );
  }

  function desktopComponent() {
    return (
      <tr>
        <th scope='row'>
          {usePopovers ? (
            <>
              <a href='#0' id={`popover-trigger-${groupInfo.groupType}`} ref={popoverRef} onClick={clickHandler}>
                {groupInfo.groupType}
              </a>
              <TooltipWrapper light={true} placement='right' trigger={`popover-trigger-${groupInfo.groupType}`}>
                <>
                  =
                  {groupInfo?.title
                    ? `${groupInfo.title} (${generateGroupTypeLabel(groupInfo, orgEvent)})${
                        groupInfo.description ? ` ${groupInfo.description}` : ''
                      }`
                    : `${generateGroupTypeLabel(groupInfo, orgEvent)} ${
                        groupInfo.description ? ` ${groupInfo.description}` : ''
                      }`}
                </>
              </TooltipWrapper>
            </>
          ) : (
            <Link onClick={handleClick({ groupType: `${groupInfo.groupType}` })} to='/students'>
              {groupInfo?.title
                ? `${groupInfo.groupType}: ${groupInfo.title} (${generateGroupTypeLabel(groupInfo, orgEvent)})`
                : `${groupInfo.groupType}: ${generateGroupTypeLabel(groupInfo, orgEvent)}`}
            </Link>
          )}

          {groupInfo.description !== '' && !usePopovers ? <p>{`${groupInfo.description}`}</p> : ''}
        </th>
        <td className='text-center'>
          {groupInfo.regCount > 0 ? (
            <Link
              onClick={handleClick({ groupType: `${groupInfo.groupType}`, status: 'waitListFlag:N' })}
              to='/students'
            >
              <span className='cb-sr-only'>Go to filtered student roster showing </span>
              {groupInfo.regCount}
              <span className='cb-sr-only'>
                {' '}
                registered student{groupInfo.regCount === 1 ? '' : 's'} in the {groupInfo.groupType} {groupInfo.title}{' '}
                {groupInfo.standard ? 'standard' : 'accommodated'} testing group
              </span>
            </Link>
          ) : (
            '0'
          )}
        </td>
        <td className='text-center'>
          {groupInfo.waitCount > 0 ? (
            <Link
              onClick={handleClick({ groupType: `${groupInfo.groupType}`, status: 'waitListFlag:Y' })}
              to='/students'
            >
              <span className='cb-sr-only'>Go to filtered student roster showing </span>
              {groupInfo.waitCount}
              <span className='cb-sr-only'>
                {' '}
                waitlisted student{groupInfo.waitCount === 1 ? '' : 's'} in the {groupInfo.groupType} {groupInfo.title}{' '}
                {groupInfo.standard ? 'standard' : 'accommodated'} testing group
              </span>
            </Link>
          ) : (
            '0'
          )}
        </td>
      </tr>
    );
  }

  const popoverRef = React.useRef();

  // Global App state.
  const windowSize = React.useContext(ResizeContext);

  return windowSize.mobile ? mobileComponent() : desktopComponent();
}

GroupInfoRow.propTypes = {
  groupInfo: PropTypes.object,
  options: PropTypes.object,
  usePopovers: PropTypes.bool,
};

export default GroupInfoRow;
