import React from 'react';
import PropTypes from 'prop-types';
import { ApolloProvider } from '@apollo/client';
import { getClient } from '../../../../apollo/configuration';
import { localStateReducer } from '../../../../utils/common';
import TwoButtonFormModal from './TwoButtonFormModal';

export default function TwoButtonFormWithQueryModal(props) {
  const [client] = React.useState(getClient());

  const [localState, setLocalState] = React.useReducer(localStateReducer, {
    disabled: props.disabled,
  });

  if (props.onWiring) {
    props.onWiring((newState) => {
      setLocalState({
        disabled: newState.disabled,
      });
    });
  }

  return (
    <TwoButtonFormModal
      {...props}
      body={<ApolloProvider client={client}>{props.body}</ApolloProvider>}
      disabled={localState.disabled}
      variant='input'
    />
  );
}

TwoButtonFormWithQueryModal.propTypes = {
  ...TwoButtonFormModal.propTypes,
  onWiring: PropTypes.func,
};
