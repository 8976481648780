import { rule } from 'indicative';

export const rules = {
  title: [
    rule('required'),
    rule('string'),
    rule('min', 1),
    rule('max', 100),
    rule('regex', new RegExp(/^[ a-zA-Z0-9()#&:.,;?!$'"]*$/)),
  ],
  capacity: [rule('required'), rule('min', 1), rule('max', 4), rule('regex', new RegExp(/^[1-9]\d*$/))],
};

export const messages = {
  'capacity.regex': 'Capacity must be a whole number.',
  'capacity.max': 'Capacity must be a number from 1 to 9999.',
  'capacity.min': 'Capacity must be a number from 1 to 9999.',
  'capacity.required': 'Capacity must be a number from 1 to 9999.',
  'max': 'Room name must be 100 characters or less.',
  'min': 'Room name must at least 1 character.',
  'regex': 'Room name can include only letters, numbers, spaces, and these symbols: ( ) # & : ; . , \' " ? ! $',
  'title.required': 'Room name is required.',
  'title.unique': 'This room name is already in use.',
};
