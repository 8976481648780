import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { analyticsTracking } from '../../../utils/analytics';
import { logUserOut } from '../../../utils/user';
import Login from '../login/Login';
import SMS from './SMS';
import Spinner from '../../ui/loading/SpinnerWrapper';
import Verify from './Verify';
import { useAuth } from '../../../utils/auth';
import { getAuthTokens } from '../../../utils/authUtil';

import {
  CHALLENGES,
  CognitoResponseType,
  getSignupParams,
  hasExhaustedAttempts,
  initAuth,
  isComplete,
  isNotAuthorizedError,
  removeSignupCookies,
  respondToChallenge,
  setSignupCookies,
} from './utility';
import { ErrInvalid, ErrSessionTimeout, ErrTooManyResends } from './VerifyErrors';
import LogoutModal from './LogoutModal';
import ErrorPage from '../../nonDigital/error/ErrorPage';

function Signup() {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const loginStatus = useAuth();
  const [cookies, setCookies] = useState(getSignupParams(params));
  const [challenge, setChallenge] = useState<CognitoResponseType>({
    ChallengeName: CHALLENGES.login,
    ChallengeParameters: { challenge: CHALLENGES.login },
  });
  const [cognitoStatus, setCognitoStatus] = useState<{ loading: boolean; error?: Error }>({
    loading: false,
  });
  const [selectedMedium, setSelectedMedium] = useState<string | undefined>();

  const cognitoRequestOptions = useMemo(
    () => ({
      onRequest: () => setCognitoStatus((prev) => ({ ...prev, loading: true })),
      onFailure: (error: Error) => setCognitoStatus({ loading: false, error }),
      onSuccess: (data: CognitoResponseType) => {
        setChallenge(data);
        setCognitoStatus((prev) => ({ ...prev, loading: false }));
      },
    }),
    []
  );

  const onVerifySuccess = useCallback((data: CognitoResponseType, formData: { [key: string]: string }) => {
    setChallenge(data);
    setSelectedMedium(formData?.medium);
  }, []);

  const exitSignup = useCallback(() => {
    navigate('/');
    removeSignupCookies();
  }, [navigate]);

  // Analytics
  useEffect(() => {
    analyticsTracking('', '', `signup-${challenge?.ChallengeParameters?.challenge}`);
  }, [challenge?.ChallengeParameters?.challenge]);

  // Set query params to cookies to preserve the values across IAM redirects
  useEffect(() => {
    setSignupCookies(params);
    setCookies(getSignupParams(params));
  }, [params]);

  // ensure proper setup before starting challenge flow
  useEffect(() => {
    const { magicUUID, userId } = cookies;
    if (!magicUUID || !userId) {
      exitSignup();
    } else if (magicUUID && userId) {
      setChallenge((prev: CognitoResponseType) => ({ ...prev, ChallengeParameters: { challenge: CHALLENGES.init } }));
    }
  }, [cookies, exitSignup]);

  // initial challenge flow: init -> magicUUID verification -> token verification
  useEffect(() => {
    if (!cognitoStatus.loading && loginStatus.loggedIn && !cognitoStatus.error) {
      if (challenge?.ChallengeParameters?.challenge === CHALLENGES.init) {
        initAuth({
          ...cognitoRequestOptions,
          onFailure: exitSignup,
        });
      } else if (challenge?.ChallengeParameters?.challenge === CHALLENGES.uuid) {
        respondToChallenge({ challenge, answer: { magicUUID: cookies.magicUUID } }, cognitoRequestOptions);
      } else if (challenge?.ChallengeParameters?.challenge === CHALLENGES.token) {
        const { authorization: token } = getAuthTokens();
        respondToChallenge({ challenge, answer: { token } }, cognitoRequestOptions);
      }
    }
  }, [
    challenge,
    cookies,
    cognitoStatus.loading,
    cognitoStatus.error,
    loginStatus.loggedIn,
    cognitoRequestOptions,
    exitSignup,
  ]);

  // user is authenticated and finished onboarding process
  useEffect(() => {
    if (isComplete(challenge)) {
      logUserOut();
      removeSignupCookies();
    }
  }, [challenge]);

  if (loginStatus.loading || cognitoStatus.loading) return <Spinner />;

  if (loginStatus.error) return <ErrorPage />;

  if (loginStatus.loggedOut) return <Login />;

  if (isNotAuthorizedError(cognitoStatus.error)) {
    // final submit of incorrect code will throw an error, use previous challenge response to derive error message.
    if (hasExhaustedAttempts(challenge)) return <ErrTooManyResends />;
    return <ErrSessionTimeout />;
  }

  if (cognitoStatus.error) return <ErrInvalid />;

  if (challenge?.ChallengeParameters?.challenge === CHALLENGES.verify)
    return <Verify challenge={challenge} onSuccess={onVerifySuccess} onFailure={cognitoRequestOptions.onFailure} />;

  if (challenge?.ChallengeParameters?.challenge === CHALLENGES.code)
    return <SMS challenge={challenge} selectedMedium={selectedMedium} cognitoRequestOptions={cognitoRequestOptions} />;

  if (challenge?.ChallengeParameters?.challenge === CHALLENGES.logout) return <LogoutModal />;

  return null;
}

export default Signup;
