import type { FieldFunctionOptions } from '@apollo/client';
import { IGetDigitalStudentsPaginatedQueryVars } from './types';

type PaginatedStudentsType = {
  lastKey?: string;
  students?: [];
};

export default {
  Query: {
    fields: {
      paginatedStudents: {
        // When set to false, responses will not be cached based on any of the field's arguments.
        keyArgs: false,

        // Concatenate the incoming list items with the existing list items.
        // If it is the first call startAt will be null, do not merge in this case, but overwrite the existing list items.
        merge(
          existing: PaginatedStudentsType,
          incoming: PaginatedStudentsType,
          options: { variables?: IGetDigitalStudentsPaginatedQueryVars }
        ) {
          const isFreshFetch = !options.variables?.input?.startKey;
          const incomingStudents = incoming?.students || [];
          const existingStudents = existing?.students || [];

          return {
            lastKey: incoming?.lastKey,
            students: isFreshFetch ? incomingStudents : [...existingStudents, ...incomingStudents],
          };
        },
      },
    },
  },
  StudentType: {
    fields: {
      status: {
        read(value: { sortOrder: number; statusText: string }, { readField }: FieldFunctionOptions) {
          const checkedInRoom = readField('checkedInRoom');
          const checkedInCenter = readField('checkedInCenter');
          const absent = readField('absent');
          const deniedEntry = readField('deniedEntry');

          return {
            sortOrder: checkedInRoom ? 5 : 1,
            statusText: checkedInRoom
              ? 'Checked in to room'
              : checkedInCenter
              ? 'Checked in to center'
              : absent
              ? 'Absent'
              : deniedEntry
              ? 'Denied entry'
              : 'Not Arrived',
          };
        },
      },
    },
  },
};
