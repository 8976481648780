import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { getRoomStaffByRole, hallMonitorRequirements } from '../../../../utils/room';
import { UPDATE_USERS } from '../../../../apollo/mutations';
import AddStaffSelection from './AddStaffSelection';
import get from 'lodash/get';
import { localStateReducer } from '../../../../utils/common';
import { useStateValue } from '../../../../context/AppContext';
import { useMutation } from '@apollo/client';
import { checkRoleChange, generateStaffOptions } from '../../../../utils/staff';
import { getTitle } from '../../../../constants/roles';
import { ModalDispatchContext } from '../../../ui/modal/ModalContext';
import TwoButtonFormModal from '../../../ui/modal/standard/TwoButtonFormModal';
import Spinner from '../../../ui/loading/SpinnerWrapper';
import { Icon } from '@cb/apricot-react';

function HallStaffList({ rooms = [], staff = [] }) {
  function updateHallUsers() {
    setLocalState({ loading: true });
    const usersWithOtherRolesChanged = [...checkRoleChange('hallMonitor', null, hallMonitorsSelected)];

    // Add these selected users, but only update their role if they are not admin.
    const changingUsers = hallMonitorsSelected.map((hm) => ({
      id: hm.value,
      role: hm.role === 'admin' || hm.role === 'CBAdmin' ? hm.role : 'hallMonitor',
      room: null,
    }));

    // If any staff were formally hall monitors, but now aren't, then remove their role if they are not an admin.
    staff
      .filter((s) => s.role === 'hallMonitor')
      .forEach((s) => {
        if (!changingUsers.find((cu) => cu.id === s.id)) {
          changingUsers.push({
            id: s.id,
            role: s.role === 'admin' || s.role === 'CBAdmin' ? s.role : null,
            room: null,
          });
        }
      });

    // Some selected users are already in another rooms or halls.
    const body = [];
    let evUser;
    let name = '';

    if (usersWithOtherRolesChanged.length) {
      // Update the messaging based on the users who are being affected.
      if (usersWithOtherRolesChanged.length === 1) {
        const oneUser = usersWithOtherRolesChanged[0];
        // Get the first/last name.
        evUser = staff.find((es) => es.id === oneUser.value);
        name = `${evUser.firstName} ${evUser.lastName ? evUser.lastName : ''}`;

        body.push(
          `${name} is already assigned to the room or role listed below. If you continue, they'll be reassigned.`
        );

        if (oneUser.role !== 'hallMonitor' || oneUser.role !== 'technologyCoordinator') {
          body.push(`\n\nRoom: ${get(oneUser, 'room.title') || 'Unassigned'}`);
          body.push(`\nRole: ${getTitle(oneUser.role) || 'Unassigned'}`);
        } else {
          body.push(`\n\nRole: ${getTitle(oneUser.role)}`);
        }
      } else if (usersWithOtherRolesChanged.length > 1) {
        body.push(
          'These staff members are already assigned to the rooms or roles listed below. If you continue, they’ll be reassigned.'
        );

        usersWithOtherRolesChanged.forEach((user) => {
          // Get the first/last name.
          evUser = staff.find((es) => es.id === user.value);
          name = `${evUser.firstName} ${evUser.lastName ? evUser.lastName : ''}`;

          body.push(`\n\n${name}`);

          if (user.role !== 'hallMonitor' || user.role !== 'technologyCoordinator') {
            body.push(`\nRoom: ${get(user, 'room.title') || 'Unassigned'}`);
            body.push(`\nRole: ${getTitle(user.role) || 'Unassigned'}`);
          } else {
            body.push(`\nRole: ${getTitle(user.role)}`);
          }
        });
      }

      // Dispatch a modal that will prompt them to confirm.
      dispatchModal(
        <TwoButtonFormModal
          body={body.join('')}
          modalId='updateStaffRoles'
          primaryButtonHandler={() => {
            // Send a call to update the appropriate staff members.
            updateSelectedUsers({
              users: [...changingUsers],
            });
          }}
          primaryButtonLabel='OK'
          secondaryButtonHandler={() => {
            setLocalState({
              ...initialState,
              loading: false,
            });
          }}
          variant='success'
        />
      );
    } else {
      // Should be good to update. Go nuts.
      updateSelectedUsers({
        users: [...changingUsers],
      });
    }
  }

  function updateStaffFormState(staffFormState) {
    hallMonitorsSelected = staffFormState['hallMonitorsSelected'];

    setLocalState({
      ...staffFormState,
    });
  }

  function handleAddStaff(e) {
    if (e) {
      e.preventDefault();
    }

    dispatchModal(
      <TwoButtonFormModal
        body={
          <AddStaffSelection
            callback={updateStaffFormState}
            hallMonitorsNeeded={hallMonitorsNeeded}
            hallMonitorsSelected={localState.hallMonitorsSelected}
            key='addHallMonitors'
            staff={staff.filter(
              (s) => s.role !== 'admin' && s.role !== 'CBAdmin' && s.role !== 'technologyCoordinator'
            )}
          />
        }
        modalId='addStaffConfirm'
        primaryButtonHandler={updateHallUsers}
        primaryButtonLabel='Save'
        secondaryButtonHandler={() => {
          setLocalState({
            ...initialState,
          });
        }}
        title='Assign Hall Monitors'
        variant='success'
      />
    );
  }

  function generateHallMonitorList() {
    const hallMonitorList = [];

    const { hallMonitors = [] } = getRoomStaffByRole([...staff]);

    if (hallMonitors.length > 0) {
      // Output all admin if assigned to this room.
      hallMonitors.forEach((m) => {
        hallMonitorList.push(<li key={`hall_proctor_${m.id}`}>{`${m.firstName} ${m.lastName}`}</li>);
      });
    }

    return {
      hallMonitorList,
    };
  }

  function updateSelectedUsers(input) {
    updateUsers({
      variables: {
        input,
      },
      refetchQueries: ['getRooms', 'getSiteStats'],
    })
      .catch((e) => {
        console.error('updateSelectedUsers failed', e);
      })
      .finally(() => {
        setLocalState({ loading: false });
      });
  }

  const hallMonitors = staff.filter((s) => s.role === 'hallMonitor');

  const initialState = {
    hallMonitorsSelected: generateStaffOptions(hallMonitors),
  };

  // Global App state.
  const dispatchModal = useContext(ModalDispatchContext);
  const { orgEvent } = useStateValue();

  // Local state.
  const [localState, setLocalState] = React.useReducer(localStateReducer, {
    hallMonitorsSelected: generateStaffOptions(hallMonitors),
    loading: true,
  });

  // If there is any update to our staff, re-render the localState.
  React.useEffect(() => {
    setLocalState({
      hallMonitorsSelected: generateStaffOptions(staff.filter((s) => s.role === 'hallMonitor')),
      loading: false,
    });
  }, [staff]);

  // Apollo.
  const [updateUsers] = useMutation(UPDATE_USERS);

  const { hallMonitorList } = generateHallMonitorList();

  const hallMonitorsNeeded = hallMonitorRequirements(rooms.length);
  const hallMonitorIconColor = hallMonitors.length < hallMonitorsNeeded ? (orgEvent?.dapInd ? 'red1' : 'orange1') : '';

  const hallMonitorRequirement = hallMonitorRequirements(rooms.length);

  const addMoreHallMonitors = hallMonitorRequirement - hallMonitors.length;

  /**
   * For some reason, we can't use localState inside of the actual submit call, because it's scope is older.
   * This was the only workaround I could get working in updateHallUsers().
   */
  let hallMonitorsSelected = localState.hallMonitorsSelected;

  return localState.loading ? (
    <Spinner />
  ) : (
    <div>
      {rooms.length > 0 ? (
        <React.Fragment>
          <div className='mb-3'>
            <a
              aria-label='Add hall monitors'
              href='#0'
              id='add-hall-monitors'
              onClick={handleAddStaff}
              className='cb-btn cb-btn-sm cb-btn-naked cb-no-padding'
            >
              + Click to assign hall monitors
            </a>
          </div>
          <span id='hMList' className='cb-sr-only'>
            Hall monitor list:
          </span>
          <ul className='mb-4' aria-labelledby='hMList'>
            {hallMonitorList}
          </ul>

          {
            <div className='d-flex align-items-center'>
              {addMoreHallMonitors > 0 ? (
                <Icon name='disc-alt' color={hallMonitorIconColor} className='mr-2' decorative />
              ) : null}
              <b aria-atomic='true' aria-live='polite'>
                {Math.abs(addMoreHallMonitors)} {addMoreHallMonitors < 0 ? 'fewer' : 'more'} hall monitor
                {addMoreHallMonitors === 1 ? '' : 's'} recommended
              </b>
            </div>
          }
        </React.Fragment>
      ) : null}
    </div>
  );
}

HallStaffList.propTypes = {
  rooms: PropTypes.array,
  staff: PropTypes.array,
};

export default HallStaffList;
