import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { BlackButton } from '@cb/apricot-react';
import { localStateReducer, formatStudentName } from '../../../../../utils/common';
import { UPDATE_STUDENT } from '../../../../../apollo/mutations';
import { useMutation } from '@apollo/client';
import { ModalDispatchContext } from '../../../../ui/modal/ModalContext';
import TwoButtonModal from '../../../../ui/modal/standard/TwoButtonModal';
import OneButtonModal from '../../../../ui/modal/standard/OneButtonModal';

export default function RemoveFromRoomButton({ student = {} }) {
  const [updateStudent] = useMutation(UPDATE_STUDENT);
  const dispatchModal = useContext(ModalDispatchContext);

  const [localState, setLocalState] = React.useReducer(localStateReducer, {
    inProgress: false,
  });

  function handleSubmit() {
    setLocalState({
      inProgress: true,
    });

    updateStudent({
      variables: {
        input: {
          id: student.id,
          assignedSeat: null,
          room: '',
          testBookNumber: null,
        },
      },
    })
      .then(() => {
        dispatchModal(
          <OneButtonModal
            modalId='removeFromRoomSuccess'
            title='The selected student has been moved out of their room.'
            variant='success'
          />
        );
      })
      .catch(() => {
        dispatchModal(
          <OneButtonModal
            modalId='removeFromRoomError'
            title='Oops. Unable to remove student at this time.'
            variant='error'
          />
        );
      })
      .finally(() => {
        setLocalState({
          inProgress: false,
        });
      });
  }

  function removeFromRoom() {
    dispatchModal(
      <TwoButtonModal
        modalId='removeFromRoom'
        primaryButtonHandler={handleSubmit}
        primaryButtonLabel='Remove from Current Room'
        secondaryButtonLabel='Cancel'
        title={`Remove ${formatStudentName(student, 'fml')} from current room?`}
        variant='input'
      />
    );
  }

  return (
    <BlackButton
      id='button-removeFromRoom'
      data-automation='button-removeFromRoom'
      onClick={removeFromRoom}
      disabled={isEmpty(student.room) || localState.inProgress}
      small
      className='mb-3 w-100'
    >
      Remove from Current Room
    </BlackButton>
  );
}

RemoveFromRoomButton.propTypes = {
  student: PropTypes.object,
};
