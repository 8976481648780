import CBUtils from '@cb/apricot/CBUtils';
import React, { ReactNode, useEffect, useState, createContext } from 'react';

interface IWindowSize {
  mobile?: boolean;
  prefix?: string;
}

interface IResizerProviderProps {
  children: ReactNode;
}

export const ResizeContext = createContext<IWindowSize>({});

/**
 * Makes the current viewport available to all child components.
 */
export const ResizeProvider = ({ children }: IResizerProviderProps) => {
  const ResizeWrapper = (windowSize: IWindowSize) => {
    return (
      <ResizeContext.Provider key='resize-wrapper' value={windowSize}>
        {children}
      </ResizeContext.Provider>
    );
  };

  const getCurrentPrefix = () => {
    const prefix = CBUtils?.viewport()?.prefix;
    const mobile = prefix === 'xs' || prefix === 'sm';

    return {
      mobile,
      prefix,
    };
  };

  const [windowSize, setWindowSize] = useState(getCurrentPrefix());

  useEffect(() => {
    const updateViewport = () => {
      setWindowSize(getCurrentPrefix());
    };

    // Let the browser know if the viewport changes.
    CBUtils.breakpoints();

    // Enable the listener for viewport changes.
    document.addEventListener('apricot_breakpointChange', updateViewport);

    return () => {
      document.removeEventListener('apricot_breakpointChange', updateViewport);
    };
  }, []);

  return ResizeWrapper(windowSize);
};
