const { REACT_APP_TDTK_ENV } = import.meta.env;

export const cbEnv =
  REACT_APP_TDTK_ENV === 'prod' || REACT_APP_TDTK_ENV === 'preprod'
    ? 'pine'
    : REACT_APP_TDTK_ENV === 'perf' || REACT_APP_TDTK_ENV === 'int'
    ? 'spruce'
    : REACT_APP_TDTK_ENV === 'uat'
    ? 'maple'
    : 'palm';
