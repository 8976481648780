import React from 'react';
import { customerSupportInfo } from '../../../constants/customerSupportInfo';
import ErrorNotification from '../../ui/message/ErrorNotification';

export const ErrInvalid = () => (
  <ErrorNotification className='mt-4' title='Invalid or Used Link' data-automation='signup-error-invalid'>
    <p>Try clicking the link again or pasting it into the browser, making sure you copy the entire link first.</p>
    <p>
      If that doesn&rsquo;t work, ask your test coordinator to send you a new link. If you are a test coordinator,{' '}
      <a href={'mailto:' + customerSupportInfo.email + '?subject=Test%20Day%20Toolkit'}>{customerSupportInfo.email}</a>{' '}
      or{' '}
      <a href={'tel:+' + customerSupportInfo.domesticPhoneNumber}>
        {customerSupportInfo.domesticPhoneNumberDisplayFormat}
      </a>{' '}
      (domestic) or{' '}
      <a href={'tel:+' + customerSupportInfo.internationalPhoneNumber}>
        {customerSupportInfo.internationalPhoneNumberDisplayFormat}
      </a>{' '}
      International.
    </p>
  </ErrorNotification>
);

export const ErrSessionTimeout = () => (
  <ErrorNotification className='mt-4' title='Session Timed Out' data-automation='signup-error-timeout'>
    <p>You have run out of time for this session. Please reload the page or try clicking the link again to resume.</p>
  </ErrorNotification>
);

export const ErrTooManyResends = () => (
  <ErrorNotification className='mt-4' title='Too Many Attempts' data-automation='signup-error-resends'>
    <p>You have attempted too many times.</p>
    <p>
      Ask your test coordinator to resend the access email. If you are a test coordinator, please contact us at{' '}
      <a href={'mailto:' + customerSupportInfo.email + '?subject=Test%20Day%20Toolkit'}>{customerSupportInfo.email}</a>{' '}
      or{' '}
      <a href={'tel:+' + customerSupportInfo.domesticPhoneNumber}>
        {customerSupportInfo.domesticPhoneNumberDisplayFormat}
      </a>{' '}
      (domestic) or{' '}
      <a href={'tel:+' + customerSupportInfo.internationalPhoneNumber}>
        {customerSupportInfo.internationalPhoneNumberDisplayFormat}
      </a>{' '}
      (international).
    </p>
  </ErrorNotification>
);
