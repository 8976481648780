import React, { createContext, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { cb } from '../../../widgetLibrary';
import { cbEnv } from '../../../constants/cbEnv';
import { getLogTimestamp } from '../../../utils/common';
import { WEBSOCKET_MESSAGE_TYPE, type WebsocketMessage } from './types';
import { useLocation } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';

export const SocketContext = createContext<{
  lastJsonMessage: WebsocketMessage[];
  readyState: ReadyState;
}>({
  lastJsonMessage: [],
  readyState: ReadyState.UNINSTANTIATED,
});
export const useSocketContext = () => useContext(SocketContext);

const { REACT_APP_WEBSOCKET_API_ENDPOINT, REACT_APP_TDTK_ENV } = import.meta.env;

const HEARTBEAT_INTERVAL = 60 * 1000 * 4.5; // 4.5 minutes

const debug = REACT_APP_TDTK_ENV !== 'prod';

export const SocketProvider = ({ children }: { children: React.ReactNode }) => {
  const { pathname } = useLocation();
  const client = useApolloClient();

  let orgEvent: { siteId?: string; asmtEventId?: string; orgId?: string } = {};
  try {
    orgEvent = JSON.parse(sessionStorage.getItem('orgEvent') || '{}') || {};
  } catch (e) {
    console.error('failed to parse orgEvent from sessionStorage', e);
  }

  let roomId;
  if (pathname?.startsWith('/rooms/get/')) {
    roomId = pathname.split('/')?.[3] || '';
  }

  const authToken = cb.core.iam.getAuthenticationToken();
  const accessToken = cb.core.iam.getAuthorizationToken();

  const hasClosed = useRef(false);
  const { lastJsonMessage, readyState } = useWebSocket<WebsocketMessage[]>(
    `${REACT_APP_WEBSOCKET_API_ENDPOINT}/${REACT_APP_TDTK_ENV}/?${roomId ? `roomId=${roomId}&` : ''}siteId=${
      orgEvent?.siteId
    }&eventId=${orgEvent?.asmtEventId}&orgId=${
      orgEvent?.orgId
    }&cbEnv=${cbEnv}&accessToken=${accessToken}&authToken=${authToken}`,
    {
      retryOnError: true,
      reconnectAttempts: 10,
      // NOTE: attemptNumber will be 0 the first time it attempts to reconnect,
      // so this equation results in a reconnect pattern of 1 second, 2 seconds, 4 seconds, 8 seconds, and then caps at 10
      reconnectInterval: (attemptNumber: number) => Math.min(Math.pow(2, attemptNumber) * 1_000, 10_000),
      shouldReconnect: () => true,
      heartbeat: {
        message: '{"action":"heartbeat"}',
        returnMessage: `{"t":${WEBSOCKET_MESSAGE_TYPE.HEARTBEAT}}`,
        timeout: HEARTBEAT_INTERVAL + 10_000,
        interval: HEARTBEAT_INTERVAL,
      },
      onOpen: (event) => {
        debug && console.debug(`${getLogTimestamp()} (ws:core) onOpen:\t`, event);
        if (hasClosed.current) {
          console.debug(`${getLogTimestamp()} (ws:core) onOpen refetching`);
          client.refetchQueries({ include: 'active' });
          hasClosed.current = false;
        }
      },
      onClose: (event) => {
        debug && console.debug(`${getLogTimestamp()} (ws:core) onClose:\t`, event);
        hasClosed.current = true;
      },
      onError: (event) => {
        debug && console.debug(`${getLogTimestamp()} (ws:core) onError:\t`, event);
      },
      onReconnectStop: (numAttempted) => {
        debug && console.debug(`${getLogTimestamp()} (ws:core) onReconnectStop:\t`, numAttempted);
      },
    }
  );

  return <SocketContext.Provider value={{ lastJsonMessage, readyState }}>{children}</SocketContext.Provider>;
};

SocketProvider.propTypes = {
  children: PropTypes.any,
};

export default SocketProvider;
