import React from 'react';
import { Link } from 'react-router-dom';
import WizardNav from '../../../common/WizardNav';
import PropTypes from 'prop-types';
import StepContentPanel from '../../../common/StepContentPanel';
import HelpNav from '../../../common/HelpNav';
import '../../../common/common.scss';
import { createCleanMarkup } from '../../../../../utils/common';

function TestCompletion({ flowData, room, cmsContent }) {
  const { stepNum } = flowData;

  const introStep = 1;
  const outerStep = 3;
  const progressStepperMax = 2;

  function Steps() {
    const stepContent = cmsContent?.steps?.find((n) => n.step_number === stepNum);
    const mainPanelData = <div dangerouslySetInnerHTML={createCleanMarkup(stepContent?.main_panel_content)} />;
    const detailPanelData = <div dangerouslySetInnerHTML={createCleanMarkup(stepContent?.detail_panel_content)} />;
    const content = {
      main_panel_content: mainPanelData,
      main_panel_header: stepContent?.main_panel_header,
      detail_panel_content: detailPanelData,
    };

    switch (stepNum) {
      case 1:
        return (
          <StepContentPanel
            room={room}
            showProgressBar={true}
            stepNum={stepNum}
            progressStepperMax={progressStepperMax}
            heading='Dismiss Students'
            content={content}
            desc={mainPanelData}
            img={<div className='test-complete-step-1-svg step-illustration-img mt-4' />}
            imgDesc='Complete steps 1-3 before you dismiss students.'
          ></StepContentPanel>
        );
      case 2:
        return (
          <StepContentPanel
            room={room}
            showProgressBar={true}
            stepNum={stepNum}
            progressStepperMax={progressStepperMax}
            heading='Report Problems'
            content={content}
            desc={mainPanelData}
            img={<div className='test-complete-step-2-svg step-illustration-img mt-4' />}
            imgDesc='Report problems to your coordinator'
          ></StepContentPanel>
        );
    }
  }

  function EndTest() {
    const endTestContent = cmsContent?.steps?.find((n) => n.step_number === 3);
    const endTestText = <div dangerouslySetInnerHTML={createCleanMarkup(endTestContent?.main_panel_content)} />;
    const detailPanelData = <div dangerouslySetInnerHTML={createCleanMarkup(endTestContent?.detail_panel_content)} />;
    const content = { main_panel_content: endTestText, detail_panel_content: detailPanelData };
    return (
      <div>
        <StepContentPanel
          room={room}
          invertStyle={true}
          heading='Thank You!'
          showStepCompletePanel={true}
          content={content}
          desc={endTestText}
          img={<div className='thankYou-svg step-illustration-img mt-4' />}
          imgDesc='Let your coordinator know your room finished testing.'
        ></StepContentPanel>
      </div>
    );
  }

  function navigation() {
    const prevButton =
      stepNum === introStep ? (
        <Link className='cb-btn cb-btn-sm cb-btn-light' to={`/rooms/get/${room.id}/flow/monitor/step/1`}>
          Back
        </Link>
      ) : stepNum > introStep ? (
        <Link
          className='cb-btn cb-btn-sm cb-btn-light'
          to={`/rooms/get/${room.id}/flow/completion/step/${stepNum - 1}`}
        >
          Back
        </Link>
      ) : (
        <></>
      );

    const nextButton =
      stepNum !== outerStep ? (
        <Link
          className='cb-btn cb-btn-sm cb-btn-yellow ml-auto'
          data-automation='nav-nextStep-Link'
          to={`/rooms/get/${room.id}/flow/completion/step/${stepNum + 1}`}
        >
          {stepNum >= introStep && stepNum < outerStep && 'Next Step'}
        </Link>
      ) : (
        <></>
      );

    return <WizardNav prevButton={prevButton} nextButton={nextButton}></WizardNav>;
  }

  return (
    <>
      {stepNum === outerStep && EndTest()}
      {stepNum >= introStep && stepNum < outerStep && Steps()}
      <HelpNav />
      {navigation()}
    </>
  );
}

TestCompletion.propTypes = {
  flowData: PropTypes.object,
  room: PropTypes.object,
  cmsContent: PropTypes.object,
};

export default TestCompletion;
