import React from 'react';
import { Icon } from '@cb/apricot-react';

type IIconStatus = {
  text: string | React.ReactNode;
  color?: string;
  icon?: string;
  id?: string;
  showIcon?: boolean;
  wrapperClass?: string;
  textClass?: string;
  iconClass?: string;
};

const IconStatus = ({
  text,
  color,
  icon = 'disc-alt',
  id = '',
  showIcon = true,
  wrapperClass = '',
  textClass = '',
  iconClass = 'cb-margin-top-4 cb-margin-right-8',
}: IIconStatus) => (
  <div className={`display-flex ${wrapperClass}`}>
    {showIcon ? <Icon name={icon} color={color} className={iconClass} decorative /> : null}
    <div className={textClass} id={id}>
      {text}
    </div>
  </div>
);

export default IconStatus;
