import React from 'react';
import PropTypes from 'prop-types';
import { ResizeContext } from '../../../../../context/ResizeContext';
import doorIcon from './svg/door-icon.svg';
import { localStateReducer } from '../../../../../utils/common';

function Door({ doorsList = [], loading = false, removeBorder = '', toggleDoor = () => {}, value = 'front-left' }) {
  function handleClick(door) {
    return function (e) {
      e && e.preventDefault && e.preventDefault();
      if (!loading) {
        if (occupied) {
          toggleDoor(door);
        } else {
          toggleDoor(door);
        }
      }
    };
  }

  function handleFocus() {
    setLocalState({
      isActive: true,
    });
  }

  function handleBlur() {
    setLocalState({
      isActive: false,
    });
  }

  // Local state.
  const [localState, setLocalState] = React.useReducer(localStateReducer, {
    isActive: false,
  });

  // Context.
  const windowSize = React.useContext(ResizeContext);

  const position = value.split('_');
  const isLeftOrRightSide = position[0] === 'right' || position[0] === 'left';

  const occupied = doorsList.find((d) => d === value) !== undefined;

  // Object literal, used in lieu of a switch().
  const doorSrContent = (value) =>
    ({
      back_left: ' on the back wall, toward your left.',
      back_center: ' at the center of the back wall.',
      back_right: ' on the back wall, toward your right.',
      left_back: ' on the wall to your left, toward the back of the room.',
      left_center: ' at the center of the wall to your left.',
      left_front: ' on the wall to your left, toward the front of the room.',
      right_back: ' on the wall to your right, toward the back of the room.',
      right_center: ' at the center of the wall to your right.',
      right_front: ' on the wall to your right, toward the front of the room.',
      front_left: ' on the front wall, toward your left.',
      front_center: ' at the center of the front wall.',
      front_right: ' on the front wall, toward your right.',
    }[value]);

  const seatingChartContent = (
    <React.Fragment>
      {
        // Change text based on if the door is being actively focused or hovered.
        localState.isActive && !windowSize.mobile ? (
          occupied ? (
            <span
              className={`
                text--color-white
                d-block
                ${isLeftOrRightSide ? (position[0] === 'left' ? ' rotate--270' : ' rotate--90') : ''}
              `}
            >
              Remove Door<span className='cb-sr-only'>{doorSrContent(value) || ''}</span>
            </span>
          ) : (
            <span
              className={`
                text--color-black
                d-block
                ${isLeftOrRightSide ? (position[0] === 'left' ? ' rotate--270' : ' rotate--90') : ''}
              `}
            >
              Add Door<span className='cb-sr-only'>{doorSrContent(value) || ''}</span>
            </span>
          )
        ) : occupied ? (
          <React.Fragment>
            {!windowSize.mobile ? (
              <img role='presentation' src={doorIcon} alt='entrance icon' />
            ) : (
              <span
                className={`
                    d-block
                    text--color-white
                    ${isLeftOrRightSide ? (position[0] === 'left' ? ' rotate--270' : ' rotate--90') : ''}`}
              >
                Door
              </span>
            )}
            <span className='cb-sr-only'>Remove door {doorSrContent(value) || ''}</span>
          </React.Fragment>
        ) : (
          <span className='cb-sr-only'>Add door {doorSrContent(value) || ''}</span>
        )
      }
    </React.Fragment>
  );

  React.useEffect(() => {
    // Blur on props update if the mouse is not actively on this element.
    setLocalState({
      isActive: false,
    });
  }, [doorsList]);

  return (
    <button
      className={`
      active--outline-blue
      seating-chart--button
      w-100
      ${
        removeBorder
          ? removeBorder
              .split(' ')
              .map((b) => `border__none--${b}`)
              .join(' ')
          : ''
      }
      ${occupied ? 'cb-purple1-bg' : 'cb-gray5-bg'}`}
      disabled={loading}
      onClick={!windowSize.mobile ? handleClick(value) : null}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onMouseEnter={handleFocus}
      onMouseLeave={handleBlur}
      tabIndex={windowSize.mobile ? '-1' : '0'}
    >
      {seatingChartContent}
    </button>
  );
}

Door.propTypes = {
  doorsList: PropTypes.array,
  loading: PropTypes.bool,
  removeBorder: PropTypes.string,
  room: PropTypes.object,
  toggleDoor: PropTypes.func,
  value: PropTypes.string.isRequired,
};

export default Door;
