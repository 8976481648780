import React from 'react';
import PropTypes from 'prop-types';
import { SquareButton } from '@cb/apricot-react';
import SimpleList from '../../../ui/list/SimpleList';
import StaffDetailsButtons from './StaffDetailsButtons';
import get from 'lodash/get';
import { cbAdminRoleProps, roles, ROLES_MAP } from '../../../../constants/roles';
import { parsePhoneNumbers } from '../../../../utils/common';
import { ResizeContext } from '../../../../context/ResizeContext';
import { staffPhoneLink } from '../roster/utils';
import { useTitle } from '../../../../constants/useTitle';
import { useNavigate } from 'react-router-dom';
import { useStateValue } from '../../../../context/AppContext';
import { isCBAdmin } from '../../../../utils/user';

function StaffDetails({ staff = {}, allStaff = [] }) {
  const navigate = useNavigate();
  useTitle(staff?.username);

  const { user } = useStateValue();

  function generateDetailsList(staff) {
    const baseDetailsList = [
      {
        title: 'Email',
        type: 'string',
        value: staff.email,
      },
      {
        title: 'Phone',
        type: 'html',
        value: (
          <ul key={`${staff.username}-phone-list`}>
            {parsePhoneNumbers(staff.phone).map((number) => {
              const type = number.type.charAt(0).toUpperCase() + number.type.slice(1);
              const value = number.phoneNumber;
              const ext = number.ext ? number.ext : '';

              return value ? (
                <li key={type + value} style={{ borderTop: 'none', borderBottom: 'none' }}>
                  <strong>{type}</strong>: {value ? staffPhoneLink(ext, value) : null}
                </li>
              ) : (
                ''
              );
            })}
          </ul>
        ),
      },
      {
        title: 'Role',
        type: 'string',
        value: roles[staff.role] || 'Unassigned',
      },
      {
        title: 'Room',
        type: 'string',
        value: get(staff, 'room.title', 'Unassigned'),
      },
    ];

    const usernameField = {
      title: 'College Board Username',
      type: 'string',
      value: staff.username,
    };
    return (isCBAdmin(user) ? [usernameField] : []).concat(baseDetailsList);
  }

  function renderDetail(detail, index) {
    let detail_title: string = detail.title || '';
    if (!isNaN(index)) {
      detail_title = detail_title + '-' + index;
    }
    return (
      detail && (
        <li key={detail_title}>
          <div className='tdtk-item-text row mx-0 px-0'>
            <span className='col-xs-4'>{detail.title}:</span>
            {detail.type === 'html' ? (
              <div className='col-xs-8'>{detail.value}</div>
            ) : (
              <span className='col-xs-8'>
                <strong>{detail.value}</strong>
              </span>
            )}
          </div>
        </li>
      )
    );
  }

  function goBack() {
    navigate(-1);
  }

  // Global App state.
  const windowSize = React.useContext(ResizeContext);

  const header = (
    <h2 className='tdtk-h2' data-automation='heading-h2'>{`${staff.firstName} ${
      staff.lastName ? staff.lastName : ''
    }`}</h2>
  );

  const admins = allStaff.filter((s) => s.role === ROLES_MAP.ADMIN);
  const isLastCoordinator = admins.length === 1 && admins.find((s) => s.id === staff.id) !== undefined;

  return (
    <React.Fragment>
      {windowSize.mobile ? (
        <div className='display-flex mx-1 align-items-center'>
          <SquareButton
            id='back-to-staff-page'
            className='cb-btn-black'
            icon='left'
            iconDecorative
            label='Go back to previous page'
            onClick={goBack}
          />
          <div className='ml-3'>{header}</div>
        </div>
      ) : (
        header
      )}
      <div className='row mt-2'>
        <div className='col-md-8'>
          <SimpleList
            id='staff-details'
            items={generateDetailsList(staff, windowSize.mobile)}
            renderItem={renderDetail}
          />
        </div>
        <div className='col-md-4'>
          <StaffDetailsButtons
            staff={staff}
            isLastCoordinator={isLastCoordinator}
            options={{ mobile: windowSize.mobile }}
          />
        </div>
      </div>
    </React.Fragment>
  );
}

StaffDetails.propTypes = {
  staff: PropTypes.object,
  allStaff: PropTypes.array,
};

export default StaffDetails;
