export const doNotDisplayRetestCategoryEventList = new Map([['2275', ['6faa24ae-23a7-4229-88fc-98db49bf0ba9']]]);

/**
 * Filter out blacklisted categories
 * @param category the category to filter
 * @param asmtEventId the assessment event id
 * @param blacklistMap the blacklist configuration with event id and list of blacked out categories
 * @returns true if the category is not blacklisted, otherwise false
 */
export const displayIrCategoryFilter = (category, asmtEventId: string, blacklistMap: Map<string, string[]>) => {
  const pattern = /(\w+)-(\w+)-([a-f\d-]+)/; // parsing example IrTemplate-DIGITAL_INSCHOOL_SAT-6faa24ae-23a7-4229-88fc-98db49bf0ba9"

  const match = category?.id?.match(pattern);

  if (match) {
    const irIdValue = match[3];
    const list = blacklistMap.get(asmtEventId);

    if (list && list.includes(irIdValue)) {
      return false;
    }
  }

  return true;
};
