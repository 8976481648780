import React from 'react';
import Roster from '../../Roster';
import IrregularityRosterRow from './IrregularityRosterRow';
import PropTypes from 'prop-types';
import { ResizeProvider } from '../../../../context/ResizeContext';

function IrregularityRoster({ irregularities = [] }) {
  const headers = [
    { title: 'Report ID', sortField: ['reportId'] },
    {
      title: 'Problem Reported',
      sortField: ['irregularityCategory.irregularity.label'],
    },
    { title: 'Student Affected' },
    { title: 'Room Name' },
    { title: 'Reporter', sortField: ['irregCreatedBy.lastName', 'irregCreatedBy.firstName'] },
    { title: 'Status', sortField: ['statusText'] },
    { title: 'Last Updated', sortField: ['updated'] },
  ];

  // Set an empty message if we have no IRs.
  const emptyMessage =
    irregularities.length > 0 ? '' : 'No one at your test center has saved or submitted reports for this test date.';

  return (
    <ResizeProvider>
      <Roster
        caption='Irregularity Reports'
        groupName='irregularities'
        headers={headers}
        items={irregularities}
        emptyMessage={emptyMessage}
        renderItem={(irregularity) => (
          <IrregularityRosterRow key={`irregularity${irregularity.id}`} irregularity={irregularity} />
        )}
        sortFields={[
          {
            name: 'updated',
            order: 'desc',
          },
        ]}
        stickyHeaders={true}
      />
    </ResizeProvider>
  );
}

IrregularityRoster.propTypes = {
  irregularities: PropTypes.array,
};

export default IrregularityRoster;
