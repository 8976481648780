import React from 'react';
import PropTypes from 'prop-types';
import Messaging from '../../../context/Messaging';
import { groupTypes } from '../../../constants/groupTypes';
import { roles } from '../../../constants/roles';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';

const rowsToExclude = [
  'SAT with Essay or Combined',
  'SAT Subject Tests',
  'SAT Subject Tests with Listening',
  'SAT with Essay or Combined (accommodated without extended time)',
  'SAT Subject Tests (accommodated) and SAT Subject Tests with Listening (accommodated)',
  'SAT with Essay or Combined (extended time all sections)',
  'SAT with Essay or Combined (extended time one section)',
  'SAT with Essay or Combined (extended time two sections)',
  '',
];

function GroupsInfo({ site = {} }) {
  function deconstructStaff(staff) {
    let essayGroupType;
    let flat = {};
    let isStandard;
    let room = 'N/A';
    let voucherReportStaffTitle;

    return staff.map((person) => {
      // Determine if they should be added to a regular or combined room.
      if (person.room && person.room.groupTypes.length > 1) {
        // Combined room, get the group type in here that has the essay and put them there.
        essayGroupType = person.room.groupTypes.filter((groupType) => groupTypes[groupType].essay)[0];

        isStandard = groupTypes[essayGroupType].standard;
        room = person.room.title ? person.room.title : 'N/A';
        voucherReportStaffTitle =
          person.room && person.room.groupTypes ? groupTypes[essayGroupType].voucherReportStaffTitle : 'N/A';
      } else if (person.room && person.room.groupTypes.length) {
        // Single room.
        isStandard = groupTypes[person.room.groupTypes[0]].standard;
        room = person.room.title ? person.room.title : 'N/A';
        voucherReportStaffTitle =
          person.room && person.room.groupTypes ? groupTypes[person.room.groupTypes[0]].voucherReportStaffTitle : 'N/A';
      } else {
        // They might be in a room, but for some reason, the group types weren't set, just set everything to N/A.
        isStandard = true;
        room = 'N/A';
        voucherReportStaffTitle = 'N/A';
      }

      // Create a flat return value with known info first.
      flat = {
        groupType: voucherReportStaffTitle,
        id: person.id,
        name: `${person.lastName ? person.lastName + ', ' : ''}${person.firstName}`,
        role: person.role && roles[person.role] ? roles[person.role] : 'N/A',
        room,
        standard: isStandard,
        students:
          person.room && person.room.students
            ? person.room.students.filter((student) => student.checkedInRoom).length
            : 0,
      };

      return flat;
    });
  }

  function deconstructStudents(students) {
    const initializer = {
      noShows: 0,
      registered: 0,
      standard: true,
      waitlisted: 0,
    };

    let essayGroupType = '';
    let isStandard = true;
    let room;
    let roomGroupTypes;
    const roomSummary = {};
    const studentRoster = {};
    let voucherReportRoomTitle = '';

    // Loop through the students once to put them where they need to go.
    students.forEach((student) => {
      room = get(student, 'room', null);
      roomGroupTypes = get(student, 'room.groupTypes', []);
      const isInvalidGroupType = groupTypes[roomGroupTypes[0]] === undefined;
      // Grab the correct Voucher Report title for where we'll put this student.
      if (room && roomGroupTypes.length) {
        // Determine if they should be added to a regular or combined room.
        if (roomGroupTypes.length > 1) {
          // Combined room, get the group type in here that has the essay and put them there.
          essayGroupType = roomGroupTypes.filter((groupType) => groupTypes[groupType].essay)[0];
          voucherReportRoomTitle = groupTypes[essayGroupType].voucherReportRoomTitle;
          isStandard = groupTypes[essayGroupType].standard;
        } else if (roomGroupTypes.length === 1) {
          if (isInvalidGroupType) {
            voucherReportRoomTitle = 'SAT';
          } else {
            voucherReportRoomTitle = groupTypes[roomGroupTypes[0]].voucherReportRoomTitle;
            isStandard = groupTypes[roomGroupTypes[0]].standard;
          }
        }

        // Ensure they are in a room with a group type!
        if (roomGroupTypes.length === 0) {
          // They are in a room that doesn't have a group type assigned!
          console.error('Student ID: ' + student.id + ' is in a room that has no group type!');
        } else {
          // Initialize the object for this group type.
          if (!studentRoster[voucherReportRoomTitle]) {
            studentRoster[voucherReportRoomTitle] = {
              ...initializer,
              standard: isStandard,
            };
          }
          // Get the room group type and add it to the room summary if it doesn't exist already.
          if (!roomSummary[voucherReportRoomTitle]) {
            // We don't have this test type in our object.
            roomSummary[voucherReportRoomTitle] = [room.id];
          } else {
            // We have this test type but do we have this room?
            if (roomSummary[voucherReportRoomTitle].length && !roomSummary[voucherReportRoomTitle].includes(room.id)) {
              // Add this room id since we don't have it yet.
              roomSummary[voucherReportRoomTitle].push(room.id);
            }
          }

          // Increment this student to the appropriate area.
          if ((student.absent || student.deniedEntry || !student.checkedInRoom) && student.waitListFlag === 'N') {
            // They are a no-show AND they were not a waitlisted student.
            studentRoster[voucherReportRoomTitle].noShows += 1;
          } else if (student.checkedInRoom && student.waitListFlag === 'Y') {
            // They are waitlisted and checked into a room.
            studentRoster[voucherReportRoomTitle].waitlisted += 1;
          }

          // If they're not waitlisted, count them as registered, no-show or not.
          if (student.waitListFlag === 'N') {
            // They are registered.
            studentRoster[voucherReportRoomTitle].registered += 1;
          }
        }
      }
    });

    return {
      roomSummary,
      studentRoster,
    };
  }

  function buildAttendeeTable(headers, data, roomSummary, isStandard) {
    // Pull out only standard or accommodated groups to loop through,
    // then only use the voucherReportRoomTitle, so the end result looks like: ['SAT', 'SAT Subject Tests', etc.]
    const filteredGroupTypes = Object.keys(groupTypes)
      .map((groupType) => groupTypes[groupType])
      .filter((item) => item.standard === isStandard)
      .map((item) => item.voucherReportRoomTitle);

    // De-dupe groups, essentially merging common combined rooms, like R3, R4, R5, and R6.
    const uniqueGroups = filteredGroupTypes.filter((item, index) => filteredGroupTypes.indexOf(item) >= index);

    const tableCell = (isSet, field) => (
      <td>
        <p>{isSet ? isSet[field] : 0}</p>
      </td>
    );

    // Special text exceptions that interrupt the text in the table, but necessary since group types are wonky.
    const textExceptions = {
      'SAT Subject Tests (accommodated) and SAT Subject Tests with Listening (accommodated)': [
        <p className='mb-4' key='subjectTest-voucherReportRoomTitle'>
          SAT Subject Tests (accommodated)
          <br /> and <br /> SAT Subject Tests with Listening (accommodated)
        </p>,
        <Messaging key='voucherSubjectTestsMessage' section='voucherSubjectTestsMessage' />,
      ],
    };

    let hasException = false;
    let row;
    return (
      <table className='cb-table cb-table-striped'>
        <thead>
          <tr>
            {headers.map((header) => (
              <th key={header} scope='col' className='sticky'>
                <p>
                  <strong>{header}</strong>
                </p>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {
            // Loop through the group types, and output as needed.
            uniqueGroups
              .filter((voucherReportRoomTitle) => {
                if (
                  voucherReportRoomTitle === 'N/A' &&
                  !get(roomSummary, `${voucherReportRoomTitle}.length`) &&
                  !get(data, `${voucherReportRoomTitle}.registered`) &&
                  !get(data, `${voucherReportRoomTitle}.waitlisted`) &&
                  !get(data, `${voucherReportRoomTitle}.noShows`)
                ) {
                  return false;
                }

                if (rowsToExclude.includes(voucherReportRoomTitle)) {
                  return false;
                }
                return true;
              })
              .map((voucherReportRoomTitle) => {
                // Check for a very specific exception.
                hasException = !!textExceptions[voucherReportRoomTitle];

                // Build the data rows.
                row = (
                  <React.Fragment key={`${voucherReportRoomTitle}-row`}>
                    {tableCell(roomSummary[voucherReportRoomTitle], 'length')}
                    {tableCell(data[voucherReportRoomTitle], 'registered')}
                    {tableCell(data[voucherReportRoomTitle], 'waitlisted')}
                    {tableCell(data[voucherReportRoomTitle], 'noShows')}
                  </React.Fragment>
                );
                return hasException ? (
                  <React.Fragment key={voucherReportRoomTitle}>
                    <tr>
                      <td rowSpan='2'>{textExceptions[voucherReportRoomTitle][0]}</td>
                      {hasException && textExceptions[voucherReportRoomTitle][1]}
                    </tr>
                    <tr>{row}</tr>
                  </React.Fragment>
                ) : (
                  <tr key={voucherReportRoomTitle}>
                    <td>
                      <p>{voucherReportRoomTitle}</p>
                    </td>
                    {row}
                  </tr>
                );
              })
          }
        </tbody>
      </table>
    );
  }

  function buildStaffTable(headers, staff, isStandard) {
    const tableCell = (person, attribute) => (
      <td key={person.id + attribute}>
        <p>{person[attribute] ? person[attribute] : 'N/A'}</p>
      </td>
    );

    return (
      <table className='cb-table cb-table-striped'>
        <thead>
          <tr>
            {headers.map((header) => (
              <th key={header} scope='col' className='sticky'>
                <p>
                  <strong>{header}</strong>
                </p>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {
            // Loop through the staff, and output as needed.
            staff
              .filter((person) => person.standard === isStandard)
              .map((person) => (
                <tr key={person.id}>
                  {tableCell(person, 'name')}
                  {tableCell(person, 'role')}
                  {tableCell(person, 'groupType')}
                  {tableCell(person, 'room')}
                  {tableCell(person, 'students')}
                </tr>
              ))
          }
        </tbody>
      </table>
    );
  }

  const { staff, students } = site;

  const { roomSummary, studentRoster } = deconstructStudents(students);

  let staffRoster = deconstructStaff(staff);

  const superHall = [];

  // Duplicate coordinators and Hall Monitors, which should appear for both standard and accommodated tables.
  staffRoster.forEach((person) => {
    if (person.role === 'Coordinator' || person.role === 'Hall monitor') {
      superHall.push({
        groupType: 'N/A',
        id: person.id,
        name: person.name,
        role: person.role,
        room: 'N/A',
        standard: !person.standard,
        students: 'N/A',
      });
    }
  });

  // Append the array with the new copies.
  staffRoster = superHall.concat(staffRoster);

  // Prioritize Coordinators first.
  staffRoster = sortBy(staffRoster, [(o) => (o.role === 'Coordinator' ? -1 : 1), 'name']);

  // Student table headers.
  const studentTableHeaders = ['Test Type', 'Number of Rooms', 'Registered', 'Waitlisted', 'No Shows'];

  // Build student attendance info.
  const standardStudentTable = buildAttendeeTable(studentTableHeaders, studentRoster, roomSummary, true);
  const accommodatedStudentTable = buildAttendeeTable(studentTableHeaders, studentRoster, roomSummary, false);

  const staffTableHeaders = ['Staff Name', 'Staff Role', 'Room Type', 'Room #', 'Examinees in Each Room'];

  // Build staff tables;
  const standardStaffTable = buildStaffTable(staffTableHeaders, staffRoster, true);
  const accommodatedStaffTable = buildStaffTable(staffTableHeaders, staffRoster, false);

  return (
    <div>
      <Messaging key='voucherIntro' section='voucherIntro' />

      <Messaging key='voucherStandardStudentIntro' section='voucherStandardStudentIntro' />

      {standardStudentTable}

      <Messaging key='voucherStandardStaffIntro' section='voucherStandardStaffIntro' />

      {standardStaffTable}

      <Messaging key='voucherAccommodatedStudentIntro' section='voucherAccommodatedStudentIntro' />

      {accommodatedStudentTable}

      <Messaging key='voucherAccommodatedStaffIntro' section='voucherAccommodatedStaffIntro' />

      {accommodatedStaffTable}
    </div>
  );
}

GroupsInfo.propTypes = {
  site: PropTypes.object,
  testEvent: PropTypes.string,
};

export default GroupsInfo;
