import React from 'react';
import get from 'lodash/get';
import { GET_STUDENT } from '../../../../apollo/queries';
import StudentDetails from './StudentDetails';
import QueryComponent from '../../../common/network/QueryComponent';
import { ResizeProvider } from '../../../../context/ResizeContext';
import { formatStudentName } from '../../../../utils/common';
import Photo from '../photo/Photo';
import { useParams } from 'react-router-dom';

function Container() {
  const { id } = useParams();
  const input = { id };

  // Analytics tracking on page load.
  React.useEffect(() => {
    const cbTrackData = window.cbTrackData;
    const _satellite = window._satellite;

    if (cbTrackData && _satellite) {
      cbTrackData.pageCode = 'student-detail';
      _satellite.track('cbTrack.viewInDom');
    }
  }, []);

  return (
    <QueryComponent query={{ kind: 'GetStudent', specification: GET_STUDENT, variables: { input } }}>
      {(data) => {
        const student = get(data, 'readStudent') || {};
        if (student && student.id) {
          // Fetch the student photo and pass it.
          student.photo = (
            <Photo
              regId={student.candRegNo}
              studentImage={student.photoUrl}
              studentName={`${formatStudentName(student, 'fml')}`}
            />
          );

          return (
            <ResizeProvider>
              <StudentDetails student={student} />
            </ResizeProvider>
          );
        } else {
          return (
            <React.Fragment>
              <h1 className='tdtk-h1 py-4' data-automation='heading-h1'>
                Student Details
              </h1>
              <div className='row'>
                <p className='col-sm-5'>Could not read student.</p>
              </div>
            </React.Fragment>
          );
        }
      }}
    </QueryComponent>
  );
}

Container.displayName = 'StudentDetailsContainer';

export default Container;
