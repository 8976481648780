import React from 'react';
import get from 'lodash/get';
import { roles } from '../constants/roles';
import { getRoomStaffByRole } from './room';

export function displayItemValue(staff) {
  return {
    role: staff.role,
    room: get(staff, 'room', null),
    title: `${staff.lastName ? staff.lastName + ', ' : ''}${staff.firstName}`,
    value: staff.id,
  };
}

export function generateStaffOptions(staffCandidates) {
  return [...staffCandidates.map((user) => displayItemValue(user))];
}

function mapUserToDisplay(user, isMobile = false) {
  return (
    <li className='staff' style={{ border: 'none' }} key={`staff_list_${user.id}`}>
      {isMobile ? null : <React.Fragment>{roles[user.role] ? `${roles[user.role]}: ` : ''}</React.Fragment>}
      <strong>
        {user.firstName} {user.lastName}
      </strong>
      <br />
    </li>
  );
}

export function generateReadOnlyStaffList(room, isMobile = false) {
  const currentRoomStaff = [...room.staff];
  const roomStaffList = [];
  const { admin, proctor, roomMonitors } = getRoomStaffByRole(currentRoomStaff);

  if (admin && admin.length > 0) {
    // Check and make sure they didn't add a Coordinator for some reason.
    admin.forEach((adm) => {
      roomStaffList.push(mapUserToDisplay(adm, isMobile));
    });
  }

  if (proctor && proctor.length > 0) {
    // Loop through users, should only be 1 for proctor, though.
    proctor.forEach((p) => {
      roomStaffList.push(mapUserToDisplay(p, isMobile));
    });
  }

  // List any room proctors.
  if (roomMonitors && roomMonitors.length > 0) {
    roomMonitors.forEach((rmProc) => {
      roomStaffList.push(mapUserToDisplay(rmProc, isMobile));
    });
  }

  return roomStaffList.length > 0 ? (
    <ul
      style={{
        listStyle: 'none',
        margin: 0,
        padding: 0,
      }}
    >
      {roomStaffList}
    </ul>
  ) : (
    ['']
  );
}

export function mapUserToEditLink(ariaLabel, clickHandler, user) {
  return (
    <li className='staff' key={`staff_${user.id}`}>
      {roles[user.role] ? `${roles[user.role]}: ` : ''}
      <a href='#0' aria-label={ariaLabel} id={`click-to-add-staff_${user.id}`} onClick={clickHandler}>
        <b>
          {user.firstName} {user.lastName}
        </b>
      </a>
    </li>
  );
}

export function checkRoleChange(newRole, newRoomId, staffList) {
  const changedUsers = [];

  // Loop through the selected users, check their roles, and if there is a mismatch, send them to the modal.
  staffList.forEach((rm) => {
    const staffRoom = get(rm, 'room.id', null);
    // If they are changing roles or rooms, add them to the list of changed users.
    // Don't need to warn if they are an admin, but may be warned if they are an admin changing rooms.
    if (
      (rm.role !== '' && rm.role !== 'admin' && rm.role !== 'CBAdmin' && rm.role !== newRole) ||
      (staffRoom !== null && staffRoom !== newRoomId)
    ) {
      // They changed their role, just confirm.
      changedUsers.push(rm);
    }
  });

  return changedUsers;
}
