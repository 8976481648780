import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { groupTypes } from '../../../../constants/groupTypes';
import StaffList from '../staff/StaffList';
import { hasAdminAccess } from '../../../../utils/user';
import { seatsAvailable } from '../../../../utils/common';
import { generateReadOnlyStaffList } from '../../../../utils/staff';
import { useStateValue } from '../../../../context/AppContext';
import { ResizeContext } from '../../../../context/ResizeContext';
import IconStatus from '../../../ui/icons/IconStatus';

function RoomRosterRow({ eventStaff = [], isTourTarget = false, room = {}, rooms = [] }) {
  function generateStatusColor(status) {
    let color = 'green3';
    // Determine which class to use for Room Status.
    switch (status) {
      case 'Test in progress':
        color = 'blue1';
        break;

      case 'Test ended':
        color = 'gray1';
        break;
      default:
        break;
    }
    return color;
  }

  function generateSeatingStatusIcon(seatingStatus: string) {
    // Determine which class to use for Room Status.
    switch (seatingStatus) {
      case 'Not started':
        return { icon: 'error' };
      case 'In progress':
        return { icon: 'pending', color: 'blue1' };
      case 'Submitted':
        return { icon: 'check', color: 'green3' };
      default:
        return { icon: 'error' };
    }
  }

  function mobileComponent() {
    return (
      <li data-tour={isTourTarget ? 'room-list' : ''}>
        <Link style={{ color: '#1e1e1e' }} to={'/rooms/get/' + room.id}>
          <div className='tdtk-item-text'>
            <div className='tdtk-item-title cb-roboto-bold' style={{ color: '#505050', fontFamily: 'inherit' }}>
              <div className='d-flex'>
                <div className='d-block w-75'>
                  <p className='mb-0'>
                    <strong className='name text-break cb-blue5-color'>{title}</strong>
                  </p>
                  <div>
                    <strong className='name text-break'>{generateReadOnlyStaffList(room, true)}</strong>
                  </div>
                  <div>{roomGroupTitles}</div>
                </div>
                <IconStatus color={statusColor} text={status.statusText} />
              </div>
            </div>
          </div>
        </Link>
      </li>
    );
  }

  function desktopComponent() {
    return (
      <tr key={`room_table_${room.id}`} data-tour={isTourTarget ? 'room-list' : ''}>
        <th scope='row'>
          <Link className='text-break' to={'/rooms/get/' + room.id}>
            {title}
          </Link>
        </th>
        <td>{roomGroupTitles}</td>
        <td>
          {hasAdminAccess(user.rid, user.role) ? (
            <StaffList eventStaff={eventStaff} room={room} rooms={rooms} />
          ) : (
            <React.Fragment>{generateReadOnlyStaffList(room, false)}</React.Fragment>
          )}
        </td>
        <td>
          <div className='progress'>
            <div
              aria-label='Number of seats taken'
              aria-valuemax={capacity}
              aria-valuemin='0'
              aria-valuenow={capacity - availableSeats}
              aria-valuetext={`${capacity - availableSeats} out of ${capacity}`}
              className='progress-bar'
              role='progressbar'
              style={{ width: `${((capacity - availableSeats) / capacity) * 100}%` }}
            >
              <span className='p-2'>
                {`${capacity - availableSeats}/${capacity}${capacity - availableSeats === capacity ? ' (Full)' : ''}`}
              </span>
            </div>
          </div>
        </td>
        <td>
          <IconStatus {...generateSeatingStatusIcon(seatingStatus.statusText)} text={seatingStatus.statusText} />
        </td>
        <td>
          <IconStatus color={statusColor} text={status.statusText} />
        </td>
      </tr>
    );
  }

  // Global App state.
  const { user } = useStateValue();
  const windowSize = React.useContext(ResizeContext);

  const { capacity = 0, seatingStatus = {}, status = {}, title = '-' } = room;

  const availableSeats = seatsAvailable(room);
  const statusColor = generateStatusColor(status.statusText);

  // Break out all group types for this room.
  const roomGroupTitles =
    room.groupTypes && room.groupTypes.length > 0 ? (
      <ul className='list-unstyled cb-margin-bottom-zero' key={`room-group-types-${title}`}>
        {room.groupTypes.map((groupType) => {
          const groupStandard =
            groupType && groupTypes[groupType]
              ? groupTypes[groupType].standard
                ? ' (standard)'
                : ' (accommodated)'
              : '';
          const groupTitle = groupTypes[groupType] !== undefined ? `: ${groupTypes[groupType].title}` : '';

          return (
            <li
              style={{ border: 'none' }}
              key={`room-group-types-${title}-${groupType}`}
            >{`${groupType}${groupTitle}${groupStandard}`}</li>
          );
        })}
      </ul>
    ) : (
      '-'
    );

  return windowSize.mobile ? mobileComponent() : desktopComponent();
}

RoomRosterRow.propTypes = {
  eventStaff: PropTypes.array,
  isTourTarget: PropTypes.bool,
  room: PropTypes.object,
  rooms: PropTypes.array,
};

export default RoomRosterRow;
