import React from 'react';
import PropTypes from 'prop-types';
import { SuccessNotification } from '@cb/apricot-react';

function SuccessMessage({ successMessage = '' }) {
  if (successMessage === '') {
    return null;
  }

  return <SuccessNotification title={successMessage} notificationId='generalInfoFormSuccessBlock' />;
}

SuccessMessage.propTypes = {
  successMessage: PropTypes.string,
};

export default SuccessMessage;
