import React from 'react';
import PropTypes from 'prop-types';
import { localStateReducer } from '../../../../../utils/common';
import Door from './Door';
import SeatWrapper from './SeatWrapper';
import { UPDATE_ROOM } from '../../../../../apollo/mutations';
import { GET_ROOM } from '../../../../../apollo/queries';
import { useMutation } from '@apollo/client';
import uniq from 'lodash/uniq';

function DoorWrapper({ room = {} }) {
  function isActive(door) {
    let ret = false;

    // Returns if the door is active for this room or not.
    if (room.doors.length > 0) {
      // Check if we're adding or deleting a door.
      if (room.doors.indexOf(door) >= 0) {
        // This value is in the array.
        ret = true;
      } else {
        // This value is not in the array.
        ret = false;
      }
    } else {
      // There are no doors or it is undefined.
      ret = false;
    }

    return ret;
  }

  function toggleDoor(door) {
    const doorIsActive = isActive(door);
    let updatedDoors = [];

    if (doorIsActive) {
      // We want to toggle it off.
      updatedDoors = room.doors.filter((d) => d !== door);
    } else {
      // Door is either not active, or there are no doors at all yet.
      updatedDoors = room.doors.length > 0 ? uniq([...room.doors, door]) : [door];
    }

    setLocalState({
      loading: true,
    });

    try {
      updateRoom({
        variables: {
          input: {
            id: room.id,
            doors: updatedDoors,
            seatingChartPDFCurrent: false,
            seatingStatus: 'drafted',
            shape: room.shape
              ? {
                  x: room.shape.x || 5,
                  y: room.shape.y || 6,
                }
              : {
                  x: 5,
                  y: 6,
                },
          },
        },
        optimisticResponse: {
          updateRoom: {
            id: room.id,
            capacity: room.capacity,
            created: new Date().getTime(),
            doors: updatedDoors,
            groupTypes: room.groupTypes,
            seatingStatus: 'drafted',
            shape: {
              ...room.shape,
              __typename: 'Shape',
            },
            status: room.status,
            staff: room.staff,
            students: room.students,
            testBookRanges: [
              ...room.testBookRanges.map((t) => ({
                start: t.start,
                end: t.end,
                __typename: 'TestingBookRangeType',
              })),
            ],
            title: room.title,
            unusedSeats: room.unusedSeats,
            updated: new Date().getTime(),
            __typename: 'RoomType',
          },
        },
      }).then(() => {
        setLocalState({
          loading: false,
        });
      });
    } catch (e) {
      console.error('Saving doors failed.', e);

      setLocalState({
        loading: false,
      });
    }
  }

  // Local state.
  const [localState, setLocalState] = React.useReducer(localStateReducer, {
    doors: room.doors,
    id: room.id,
    loading: false,
    shape: room.shape,
  });

  // Update this component when props are changed.
  React.useEffect(() => {
    setLocalState({
      doors: room.doors,
      id: room.id,
      shape: room.shape,
    });
  }, [room.doors, room.id, room.shape]);

  // Apollo.
  const [updateRoom] = useMutation(UPDATE_ROOM, {
    update(cache, { data: { updateRoom } }) {
      const { readRoom } = cache.readQuery({
        query: GET_ROOM,
        variables: {
          input: { id: room.id },
        },
      });

      cache.writeQuery({
        query: GET_ROOM,
        data: {
          readRoom: { ...readRoom, ...updateRoom },
        },
      });
    },
  });

  return (
    <div className='row'>
      <div className='d-flex w-100'>
        <div className='d-flex flex-1 flex-direction--column seating-chart--column__vertical'>
          <div className='flex-2 seating-chart--cell__vertical'>
            <Door
              axis='vertical'
              doorsList={localState.doors}
              loading={localState.loading}
              removeBorder='bottom'
              toggleDoor={toggleDoor}
              value='left_back'
            />
          </div>

          <div className='seating-chart--cell__vertical'>
            <Door
              axis='vertical'
              doorsList={localState.doors}
              loading={localState.loading}
              removeBorder='bottom'
              toggleDoor={toggleDoor}
              value='left_center'
            />
          </div>

          <div className='flex-2 seating-chart--cell__vertical'>
            <Door
              axis='vertical'
              doorsList={localState.doors}
              loading={localState.loading}
              removeBorder=''
              toggleDoor={toggleDoor}
              value='left_front'
            />
          </div>
        </div>
        <div className='flex-1'>
          <div className='flex-2'>
            <div className='d-table w-100'>
              <div className='d-table-cell seating-chart--cell__horizontal w-33'>
                <Door
                  axis='horizontal'
                  doorsList={localState.doors}
                  loading={localState.loading}
                  removeBorder='left'
                  toggleDoor={toggleDoor}
                  value='back_left'
                />
              </div>

              <div className='d-table-cell seating-chart--cell__horizontal w-33'>
                <Door
                  axis='horizontal'
                  doorsList={localState.doors}
                  loading={localState.loading}
                  removeBorder='left right'
                  toggleDoor={toggleDoor}
                  value='back_center'
                />
              </div>

              <div className='d-table-cell seating-chart--cell__horizontal w-33'>
                <Door
                  axis='horizontal'
                  doorsList={localState.doors}
                  loading={localState.loading}
                  removeBorder='right'
                  toggleDoor={toggleDoor}
                  value='back_right'
                />
              </div>
            </div>
            {/**
             * MAIN SEATING CHART.
             */}
            <div>
              <div className='flex-2 w-100'>
                <SeatWrapper room={room} />
              </div>
            </div>

            <div className='d-table w-100'>
              <div className='d-table-cell seating-chart--cell__horizontal w-33'>
                <Door
                  axis='horizontal'
                  doorsList={localState.doors}
                  loading={localState.loading}
                  removeBorder='left'
                  toggleDoor={toggleDoor}
                  value='front_left'
                />
              </div>

              <div className='d-table-cell seating-chart--cell__horizontal w-33'>
                <Door
                  axis='horizontal'
                  doorsList={localState.doors}
                  loading={localState.loading}
                  removeBorder='left right'
                  toggleDoor={toggleDoor}
                  value='front_center'
                />
              </div>

              <div className='d-table-cell seating-chart--cell__horizontal w-33'>
                <Door
                  axis='horizontal'
                  doorsList={localState.doors}
                  loading={localState.loading}
                  removeBorder='right'
                  toggleDoor={toggleDoor}
                  value='front_right'
                />
              </div>
            </div>
          </div>
        </div>

        <div className='d-flex flex-1 flex-direction--column text-center seating-chart--column__vertical'>
          <div className='flex-2 seating-chart--cell__vertical'>
            <Door
              axis='vertical'
              doorsList={localState.doors}
              loading={localState.loading}
              removeBorder='bottom'
              toggleDoor={toggleDoor}
              value='right_back'
            />
          </div>

          <div className='seating-chart--cell__vertical'>
            <Door
              axis='vertical'
              doorsList={localState.doors}
              loading={localState.loading}
              removeBorder='bottom'
              toggleDoor={toggleDoor}
              value='right_center'
            />
          </div>

          <div className='flex-2 seating-chart--cell__vertical'>
            <Door
              axis='vertical'
              doorsList={localState.doors}
              loading={localState.loading}
              removeBorder=''
              toggleDoor={toggleDoor}
              value='right_front'
            />
          </div>
        </div>
      </div>
    </div>
  );
}

DoorWrapper.propTypes = {
  room: PropTypes.object,
};

export default DoorWrapper;
