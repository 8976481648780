import { cbAdminRoleProps } from '../../../../../../constants/roles';

function getHeaderClass(columnName, user) {
  function classes() {
    if (user?.rid === cbAdminRoleProps.CB_ADMIN_USER_RID) {
      return {
        studentName: 'col-xs-12 col-sm-6 col-md-3',
        accommodations: 'hidden-xs hidden-sm col-md-3',
        attendance: 'hidden-xs hidden-sm col-xs-12 col-md-2',
        dapTestStatus: 'hidden-xs col-xs-12 col-sm-3 col-md-2',
        lastUpdated: 'hidden-xs col-xs-12 col-sm-3 col-md-2 px-lg-1',
      };
    } else {
      return {
        studentName: 'col-xs-12 col-sm-6 col-md-3',
        accommodations: 'hidden-xs hidden-sm col-md-3',
        attendance: 'hidden-xs col-sm-3 col-xs-12 col-md-3',
        dapTestStatus: 'hidden-xs col-xs-12 col-sm-3 col-md-3',
        lastUpdated: 'hidden',
      };
    }
  }

  return classes()[columnName];
}

export function generateHeaders(user) {
  // We're passing an array for sorting multiple values in a single column header, so put them in reverse order of precedence.
  // e.g. last name will be sorted first here.
  return [
    {
      title: 'Student',
      sortField: ['candMidInit', 'candFirstName', 'candLastName'],
      className: getHeaderClass('studentName', user),
    },
    { title: 'Accommodations', className: getHeaderClass('accommodations', user) },
    { title: 'Attendance', sortField: ['status.sortOrder'], className: getHeaderClass('attendance', user) },
    { title: 'Testing', sortField: ['dapTestStatus.sortOrder'], className: getHeaderClass('dapTestStatus', user) },
    { title: 'Last Updated', sortField: ['updated'], className: getHeaderClass('lastUpdated', user) },
  ];
}
