import { FEATURE_FLAGS } from '../constants/featureFlags';

export function mergeFeatureFlags(evidentlyFlags = [], eventFlags = []) {
  let mergedFlags = [...evidentlyFlags];
  const localFlags = getLocalFlags();

  // merge event flags
  if (Array.isArray(eventFlags)) {
    for (const flag of eventFlags) {
      if (!mergedFlags.includes(flag)) {
        mergedFlags.push(flag);
      }
    }
  }

  // handle local ALL_FLAGS in "off" flags
  if (localFlags.off.includes('ALL_FLAGS')) {
    mergedFlags = [];
  } else {
    // filter local "off" flags
    for (const flag of localFlags.off) {
      if (mergedFlags.includes(flag)) {
        mergedFlags = mergedFlags.filter((item) => item !== flag);
      }
    }
  }

  // handle local ALL_FLAGS in "on" flags
  if (localFlags.on.includes('ALL_FLAGS')) {
    // return all known flags
    mergedFlags = Object.keys(FEATURE_FLAGS).map((key) => FEATURE_FLAGS[key]);
  } else {
    // add local "on" flags
    for (const flag of localFlags.on) {
      if (!mergedFlags.includes(flag)) {
        mergedFlags.push(flag);
      }
    }
  }

  return mergedFlags;
}

export function getLocalFlags() {
  const localFlags = { on: [], off: [] };

  try {
    localFlags.off =
      import.meta.env?.REACT_APP_LOCAL_FEATURE_FLAGS_OFF > ''
        ? import.meta.env.REACT_APP_LOCAL_FEATURE_FLAGS_OFF.split(',')
        : [];
    localFlags.on =
      import.meta.env?.REACT_APP_LOCAL_FEATURE_FLAGS_ON > ''
        ? import.meta.env.REACT_APP_LOCAL_FEATURE_FLAGS_ON.split(',')
        : [];
  } catch (e) {
    console.error('error parsing local feature flags', e);
  }

  return localFlags;
}
