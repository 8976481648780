import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import get from 'lodash/get';
import { Icon } from '@cb/apricot-react';
import { ResizeContext } from '../../../../context/ResizeContext';
import { formatStudentName } from '../../../../utils/common';
import IconStatus from '../../../ui/icons/IconStatus';

function IrregularityRosterRow({ irregularity = {} }) {
  function generateStatus(irregularityStatus) {
    let displayStatus = '';
    let statusColor = '';

    switch (irregularityStatus) {
      case 'drafted':
        statusColor = 'gray1';
        displayStatus = 'Drafted';
        break;
      case 'coordinator_submit':
        statusColor = 'orange1';
        displayStatus = 'Submitted to Coordinator';
        break;
      case 'cb_submit':
        statusColor = 'green3';
        displayStatus = 'Submitted to College Board';
        break;
      default:
        displayStatus = 'Drafted';
    }
    return { displayStatus, statusColor };
  }

  function listTransform(list, itemFn) {
    if (!list || list.length === 0) {
      list = '-';
    } else if (list.length === 1) {
      list = list.map((item) => <span key={item.id}>{itemFn(item)}</span>);
    } else {
      list = 'Multiple';
    }
    return list;
  }

  function mobileComponent() {
    return (
      <li>
        <Link to={`/irregularities/get/${irregularity.id}`}>
          <div className='tdtk-item-text'>
            <div className='tdtk-item-title cb-roboto-bold row'>
              <div className='cb-float-left col-xs-1'>
                <Icon name='disc-alt' color={statusColor} decorative />
              </div>
              <div className='col-xs-11'>
                <b>Report ID:</b> <span className='text--color-black text--weight-normal'>{irregularityReportId}</span>
                <br />
                <b>Type:</b> <span className='text--color-black text--weight-normal'>{irregularityProblem}</span>
                <br />
                <b>Reporter:</b>{' '}
                <span className='text--color-black text--weight-normal'>{irregularityReporterDisplay}</span>
                <br />
                <b>Status:</b> <span className='text--color-black text--weight-normal'>{displayStatus}</span>
              </div>
            </div>
          </div>
        </Link>
      </li>
    );
  }

  function desktopComponent() {
    return (
      <tr key={`irregularity_${irregularity.id}`}>
        <th scope='row'>
          <Link to={`/irregularities/get/${irregularity.id}`}>{irregularityReportId}</Link>
        </th>
        <td>{irregularityProblem}</td>
        <td>{irregularityStudents}</td>
        <td>{irregularityRooms}</td>
        <td>{irregularityReporterDisplay}</td>
        <td>
          <IconStatus color={statusColor} text={displayStatus} />
        </td>
        <td>{irregularityLastUpdated}</td>
      </tr>
    );
  }

  // Global App state.
  const windowSize = React.useContext(ResizeContext);

  const irregularityReportId = irregularity.reportId || '-';
  const irregularityProblem = get(irregularity, 'irregularityCategory.irregularity.label') || '-';
  const irregularityReporter = irregularity.irregCreatedBy || { lastName: '', firstName: '' };
  const irregularityReporterDisplay = `${irregularityReporter.lastName ? irregularityReporter.lastName + ', ' : ''}${
    irregularityReporter.firstName
  }`;
  const irregularityStatus = irregularity.statusText || '-';
  const irregularityLastUpdated = moment(irregularity.updated).local().format('lll');
  const irregularityEntireCenter = irregularity.entireTestCenter || '';

  let irregularityStudents = irregularity.students || [];
  let irregularityRooms = irregularity.rooms || [];

  // Determine which class to use for irregularity Status.
  const { displayStatus, statusColor } = generateStatus(irregularityStatus);

  // Loop through every user to see if they are assigned to this irregularity.
  irregularityStudents = listTransform(irregularityStudents, (student) => formatStudentName(student));
  irregularityRooms = listTransform(irregularityRooms, (room) => `${room.title}`);

  if (irregularityEntireCenter === 'entireTestCenter') {
    irregularityStudents = 'Multiple';
    irregularityRooms = 'Multiple';
  }

  return windowSize.mobile ? mobileComponent() : desktopComponent();
}

IrregularityRosterRow.propTypes = {
  irregularity: PropTypes.object,
};

export default IrregularityRosterRow;
