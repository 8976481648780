import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { nest } from '../../../../../constants/nest';
import Select from '../../../../ui/form/Select';
import { ResizeContext } from '../../../../../context/ResizeContext';
import { BlackButton } from '@cb/apricot-react';

function DoorForm({
  addDoor = () => {},
  doors = [],
  editDoor = () => {},
  i = 0,
  isAdd = true,
  loading = false,
  position = '',
  removeDoor = () => {},
  wall = '',
}) {
  function getFilteredWalls(wall) {
    return defaultWalls.filter((option) => {
      if (wall === option.value) {
        return true;
      }
      if (groupedDoorsByWall[option.value] && groupedDoorsByWall[option.value].length >= 3) {
        return false;
      }
      return true;
    });
  }

  function getFilteredPositions(wall, position) {
    if (wall === 'front' || wall === 'back') {
      return defaultsFrontBack.filter((item) => {
        if (item.value === position) {
          return true;
        }
        if (get(groupedDoorsByWallAndPosition, `[${wall}][${item.value}].length`, 0) >= 1) {
          return false;
        }
        return true;
      });
    } else if (wall === 'left' || wall === 'right') {
      return defaultsSides.filter((item) => {
        if (item.value === position) {
          return true;
        }
        if (get(groupedDoorsByWallAndPosition, `[${wall}][${item.value}].length`, 0) >= 1) {
          return false;
        }
        return true;
      });
    } else {
      return [];
    }
  }

  /**
   * Available door positions.
   */
  const defaultsFrontBack = [
    {
      label: 'Left',
      title: 'Left',
      value: 'left',
    },
    {
      label: 'Center',
      title: 'Center',
      value: 'center',
    },
    {
      label: 'Right',
      title: 'Right',
      value: 'right',
    },
  ];

  const defaultsSides = [
    {
      label: 'Near the back of the room',
      title: 'Near the back of the room',
      value: 'back',
    },
    {
      label: 'Center',
      title: 'Center',
      value: 'center',
    },
    {
      label: 'Near the front of the room',
      title: 'Near the front of the room',
      value: 'front',
    },
  ];

  const defaultWalls = [
    {
      label: 'Front',
      title: 'Front',
      value: 'front',
    },
    {
      label: 'Back',
      title: 'Back',
      value: 'back',
    },
    {
      label: 'Left',
      title: 'Left',
      value: 'left',
    },
    {
      label: 'Right',
      title: 'Right',
      value: 'right',
    },
  ];

  // Global state.
  const windowSize = React.useContext(ResizeContext);

  const groupedDoorsByWall = nest(doors, ['wall']);
  const groupedDoorsByWallAndPosition = nest(doors, ['wall', 'position']);

  return (
    <fieldset className='my-2 seating-chart-fieldset row' key={`door-group-${i}`} data-tour='test-doors'>
      <legend className={`${windowSize.prefix === 'xs' ? 'tdtk-h4 cb-roboto-bold col-xs-12' : 'cb-sr-only'}`}>
        {isAdd ? (
          <span>Add a new door</span>
        ) : (
          <span>
            Door <span className='cb-sr-only'>position for door </span>number {i}
          </span>
        )}
      </legend>

      <div className='col-sm-4 pb-4 pb-sm-0'>
        <Select
          disabled={loading}
          id={`door-wall-${i}`}
          label={`What wall is door ${i + 1} on?`}
          labelClassName='cb-sr-only'
          name={`door-wall-${i}`}
          onChange={editDoor(i, 'wall')}
          value={wall ? wall : '-'}
          values={[
            {
              label: '-Select-',
              value: '',
            },
            ...getFilteredWalls(wall),
          ]}
        />
      </div>

      <div className='col-sm-4  pb-4 pb-sm-0'>
        <Select
          disabled={!wall || loading}
          id={`door-position-${i}`}
          label={`What position is door ${i + 1}?`}
          labelClassName='cb-sr-only'
          name={`door-position-${i}`}
          onChange={editDoor(i, 'position')}
          value={position && wall ? position : '-'}
          values={[
            {
              label: '-Select-',
              value: '',
            },
            ...getFilteredPositions(wall, position),
          ]}
        />
      </div>
      <div className='col-sm-4  pb-4 pb-sm-0'>
        <BlackButton
          className={`${!isAdd ? 'cb-btn-naked' : ''} w-100`}
          disabled={loading || (isAdd && (!wall || !position))}
          onClick={isAdd ? addDoor : removeDoor(i)}
        >
          {isAdd ? '+ Add This Door' : 'Delete'}{' '}
          <span className='cb-sr-only'>{position && wall ? ` door at ${position} to ${wall}` : ' blank door row'}</span>
        </BlackButton>
      </div>
    </fieldset>
  );
}

DoorForm.propTypes = {
  addDoor: PropTypes.func.isRequired,
  editDoor: PropTypes.func.isRequired,
  doors: PropTypes.arrayOf(
    PropTypes.shape({
      position: PropTypes.string,
      wall: PropTypes.string,
    })
  ),
  i: PropTypes.number.isRequired,
  isAdd: PropTypes.bool,
  loading: PropTypes.bool,
  position: PropTypes.string,
  removeDoor: PropTypes.func.isRequired,
  wall: PropTypes.string,
};

export default DoorForm;
