import React, { useContext } from 'react';
import ModalDialogWrapper from '../../../../../ui/modal/ModalDialogWrapper';
import { ModalDispatchContext } from '../../../../../ui/modal/ModalContext';
import ModalFooter from '../../../../../ui/modal/ModalFooter';

function SaveSeatingChartConfirmationModal() {
  function handleClose() {
    dispatchModal(null);
  }

  // Modal Context.
  const dispatchModal = useContext(ModalDispatchContext);

  return (
    <ModalDialogWrapper
      modalId='SaveSeatingChartConfirmationModal'
      onClose={handleClose}
      open
      title='Thanks for submitting your seating chart!'
      variant='success'
    >
      <React.Fragment key='modal-content'>
        <p>You can update and resubmit your seating chart if you need to.</p>
        <p>
          <strong>Reminder: </strong>When students finish testing and you complete the paper Testing Room Materials
          Report, you can skip the seating chart section.
        </p>
      </React.Fragment>
      <ModalFooter
        buttons={[
          {
            handler: handleClose,
            id: 'save-seating-chart-confirmation-modal-ok',
            style: 'yellow',
            text: 'OK',
          },
        ]}
      />
    </ModalDialogWrapper>
  );
}

export default SaveSeatingChartConfirmationModal;
