import React from 'react';
import PropTypes from 'prop-types';
import Input from '../../../../ui/form/Input';
import { BlackButton, NumericIcon } from '@cb/apricot-react';

function TestBookRange({
  addSerial = () => {},
  editSerial = () => {},
  enabled = false,
  end = '',
  errend = '',
  errstart = '',
  i = 0,
  isAdd = true,
  loading = false,
  removeSerial = () => {},
  start = '',
}) {
  let bookCount = null;

  if (!isAdd && start && start.length === 6 && end && end.length === 6 && errend.length === 0) {
    bookCount = parseInt(end) - parseInt(start) + 1;
  }

  return (
    <fieldset className='my-2 seating-chart-fieldset' key={`serial-number-group-${i}`} data-tour='test-books'>
      <legend className='cb-sr-only'>Test book range for package {i + 1}</legend>
      <div className='row px-2 align-items-center'>
        <div className='col-xs-5 col-sm-3 col-md-4'>
          <Input
            disabled={loading}
            errorMessage={errstart || ''}
            id={`serial-${i + 1}-start`}
            label={`Package ${i + 1}: First serial number`}
            labelClassName='cb-sr-only'
            name={`serial-${i + 1}-start`}
            onChange={editSerial(i, 'start')}
            placeholder='First serial number'
            value={`${start ? start : ''}`}
          />
        </div>

        <div className='col-xs-2 col-sm-2 col-md-1 cb-align-center'>to</div>

        <div className='col-xs-5 col-sm-3 col-md-4 display-flex align-items-center'>
          <span className='w-100'>
            <Input
              disabled={loading}
              errorMessage={errend || ''}
              id={`serial-${i + 1}-end`}
              label={`Package ${i + 1}: Last serial number`}
              labelClassName='cb-sr-only'
              name={`serial-${i + 1}-end`}
              onChange={editSerial(i, 'end')}
              placeholder='Last serial number'
              value={`${end ? end : ''}`}
            />
          </span>
          {bookCount && (
            <>
              <NumericIcon color='blue2' inverted size='24' className='cb-margin-left-8' decorative>
                {bookCount}
              </NumericIcon>
              <span className='cb-sr-only'>
                {bookCount} in range {start}-{end}
              </span>
            </>
          )}
        </div>
        <div className='my-4 my-sm-0 col-xs-12 col-sm-4 col-md-3'>
          <BlackButton
            small
            aria-describedby='text-book-form-empty'
            className={`${!isAdd ? 'cb-btn-naked' : ''} w-100`}
            disabled={loading || !enabled}
            onClick={
              isAdd
                ? addSerial({
                    start,
                    end,
                  })
                : removeSerial(i)
            }
          >
            {isAdd ? '+ Add This Range' : 'Delete'}{' '}
            <span className='cb-sr-only'>
              {start && end ? ` test book range ${start} to ${end}` : ' test book range'}
            </span>
          </BlackButton>
        </div>
      </div>
    </fieldset>
  );
}

TestBookRange.propTypes = {
  addSerial: PropTypes.func.isRequired,
  editSerial: PropTypes.func.isRequired,
  enabled: PropTypes.bool,
  end: PropTypes.string,
  errend: PropTypes.string,
  errstart: PropTypes.string,
  i: PropTypes.number.isRequired,
  isAdd: PropTypes.bool,
  loading: PropTypes.bool,
  removeSerial: PropTypes.func.isRequired,
  start: PropTypes.string,
};

export default TestBookRange;
