import React from 'react';
import PropTypes from 'prop-types';
import SerialNumberForm from './test-book-range-form/Container';
import DoorForm from './door-form/Container';
import SeatingChartWrapper from './seating-chart-wrapper/Container';
import { convertTestBooksToStrings, getAllTestBookNumbers } from './utils';
import SaveAndFinalize from './save-and-finalize/Container';
import { useTitle } from '../../../../constants/useTitle';

function ChartWrapper({ orgEvent = {}, room = {} }) {
  useTitle(`Seating Chart - ${room.title}`);
  const sanitizedRoom = {
    ...room,
    availableSerialNumbers:
      room.testBookRanges && room.testBookRanges.length ? getAllTestBookNumbers(room.testBookRanges) : [],
    doors: room.doors && room.doors.length ? room.doors : [],
    groupTypes: room.groupTypes ? room.groupTypes : [],
    id: room.id,
    loading: false,
    shape:
      room.shape && room.shape.x && room.shape.y
        ? room.shape
        : {
            x: 5,
            y: 6,
          },
    staff: room.staff && room.staff.length ? room.staff : [],
    students: room.students && room.students.length ? room.students : [],
    testBookRanges:
      room.testBookRanges && room.testBookRanges.length ? convertTestBooksToStrings(room.testBookRanges) : [],
    title: room.title,
    unusedSeats: room.unusedSeats,
  };

  return (
    <React.Fragment>
      <div className='mb-3 pb-3 clearfix'>
        <SerialNumberForm room={sanitizedRoom} />
      </div>

      <div className='my-3 py-3 clearfix'>
        <DoorForm room={sanitizedRoom} />
      </div>

      <div className='my-3 py-3 clearfix'>
        <SeatingChartWrapper room={sanitizedRoom} />
      </div>

      <div className='my-3 py-3 clearfix'>
        <SaveAndFinalize orgEvent={orgEvent} room={sanitizedRoom} />
      </div>
    </React.Fragment>
  );
}

ChartWrapper.propTypes = {
  orgEvent: PropTypes.object.isRequired,
  room: PropTypes.object.isRequired,
};

export default ChartWrapper;
