import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { UPDATE_USER, BULK_REMOVE_USERS, RESEND_EMAIL } from '../../../../apollo/mutations';
import { useStateValue } from '../../../../context/AppContext';
import { customerSupportInfo } from '../../../../constants/customerSupportInfo';
import contentStrings from '../../../../constants/contentStrings';
import { ModalDispatchContext } from '../../../ui/modal/ModalContext';
import OneButtonModal from '../../../ui/modal/standard/OneButtonModal';
import TwoButtonModal from '../../../ui/modal/standard/TwoButtonModal';
import { useNavigate } from 'react-router-dom';
import { Button } from '@cb/apricot-react';
import TooltipWrapper from '../../../ui/tooltip/TooltipWrapper';

function StaffDetailsButtons({ staff = {}, isLastCoordinator = false }) {
  const navigate = useNavigate();
  function GenerateButtons() {
    return [
      {
        handler: editStaff,
        id: 'button-editStaff',
        title: 'Edit',
      },
      {
        buttonRef: refButtonChangeStatus,
        handler: changeStaffStatus,
        id: 'button-changeStaffStatus',
        title: !staff.active ? 'Grant access' : 'Revoke access',
      },
      {
        buttonRef: refButtonDelete,
        handler: deleteStaff,
        id: 'button-deleteStaff',
        disabled: isLastCoordinator,
        title: 'Remove from administration',
      },
      {
        buttonRef: refButtonResendEmail,
        handler: resendEmailHandler,
        id: 'button-resendEmail',
        title: 'Send access email',
      },
    ];
  }

  function renderButton(detail) {
    return (
      <div key={detail.id}>
        <Button
          variant={detail.primary ? 'yellow' : 'black'}
          small
          className={'w-100 mb-4'}
          data-automation={detail.id}
          key={detail.id}
          disabled={detail.disabled}
          id={detail.id}
          onClick={() => {
            detail.handler(staff);
          }}
          ref={detail.buttonRef}
        >
          {detail.title}
        </Button>
        {detail.disabled && (
          <TooltipWrapper light={true} trigger={detail.id} tooltipId={`tooltip-${detail.id}`}>
            {contentStrings.staff.warn.coordinator}
          </TooltipWrapper>
        )}
      </div>
    );
  }

  function editStaff() {
    navigate(`/staff/edit/${staff.id}`);
  }

  function changeStaffStatus() {
    let body = (
      <p key={1}>
        If you grant this staff member access to the Test Day Toolkit, they will see sensitive student information.
        <br />
        <br />
        Tip: Wait until test day to grant access.
      </p>
    );

    function handleStaffStatusChange() {
      const input = {
        active: !staff.active,
        id: staff.id,
      };

      return updateUser({
        variables: {
          input,
        },
        refetchQueries: ['inventory', 'getStaff', 'getSiteStats'],
      }).then(() => {
        return dispatchModal(
          <OneButtonModal
            modalId='staffChangeStatusCompleted'
            title={
              !staff.active
                ? 'You’ve granted this staff member access to the Toolkit.'
                : 'You’ve revoked this staff member’s Toolkit access.'
            }
            variant='success'
          />
        );
      });
    }

    if (staff.active) {
      if (staff.id !== user.id) {
        body = 'You’re about to revoke this staff member’s access—they won’t be able use Test Day Toolkit.';
      } else {
        return dispatchModal(
          <OneButtonModal
            buttonLabel='OK'
            modalId='changeStaffStatusError'
            title='You can’t revoke your own Test Day Toolkit access.'
            variant='error'
          />
        );
      }
    }

    return dispatchModal(
      <TwoButtonModal
        body={body}
        primaryButtonHandler={handleStaffStatusChange}
        modalId='changeStaffStatusConfirmation'
        variant='error'
      />
    );
  }

  function deleteStaff() {
    if (staff.id === user.id) {
      return dispatchModal(
        <OneButtonModal
          buttonLabel='OK'
          modalId='changeStaffStatusError'
          title='You can’t delete yourself.'
          variant='error'
        />
      );
    } else if (staff.role === 'admin' && user.role === 'admin') {
      return dispatchModal(
        <OneButtonModal
          body='Contact Test Administration Services (TAS) for help.'
          buttonLabel='OK'
          modalId='changeStaffStatusError'
          title={contentStrings.staff.warn.coordinator}
          variant='error'
        />
      );
    }

    function handleStaffDelete() {
      const input = {
        ids: staff.id,
      };

      bulkRemoveUsers({
        variables: { input },
        refetchQueries: ['inventory', 'getStaff', 'getSiteStats'],
      }).then(() => {
        return dispatchModal(
          <OneButtonModal
            modalId='staffChangeStatusCompleted'
            onClose={() => navigate('/staff')}
            title='You’ve removed selected staff from this test administration.'
            variant='success'
          />
        );
      });
    }

    return dispatchModal(
      <TwoButtonModal
        body='You’re about to remove the selected staff from this administration. They’ll still be on your test center staff list.'
        primaryButtonHandler={handleStaffDelete}
        modalId='changeStaffStatusConfirmation'
        variant='error'
      />
    );
  }

  function resendEmailHandler() {
    const input = {
      ids: [staff.id],
    };

    if (staff.id !== user.id) {
      resendEmail({
        variables: { input },
      })
        .then(() => {
          return dispatchModal(
            <OneButtonModal
              modalId='staffChangeStatusCompleted'
              title='We’ve sent an access email to selected staff member.'
              variant='success'
            />
          );
        })
        .catch(() => {
          return dispatchModal(
            <OneButtonModal
              body='Please try again later.'
              modalId='changeStaffStatusError'
              title='We were unable to send this staff member an access email.'
              variant='error'
            />
          );
        });
    } else {
      return dispatchModal(
        <OneButtonModal
          modalId='changeStaffStatusError'
          title={'You can’t send yourself an access email. Email ' + customerSupportInfo.email}
          variant='error'
        />
      );
    }
  }

  // Refs.
  const refButtonChangeStatus = React.useRef();
  const refButtonDelete = React.useRef();
  const refButtonResendEmail = React.useRef();

  // Global App state.
  const { user } = useStateValue();
  const dispatchModal = useContext(ModalDispatchContext);

  // Apollo.
  const [bulkRemoveUsers] = useMutation(BULK_REMOVE_USERS);
  const [resendEmail] = useMutation(RESEND_EMAIL);
  const [updateUser] = useMutation(UPDATE_USER);
  const generatedButtons = GenerateButtons(staff);

  return <React.Fragment>{generatedButtons.map((btn) => renderButton(btn))}</React.Fragment>;
}

StaffDetailsButtons.propTypes = {
  staff: PropTypes.object,
  isLastCoordinator: PropTypes.bool,
};

export default StaffDetailsButtons;
