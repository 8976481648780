import React from 'react';
import { Icon } from '@cb/apricot-react-icon';
import { AddStaffRow } from './AddStaffRow';
import { orderBy } from 'natural-orderby';
import { setCheckedProp } from '../../../../../utils/common';
import { usePreviousValue } from '../../../../../constants/usePreviousValue';
import chunk from 'lodash/chunk';
import PropTypes from 'prop-types';
import Table from '../../../../ui/table/Table';
import PagerWrapper from '../../../../ui/pagination/PagerWrapper';

const SortIcon = ({ sortOrder }: { sortOrder?: string }) => {
  if (!sortOrder) {
    return null;
  }
  return <Icon name={sortOrder === 'asc' ? 'sort-asc' : 'sort-desc'} decorative />;
};

export const AddStaff = ({ items = [], addUserHandler = () => {} }) => {
  const pageSize = 30;
  const totalPages = Math.ceil(items.length / pageSize);
  const checkboxAllRef = React.useRef();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [sortOrder, setSortOrder] = React.useState('asc');
  const [checkedStaff, setCheckedStaff] = React.useState([]);
  const getSorted = React.useCallback(() => {
    let _items = orderBy(items, [(user) => `${user.lastName}, ${user.firstName}`], [sortOrder]);
    _items = chunk(_items, pageSize);
    return _items;
  }, [items, sortOrder]);
  const [sortedItems, setSortedItems] = React.useState(getSorted);

  const prevItemsLength = usePreviousValue(items.length);

  React.useEffect(() => {
    if (prevItemsLength !== items.length) {
      const _items = getSorted();
      setSortedItems(_items);
    }
  }, [items.length, prevItemsLength, getSorted]);

  const sortHandler = () => {
    let _sortOrder;
    switch (sortOrder) {
      case 'asc':
        _sortOrder = 'desc';
        break;
      case 'desc':
        _sortOrder = null;
        break;
      default:
        _sortOrder = 'asc';
        break;
    }
    if (!_sortOrder) {
      setSortedItems(chunk(items, pageSize));
      setSortOrder(null);
    } else {
      let _items = orderBy(items, [(user) => `${user.lastName}, ${user.firstName}`], [_sortOrder]);
      _items = chunk(_items, pageSize);
      setSortedItems(_items);
      setSortOrder(_sortOrder);
    }
  };

  const pageChangeHandler = React.useCallback(
    (num: number) => {
      if (typeof num !== 'number') {
        num = 0;
      } else if (num < 0) {
        num = 0;
      } else if (num > totalPages) {
        num = totalPages - 1;
      }

      if (currentPage !== num) {
        setCurrentPage(num);
      }
    },
    [totalPages, currentPage]
  );

  React.useEffect(() => {
    if (checkedStaff.length === items.length) {
      setCheckedProp(checkboxAllRef, true);
    }
  }, [sortedItems, checkedStaff, currentPage, items]);

  const handleCheckStaff = React.useCallback(
    (staffIds = [], checkAll) => {
      let index;
      const updatedCheckedStaff = [...checkedStaff];

      staffIds.forEach((id) => {
        index = updatedCheckedStaff.indexOf(id);

        // Determine if we should un-check them based on a "check all" command.
        if (checkAll !== undefined) {
          // The ID exists, and they want to "un-check all".
          if (index > -1 && !checkAll) {
            updatedCheckedStaff.splice(index, 1);
          } else if (index === -1 && checkAll) {
            // They want to check-all and this ID is not in our array.
            updatedCheckedStaff.push(id);
          }
        } else {
          // They are probably just checking an individual item.
          if (index > -1) {
            // Un-check them since it's already in the array, and un-check the "check all" button.
            updatedCheckedStaff.splice(index, 1);
            setCheckedProp(checkboxAllRef, false);
          } else {
            // They are not checked, so check them.
            updatedCheckedStaff.push(id);
          }
        }
      });

      // Update our state to have all checked values accordingly.
      setCheckedStaff(updatedCheckedStaff);
      addUserHandler(updatedCheckedStaff);
    },
    [checkedStaff, addUserHandler]
  );

  const handleCheckAll = React.useCallback(
    (toCheck) => {
      return function (e) {
        const checked = e.target.checked;
        const checkItems = [];
        toCheck = items;
        // Loop through the checked items and add as needed.
        toCheck.forEach((item) => {
          checkItems.push(item.id);
        });

        handleCheckStaff(checkItems, checked);
      };
    },
    [handleCheckStaff, items]
  );

  function renderHeader(header, index) {
    const key = `header_${header.title}_${index}`;
    return (
      <th scope='col' aria-sort={headerSortOrder()} className='sticky' key={key}>
        {header.sortField ? (
          <a
            aria-roledescription='sort header'
            className='text--color-white'
            data-automation={`roster-${header.title.split(' ').join('-').toLowerCase()}`}
            href='#0'
            onClick={(e) => {
              e.preventDefault();
              sortHandler();
            }}
          >
            {header.title}
            <SortIcon {...{ sortOrder }} />
          </a>
        ) : (
          <span data-automation={`roster-${header.title.split(' ').join('-').toLowerCase()}`}>{header.title}</span>
        )}
      </th>
    );
  }

  function headerSortOrder() {
    if (!sortOrder) {
      return 'none';
    } else if (sortOrder === 'asc') {
      return 'ascending';
    } else {
      return 'descending';
    }
  }

  return (
    <>
      <Table
        items={sortedItems[currentPage - 1]}
        headers={[{ title: 'checkAll' }, { title: 'Staff Name', sortField: ['firstName', 'lastName'] }]}
        renderHeader={renderHeader}
        renderItem={(staffUser, options) => (
          <AddStaffRow
            handleCheckItems={handleCheckStaff}
            key={`staff_${staffUser.id}`}
            options={{
              ...options,
              checked: checkedStaff.indexOf(staffUser.id) > -1,
            }}
            staff={staffUser}
          />
        )}
        stickyHeaders={true}
        addStaff
        {...{ checkboxAllRef, handleCheckAll }}
      />
      {items.length > pageSize && (
        <PagerWrapper
          ariaLabel='pagination'
          buttonId='addStaff-pagination-page'
          current={currentPage}
          className='cb-align-right'
          max={totalPages}
          onPageChange={pageChangeHandler}
          pagerId='addStaff-Pagination'
        />
      )}
    </>
  );
};

AddStaff.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  addUserHandler: PropTypes.func,
};

SortIcon.propTypes = {
  sortOrder: PropTypes.string,
};
