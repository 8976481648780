import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import groupBy from 'lodash/groupBy';
import uniqBy from 'lodash/uniqBy';
import { useStateValue } from '../../../../context/AppContext';
import ErrorMessages from '../../../ui/message/ErrorMessage';
import { getGroupTypes } from '../../../../utils/getGroupTypes';
import { BULK_ASSIGN, UPDATE_STUDENT } from '../../../../apollo/mutations';
import { localStateReducer } from '../../../../utils/common';
import { ModalDispatchContext } from '../../../ui/modal/ModalContext';
import ConfirmationOfBulkAssign from './ConfirmationOfBulkAssign';
import ConfirmationTestInProgress from './ConfirmationOfTestInProgress';
import RoomChooser from './RoomChooser';
import { NakedButton, YellowButton } from '@cb/apricot-react';

function BulkAssign({ rooms = [], students = [] }) {
  const { orgEvent, user } = useStateValue();
  const navigate = useNavigate();

  function generateGroupTypeInfo() {
    const groupTypes = getGroupTypes(orgEvent);
    const groupTypeGroupedStudents = groupBy(students, 'groupType');
    const allGroupTypes = Object.keys(groupTypeGroupedStudents).sort();
    return allGroupTypes.map((item) => ({
      tag: item,
      info: groupTypes[item],
      studentCount: (groupTypeGroupedStudents[item] || []).length,
    }));
  }

  function handleSubmit(students = []) {
    return function (e) {
      e && e.preventDefault && e.preventDefault();

      const chosenRoom = rooms.find((room) => room.id === localState.room);
      const isPlural = students.length > 1;
      let input;
      let mutation;
      let assignFunction = () => {};

      if (students.length === 1) {
        input = {
          absent: students[0].absent,
          assignedSeat: null, // Remove any assigned seat they had before
          checkedInCenter: students[0].checkedInCenter,
          checkedInRoom: false,
          deniedEntry: students[0].deniedEntry,
          id: students[0].id,
          room: localState.room || '',
          testBookNumber: null,
        };
        mutation = updateStudent;
      } else {
        input = {
          ids: students.map((student) => student.id || ''),
          operation: 'assign',
          room: localState.room || '',
        };
        mutation = bulkAssign;
      }

      assignFunction = () => {
        // Disable the button while it's processing.
        setLocalState({
          submitDisabled: true,
        });
        mutation({
          variables: { input },
          refetchQueries: ['getSiteStats'],
        })
          .then(() => {
            // Reset the state.
            setLocalState({
              submitDisabled: false,
              submitErrors: [],
            });

            // If we have combined a room, show a special message
            // Else just tell them we have moved the students
            let title = '';
            if (uniqBy(students, 'groupType').length > 1 && (chosenRoom.groupTypes || []).length < 2) {
              title = `You’ve moved the student${isPlural ? 's' : ''} to a new room. That room is now a combined room.`;
            } else {
              title = `You’ve moved the student${isPlural ? 's' : ''} to a new room.`;
            }

            return dispatchModal(<ConfirmationOfBulkAssign title={title} />);
          })
          .catch((e) => {
            let submitErrors = [];
            if (
              e.graphQLErrors &&
              e.graphQLErrors[0].payload.code === 'student:checkRoomForSeats:invalid_room_action'
            ) {
              // We got a service error.
              submitErrors = [
                {
                  key: 'bulk-assign-rooms',
                  uiName: 'bulk-assign-rooms',
                  errorMessage:
                    'There are no seats available that match this student’s testing group. See your test center coordinator.',
                },
              ];
            }
            setLocalState({
              submitDisabled: true,
              submitErrors,
            });
          });
      };

      if (localState.room) {
        // If the room has started testing, prompt them before saving.
        if (chosenRoom && chosenRoom.status !== 'none') {
          dispatchModal(<ConfirmationTestInProgress isPlural={isPlural} handlerFunction={assignFunction} />);
        } else {
          assignFunction();
        }
      }
    };
  }

  // Global App state.
  const dispatchModal = useContext(ModalDispatchContext);

  // Local state.
  const [localState, setLocalState] = React.useReducer(localStateReducer, {
    room: '',
    submitDisabled: false,
    submitErrors: [],
  });

  // Apollo.
  const [bulkAssign] = useMutation(BULK_ASSIGN);
  const [updateStudent] = useMutation(UPDATE_STUDENT);

  const { submitErrors } = localState;

  const numStudents = students.length;
  const groupTypeInfo = generateGroupTypeInfo();

  return (
    <React.Fragment>
      <ErrorMessages errorMessages={submitErrors} />

      <h2 className='tdtk-h2 mb-4' data-automation='heading-h2'>
        You’re about to move {numStudents} student
        {numStudents === 1 ? '.' : 's.'}
      </h2>
      <h3 className='tdtk-h3 mb-4' data-automation='heading-h3'>
        Testing group{groupTypeInfo && groupTypeInfo.length > 1 ? 's' : ''}:
        {groupTypeInfo &&
          groupTypeInfo.length === 1 &&
          ` ${groupTypeInfo[0]?.tag || ''}: ${groupTypeInfo[0]?.info?.title || ''} ${
            groupTypeInfo[0].info ? groupTypeInfo[0]?.info?.label : ''
          }`}
      </h3>
      {groupTypeInfo &&
        groupTypeInfo.length > 1 &&
        (groupTypeInfo || []).map((item) => (
          <p className='mb-4' key={item.tag}>
            <strong>{item.tag}:</strong>
            {` ${item?.info?.title || 'N/A'} (${item?.info?.standard ? 'standard' : 'accommodated'}) - ${
              item?.studentCount
            } student${item?.studentCount > 1 ? 's' : ''}`}
          </p>
        ))}

      {
        <>
          <RoomChooser
            defaultValue={localState.room}
            onChange={(newValue) => setLocalState({ room: newValue })}
            rooms={rooms}
            students={students}
            user={user}
          />
          <div className='tdtk-form-group tdtk-primary-secondary display-flex--column-mobile'>
            <YellowButton
              small
              disabled={!localState.room || localState.submitDisabled}
              id='button-submitAssign'
              onClick={handleSubmit(students)}
            >
              Continue
            </YellowButton>
            <NakedButton small id='button-cancelAssign' onClick={() => navigate(-1)}>
              Cancel
            </NakedButton>
          </div>
        </>
      }
    </React.Fragment>
  );
}

BulkAssign.propTypes = {
  rooms: PropTypes.array,
  students: PropTypes.array,
};

export default BulkAssign;
