import React from 'react';
import PropTypes from 'prop-types';
import './ProgressBar.scss';

function ProgressBar({ totalSteps, currentStep }) {
  // Calculate the progress for each step we fill
  const progressPerStep = 100 / totalSteps;
  // Calculate the progress based on the step we are in
  const progress = currentStep * progressPerStep;

  return (
    <div className='stepper-progress'>
      <div className='stepper-progress-status' id='wizard-progress-status' role='status'>
        Step {currentStep} of {totalSteps}
      </div>
      <div className='stepper-progress-wrapper'>
        <div className='stepper-progress-bar' style={{ width: progress + '%' }} role='presentation' />
      </div>
    </div>
  );
}

ProgressBar.propTypes = {
  totalSteps: PropTypes.number,
  currentStep: PropTypes.number,
};

export default ProgressBar;
