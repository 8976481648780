import { useEffect } from 'react';

export const useTitle = (title = 'The College Board') => {
  useEffect(() => {
    document.title = `Test Day Toolkit | ${title ?? 'The College Board'}`;
    return () => {
      document.title = 'Test Day Toolkit | The College Board';
    };
  }, [title]);
};
