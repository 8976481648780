import React from 'react';
import PropTypes from 'prop-types';
import Input from '../ui/form/Input';
import RadioButton from '../ui/form/RadioButton';
import RadioButtonGroup from '../ui/form/RadioButtonGroup';
import Select from '../ui/form/Select';
import { NakedButton, YellowButton } from '@cb/apricot-react';

function RosterFilters({ applyFilter = () => {}, filters = [], updateFilter = () => {} }) {
  function renderFilter(filter) {
    switch (filter.type) {
      case 'textbox':
        return renderTextBox(filter);

      case 'checkbox':
      case 'radio':
        return renderRadio(filter);

      case 'select':
        return renderSelect(filter);

      default:
        console.error('Unknown filter field type');
    }
  }

  function renderTextBox(filter) {
    return (
      <Input
        additionalInstructions={filter.description}
        floating={false}
        id={filter.field}
        key={filter.id}
        label={filter.label}
        name={filter.field}
        onChange={(val) => {
          val = {
            target: {
              name: filter.field,
              type: 'input',
              value: val,
            },
          };
          updateFilter(val);
        }}
        value={filter.defaultValue || ''}
      />
    );
  }

  function renderRadio(filter) {
    return (
      <RadioButtonGroup
        fieldsetId={`radio-${filter.id}-label`}
        id={filter.field}
        legend={filter.label}
        name={filter.field}
        onChange={(val) => {
          val = {
            target: {
              name: filter.field,
              type: 'select',
              value: val,
            },
          };
          updateFilter(val);
        }}
        required={false}
        value={filter.defaultValue !== undefined ? filter.defaultValue.toString() : 'none'}
        vertical
      >
        {Object.keys(filter.selectOptions).map((key) => {
          return <RadioButton id={`${filter.id}-${key}`} key={key} label={filter.selectOptions[key]} value={key} />;
        })}
      </RadioButtonGroup>
    );
  }

  function renderSelect(filter) {
    // enchance options objects to have a label that we can use (based on their title)
    const transformSelectOptions = (a) => {
      return a.reduce((prev, cur) => {
        return [
          ...prev,
          {
            label: cur.title,
            ...cur,
          },
        ];
      }, []);
    };

    return (
      <Select
        additionalInstructions={filter.description}
        id={filter.id}
        key={filter.id}
        label={filter.label}
        name={filter.field}
        onChange={(val) => {
          val = {
            target: {
              name: filter.field,
              type: 'select',
              value: val,
            },
          };
          updateFilter(val);
        }}
        required={filter.required}
        value={filter?.defaultValue[0] || '-'}
        values={transformSelectOptions(filter.selectOptions)}
      />
    );
  }

  const renderedFields = filters.map((filter) => {
    return (
      <div key={filter.id} className='tdtk-form-group'>
        {renderFilter(filter)}
      </div>
    );
  });

  return (
    <div className='box-card shadow hide-from-print'>
      <form>
        <fieldset>
          {renderedFields}
          <div className='display-flex flex-xs-column-only my-4'>
            <YellowButton small id='applyFilters' onClick={applyFilter}>
              Apply Filters
              <span className='cb-sr-only'> and close filter form </span>
            </YellowButton>
            <NakedButton
              small
              className='cb-margin-xs-top-16 cb-margin-sm-up-left-16'
              disabled={!filters.length}
              id='resetFilters'
              name='resetFilters'
              onClick={updateFilter}
            >
              Reset Filters
            </NakedButton>
          </div>
        </fieldset>
      </form>
    </div>
  );
}

RosterFilters.propTypes = {
  applyFilter: PropTypes.func,
  filters: PropTypes.array,
  updateFilter: PropTypes.func,
};

export default RosterFilters;
