import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { getGroupTypes } from '../../../../utils/getGroupTypes';
import { roomStatus } from '../../../../constants/roomConstants';
import { useStateValue } from '../../../../context/AppContext';
import { hasAdminAccess } from '../../../../utils/user';
import { seatsAvailable, sortItems } from '../../../../utils/common';
import { ModalDispatchContext } from '../../../ui/modal/ModalContext';
import groupBy from 'lodash/groupBy';
import keyBy from 'lodash/keyBy';
import Select from '../../../ui/form/Select';

function RoomChooser({ onChange = () => {}, rooms = [], students = [], defaultValue = '', user = {} }) {
  const dispatchModal = React.useContext(ModalDispatchContext);
  const { orgEvent } = useStateValue();

  function generateRooms(rooms, numStudents) {
    if (rooms.length) {
      let transformedRooms = rooms.map((room) => {
        const availableSeats = seatsAvailable(room);
        const status = roomStatus[room.status].statusText || 'Ready';
        const label =
          availableSeats > 0
            ? `${room.title} | ${
                room.groupTypes.length ? room.groupTypes.join(', ') : 'Unassigned'
              } | (Seats left: ${availableSeats}) - ${status}`
            : `${room.title} | ${
                room.groupTypes.length ? room.groupTypes.join(', ') : 'Unassigned'
              } | (No Seats Available)`;
        return {
          disabled: availableSeats < 1 || numStudents > availableSeats,
          label,
          value: room.id,
        };
      });

      transformedRooms = sortItems(
        transformedRooms,
        [
          {
            name: 'title',
            order: 'asc',
          },
        ],
        'natural'
      );

      return transformedRooms;
    } else {
      return [];
    }
  }

  function generateGroupTypeInfo() {
    const groupTypes = getGroupTypes(orgEvent);
    const groupTypeGroupedStudents = groupBy(students, 'groupType');
    const allGroupTypes = Object.keys(groupTypeGroupedStudents).sort();

    return allGroupTypes.map((item) => ({
      info: groupTypes[item],
      studentCount: (groupTypeGroupedStudents[item] || []).length,
      tag: item,
    }));
  }

  // Reduce the rooms the students are in, to see if we should
  // exclude any from the list.

  const studentsRooms = students.reduce(
    (accumulator, student) => ({ ...accumulator, [student.room ? student.room.id : 0]: student.id }),
    {}
  );

  // Count the number of available rooms.

  const availableRooms = rooms.filter((room) => {
    const availableSeats = seatsAvailable(room);
    const allGroupTypes = Object.keys(keyBy(students, 'groupType'));

    // Make sure at least one room's group types matches any of
    // the students group types.

    const showRoom = (room.groupTypes || []).reduce((accum, item) => accum || allGroupTypes.includes(item), false);

    // If there is only 1 room for all selected students, and
    // this room is that ID, we don't want to show this room
    // because they are already in it.

    if (Object.keys(studentsRooms).length === 1 && studentsRooms[room.id] !== undefined) {
      return false;
    } else {
      return (room.groupTypes.length === 0 || showRoom) && availableSeats > 0 && students.length <= availableSeats;
    }
  });

  const renderedRooms = generateRooms(availableRooms, students.length);
  const groupTypeInfo = generateGroupTypeInfo();

  return renderedRooms.length ? (
    <Select
      additionalInstructions={
        groupTypeInfo && groupTypeInfo.length > 1
          ? 'Students in different testing groups will be seated in the same room.'
          : ''
      }
      defaultValue={defaultValue}
      id='input-room-chooser'
      label={`Where do you want to move ${students.length === 1 ? 'this student' : 'these students'}?`}
      labelNoValue='Choose a room'
      name='input-room-chooser'
      onChange={onChange}
      values={renderedRooms}
    />
  ) : hasAdminAccess(user.rid, user.role) ? (
    <p data-automation='no-available-seats-msg'>
      You can&rsquo;t make this change because there aren&rsquo;t enough available seats. You can{' '}
      <Link
        to='/rooms'
        id='add-new-room'
        state={{ showAddForm: true }}
        replace={true}
        onClick={(e) => {
          e.stopPropagation();
          dispatchModal(null);
        }}
      >
        add a new room
      </Link>
      .
    </p>
  ) : (
    <p className='mb-4'>
      There are no seats available that match this student&rsquo;s testing group. See your test center coordinator.
    </p>
  );
}

RoomChooser.propTypes = {
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  rooms: PropTypes.array.isRequired,
  students: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
};

export default RoomChooser;
