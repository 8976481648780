import { getGroupTypes } from './getGroupTypes';
import { sortItems } from '../utils/common';
import get from 'lodash/get';

function _isBetween(n, a, b) {
  // Is n between a and b?
  // NOTE: This is inclusive of both a, b
  return (n - a) * (n - b) <= 0;
}

export function getRoomStaffByRole(roomStaff) {
  let admin = roomStaff.filter((user) => {
    return user.role === 'admin';
  });

  let proctor = roomStaff.filter((user) => {
    return user.role === 'proctor';
  });

  let roomMonitors = roomStaff.filter((user) => {
    return user.role === 'roomMonitor';
  });

  let hallMonitors = roomStaff.filter((user) => {
    return user.role === 'hallMonitor';
  });

  let technologyCoordinators = roomStaff.filter((user) => {
    return user.role === 'technologyCoordinator';
  });

  // Sort the various arrays by name.
  admin = sortItems(admin, [
    {
      name: 'lastName',
      order: 'asc',
    },
    {
      name: 'firstName',
      order: 'asc',
    },
  ]);

  proctor = sortItems(proctor, [
    {
      name: 'lastName',
      order: 'asc',
    },
    {
      name: 'firstName',
      order: 'asc',
    },
  ]);

  roomMonitors = sortItems(roomMonitors, [
    {
      name: 'lastName',
      order: 'asc',
    },
    {
      name: 'firstName',
      order: 'asc',
    },
  ]);

  hallMonitors = sortItems(hallMonitors, [
    {
      name: 'lastName',
      order: 'asc',
    },
    {
      name: 'firstName',
      order: 'asc',
    },
  ]);

  technologyCoordinators = sortItems(technologyCoordinators, [
    {
      name: 'lastName',
      order: 'asc',
    },
    {
      name: 'firstName',
      order: 'asc',
    },
  ]);

  return { admin, proctor, roomMonitors, hallMonitors, technologyCoordinators };
}

export function roomStaffRequirements(room, orgEvent) {
  const studentsInRoom = room.students.length;

  // Determine if they already have a proctor or coordinator who is filling in the role.
  const proctorTotal = get(room, 'staff', []).filter((s) => s.role === 'admin' || s.role === 'proctor').length;

  // Number of room monitors recommended/required.
  const roomMonitorRequirement = roomMonitorRequirements(room.groupTypes, studentsInRoom, orgEvent);

  return {
    proctorTotal,
    roomMonitorRequirement,
  };
}

export function roomMonitorRequirements(roomGroupTypes = [], numberOfStudents, orgEvent) {
  const groupTypes = getGroupTypes(orgEvent);
  // Assume the room will be standard or accommodated based on the initial group type.
  const groupType = roomGroupTypes[0];
  let roomMonitors = 0;

  // Determine if this room is "standard" or "accommodated".
  if (groupTypes[groupType] !== undefined && groupTypes[groupType].standard) {
    // This is standard, so calculate requirements based on this.
    roomMonitors = _isBetween(numberOfStudents, 35, 50)
      ? // 1 room monitor between (inclusively) 35 and 50 students.
        1
      : _isBetween(numberOfStudents, 51, 100)
      ? // 2 room monitors between (inclusively) 51 and 100 students.
        2
      : numberOfStudents > 100
      ? // 2 room monitors > 100 students, with 1 monitor for each additional 50 students.
        2 + Math.ceil((numberOfStudents - 100) / 50)
      : 0;
  } else if (groupTypes[groupType] !== undefined && !groupTypes[groupType].standard) {
    // 0 room monitors 0 - 20 students, with 1 monitor for each additional 20 students.
    roomMonitors = numberOfStudents > 20 ? Math.ceil((numberOfStudents - 20) / 20) : 0;
  } else {
    // Group Type is not defined, we don't know what we need.
  }

  return roomMonitors;
}

export function hallMonitorRequirements(numberOfRooms) {
  // "Start with one hall monitor even if you are testing fewer than 35 students."
  let hallMonitors = 1;

  // Calculate hall monitors.
  hallMonitors = _isBetween(numberOfRooms, 0, 5)
    ? // 1 hall monitor between (inclusively) 1 and 5 rooms.
      1
    : _isBetween(numberOfRooms, 6, 10)
    ? // 2 hall monitors between (inclusively) 6 and 10 rooms.
      2
    : _isBetween(numberOfRooms, 11, 15)
    ? // 3 hall monitors between (inclusively) 11 and 15 rooms.
      3
    : _isBetween(numberOfRooms, 16, 20)
    ? // 4 hall monitors between (inclusively) 16 and 20 rooms.
      4
    : numberOfRooms > 20
    ? // 4 hall monitors > 20 rooms, with 1 monitor for each additional 5 rooms.
      4 + Math.ceil((numberOfRooms - 20) / 5)
    : 1;

  return hallMonitors;
}

export function getStaffingPriority(availableSeats, room, staffInRoom, orgEvent) {
  const staffNeeded = availableSeats < room.capacity;

  let staffingPriority = 0;
  if (staffNeeded) {
    // If the room doesn't have a proctor or admin.
    if (!staffInRoom.find((item) => item.role === 'proctor') || !staffInRoom.find((item) => item.role === 'admin')) {
      staffingPriority += 1000;
    }
    if (room.groupTypes) {
      const roomMonitorsNeeded = roomMonitorRequirements(room.groupTypes, room.capacity - availableSeats, orgEvent);
      const roomMonitorsInRoom = staffInRoom.reduce((curr, item) => {
        return item.role === 'roomMonitor' ? curr + 1 : curr;
      }, 0);
      staffingPriority += roomMonitorsNeeded - roomMonitorsInRoom;
    }
  } else {
    staffingPriority = -1000;
  }
  return staffingPriority;
}
