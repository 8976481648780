import {
  blueboxSectionNameFormatter,
  getDynamicBlueboxTestStatuses,
  getUniqueSectionNames,
  sectionRegex,
} from '../../../../../constants/blueboxTestStatus';
import { DAP_STUDENT_TEST_STATUS } from '../../../../../constants/bluebox';
import { generateStatus } from '../../../../common/students/utility';

export function getStudentDisplayData(students = [], tdtkAsmtType) {
  const blueboxTestStatuses = getDynamicBlueboxTestStatuses(getUniqueSectionNames(students), tdtkAsmtType);

  return students.map((student) => {
    const matchingEnum = blueboxTestStatuses.find((stat) => stat?.name === blueboxSectionNameFormatter(student));

    let displayName = '';
    if (
      matchingEnum?.name?.startsWith(DAP_STUDENT_TEST_STATUS.SECTION_START_TIME) &&
      student?.dapTestStatus?.payload?.sectionName
    ) {
      displayName = student.dapTestStatus.payload.sectionName;
    } else {
      displayName = matchingEnum?.displayName;
    }

    let name = '';
    if (
      matchingEnum?.name === DAP_STUDENT_TEST_STATUS.SECTION_START_TIME &&
      student?.dapTestStatus?.payload?.sectionName?.match(sectionRegex)
    ) {
      name = `${DAP_STUDENT_TEST_STATUS.SECTION_START_TIME}-${
        student.dapTestStatus.payload.sectionName.match(sectionRegex)[0]
      }`;
    } else if (
      matchingEnum?.name === DAP_STUDENT_TEST_STATUS.EXAM_CHECKIN_STARTED ||
      matchingEnum?.name === DAP_STUDENT_TEST_STATUS.WAITING_ROOM_ARRIVAL
    ) {
      // bin "app checkin" and "waiting room arrival" into "not started"
      name = DAP_STUDENT_TEST_STATUS.NONE;
    } else {
      name = matchingEnum?.sectionName || matchingEnum?.name;
    }

    return {
      ...student,
      assignedSeatComposite: student?.assignedSeat ? `${student?.assignedSeat?.y}-${student?.assignedSeat?.x}` : '',
      status: generateStatus(student),
      dapTestStatus: {
        ...student.dapTestStatus,
        displayName, // used by the RosterRow component
        name,
        sortOrder: matchingEnum?.sortOrder,
      },
    };
  });
}
