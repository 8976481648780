import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import ModalDialogWrapper from '../../../../../ui/modal/ModalDialogWrapper';
import { ModalDispatchContext } from '../../../../../ui/modal/ModalContext';
import ModalFooter from '../../../../../ui/modal/ModalFooter';
import { formatStudentName } from '../../../../../../utils/common';

function SaveSeatingChartModal({ handlerFunction, studentsWithoutAssignedSeats }) {
  function handleClose() {
    dispatchModal(null);
  }

  // Modal Context.
  const dispatchModal = useContext(ModalDispatchContext);

  return (
    <ModalDialogWrapper
      modalId='SaveSeatingChartModal'
      onClose={handleClose}
      open
      title='Are you sure?'
      variant='error'
    >
      <React.Fragment key='.0'>
        <p>These students are checked in to your room but don’t have assigned seats:</p>
        <ul className='cb-list-style'>
          {studentsWithoutAssignedSeats.map((student) => (
            <li key={student.candRegNo}>{formatStudentName(student)}</li>
          ))}
        </ul>
        <p>
          Click <strong>Cancel</strong> to assign them seats.
        </p>
        <p>You can update your seating chart after you submit it.</p>
      </React.Fragment>
      <ModalFooter
        buttons={[
          {
            handler: handleClose,
            id: 'save-seating-chart-modal-cancel',
            style: 'default',
            text: 'Cancel',
          },
          {
            id: 'save-seating-chart-modal-continue',
            style: 'yellow',
            text: 'Continue',
            handler: handlerFunction,
          },
        ]}
      />
    </ModalDialogWrapper>
  );
}

SaveSeatingChartModal.propTypes = {
  handlerFunction: PropTypes.func,
  studentsWithoutAssignedSeats: PropTypes.arrayOf(PropTypes.object),
};

export default SaveSeatingChartModal;
