import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { BULK_UNASSIGN, UPDATE_STUDENT } from '../../../../apollo/mutations';
import { ModalDispatchContext } from '../../../ui/modal/ModalContext';
import ConfirmationOfBulkUnassign from './ConfirmationOfBulkUnassign';
import { NakedButton, YellowButton } from '@cb/apricot-react';

function BulkUnassign({ students = [] }) {
  const navigate = useNavigate();

  function handleSubmit() {
    let input;
    let mutation;

    if (students.length === 1) {
      input = {
        assignedSeat: null,
        id: students[0].id,
        room: '',
        testBookNumber: null,
      };
      mutation = updateStudent;
    } else {
      input = {
        ids: students.map((item) => item.id || ''),
        operation: 'unassign',
      };
      mutation = bulkUnassign;
    }
    const isPlural = students.length > 1;
    const title = `The selected ${isPlural ? 'students have' : 'student has'} been moved out of their room${
      isPlural ? 's' : ''
    }.`;

    mutation({
      variables: { input },
      refetchQueries: ['getSiteStats'],
    }).then(() => {
      dispatchModal(<ConfirmationOfBulkUnassign title={title} />);
    });
  }

  function handleCancel() {
    navigate(-1);
  }

  // Global App state.
  const dispatchModal = useContext(ModalDispatchContext);

  // Apollo.
  const [bulkUnassign] = useMutation(BULK_UNASSIGN);
  const [updateStudent] = useMutation(UPDATE_STUDENT);

  const numStudents = students.length;

  return (
    <React.Fragment>
      <h2 className='tdtk-h2 my-4' data-automation='heading-h2'>
        Are you sure?
      </h2>
      <div className='my-4'>
        You’re about to move {numStudents} student
        {numStudents === 1 ? '' : 's'} out of their room.
      </div>
      <div className='tdtk-form-group tdtk-primary-secondary display-flex--column-mobile'>
        <YellowButton small id='button-submitAssign' onClick={handleSubmit}>
          Remove Student
          {numStudents === 1 ? '' : 's'}
        </YellowButton>
        <NakedButton small id='button-cancelAssign' onClick={handleCancel}>
          Cancel
        </NakedButton>
      </div>
    </React.Fragment>
  );
}

BulkUnassign.propTypes = {
  students: PropTypes.array,
};

export default BulkUnassign;
