import React from 'react';
import { AccommodationLookup } from '../../../common/accommodations/lookup';
import { formatStudentName } from '../../../../utils/common';
import { generateStatus } from '../../../common/students/utility';
import { groupTypes } from '../../../../constants/groupTypes';
import { languageListeningTable } from '../../../../constants/languageListeningTable';
import { ResizeContext } from '../../../../context/ResizeContext';
import { TourContent, TourContainer, useTour, useTakeTheTour } from '../../../common/productTour';
import { useTitle } from '../../../../constants/useTitle';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import SimpleList from '../../../ui/list/SimpleList';
import StudentDetailsButtons from './StudentDetailsButtons';
import { useStateValue } from '../../../../context/AppContext';
import { generateGroupTypeCellStr } from '../../../../utils/groupTypeLabels';
import { formattedDate } from '../../../../utils/date';
import { useNavigate } from 'react-router-dom';

function StudentDetails({ student = {} }) {
  const navigate = useNavigate();
  useTitle(`Student - ${formatStudentName(student, 'fml')}`);
  const { orgEvent } = useStateValue();
  const { isUserTourValid, tourButton } = useTakeTheTour();

  function generateAccommodationList(accommodations = []) {
    return AccommodationLookup(accommodations, 'cb').map((item, index) => {
      return (
        <li data-automation={item.title} key={`accommodations_${index}`} className='student-details-ssd-li'>
          <div>{`${item.title} - ${item.description}`}</div>
          <div className='student-details-ssd-supplemental-info'>
            {item.supplementalInfo ? (
              <span data-automation={`${item.title}-supplementalInfo`}>{item.supplementalInfo}</span>
            ) : (
              ''
            )}
          </div>
        </li>
      );
    });
  }

  function generateDetailsList(student) {
    const listeningInfo = languageListeningTable[student?.satIIListening] || null;
    const group = groupTypes[student.groupType];
    const groupText = generateGroupTypeCellStr(group, student, orgEvent);
    const accommodationsList = generateAccommodationList(student?.accommodationsList || []);

    // Add gender options.
    const genderText = (gender) => {
      switch (gender) {
        case 'm':
          return 'Male';
        case 'f':
          return 'Female';
        case 'a':
          return 'Another';
        default:
          return 'Unknown';
      }
    };

    return [
      { title: 'Testing Room', value: student?.room?.title || 'Unassigned' },
      { title: 'Testing Group', value: groupText },
      listeningInfo
        ? {
            title: 'Language with Listening Test',
            value: listeningInfo,
          }
        : null,
      student && !isEmpty(accommodationsList)
        ? {
            title: 'SSD Accommodations',
            value: (
              <ul
                className='cb-resource'
                style={{
                  listStyle: 'disc',
                  padding: '0 1rem 0 1rem',
                }}
              >
                {accommodationsList}
              </ul>
            ),
          }
        : null,
      {
        title: 'Seat',
        value:
          student?.assignedSeat?.x && student?.assignedSeat?.y ? (
            <React.Fragment>
              Row {student.assignedSeat.y} &mdash; Seat {student.assignedSeat.x}
            </React.Fragment>
          ) : (
            'Unassigned'
          ),
      },
      { title: 'Status', value: generateStatus(student).statusText },
      { title: 'Registration Number', value: student?.candRegNo || '-' },
      {
        title: 'Date of Birth',
        value: student?.candDOB ? formattedDate(student.candDOB) : '-',
      },
      { title: 'Gender', value: genderText(student?.candGender?.toLowerCase() || '') },
      { title: 'Photo Required', value: student?.photoRequiredFlag === 'Y' ? 'Yes' : 'No' },
      { title: 'Grade 8 or Below', value: student?.grade8OrLowerFlag === 'Y' ? 'Yes' : 'No' },
      { title: '21 or Over', value: student?.adultTestTakerFlag === 'Y' ? 'Yes' : 'No' },
    ];
  }

  function renderDetail(detail) {
    const tourProps = {};

    switch (detail?.title) {
      case 'Registration Number':
        tourProps['data-tour'] = 'registration-number';
        break;
      case 'Date of Birth':
        tourProps['data-tour'] = 'date-of-birth';
        break;
      case 'Gender':
        tourProps['data-tour'] = 'gender';
        break;
      default:
        break;
    }

    return detail ? (
      <li {...tourProps} key={detail?.title || 'detail-title'}>
        <div className='tdtk-item-text row mx-0'>
          <span className='col-xs-5 col-sm-6 col-md-6'>{detail?.title}:</span>
          <span className='col-xs-7 col-sm-6 col-md-6'>
            <strong data-automation={`student-${detail?.title?.split(' ')?.join('-')?.toLowerCase()}`}>
              {detail?.value || ''}
            </strong>
          </span>
        </div>
      </li>
    ) : null;
  }

  function mobileComponent() {
    function handleGoBack(e) {
      e && e.preventDefault && e.preventDefault();
      navigate(-1);
    }

    return (
      <React.Fragment>
        {isUserTourValid && <div className='cb-float-right'>{tourButton}</div>}
        <div className='row'>
          <div className='cb-float-left'>
            <p
              className={windowSize.prefix === 'xs' || windowSize.prefix === 'sm' ? 'ml-2 mt-2 mb-0' : 'ml-2 mt-4 mb-0'}
            >
              <a className='pt-3' href='#0' id='back-to-allStudents' onClick={handleGoBack}>
                <span className='cb-sr-only'>Go </span>&#60; Back<span className='cb-sr-only'> to previous page</span>
              </a>
            </p>
          </div>
          <div className='col-xs-10'>
            <h1 className='tdtk-h1 py-4' data-automation='heading-h1'>
              {formatStudentName(student, 'fml')}
              {student?.waitListFlag === 'Y' && (
                <React.Fragment>
                  <br />
                  Waitlisted
                </React.Fragment>
              )}
            </h1>
          </div>
        </div>
      </React.Fragment>
    );
  }

  function desktopComponent() {
    return (
      <div className='d-flex justify-content-space-between align-items-center'>
        <h1 className='tdtk-h1 py-4' data-automation='heading-h1'>
          {formatStudentName(student, 'fml')}
          {student?.waitListFlag === 'Y' && (
            <React.Fragment>
              <br />
              Waitlisted
            </React.Fragment>
          )}
        </h1>
        {isUserTourValid && tourButton}
      </div>
    );
  }

  // Global App state.
  const windowSize = React.useContext(ResizeContext);
  const { shouldShowTour, tourOpen, onRequestClose } = useTour('studentDetails');

  const tourSteps = [
    {
      selector: '[data-tour="student-photo"]',
      content: function tourFunction1(props) {
        return (
          <TourContent
            totalSteps={tourSteps.length}
            title='Student Photo'
            content='The student’s name and photo are shown at the top of the page.'
            {...props}
          />
        );
      },
    },
    {
      selector: '[data-tour="registration-number"]',
      content: function tourFunction2(props) {
        return (
          <TourContent
            totalSteps={tourSteps.length}
            title='Registration Number'
            content='Compare this registration number to the one on the student’s admission ticket.'
            {...props}
          />
        );
      },
    },
    {
      selector: '[data-tour="date-of-birth"]',
      content: function tourFunction3(props) {
        return (
          <TourContent
            totalSteps={tourSteps.length}
            title='Date of Birth'
            content='This birthdate should match the one on the student’s photo ID and admission ticket.'
            {...props}
          />
        );
      },
    },
    {
      selector: '[data-tour="gender"]',
      content: function tourContent4(props) {
        return (
          <TourContent
            totalSteps={tourSteps.length}
            title='Gender'
            content='The gender shown here should match the gender on the admission ticket.'
            {...props}
          />
        );
      },
    },
    {
      selector: '[data-tour="check-in"]',
      content: function tourContent5(props) {
        return (
          <TourContent
            totalSteps={tourSteps.length}
            title='Check-In'
            content='Click &ldquo;Room Check-In&rdquo; after you verify the student’s identity.'
            {...props}
          />
        );
      },
    },
  ];

  return (
    <React.Fragment>
      {shouldShowTour && <TourContainer steps={tourSteps} isOpen={tourOpen} onRequestClose={onRequestClose} />}
      {windowSize.mobile ? mobileComponent() : desktopComponent()}
      <div className='row'>
        <div data-tour='student-photo' className='col-md-4 col-lg-3 text-center'>
          {student?.photo || null}
        </div>

        <div className='col-md-4 col-lg-6'>
          <SimpleList items={generateDetailsList(student)} renderItem={renderDetail} callerName='student' />
        </div>

        {student?.id ? (
          <div className='col-md-4 col-lg-3'>
            <StudentDetailsButtons
              student={{
                absent: student?.absent,
                altGroupType: student?.altGroupType,
                candFirstName: student?.candFirstName,
                candLastName: student?.candLastName,
                candMidInit: student?.candMidInit,
                checkedInCenter: student?.checkedInCenter,
                checkedInRoom: student?.checkedInRoom,
                deniedEntry: student?.deniedEntry,
                groupType: student?.groupType,
                id: student?.id,
                origGroupType: student?.origGroupType,
                room: student?.room,
              }}
            />
          </div>
        ) : null}
      </div>
    </React.Fragment>
  );
}

StudentDetails.propTypes = {
  student: PropTypes.object,
};

export default StudentDetails;
