import React from 'react';
import { ISimpleList } from './types';

function SimpleList<T>({ items = [], id, removeBottomMargin = false, renderItem }: ISimpleList<T>) {
  const listId = id ? `${id}-list-view` : undefined;
  const classNames = `tdtk-item-list tdtk-item-list-simple ${removeBottomMargin ? 'mb-0' : ''}`;

  return (
    <div id={listId} className={classNames}>
      <ul>{items.map((item: T) => renderItem(item))}</ul>
    </div>
  );
}

export default SimpleList;
