import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { TextArea as Ap4TextArea } from '@cb/apricot-react';

export default function TextArea({ additionalInstructions, errorMessage = '', name, onChange, value, ...props }) {
  const [cursor, setCursor] = useState(null);
  const ref = useRef(null);

  useEffect(() => {
    const input = ref.current;
    try {
      input.setSelectionRange(cursor, cursor);
    } catch (err) {
      console.error('Error for setSelectionRange call in <TextArea>', err);
    }
  }, [ref, cursor, value]);

  const handleChange = (e) => {
    setCursor(e.target.selectionStart);
    onChange && onChange(e);
  };

  return (
    <>
      {additionalInstructions && (
        <p className='mb-0' id={`${name}-additionalLabel`}>
          {additionalInstructions}
        </p>
      )}
      <Ap4TextArea
        name={name}
        onChange={handleChange}
        ref={ref}
        validation={errorMessage ? 'error' : null}
        validationMsg={errorMessage ? errorMessage : ''}
        value={value}
        {...props}
      />
    </>
  );
}

TextArea.propTypes = {
  additionalInstructions: PropTypes.string,
  errorMessage: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
};
