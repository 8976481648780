import React from 'react';
import PropTypes from 'prop-types';
import orderBy from 'lodash/orderBy';
import GroupInfoRow from './GroupInfoRow';
import Roster from '../../common/Roster';
import { ResizeProvider } from '../../../context/ResizeContext';
import { getGroupTypes } from '../../../utils/getGroupTypes';
import { useStateValue } from '../../../context/AppContext';

function setGroupsOptions(students = [], groupTypes = {}) {
  // Get group types if needed.
  const groupsOptions = {};
  let regCount = 0;
  let waitCount = 0;

  students.forEach((student) => {
    if (groupTypes[student.groupType]) {
      // Get the current count for this object if it exists.
      if (groupsOptions[student.groupType] !== undefined) {
        regCount = groupsOptions[student.groupType].regCount;
        waitCount = groupsOptions[student.groupType].waitCount;
      } else {
        regCount = 0;
        waitCount = 0;
      }
      groupsOptions[student.groupType] = {
        description: `${groupTypes[student.groupType]['description']}`,
        groupType: student.groupType,
        regCount: student.waitListFlag === 'N' ? ++regCount : regCount,
        standard: groupTypes[student.groupType]['standard'],
        title: groupTypes[student.groupType]['title'],
        waitCount: student.waitListFlag !== 'N' ? ++waitCount : waitCount,
        label: groupTypes[student.groupType]['label'] ? groupTypes[student.groupType]['label'] : '',
      };
    } else {
      console.error('Student group type not valid: ', JSON.stringify(student));
    }
  });

  const groupsInfo = orderBy(
    Object.keys(groupsOptions).map((key) => {
      const value = groupsOptions[key];
      return {
        groupType: key,
        ...value,
      };
    }),
    'groupType',
    'asc'
  );

  return groupsInfo;
}

function GroupsInfo({
  description = [
    'Each group listed below includes students who can test in the same room. Registered students include only those students with a confirmed seat.',
  ],
  showHeading1 = true,
  showHeading2 = false,
  students = [],
  usePopovers = false,
}) {
  // Global App state.
  const { orgEvent } = useStateValue();
  const groupTypes = getGroupTypes(orgEvent);

  const headers = [
    { title: 'Testing Group', sortField: ['groupType'] },
    { title: 'Registered Students' },
    { title: 'Waitlist Students' },
  ];

  const groupsInfo = React.useMemo(() => {
    return setGroupsOptions(students, groupTypes);
  }, [students, groupTypes]);

  // Pass a message if they don't have any group types.
  const emptyMessage = groupsInfo.length ? '' : 'Student roster information is unavailable.';

  return (
    <React.Fragment>
      {showHeading1 ? (
        <h1 className='tdtk-h1 py-4' data-automation='heading-h1'>
          Testing Groups ({`${groupsInfo.length}`})
        </h1>
      ) : null}
      {
        // Show an H2 if asked, but also make sure we don't accidentally have an H1.
        !showHeading1 && showHeading2 ? (
          <h2 className='tdtk-h2 my-4' data-automation='heading-h2'>
            Testing Groups ({`${groupsInfo.length}`})
          </h2>
        ) : null
      }

      {description}

      <React.Fragment>
        <ResizeProvider>
          <Roster
            emptyMessage={emptyMessage}
            headers={headers}
            items={groupsInfo}
            renderItem={(groupInfo, options) => (
              <GroupInfoRow
                key={`group_type_${groupInfo.groupType}`}
                groupInfo={groupInfo}
                options={options}
                usePopovers={usePopovers}
              />
            )}
            sortFields={[
              {
                name: 'groupType',
                order: 'asc',
              },
            ]}
            stickyHeaders={true}
          />
        </ResizeProvider>
      </React.Fragment>
    </React.Fragment>
  );
}

GroupsInfo.propTypes = {
  description: PropTypes.array,
  showHeading1: PropTypes.bool,
  showHeading2: PropTypes.bool,
  students: PropTypes.array,
  usePopovers: PropTypes.bool,
};

export default GroupsInfo;
