import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { YellowButton, BlackButton } from '@cb/apricot-react';
import { UPDATE_STUDENT } from '../../../../../apollo/mutations';
import { formatStudentName } from '../../../../../utils/common';
import { ModalDispatchContext } from '../../../../ui/modal/ModalContext';
import OneButtonModal from '../../../../ui/modal/standard/OneButtonModal';
import TwoButtonModal from '../../../../ui/modal/standard/TwoButtonModal';
import { useNavigate } from 'react-router-dom';

function CenterCheckinButton({ student = {} }) {
  // Global Modal context.
  const dispatchModal = useContext(ModalDispatchContext);

  // Apollo.
  const [updateStudent] = useMutation(UPDATE_STUDENT);

  const navigate = useNavigate();

  function centerCheckin() {
    if (student.absent || student.deniedEntry) {
      console.error('centerCheckin - absent or deniedEntry');
      return;
    }

    const input = {
      id: student.id,
      checkedInCenter: !student.checkedInCenter,
      checkedInRoom: false,
    };

    function handleCheckinCompleted() {
      dispatchModal(null);

      if (!student.checkedInCenter) {
        navigate(-1);
      }
    }

    function handleCheckinContinueFromModal() {
      updateStudent({ variables: { input } })
        .then(() => {
          if (student.checkedInCenter) {
            dispatchModal(
              <OneButtonModal
                buttonLabel={!student.checkedInCenter ? 'Check In Another Student' : 'Close'}
                modalId='studentCheckinCompleted'
                onClose={handleCheckinCompleted}
                title={`${formatStudentName(student, 'fml')} is ${
                  student.checkedInCenter ? 'no longer checked in to' : 'checked in to'
                } the test center.`}
                variant='success'
              />
            );
          } else {
            dispatchModal(
              <TwoButtonModal
                modalId='studentCheckinCompleted'
                primaryButtonHandler={handleCheckinCompleted}
                primaryButtonLabel='Check In Another Student'
                secondaryButtonLabel='Close'
                title={`${formatStudentName(student, 'fml')} is ${
                  student.checkedInCenter ? 'no longer checked in to' : 'checked in to'
                } the test center.`}
                variant='success'
              />
            );
          }
        })
        .catch((e) => {
          // Some server error.
          console.error('student center checkin failed', e);
        });
    }

    return dispatchModal(
      <TwoButtonModal
        body={
          student.checkedInCenter
            ? 'Students never need to be checked out. Continue only if they were checked in by mistake.'
            : 'You’re about to check this student in to the test center.'
        }
        modalId='studentCheckinConfirmation'
        primaryButtonHandler={handleCheckinContinueFromModal}
        variant='error'
      />
    );
  }

  const buttonProps = {
    'id': 'button-centerCheckin',
    'className': 'mb-3 w-100',
    'data-automation': 'button-centerCheckin',
    'onClick': centerCheckin,
    'disabled': student.deniedEntry || student.absent,
    'small': true,
  };

  if (student.checkedInCenter) {
    return <BlackButton {...buttonProps}>Undo Center Check-In</BlackButton>;
  }

  return <YellowButton {...buttonProps}>Center Check-In</YellowButton>;
}

CenterCheckinButton.propTypes = {
  student: PropTypes.object,
};

export default CenterCheckinButton;
