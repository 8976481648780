import React from 'react';
import PropTypes from 'prop-types';
import { AccordionItem, Icon } from '@cb/apricot-react';

const AccordionItemWrapper = React.forwardRef((props, ref) => {
  const { glyphIcon } = props;
  let { title } = props;

  if (glyphIcon && title) {
    title = (
      <div className='display-flex align-items-center'>
        <Icon name={glyphIcon.replace(/^cb-/, '')} color='blue2' decorative />
        <span className='cb-margin-left-8 cb-font-weight-medium'>{title}</span>
      </div>
    );
  }

  return (
    <AccordionItem ref={ref} {...{ ...props, title }}>
      {props.children}
    </AccordionItem>
  );
});

AccordionItemWrapper.displayName = 'AccordionItemWrapper';

AccordionItemWrapper.propTypes = {
  glyphIcon: PropTypes.string,
  title: PropTypes.any,
  children: PropTypes.any,
};

export default AccordionItemWrapper;
