import React from 'react';
import { hasAdminAccess } from '../../utils/user';
import { isEqual } from 'apollo-utilities';
import { Icon } from '@cb/apricot-react-icon';
import PropTypes from 'prop-types';
import { localStateReducer, scrollTo, sortItems } from '../../utils/common';
import { useStateValue } from '../../context/AppContext';
import AmountCheckedDisplay from '../ui/AmountCheckedDisplay';
import TableControls from '../ui/table/TableControls';
import TableII from '../ui/table/TableII';
import Pager from '../ui/pagination/PagerWrapper';

function Roster({
  bulkAction = null,
  caption = '',
  checkboxAllRef = {},
  checkedItems = [],
  emptyMessage = '',
  goToPage = null,
  groupName = '',
  handleCheckItems = () => {},
  headers = [],
  items = [],
  pageSize = 300,
  renderItem = () => {},
  showChecked = true,
  showSelected = false,
  sortFields = [],
  sortStrategy = 'natural',
  stickyHeaders = false,
  tableControlElements = [],
  tableControlForm = null,
}) {
  function renderHeader(header, index) {
    const key = `header_${header.title}_${index}`;
    const headerSortField =
      header.sortField && header.sortField.length > 0 ? header.sortField[header.sortField.length - 1] : '';
    return (
      <div aria-sort={headerSortOrder(headerSortField)} className={header.className} key={key} role='columnheader'>
        {header.sortField ? (
          <button
            data-automation={`roster-${header.title.split(' ').join('-').toLowerCase()}`}
            href='#0'
            onClick={handleHeaderOnClick(header.sortField)}
            type='button'
          >
            {header.title}
            {generateIcon(header.sortField)}
          </button>
        ) : (
          <span data-automation={`roster-${header.title.split(' ').join('-').toLowerCase()}`}>{header.title}</span>
        )}
      </div>
    );
  }

  function headerSortOrder(field) {
    // See if this field is already being sorted.
    const matchingField = localState.sortFields.find((item) => item.name === field);

    // Invert the sort criteria if necessary.
    if (!matchingField || !matchingField.order) {
      return 'none';
    } else {
      return matchingField.order === 'asc' ? 'ascending' : 'descending';
    }
  }

  // sortable header arrow
  function generateIcon(field: string[]) {
    // We're using arrays now, so just use the last one in the list.
    const sortOrder = headerSortOrder(field[field.length - 1]);

    if (sortOrder === 'none') {
      return null;
    } else {
      return <Icon name={sortOrder === 'ascending' ? 'sort-asc' : 'sort-desc'} decorative />;
    }
  }

  function handleHeaderOnClick(fields) {
    return function (e) {
      e && e.preventDefault && e.preventDefault();

      let matchingIndex = -1;
      const newSortFields = JSON.parse(JSON.stringify(localState.sortFields));
      let oldSortObj = {};
      let order = 'asc';

      // We might be passing an array of sortable fields per column header, so go through them.
      fields.forEach((field) => {
        // Search the current list of sorted fields, and adjust accordingly.
        matchingIndex = newSortFields.findIndex((sortField) => sortField.name === field);

        if (matchingIndex > -1) {
          // Remove the old obj, while returning the object that was removed.
          oldSortObj = newSortFields.splice(matchingIndex, 1)[0];

          // Change the order as per rotation -> asc -> desc -> default.
          if (oldSortObj.order === 'asc') {
            order = 'desc';
          } else if (oldSortObj.order === 'desc') {
            order = 'default';
          }
        }

        // Add it back in to the beginning of the order array with the new order.
        if (order !== 'default') {
          newSortFields.unshift({
            name: field,
            order,
          });
        }
      });

      setLocalState({
        paginationPage: 0,
        sortFields: newSortFields,
      });
    };
  }

  function desktopComponent() {
    function handleCheckAll(toCheck) {
      return function (e) {
        const checked = e.target.checked;
        const checkItems = [];

        // Loop through the checked items and add as needed.
        toCheck.forEach((item) => {
          checkItems.push(item.id);
        });

        handleCheckItems(checkItems, checked);
      };
    }

    return (
      <React.Fragment>
        <a href='#0' name='top-of-table' id='top-of-table' tabIndex='-1' className='cb-sr-only'>
          Top of main table
        </a>
        <TableII
          checkboxAllRef={checkboxAllRef}
          handleCheckAll={handleCheckAll}
          headers={headers}
          items={displayItems}
          options={{ caption }}
          renderHeader={renderHeader}
          renderItem={renderItem}
          stickyHeaders={stickyHeaders}
        />
      </React.Fragment>
    );
  }

  // Global App state.
  const { user } = useStateValue();

  // Local state.
  const [localState, setLocalState] = React.useReducer(localStateReducer, {
    initialItems: items,
    paginationPage: 0,
    sortFields,
    sortStrategy,
  });

  const handleChangePage = React.useCallback(
    (num) => {
      return function (e) {
        e && e.preventDefault && e.preventDefault();

        const maxPages = Math.ceil(items.length / pageSize);

        if (typeof num !== 'number') {
          num = 0;
        } else if (num < 0) {
          num = 0;
        } else if (num >= maxPages) {
          num = maxPages - 1;
        }

        if (localState.paginationPage !== num) {
          setLocalState({
            paginationPage: num,
          });
        }
        // Scroll to the top of the Roster.
        scrollTo('top-of-table');
      };
    },
    [/*checkboxAllRef, */ items.length, localState.paginationPage, pageSize]
  );

  const sortedItems = localState.sortFields ? sortItems(items, localState.sortFields, localState.sortStrategy) : items;

  const startIndex = localState.paginationPage * pageSize;
  const endIndex = localState.paginationPage * pageSize + pageSize;
  const displayItems = sortedItems.slice(startIndex, endIndex);

  React.useEffect(() => {
    // Update the page to 1 if we get an updated or filtered list.
    if (!isEqual(localState.initialItems, items)) {
      setLocalState({
        initialItems: items,
        paginationPage: 0,
      });
    }
  }, [localState.initialItems, items, localState.paginationPage]);

  React.useEffect(() => {
    if (typeof goToPage === 'function' && sortedItems.length > pageSize) {
      const gotoPage = goToPage(sortedItems, pageSize);
      if (gotoPage) {
        handleChangePage(gotoPage)();
      }
    }
  }, [goToPage, sortedItems, handleChangePage, pageSize]);

  return (
    <React.Fragment>
      {<TableControls tableControlElements={tableControlElements} tableControlForm={tableControlForm} />}
      {!!items.length && !!groupName && showChecked && (
        <div className='row my-4'>
          {
            <div className='col-sm-6'>
              <AmountCheckedDisplay
                amountSelected={checkedItems.length}
                endDisplayIndex={startIndex + displayItems.length}
                itemsPerPage={pageSize}
                showSelected={hasAdminAccess(user.rid, user.role) && showSelected}
                startDisplayIndex={startIndex + 1}
                tableName={caption}
                totalCount={items.length}
              />
            </div>
          }
          {bulkAction && <div className='col-sm-6'>{bulkAction}</div>}
        </div>
      )}

      {emptyMessage ? (
        <div className='row' style={{ marginTop: '1rem' }} aria-live='polite'>
          <div className='col-xs-12'>{emptyMessage}</div>
        </div>
      ) : !displayItems.length ? (
        <div className='row' style={{ marginTop: '1rem' }} aria-live='polite'>
          <span style={{ marginLeft: '1rem' }}>0 results. Try again.</span>
        </div>
      ) : (
        <React.Fragment>
          {desktopComponent(displayItems)}
          {items.length > pageSize ? (
            <div className='row mx-0 my-4'>
              <div className='w-100'>
                <Pager
                  ariaLabel='pagination'
                  buttonId='studentRoster-pagination-page'
                  className='d-flex justify-content-center'
                  current={localState.paginationPage + 1}
                  delta={4}
                  max={Math.ceil(items.length / pageSize)}
                  onPageChange={(num) => handleChangePage(num - 1)()}
                  pagerId='studentRoster-Pager'
                />
              </div>
            </div>
          ) : null}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

Roster.propTypes = {
  bulkAction: PropTypes.object,
  caption: PropTypes.string,
  checkboxAllRef: PropTypes.object,
  checkedItems: PropTypes.array,
  emptyMessage: PropTypes.string,
  goToPage: PropTypes.func,
  groupName: PropTypes.string,
  handleCheckItems: PropTypes.func,
  headers: PropTypes.array,
  items: PropTypes.array,
  pageSize: PropTypes.number,
  renderItem: PropTypes.func,
  setScreenItems: PropTypes.func,
  showChecked: PropTypes.bool,
  showSelected: PropTypes.bool,
  sortFields: PropTypes.array,
  sortStrategy: PropTypes.string,
  stickyHeaders: PropTypes.bool,
  tableControlElements: PropTypes.array,
  tableControlForm: PropTypes.element,
};

export default Roster;
