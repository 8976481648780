import React from 'react';
import PropTypes from 'prop-types';
import QueryComponent from '../components/common/network/QueryComponent';
import { GET_CMS_CONTENT } from '../apollo/queries';
import { sat_national } from './sat_national';
import { createCleanMarkup } from '../utils/common';

function Messaging({ cmsContentLoaded, dynamic = false, section = '', updateStartPinElement }) {
  // Put the content into state so it doens't keep re-rendering.
  const [content, setContent] = React.useState(null);

  React.useEffect(() => {
    // Only query for data if we don't already have it.
    if (!content) {
      setContent(
        dynamic ? (
          <QueryComponent
            query={{ kind: 'GetCMSContent', specification: GET_CMS_CONTENT, variables: { input: { section } } }}
          >
            {(data) => {
              // Run this asyncronously so it should run Apricot after the data renders.
              async function runCallback() {
                setTimeout(function () {
                  if (data?.getCmsContent?.length && data?.getCmsContent[0]?.content) {
                    cmsContentLoaded && cmsContentLoaded(data?.getCmsContent[0]?.content);
                    updateStartPinElement && updateStartPinElement();
                  }
                }, 100);
              }

              if (data) {
                runCallback();
              }

              return data && data?.getCmsContent && data?.getCmsContent.length && data?.getCmsContent[0]?.content
                ? [
                    <div
                      key={`cms-${section}`}
                      dangerouslySetInnerHTML={createCleanMarkup(data?.getCmsContent[0]?.content)}
                    />,
                  ]
                : sat_national[section] || '';
            }}
          </QueryComponent>
        ) : (
          sat_national[section]
        )
      );
    }
  }, [cmsContentLoaded, content, dynamic, section, updateStartPinElement]);

  return content;
}

Messaging.propTypes = {
  cmsContentLoaded: PropTypes.func,
  dynamic: PropTypes.bool,
  section: PropTypes.string,
  updateStartPinElement: PropTypes.func,
};

export default Messaging;
