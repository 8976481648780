import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';
import { useNavigate, useLocation } from 'react-router-dom';
import { YellowButton, BlackButton } from '@cb/apricot-react';
import { UPDATE_STUDENT } from '../../../../../apollo/mutations';
import { useStateValue } from '../../../../../context/AppContext';
import { formatStudentName, seatsAvailable } from '../../../../../utils/common';
import { ModalDispatchContext } from '../../../../ui/modal/ModalContext';
import TooltipWrapper from '../../../../ui/tooltip/TooltipWrapper';
import OneButtonModal from '../../../../ui/modal/standard/OneButtonModal';
import TwoButtonModal from '../../../../ui/modal/standard/TwoButtonModal';

function RoomCheckinButton({ student = {} }) {
  // Global Modal context.
  const dispatchModal = useContext(ModalDispatchContext);

  // Apollo.
  const [updateStudent] = useMutation(UPDATE_STUDENT);

  // Global App state.
  const { orgEvent } = useStateValue();
  const navigate = useNavigate();
  const location = useLocation();

  function changeRoom() {
    dispatchModal(null);

    navigate('/students/bulkOperations/assign', {
      state: {
        students: [student],
      },
    });
  }

  function roomCheckIn() {
    // Ensure they have enough seats to do the operation.
    const availableSeats = seatsAvailable(student.room);

    const input = {
      id: student.id,
      checkedInRoom: !student.checkedInRoom,
    };

    if (input.checkedInRoom) {
      input.checkedInCenter = true;
    }

    function handleCheckinCompleted() {
      dispatchModal(null);

      if (orgEvent?.dapInd) {
        if (location?.state?.from) {
          if (!student?.checkedInRoom) {
            navigate(`${location?.state?.from}#digital-student-list`);
          } else {
            navigate(location?.state?.from);
          }
        } else {
          navigate(-1);
        }
      } else {
        if (!student.checkedInRoom) {
          navigate(-1);
        }
      }
    }

    function handleCheckinContinueFromModal() {
      updateStudent({ variables: { input } })
        .then(() => {
          if (student.checkedInRoom) {
            dispatchModal(
              <OneButtonModal
                buttonLabel={!student.checkedInRoom ? 'Check In Another Student' : 'Close'}
                modalId='studentCheckinCompleted'
                onClose={handleCheckinCompleted}
                title={`${formatStudentName(student, 'fml')} is ${
                  student.checkedInRoom ? 'no longer checked in to' : 'checked in to'
                } ${student.room.title}.`}
                variant='success'
              />
            );
          } else {
            dispatchModal(
              <TwoButtonModal
                modalId='studentCheckinCompleted'
                primaryButtonHandler={handleCheckinCompleted}
                primaryButtonLabel='Check In Another Student'
                secondaryButtonLabel='Close'
                title={`${formatStudentName(student, 'fml')} is ${
                  student.checkedInRoom ? 'no longer checked in to' : 'checked in to'
                } ${student.room.title}.`}
                variant='success'
              />
            );
          }
        })
        .catch((e) => {
          // Some server error.
          console.error('student room checkin failed', e);
        });
    }

    // They shouldn't see this since the check in button should be disabled, but check anyway.
    if ((student.absent || student.deniedEntry) && student.room) {
      // The student is a no-show, but is assigned to a room. If you check them in, you should check available seats first.
      if (availableSeats > 0) {
        // We have enough seats, remove their no-show status.
        // these options will apply in the handleCheckinContinueFromModal function
        input.absent = false;
        input.deniedEntry = false;
      } else {
        // not enough seats, offer to move to a new room
        return dispatchModal(
          <TwoButtonModal
            modalId='studentChangeTestingRoom'
            primaryButtonHandler={changeRoom}
            primaryButtonLabel='Change Testing Room'
            title='Move the student to a new room. There are no seats available in their original room.'
            variant='error'
          />
        );
      }
    } else if (!student.room) {
      // This student is not in a room.
      return dispatchModal(
        <TwoButtonModal
          modalId='studentChangeTestingRoom'
          primaryButtonHandler={changeRoom}
          primaryButtonLabel='Change Testing Room'
          title='Please assign this student a room before checking them in.'
          variant='error'
        />
      );
    }

    return dispatchModal(
      <TwoButtonModal
        body={
          student.checkedInRoom
            ? orgEvent?.dapInd
              ? 'You’re about to undo check-in for this student.'
              : 'Students never need to be checked out. Continue only if they were checked in by mistake.'
            : 'You’re about to check this student in to their room.'
        }
        modalId='studentCheckinConfirmation'
        primaryButtonHandler={handleCheckinContinueFromModal}
        variant='error'
      />
    );
  }

  let popoverStatusTitle = '';

  if (student.deniedEntry) {
    popoverStatusTitle = 'Student was denied entry.';
  } else if (student.absent) {
    popoverStatusTitle = 'Student was marked absent.';
  } else if (!student.room) {
    popoverStatusTitle = 'Assign the student to a room first.';
  }

  const buttonProps = {
    'onClick': roomCheckIn,
    'id': 'button-roomCheckIn',
    'disabled': !student.room || student.deniedEntry || student.absent,
    'className': 'mb-3 w-100',
    'small': true,
    'data-automation': 'button-roomCheckIn',
  };

  return (
    <>
      {student.checkedInRoom ? (
        <BlackButton {...buttonProps}>{orgEvent?.dapInd ? 'Undo Check-In' : 'Undo Room Check-In'}</BlackButton>
      ) : (
        <YellowButton {...buttonProps}>{orgEvent?.dapInd ? 'Check-In' : 'Room Check-In'}</YellowButton>
      )}
      {popoverStatusTitle ? (
        <TooltipWrapper light={true} trigger={buttonProps.id}>
          {popoverStatusTitle}
        </TooltipWrapper>
      ) : null}
    </>
  );
}

export default RoomCheckinButton;
