import React from 'react';
import PropTypes from 'prop-types';
import { AccordionList } from '@cb/apricot-react';

function AccordionWrapper(props) {
  return <AccordionList {...props}>{props.children}</AccordionList>;
}

AccordionWrapper.propTypes = {
  children: PropTypes.any,
};

export default AccordionWrapper;
