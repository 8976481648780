import { validate, validateAll, validations } from 'indicative';

export function generateStatus(irregularityStatus) {
  let displayStatus = '';
  switch (irregularityStatus) {
    case 'drafted':
      displayStatus = 'Drafted';
      break;
    case 'coordinator_submit':
      displayStatus = 'Submitted to Coordinator';
      break;
    case 'cb_submit':
      displayStatus = 'Submitted to College Board';
      break;
    default:
      displayStatus = 'Drafted';
      break;
  }
  return displayStatus;
}

function choiceFn(data, field, message, args, get) {
  return new Promise((resolve, reject) => {
    let fieldValues = get(data, field);

    if (fieldValues) {
      // Coerce to array if the value is a string
      if (typeof fieldValues === 'string' || fieldValues instanceof String) {
        fieldValues = [fieldValues];
      }
      if (fieldValues.length !== 1) {
        reject(`Need one choice from [${args.toString()}]`);
        return;
      } else if (!args.includes(fieldValues[0])) {
        reject(`Choice "${fieldValues[0]}" not in [${args.toString()}]`);
        return;
      }
    }

    resolve('validation passed');
  });
}

function selectionFn(data, field, message, args, get) {
  return new Promise((resolve, reject) => {
    const fieldValues = get(data, field);

    if (fieldValues) {
      const valuesAreValid = fieldValues.every((val) => {
        const isString = typeof val === 'string' || val instanceof String;
        return args.includes(isString ? val : val.value);
      });
      if (!valuesAreValid) {
        reject(`Selection set was not subset of [${args.toString()}]`);
        return;
      }
    }

    resolve('validation passed');
  });
}

function checkboxFn(data, field, message, args, get) {
  return new Promise((resolve, reject) => {
    const fieldValues = get(data, field);
    if (+args[0] > 0 && fieldValues.length < +args[0]) {
      reject(`Must check ${args[0]} ${+args[0] > 0 ? 'options' : 'option'}`);
      return;
    }

    resolve('validation passed');
  });
}
validations.choice = choiceFn;
validations.selection = selectionFn;
validations.checkbox = checkboxFn;

export { validate, validateAll };
