import React, { useEffect, useRef, useState } from 'react';
import { Input as Ap4Input } from '@cb/apricot-react';
import { IInputProps } from './types';

const SUPPORTED_TYPES = ['text', 'search', 'url', 'tel', 'password'];

const Input: React.FC<IInputProps> = ({
  additionalInstructions,
  errorMessage = '',
  id,
  type = 'text',
  value,
  onChange,
  ...props
}) => {
  const [cursor, setCursor] = useState<number | null>(null);
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const input = ref.current;
    if (input && SUPPORTED_TYPES.includes(type)) {
      try {
        input.setSelectionRange(cursor, cursor);
      } catch (err) {
        console.error('Invalid input type for setSelectionRange call:', type);
      }
    }
  }, [ref, cursor, type, value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCursor(e.target.selectionStart);
    onChange && onChange(e);
  };

  return (
    <>
      {additionalInstructions && (
        <p className='mb-0' id={`${id}-additionalLabel`}>
          {additionalInstructions}
        </p>
      )}
      <Ap4Input
        className='cb-input-no-label'
        clearable={false}
        id={id}
        onChange={handleChange}
        ref={ref}
        type={type}
        validation={errorMessage ? 'error' : undefined}
        validationMsg={errorMessage ? errorMessage : ''}
        validationMessageId={`${id}-error`}
        value={value}
        {...props}
      />
    </>
  );
};

export default Input;
