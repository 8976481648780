import { gql } from 'graphql-tag';
import { SirFragment, SirMutationFragment } from './fragments';
import type { TypedDocumentNode, DocumentNode } from '@apollo/client';
import type {
  ICheckUserNameData,
  ICheckUserNameVars,
  IGetAvailableEventsQueryData,
  IGetAvailableEventsQueryVars,
  IGetAvailableSitesQueryData,
  IGetAvailableSitesQueryVars,
  IGetClosureReasonsData,
  IGetCMSContentData,
  IGetCMSContentVars,
  IGetCMSMultiStepperData,
  IGetDataExportActivityData,
  IGetDataExportActivityVars,
  IGetDigitalGroupsInfoData,
  IGetDigitalStudentsPaginatedQueryVars,
  IGetDigitalStudentsPaginatedRosterData,
  IGetLoginUserData,
  IGetNonDigitalGroupsInfoData,
  IGetNonDigitalPrintableData,
  IGetNonDigitalRoomsFormData,
  IGetNonDigitalStudentsRosterData,
  IGetNotificationsData,
  IGetPastEventsData,
  IGetPastEventsVars,
  IGetPromptsData,
  IGetRoomData,
  IGetRoomDirectoryActivityData,
  IGetRoomDirectoryActivityVars,
  IGetRoomsAndStudentsData,
  IGetRoomsData,
  IGetRoomVars,
  IGetSignInTicketActivityData,
  IGetSignInTicketActivityVars,
  IGetSirData,
  IGetSirsData,
  IGetSirVars,
  IGetSiteStatsData,
  IGetStaffAndRoomsData,
  IGetStaffData,
  IGetStaffDetailData,
  IGetStaffDetailVars,
  IGetStaffInventoryData,
  IGetStudentData,
  IGetStudentVars,
  IGetTestCenterData,
  IGetVoucherData,
  IStaffFormData,
  IStaffFormVars,
} from './types';

// Get everything for doing a full test center report.
export const GET_TEST_CENTER: TypedDocumentNode<IGetTestCenterData> = gql`
  query getTestCenter {
    viewer {
      id
      site {
        id
        staff {
          firstName
          id
          lastName
          role
          room {
            id
            groupTypes
            students {
              id
              checkedInRoom
              dapExamStatus {
                name
                timestamp
              }
              dapTestStatus {
                name
                payload {
                  ... on DAPSectionStartTime {
                    sectionName
                  }
                }
                timestamp
              }
            }
            title
          }
        }
        students {
          absent
          checkedInCenter
          checkedInRoom
          dapExamStatus {
            name
            timestamp
          }
          dapTestStatus {
            name
            payload {
              ... on DAPSectionStartTime {
                sectionName
              }
            }
            timestamp
          }
          deniedEntry
          groupType
          id
          room {
            id
            groupTypes
            title
          }
          waitListFlag
        }
      }
    }
  }
`;

// Get closure reasons, makeup dates, and next steps.
export const GET_CLOSURE_REASONS: TypedDocumentNode<IGetClosureReasonsData> = gql`
  query getClosureEventsQuery {
    getClosureEvents {
      closures {
        event_other
        isSunday
        makeup_day
        makeup_month
        makeup_year
        next_steps
        title
      }
    }
  }
`;

// Get closure reasons, makeup dates, and next steps.
export const GET_CMS_MULTI_STEPPER: TypedDocumentNode<IGetCMSMultiStepperData> = gql`
  query getCMSMultiStepperQuery {
    getMultiStepperContent {
      proctorSteps {
        section_name
        steps {
          main_panel_header
          detail_panel_header
          step_number
          main_panel_content
          detail_panel_content
          field_tooltips
        }
      }
    }
  }
`;

// Get CMS Content.
export const GET_CMS_CONTENT: TypedDocumentNode<IGetCMSContentData, IGetCMSContentVars> = gql`
  query getCmsContentQuery($input: ReadCmsContentInput!) {
    getCmsContent(input: $input) {
      content
    }
  }
`;

// Get top level categories and irregularities.
export const GET_PROMPTS: TypedDocumentNode<IGetPromptsData> = gql`
  query getPrompts {
    viewer {
      id
      role
    }
    irregularityCategoryList {
      id
      label
      description
      order
      irregularityList {
        id
        label
        description
        order
        instructions {
          id
          body
          glyphicon
          showExpanded
          title
          weight
        }
        promptCategoryList {
          label
          description
          order
          promptList {
            description
            id
            label
            order
            required
            responseList
            responseType
            validation_maxlength
            validation_minlength
            validation_regex
            validation_regex_message
          }
        }
      }
    }
  }
`;

// Get a single saved SIR query.
export const GET_SIR: TypedDocumentNode<IGetSirData, IGetSirVars> = gql`
  query getSir($input: ReadSirInput!) {
    viewer {
      id
      role
    }
    getSir(input: $input) {
      ...SirMutationFragment
    }
  }
  ${SirMutationFragment}
`;

export const GET_SIRS: TypedDocumentNode<IGetSirsData> = gql`
  query getSirs {
    viewer {
      id
      site {
        id
        sirs {
          ...SirFragment
        }
      }
    }
  }
  ${SirFragment}
`;

export const GET_ROOMS_AND_STUDENTS: TypedDocumentNode<IGetRoomsAndStudentsData> = gql`
  query getRoomsAndStudents {
    viewer {
      id
      site {
        id
        rooms {
          id
          groupTypes
          title
        }
        students {
          # absent
          candDOB
          candFirstName
          candLastName
          candRegNo
          candMidInit
          # deniedEntry
          displayedRegNo
          groupType
          id
          multiDayInd
          room {
            id
            title
          }
          testPackageCount
          testPackageSeq
          accommodationsList {
            code
            supplementalInfo
          }
        }
      }
    }
  }
`;

export const GET_ROOMS: TypedDocumentNode<IGetRoomsData> = gql`
  query getRooms {
    viewer {
      id
      site {
        id
        rooms {
          capacity
          created
          groupTypes
          id
          staff {
            id
            lastName
            firstName
            role
          }
          seatingStatus
          startPin
          status
          students {
            absent
            deniedEntry
            id
          }
          title
          updated
        }
      }
    }
  }
`;

export const GET_ROOM: TypedDocumentNode<IGetRoomData, IGetRoomVars> = gql`
  query getRoom($input: ReadRoomInput!) {
    readRoom(input: $input) {
      id
      capacity
      created
      doors
      groupTypes
      joinCode
      seatingStatus
      shape {
        x
        y
      }
      status
      staff {
        active
        confirmed
        email
        firstName
        id
        lastName
        phone
        role
        welcomed
      }
      startPin
      students {
        absent
        adultTestTakerFlag
        assignedSeat {
          x
          y
        }
        candDOB
        candFirstName
        candGender
        candLastName
        candMidInit
        candRegNo
        checkedInCenter
        checkedInRoom
        checkedInRoomTimestamp
        dapExamStatus {
          name
          timestamp
        }
        dapTestStatus {
          name
          payload {
            ... on DAPSectionStartTime {
              sectionName
            }
          }
          timestamp
        }
        deniedEntry
        displayedRegNo
        grade8OrLowerFlag
        groupType
        id
        joinCode
        joinCodeTimestamp
        multiDayInd
        origGroupType
        photoRequiredFlag
        room {
          id
          groupTypes
          title
        }
        roomIdTimestamp
        satIIListening
        accommodationsList {
          code
          supplementalInfo
        }
        startPin
        startPinTimestamp
        testBookNumber
        testPackageCount
        testPackageSeq
        updated
        waitListFlag
      }
      testBookRanges {
        start
        end
      }
      title
      unusedSeats {
        x
        y
      }
      updated
    }
  }
`;

export const GET_STAFF_INVENTORY: TypedDocumentNode<IGetStaffInventoryData> = gql`
  query getStaffInventory {
    viewer {
      id
      site {
        id
        inventory {
          email
          firstName
          id
          lastName
          phone
          role
          username
        }
      }
    }
  }
`;

export const GET_STAFF_AND_ROOMS: TypedDocumentNode<IGetStaffAndRoomsData> = gql`
  query getStaffAndRooms {
    viewer {
      id
      site {
        id
        rooms {
          capacity
          created
          groupTypes
          id
          staff {
            id
            lastName
            firstName
            role
            username
          }
          status
          students {
            absent
            deniedEntry
            displayedRegNo
            id
          }
          title
          updated
        }
        staff {
          active
          confirmed
          email
          firstName
          id
          lastName
          phone
          role
          room {
            id
            title
          }
          username
          welcomed
        }
      }
    }
  }
`;

export const GET_STAFF: TypedDocumentNode<IGetStaffData> = gql`
  query getStaff {
    viewer {
      id
      site {
        id
        staff {
          active
          confirmed
          email
          firstName
          id
          lastName
          phone
          role
          room {
            id
            title
          }
          welcomed
        }
      }
    }
  }
`;

export const CHECK_USER_NAME: TypedDocumentNode<ICheckUserNameData, ICheckUserNameVars> = gql`
  query getUserName($userName: String!) {
    checkUserName(userName: $userName)
    duplicateUserName(userName: $userName)
    siteStaffList(userName: $userName)
  }
`;

// Get closure reasons, makeup dates, and next steps.
export const GET_NOTIFICATIONS: TypedDocumentNode<IGetNotificationsData> = gql`
  query getNotificationsQuery {
    getNotifications {
      alert_level
      body
      header
      show_accordion
    }
  }
`;

export const GET_SIGN_IN_TICKET_ACTIVITY: TypedDocumentNode<
  IGetSignInTicketActivityData,
  IGetSignInTicketActivityVars
> = gql`
  query getSignInTicketActivityQuery($input: ReadSignInTicketActivityInput!) {
    getSignInTicketActivity(input: $input) {
      lastName
      firstName
      username
      lastPrintedOn
    }
  }
`;

export const GET_DATA_EXPORT_ACTIVITY: TypedDocumentNode<IGetDataExportActivityData, IGetDataExportActivityVars> = gql`
  query getDataExportActivityQuery($input: DataExportActivityQueryInput!) {
    getDataExportActivity(input: $input) {
      lastName
      firstName
      username
      lastGeneratedOn
    }
  }
`;

export const GET_ROOM_DIRECTORY_ACTIVITY: TypedDocumentNode<
  IGetRoomDirectoryActivityData,
  IGetRoomDirectoryActivityVars
> = gql`
  query getRoomDirectoryQuery($input: RoomDirectoryActivityQueryInput!) {
    getRoomDirectoryActivity(input: $input) {
      lastName
      firstName
      username
      lastGeneratedOn
    }
  }
`;

export const GET_STAFF_DETAIL: TypedDocumentNode<IGetStaffDetailData, IGetStaffDetailVars> = gql`
  query getStaffDetail($input: ReadUserInput!) {
    readUser(input: $input) {
      id
      firstName
      lastName
      username
      email
      phone
      role
      active
      confirmed
      room {
        id
        title
      }
      welcomed
    }
  }
`;

export const GET_STUDENT: TypedDocumentNode<IGetStudentData, IGetStudentVars> = gql`
  query getStudent($input: ReadStudentInput!) {
    readStudent(input: $input) {
      absent
      accommodationsList {
        code
        supplementalInfo
      }
      adultTestTakerFlag
      altGroupType
      assignedSeat {
        x
        y
      }
      candDOB
      candFirstName
      candGender
      candLastName
      candMidInit
      candRegNo
      checkedInCenter
      checkedInRoom
      dapExamStatus {
        name
        timestamp
      }
      dapTestStatus {
        name
        payload {
          ... on DAPSectionStartTime {
            sectionName
          }
        }
        timestamp
      }
      deniedEntry
      displayedRegNo
      grade8OrLowerFlag
      groupType
      id
      multiDayInd
      origGroupType
      photoRequiredFlag
      photoUrl
      photoSrc
      photoThumbnailUrl
      room {
        capacity
        id
        groupTypes
        students {
          absent
          deniedEntry
          id
        }
        title
      }
      testDisplayName
      testPackageCount
      testPackageSeq
      satIIListening
      specialUseOnly
      studentIds {
        studentId
        studentIdTypeCd
      }
      waitListFlag
    }
  }
`;

const GET_STUDENTS_BASE = <T>(
  {
    student = gql`
      fragment Student on StudentType {
        id
      }
    `,
  }: { student?: DocumentNode } = { student: undefined }
): TypedDocumentNode<T> => gql`
  query getStudents {
    viewer {
      id
      site {
        id
        students {
          ...Student
        }
      }
    }
  }
  ${student}
`;

export const GET_DIGITAL_STUDENTS_PAGINATED_ROSTER: TypedDocumentNode<
  IGetDigitalStudentsPaginatedRosterData,
  IGetDigitalStudentsPaginatedQueryVars
> = gql`
  query getPaginatedStudentRoster($input: PaginatedStudentInput) {
    paginatedStudents(input: $input) {
      lastKey
      students {
        id
        absent
        candFirstName
        candGender
        candLastName
        candMidInit
        candRegNo
        checkedInCenter
        checkedInRoom
        dapExamStatus {
          name
          timestamp
        }
        dapTestStatus {
          name
          payload {
            ... on DAPSectionStartTime {
              sectionName
            }
          }
          timestamp
        }
        deniedEntry
        displayedRegNo
        groupType
        multiDayInd
        testPackageSeq
        room {
          id
          groupTypes
          title
        }
        satIIListening
        accommodationsList {
          code
          supplementalInfo
        }
        updated
        status @client
      }
    }
  }
`;

export const GET_DIGITAL_GROUPS_INFO = GET_STUDENTS_BASE<IGetDigitalGroupsInfoData>({
  student: gql`
    fragment Student on StudentType {
      id
      groupType
      waitListFlag
    }
  `,
});

export const GET_NON_DIGITAL_PRINTABLE = GET_STUDENTS_BASE<IGetNonDigitalPrintableData>({
  student: gql`
    fragment Student on StudentType {
      id
      absent
      candFirstName
      candLastName
      candMidInit
      candDOB
      candRegNo
      checkedInRoom
      deniedEntry
      groupType
      candGender
      photoRequiredFlag
      grade8OrLowerFlag
      adultTestTakerFlag
      waitListFlag
      accommodationsList {
        code
      }
      room {
        id
        title
        groupTypes
      }
    }
  `,
});

export const GET_NON_DIGITAL_STUDENTS_ROSTER = GET_STUDENTS_BASE<IGetNonDigitalStudentsRosterData>({
  student: gql`
    fragment Student on StudentType {
      id
      absent
      accommodationsList {
        code
        supplementalInfo
      }
      adultTestTakerFlag
      assignedSeat {
        x
        y
      }
      candDOB
      candFirstName
      candGender
      candLastName
      candMidInit
      candRegNo
      checkedInCenter
      checkedInRoom
      deniedEntry
      groupType
      multiDayInd
      room {
        id
        groupTypes
        title
      }
      satIIListening
      updated
      waitListFlag
    }
  `,
});

export const GET_NON_DIGITAL_GROUPS_INFO = GET_STUDENTS_BASE<IGetNonDigitalGroupsInfoData>({
  student: gql`
    fragment Student on StudentType {
      id
      groupType
      waitListFlag
    }
  `,
});

export const GET_NON_DIGITAL_ROOMS_FORM = GET_STUDENTS_BASE<IGetNonDigitalRoomsFormData>({
  student: gql`
    fragment Student on StudentType {
      id
      groupType
      waitListFlag
    }
  `,
});

export const GET_SITE_STATS: TypedDocumentNode<IGetSiteStatsData> = gql`
  query getSiteStats {
    viewer {
      id
      site {
        id
        stat {
          cta
          ctaTimestamp
          stat
          statTimestamp
        }
      }
    }
  }
`;

export const GET_AVAILABLE_SITES_QUERY: TypedDocumentNode<
  IGetAvailableSitesQueryData,
  IGetAvailableSitesQueryVars
> = gql`
  query getAvailableSitesQuery($input: AvailableSitesQueryInput!) {
    availableSitesQuery(input: $input) {
      sites {
        orgId
        siteId
        testCenterTypeCd
        title
      }
    }
  }
`;

export const GET_AVAILABLE_EVENTS_QUERY: TypedDocumentNode<
  IGetAvailableEventsQueryData,
  IGetAvailableEventsQueryVars
> = gql`
  query getAvailableEventsQuery($input: AvailableEventsQueryInput!) {
    availableEventsQuery(input: $input) {
      events {
        asmtEventId
        asmtEventTypeCd
        asmtId
        asmtSubtypeCd
        default
        dapInd
        doNotDisplayAfterDt
        eventEndDt
        eventStartDt
        eventTitle
        featureFlags
        tdtkAsmtType
      }
    }
  }
`;

//(ref: admin_event_payload_do_not_display_special_only)
// only the field doNotDisplaySpecialUseOnlyDt
export const GET_LOGIN_USER: TypedDocumentNode<IGetLoginUserData> = gql`
  query getLoginUser {
    features
    viewer {
      id
      username
      confirmed
      active
      role
      room {
        id
        title
      }
      site {
        id
        title
        orgId
        testCenterCd
        enableEssaySection
        event {
          asmtEventId
          asmtEventTypeCd
          asmtId
          dapInd
          doNotDisplaySpecialUseOnlyDt
          eventDisplayDescr
          eventEndDt
          eventStartDt
          eventTitle
          featureFlags
          tdtkAsmtType
          closure {
            id
            canSupportMakeup
            confirmationId
            created
            closureCreatedBy {
              id
              firstName
              lastName
            }
            makeupDate
            nextSteps
            reason
          }
        }
      }
      welcomed
    }
  }
`;

export const GET_PAST_EVENTS: TypedDocumentNode<IGetPastEventsData, IGetPastEventsVars> = gql`
  query getPastEvents($input: PastEventsInput!) {
    pastEvents(input: $input) {
      eventTitle
      asmtEventId
      eventStartDt
      rooms {
        title
        capacity
      }
    }
  }
`;

export const STAFF_FORM: TypedDocumentNode<IStaffFormData, IStaffFormVars> = gql`
  query getRoomsAndStaff($input: ReadUserInput!) {
    readUser(input: $input) {
      id
      firstName
      lastName
      username
      email
      phone
      role
      active
      confirmed
      room {
        id
        title
      }
      welcomed
    }
    viewer {
      id
      site {
        id
        rooms {
          capacity
          created
          groupTypes
          id
          staff {
            id
            lastName
            firstName
            role
          }
          status
          students {
            absent
            deniedEntry
            id
          }
          title
          updated
        }
        staff {
          id
          role
        }
      }
    }
  }
`;

export const GET_VOUCHER: TypedDocumentNode<IGetVoucherData> = gql`
  query getVoucher {
    viewer {
      site {
        id
        voucher {
          id
          updated
          voucherSubmittedBy {
            id
            lastName
            firstName
            role
            username
          }
          voucherSubmissionStatus
          voucherRoomDetail {
            title
            roomId
            groupType
            testTakerCounts {
              absent
              deniedEntry
              registered
            }
            staff {
              id
              role
              voucherRole
              firstName
              lastName
            }
          }
          voucherUnassignedStaffDetail {
            id
            role
            voucherRole
            groupType
            needsTokenPayment
            firstName
            lastName
          }
          voucherExpense
        }
      }
    }
  }
`;
