export interface IGroupType {
  canCombineWith: string[];
  description: string;
  essay?: boolean;
  standard: boolean;
  title?: string;
  label?: string;
  voucherReportOrder?: number;
  voucherReportRoomTitle?: string;
  voucherReportStaffTitle?: string;
}

export interface IGroupTypes {
  [key: string]: IGroupType;
}
export const groupTypes: IGroupTypes = {
  NA: {
    canCombineWith: [],
    description: '',
    essay: false,
    standard: true,
    title: 'N/A',
    voucherReportOrder: 0,
    voucherReportRoomTitle: 'N/A',
    voucherReportStaffTitle: 'N/A',
  },
  A0: {
    canCombineWith: ['Z0'],
    description: '',
    essay: false,
    label: 'Under 3.5',
    standard: true,
    title: 'SAT',
    voucherReportOrder: 0,
    voucherReportRoomTitle: '',
    voucherReportStaffTitle: '',
  },
  Z0: {
    canCombineWith: ['A0'],
    description: '',
    essay: false,
    label: 'Over 3.5',
    standard: false,
    title: 'SAT',
    voucherReportOrder: 0,
    voucherReportRoomTitle: '',
    voucherReportStaffTitle: '',
  },
  APA0: {
    canCombineWith: [],
    description: '',
    essay: false,
    standard: true,
    title: 'AP Exam',
    voucherReportOrder: 0,
    voucherReportRoomTitle: '',
    voucherReportStaffTitle: '',
  },
  A1: {
    canCombineWith: ['B1'],
    description: '',
    essay: false,
    standard: true,
    title: 'SAT',
    voucherReportOrder: 1,
    voucherReportRoomTitle: 'SAT',
    voucherReportStaffTitle: 'SAT',
  },
  B1: {
    canCombineWith: ['A1'],
    description: '',
    essay: true,
    standard: true,
    title: 'SAT with Essay',
    voucherReportStaffTitle: 'SAT with Essay or Combined',
    voucherReportOrder: 2,
    voucherReportRoomTitle: 'SAT with Essay or Combined',
  },
  C1: {
    canCombineWith: [],
    description: '',
    standard: true,
    title: 'SAT Subject Tests',
    voucherReportStaffTitle: 'SAT Subject Tests',
    voucherReportOrder: 3,
    voucherReportRoomTitle: 'SAT Subject Tests',
  },
  D1: {
    canCombineWith: [],
    description: '',
    standard: true,
    title: 'Listening Subject Tests',
    voucherReportStaffTitle: 'Listening Subject Tests',
    voucherReportOrder: 4,
    voucherReportRoomTitle: 'SAT Subject Tests with Listening',
  },
  T1: {
    canCombineWith: ['S1'],
    description: 'Small group, blood sugar testing, and 4-func. calc. accommodations',
    essay: false,
    standard: false,
    title: 'SAT',
    voucherReportStaffTitle: 'SAT',
    voucherReportOrder: 5,
    voucherReportRoomTitle: 'SAT (accommodated without extended time)',
  },
  T2: {
    canCombineWith: ['R5', 'S2'],
    description: 'Extra breaks',
    essay: false,
    standard: false,
    title: 'SAT',
    voucherReportStaffTitle: 'SAT',
    voucherReportOrder: 6,
    voucherReportRoomTitle: 'SAT (accommodated without extended time)',
  },
  T3: {
    canCombineWith: ['R6', 'S3'],
    description: 'Extended breaks',
    essay: false,
    standard: false,
    title: 'SAT',
    voucherReportStaffTitle: 'SAT',
    voucherReportOrder: 7,
    voucherReportRoomTitle: 'SAT (accommodated without extended time)',
  },
  T4: {
    canCombineWith: ['R6', 'S4'],
    description: 'Extra and extended breaks',
    essay: false,
    standard: false,
    title: 'SAT',
    voucherReportStaffTitle: 'SAT',
    voucherReportOrder: 8,
    voucherReportRoomTitle: 'SAT (accommodated without extended time)',
  },
  S1: {
    canCombineWith: ['T1'],
    description: 'Small group, blood sugar testing, and 4-func. calc. accommodations',
    essay: true,
    standard: false,
    title: 'SAT with Essay',
    voucherReportStaffTitle: 'SAT with Essay or Combined',
    voucherReportOrder: 9,
    voucherReportRoomTitle: 'SAT with Essay or Combined (accommodated without extended time)',
  },
  S2: {
    canCombineWith: ['T2'],
    description: 'Extra breaks',
    essay: true,
    standard: false,
    title: 'SAT with Essay',
    voucherReportStaffTitle: 'SAT with Essay or Combined',
    voucherReportOrder: 10,
    voucherReportRoomTitle: 'SAT with Essay or Combined (accommodated without extended time)',
  },
  S3: {
    canCombineWith: ['T3'],
    description: 'Extended breaks',
    essay: true,
    standard: false,
    title: 'SAT with Essay',
    voucherReportStaffTitle: 'SAT with Essay or Combined',
    voucherReportOrder: 11,
    voucherReportRoomTitle: 'SAT with Essay or Combined (accommodated without extended time)',
  },
  S4: {
    canCombineWith: ['T4'],
    description: 'Extra and extended breaks',
    essay: true,
    standard: false,
    title: 'SAT with Essay',
    voucherReportStaffTitle: 'SAT with Essay or Combined',
    voucherReportOrder: 12,
    voucherReportRoomTitle: 'SAT with Essay or Combined (accommodated without extended time)',
  },
  J1: {
    canCombineWith: [],
    description: '',
    standard: false,
    title: 'SAT Subject Tests',
    voucherReportStaffTitle: 'SAT Subject or SAT Subject with Listening',
    voucherReportOrder: 13,
    voucherReportRoomTitle: 'SAT Subject Tests (accommodated) and SAT Subject Tests with Listening (accommodated)',
  },
  T5: {
    canCombineWith: ['R1'],
    description: 'Reading 50% extended time with or without extra breaks',
    essay: false,
    standard: false,
    title: 'SAT',
    voucherReportStaffTitle: 'SAT - Extended Time All Sections',
    voucherReportOrder: 14,
    voucherReportRoomTitle: 'SAT (extended time all sections)',
  },
  T6: {
    canCombineWith: ['R2'],
    description: 'Reading 50% extended time with or without extended breaks',
    essay: false,
    standard: false,
    title: 'SAT',
    voucherReportStaffTitle: 'SAT - Extended Time All Sections',
    voucherReportOrder: 15,
    voucherReportRoomTitle: 'SAT (extended time all sections)',
  },
  T7: {
    canCombineWith: ['R3', 'R7'],
    description: 'Math 50% extended time with or without extra breaks (applies to Math Test only)',
    essay: false,
    standard: false,
    title: 'SAT',
    voucherReportStaffTitle: 'SAT - Extended Time One Section',
    voucherReportOrder: 16,
    // voucherReportRoomTitle: 'SAT (extended time one section)',
    voucherReportRoomTitle: 'SAT (extended time Math only)',
  },
  T8: {
    canCombineWith: ['R4', 'R8'],
    description: 'Math 50% extended time with or without extended breaks (applies to Math Test only)',
    essay: false,
    standard: false,
    title: 'SAT',
    voucherReportStaffTitle: 'SAT - Extended Time One Section',
    voucherReportOrder: 17,
    // voucherReportRoomTitle: 'SAT (extended time one section)',
    voucherReportRoomTitle: 'SAT (extended time Math only)',
  },
  R1: {
    canCombineWith: ['T5'],
    description: 'Reading 50% extended time with or without extra breaks',
    essay: true,
    standard: false,
    title: 'SAT with Essay',
    voucherReportStaffTitle: 'SAT with Essay or Combined - Extended Time All Sections',
    voucherReportOrder: 18,
    voucherReportRoomTitle: 'SAT with Essay or Combined (extended time all sections)',
  },
  R2: {
    canCombineWith: ['T6'],
    description: 'Reading 50% extended time and extended breaks',
    essay: true,
    standard: false,
    title: 'SAT with Essay',
    voucherReportStaffTitle: 'SAT with Essay or Combined - Extended Time All Sections',
    voucherReportOrder: 19,
    voucherReportRoomTitle: 'SAT with Essay or Combined (extended time all sections)',
  },
  R3: {
    canCombineWith: ['T7'],
    description: 'Math 50% extended time with or without extra breaks (applies to Math Test only)',
    essay: true,
    standard: false,
    title: 'SAT with Essay',
    voucherReportStaffTitle: 'SAT with Essay or Combined - Extended Time One Section',
    voucherReportOrder: 20,
    voucherReportRoomTitle: 'SAT with Essay or Combined (extended time one section)',
  },
  R4: {
    canCombineWith: ['T8'],
    description: 'Math 50% extended time with or without extended breaks (applies to Math Test only)',
    essay: true,
    standard: false,
    title: 'SAT with Essay',
    voucherReportStaffTitle: 'SAT with Essay or Combined - Extended Time One Section',
    voucherReportOrder: 21,
    voucherReportRoomTitle: 'SAT with Essay or Combined (extended time one section)',
  },
  R5: {
    canCombineWith: ['T2'],
    description: 'Writing 50% extended time with or without extra breaks (applies to SAT Essay only)',
    essay: true,
    standard: false,
    title: 'SAT with Essay',
    voucherReportStaffTitle: 'SAT with Essay or Combined - Extended Time One Section',
    voucherReportOrder: 22,
    voucherReportRoomTitle: 'SAT with Essay or Combined (extended time one section)',
  },
  R6: {
    canCombineWith: ['T3', 'T4'],
    description: 'Writing 50% extended time with or without extended breaks (applies to SAT Essay only)',
    essay: true,
    standard: false,
    title: 'SAT with Essay',
    voucherReportStaffTitle: 'SAT with Essay or Combined - Extended Time One Section',
    voucherReportOrder: 23,
    voucherReportRoomTitle: 'SAT with Essay or Combined (extended time one section)',
  },
  R7: {
    canCombineWith: ['T7'],
    description:
      'Writing and math 50% extended time with or without extra breaks (applies to Math Test and SAT Essay only)',
    essay: true,
    standard: false,
    title: 'SAT with Essay',
    voucherReportStaffTitle: 'SAT with Essay or Combined - Extended Time Two Sections',
    voucherReportOrder: 24,
    voucherReportRoomTitle: 'SAT with Essay or Combined (extended time two sections)',
  },
  R8: {
    canCombineWith: ['T8'],
    description:
      'Writing and math 50% extended time with or without extended breaks (applies to Math Test and SAT Essay only)',
    essay: true,
    standard: false,
    title: 'SAT with Essay',
    voucherReportStaffTitle: 'SAT with Essay or Combined - Extended Time Two Sections',
    voucherReportOrder: 25,
    voucherReportRoomTitle: 'SAT with Essay or Combined (extended time two sections)',
  },
};
