import { iamCoordinatorRoles } from '../constants/iamRoles';
import { cbAdminRoleProps } from '../constants/roles';

export function logUserOut(usePath = false) {
  const currentDomain = window?.location?.origin || 'https://testday.collegeboard.org/';
  const currentPath = usePath ? `${window?.location?.pathname ?? '/'}${window?.location?.search ?? ''}` : '';

  // Remove any local session storage.
  sessionStorage.clear();

  // Get the current URL so we can redirect them back after we log them out.
  const returnUrl = `${currentDomain}${currentPath}`;

  // Log the user out.
  window.location = `https://account.collegeboard.org/login/logout?appId=378&DURL=${returnUrl}`;
}

export function hasAdminAccess(rid: number, role: string) {
  const isIAMCoordinator = Object.keys(iamCoordinatorRoles).indexOf('' + rid) > -1;
  if (isIAMCoordinator) {
    return role === 'admin' || role === 'CBAdmin';
  } else if (rid === 0) {
    return role === 'admin';
  }
  return false;
}

export const isCBAdmin = (user: { rid: string; role: string }) =>
  user.rid === cbAdminRoleProps.CB_ADMIN_USER_RID && user.role === cbAdminRoleProps.CB_ADMIN_USER_ROLE;
