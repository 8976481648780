import React, { useState, useRef } from 'react';
import Input from '../../ui/form/Input';
import Timer from '../../ui/timer/Timer';
import { YellowButton } from '@cb/apricot-react';
import {
  ACCESS_CODE_LEN,
  CognitoResponseType,
  DELIVERY_METHODS,
  respondToChallenge,
  restartVerification,
  CognitoOptionsType,
} from './utility';

type SMSInputType = {
  challenge: CognitoResponseType;
  selectedMedium?: string;
  cognitoRequestOptions: CognitoOptionsType;
};

const MSG_INVALID_CODE = 'Invalid code. Please try again.';

function SMS({ challenge, selectedMedium, cognitoRequestOptions }: SMSInputType) {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [code, setCode] = useState<string>('');
  const [clock, setClock] = useState(false);
  const [submitStatus, setSubmitStatus] = useState({ loading: false, error: '' });
  const { attemptsRemaining, resendsRemaining } = challenge?.ChallengeParameters || {};

  const onSubmit = async (e: React.SyntheticEvent<HTMLFormElement>) => {
    e?.preventDefault?.();
    await respondToChallenge(
      { challenge, answer: { code } },
      {
        onRequest: () => setSubmitStatus({ loading: true, error: '' }),
        onSuccess: (data: CognitoResponseType) => {
          const enteredInvalidCode = Number(data.ChallengeParameters?.attemptsRemaining) < Number(attemptsRemaining);
          if (enteredInvalidCode) {
            inputRef?.current?.querySelector('input')?.focus();
          }
          setSubmitStatus({
            loading: false,
            error: enteredInvalidCode ? MSG_INVALID_CODE : '',
          });
          cognitoRequestOptions.onSuccess?.(data);
        },
        onFailure: cognitoRequestOptions.onFailure,
      }
    );
  };

  return (
    <div data-automation='sms'>
      <h1 className='tdtk-h1 py-4' data-automation='heading-h1'>
        Verify Your Identity
      </h1>

      <form onSubmit={onSubmit}>
        <p className='mb-4'>
          {selectedMedium === DELIVERY_METHODS.email.value
            ? 'We emailed a code to you.'
            : 'We sent a code to your phone number.'}
        </p>
        <div className='row mb-4'>
          <div className='col-md-3'>
            <Input
              mainRef={inputRef}
              errorMessage={submitStatus.error}
              floating={false}
              label='Enter code:'
              onChange={(e) => {
                setSubmitStatus({ loading: false, error: '' });
                setCode(e?.target?.value.slice(0, ACCESS_CODE_LEN));
              }}
              required={true}
              type='number'
              value={code}
              id='signupCodeInput'
              maxLength={ACCESS_CODE_LEN}
            />
          </div>
        </div>
        {
          //Only display resend code text if the user has resend attempts remaining
          Number(resendsRemaining) > 0 ? (
            <p className='mb-4'>
              <a
                href='#0'
                onClick={() => restartVerification(challenge, cognitoRequestOptions)}
                data-automation='signup-reload-link'
              >
                Didn’t receive a code? Try again.
              </a>
            </p>
          ) : null
        }
        <p className='mb-4'>
          Time left: <Timer startTime={180} timerCallback={setClock} />
        </p>
        <div className='row'>
          <div className='col-md-6 mb-4'>
            {clock ? (
              <YellowButton
                small
                onClick={() => {
                  window.location.reload();
                }}
              >
                Reload
              </YellowButton>
            ) : (
              <YellowButton
                loading={submitStatus.loading}
                disabled={Boolean(code?.length !== ACCESS_CODE_LEN || submitStatus.error || submitStatus.loading)}
                type='submit'
                small
              >
                Submit
              </YellowButton>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}

export default SMS;
