import React from 'react';
import { Link } from 'react-router-dom';
import { parseDateFormat } from '../../../utils/common';
import { isNoDisplayDateEvent, isInSchoolAPEvent } from '../../../utils/event';
import { useStateValue } from '../../../context/AppContext';
import { ResizeContext } from '../../../context/ResizeContext';
import moment from 'moment';

function CenterInfo() {
  // Global App state.
  const { orgEvent } = useStateValue();
  const windowSize = React.useContext(ResizeContext);

  const {
    asmtEventId = '',
    eventStartDt = '',
    eventEndDt = '',
    eventTitle = '',
    siteId = '',
    title = '',
    testCenterTypeCd = '',
  } = orgEvent;

  const renderDateRange = () => {
    const setEventStartDt = moment(eventStartDt).format('MMM D, YYYY');

    if (!isNoDisplayDateEvent(orgEvent)) {
      const setDateRange = isInSchoolAPEvent(orgEvent) ? setEventStartDt : parseDateFormat(eventStartDt, eventEndDt);
      return (
        <>
          <span
            className='center-info-title-wrap'
            data-automation='center-info-date-ctn'
            style={{
              fontWeight: '700',
              minWidth: '5.25rem',
              textAlign: 'right',
            }}
            title={setDateRange}
          >
            {setDateRange}
          </span>
          <span className='center-info-title-divider'>|</span>
        </>
      );
    }
  };

  return (
    <div className={`center-info-container ${windowSize.mobile ? 'container' : 'px-2'}`}>
      <div className='center-info-title-wrapper'>
        {renderDateRange()}
        <span
          className='center-info-title-wrap'
          data-automation='center-info-event-descr'
          style={{
            fontWeight: '700',
          }}
          title={`${eventTitle} (${asmtEventId})`}
        >
          {eventTitle}
        </span>
        <span className='center-info-title-divider'>|</span>
        <Link
          className='cb-palette-blue2'
          data-automation='center-info-switch-button'
          onClick={() => {
            sessionStorage.removeItem('studentDynamicColumns');
            sessionStorage.removeItem('studentFilters');
            sessionStorage.removeItem('studentSearch');
            sessionStorage.removeItem('studentPageSize');
          }}
          to='/event'
          state={{ fromSwitch: true }}
        >
          Switch
        </Link>
      </div>
      <div className='center-info-title-wrapper'>
        <span className='center-info-title-wrap' title={title}>
          {title}
        </span>
        <span>
          - {siteId} {testCenterTypeCd === 'R' ? '(Sunday)' : ''}
        </span>
      </div>
    </div>
  );
}

export default CenterInfo;
