import React, { useContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import StudentRosterCard from './StudentRosterCard';
import { localStateReducer } from '../../../../../../utils/common';
import { ResizeContext } from '../../../../../../context/ResizeContext';
export default function StudentRosterColumn({
  columnType = '',
  emptyStateText = '',
  headerText = '',
  studentStatusArray = [],
  toggleVisible = () => {},
  visible = false,
}) {
  const windowSize = useContext(ResizeContext);

  const [localState, setLocalState] = useReducer(localStateReducer, {
    isMobile: windowSize.mobile,
  });

  useEffect(() => {
    setLocalState({
      isMobile: windowSize.mobile,
    });
  }, [windowSize.mobile]);

  const a11yBtnProps = !localState.isMobile
    ? null
    : {
        'aria-expanded': visible,
      };

  const a11yContentProps = !localState.isMobile
    ? null
    : {
        'aria-hidden': !visible,
      };

  return (
    <div className={`student-roster-swimLane-col col-md-4 px-0 ${visible ? '' : 'student-roster-swimLane-hide'}`}>
      <div
        aria-level='3'
        className='student-roster-swimLane-header'
        id={`roster-column-${columnType}-header`}
        role='heading'
      >
        <div className={'hidden-xs hidden-sm'}>
          {headerText} ({studentStatusArray?.length || '0'})
        </div>
        <button
          className={'hidden-md-up'}
          onClick={() => toggleVisible(columnType)}
          aria-controls={`roster-column-${columnType}`}
          {...a11yBtnProps}
        >
          {headerText} ({studentStatusArray?.length || '0'})
        </button>
      </div>
      <div
        className={'collapsable'}
        role={'region'}
        id={`roster-column-${columnType}`}
        aria-labelledby={`roster-column-${columnType}-header`}
        {...a11yContentProps}
      >
        {studentStatusArray?.length > 0 ? (
          <div role='list' className='student-roster-swimLane-container'>
            {studentStatusArray.map((student) => (
              <StudentRosterCard
                key={`${columnType}-student-card-${student?.id}`}
                student={student}
                type={columnType}
              />
            ))}
          </div>
        ) : (
          <div className='student-roster-swimLane-container student-roster-swimLane-desc'>{emptyStateText}</div>
        )}
      </div>
    </div>
  );
}

StudentRosterColumn.propTypes = {
  columnType: PropTypes.string,
  emptyStateText: PropTypes.string,
  headerText: PropTypes.string,
  studentStatusArray: PropTypes.array,
  toggleVisible: PropTypes.func,
  visible: PropTypes.bool,
};
