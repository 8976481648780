import React from 'react';
import PropTypes from 'prop-types';
import { generateHeaders } from './tableHeaders';
import Dashboard from '../../../../common/rooms/details/Monitor/Dashboard';
import RoomCode from '../../../../common/RoomCode';
import StudentRosterRow from '../../../students/roster/StudentRosterRow';

export default function MonitorStudents({ toggleDirections, room, refetch = () => {} }) {
  return (
    <>
      <div className='wizard-heading-container row'>
        <div
          aria-level='1'
          className='wizard-heading col-xs-12 col-md-7'
          data-automation='wizard-main-heading'
          id='wizard-main-heading'
          role='heading'
          tabIndex='-1'
        >
          Monitoring Dashboard
        </div>
        <div className='wizard-right-panel col-xs-12 col-md-5'>
          <div className='wizard-room-code-container ml-auto text-right'>
            <div className='wizard-room-code-title' data-automation='wizard-room-start-code'>
              Start Code:
            </div>
            <div className='wizard-room-code'>
              <RoomCode code={room?.startPin} />
            </div>
          </div>
        </div>
      </div>
      <div className='mt-3'>{toggleDirections}</div>
      <div className='dashboard-wrapper mt-3'>
        <Dashboard
          generateHeaders={generateHeaders}
          room={room}
          refetch={refetch}
          StudentRosterRow={StudentRosterRow}
        />
      </div>
    </>
  );
}

MonitorStudents.propTypes = {
  room: PropTypes.object,
  refetch: PropTypes.func,
  toggleDirections: PropTypes.object,
};
