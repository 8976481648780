import React, { useState, useMemo, useCallback } from 'react';
import { customerSupportInfo } from '../../../constants/customerSupportInfo';
import Select from '../../ui/form/Select';
import Timer from '../../ui/timer/Timer';
import { YellowButton } from '@cb/apricot-react';
import { CognitoResponseType, DELIVERY_METHODS, getSignupCookies, respondToChallenge } from './utility';

const DELIVERY_OPTIONS = Object.values(DELIVERY_METHODS);

type VerifyInputType = {
  challenge: CognitoResponseType;
  onSuccess: (data: CognitoResponseType, formData: { [key: string]: string }) => void;
  onFailure: (err: Error) => void;
};

function Verify({ challenge, onSuccess, onFailure }: VerifyInputType) {
  const { isAdmin } = getSignupCookies();
  const phoneNumbers = useMemo(
    () =>
      JSON.parse(challenge.ChallengeParameters?.phoneNumbers || '[]').map((p: string) => ({
        label: `***${p}`,
        value: p,
      })),
    [challenge.ChallengeParameters?.phoneNumbers]
  );
  const emails = useMemo(
    () => JSON.parse(challenge.ChallengeParameters?.emails || '[]').map((e: string) => ({ label: e, value: e })),
    [challenge.ChallengeParameters?.emails]
  );
  const [formData, setFormData] = useState({
    medium: '',
    chosenEmail: emails[0]?.value,
    chosenNumber: phoneNumbers[0]?.value,
  });
  const [clock, setClock] = React.useState(false);

  const onSubmit = useCallback(
    (e: React.SyntheticEvent<HTMLFormElement>) => {
      e?.preventDefault?.();
      respondToChallenge(
        { answer: formData, challenge },
        { onSuccess: (data) => onSuccess?.(data, formData), onFailure }
      );
    },
    [formData, challenge, onSuccess, onFailure]
  );

  const onFormChange = (field: string) => (value: string) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  return (
    <div className='row' data-automation='verify'>
      <form className='col-md-6' onSubmit={onSubmit}>
        <h1 className='tdtk-h1 py-4' data-automation='heading-h1'>
          Verify Your Identity
        </h1>
        <p>
          To double-check your identity, we’ll text, email, or call you with a code, ask you to enter it, then take you
          to Test Day Toolkit.
        </p>

        {isAdmin ? (
          <p className='mb-4' data-automation='signup-admin-contacts'>
            If you don’t have access to the phone numbers or email listed, email{' '}
            <a
              href={'mailto:' + customerSupportInfo.email + '?subject=Test%20Day%20Toolkit'}
              rel='noopener noreferrer'
              target='_blank'
            >
              {customerSupportInfo.email}
            </a>{' '}
            or call{' '}
            <a href={'tel:+' + customerSupportInfo.domesticPhoneNumber} rel='noopener noreferrer' target='_blank'>
              {customerSupportInfo.domesticPhoneNumberDisplayFormat}
            </a>{' '}
            or call{' '}
            <a href={'tel:+' + customerSupportInfo.internationalPhoneNumber} rel='noopener noreferrer' target='_blank'>
              {customerSupportInfo.internationalPhoneNumberDisplayFormat}
            </a>{' '}
            (international).
          </p>
        ) : (
          <p className='mb-4'>
            If you don’t have access to the phone numbers or email listed, ask your test coordinator to update your
            information in Test Day Toolkit.
          </p>
        )}

        <div className='mb-4'>
          <Select
            id='validateDeliveryMethod'
            label='Choose a delivery method'
            name='validateDeliveryMethod'
            onChange={onFormChange('medium')}
            labelNoValue='-Select-'
            values={DELIVERY_OPTIONS}
            defaultValue={formData.medium}
          />
        </div>

        {formData.medium && (
          <div>
            <div className='mb-4'>
              {formData.medium === DELIVERY_METHODS.email.value ? (
                <Select
                  id='validateEmail'
                  label='Your email address'
                  name='validateEmail'
                  onChange={onFormChange('chosenEmail')}
                  values={emails}
                  defaultValue={formData.chosenEmail}
                />
              ) : (
                <Select
                  id='validatePhone'
                  label='Choose a phone number'
                  name='validatePhone'
                  onChange={onFormChange('chosenNumber')}
                  values={phoneNumbers}
                  defaultValue={formData.chosenNumber}
                />
              )}
            </div>
            <p className='mb-4'>
              {formData.medium === DELIVERY_METHODS.sms.value
                ? `By selecting “${DELIVERY_METHODS.sms.label},” you agree to receive a one-time text message with an access code from College Board. Standard data and Msg rates apply.`
                : formData.medium === DELIVERY_METHODS.email.value
                ? `By selecting “${DELIVERY_METHODS.email.label},” you agree to receive a one-time email with an access code from College Board.`
                : `By selecting “${DELIVERY_METHODS.call.label},” you agree to receive a one-time call with an access code from College Board.`}
            </p>
          </div>
        )}

        <p className='mb-4' data-automation='verify-timer'>
          Time left: <Timer startTime={180} timerCallback={setClock} />
        </p>

        {clock ? (
          <YellowButton
            className='mb-4 mt-0'
            onClick={() => {
              window.location.reload();
            }}
          >
            Reload
          </YellowButton>
        ) : (
          <YellowButton
            className='mb-4 mt-0'
            disabled={!(formData.medium && (formData.chosenNumber || formData.chosenEmail) !== '')}
            type='submit'
            small
          >
            Submit
          </YellowButton>
        )}
      </form>
    </div>
  );
}

export default Verify;
