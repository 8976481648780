import React from 'react';
import { generateStatus } from '../../../common/students/utility';
import { GET_NON_DIGITAL_STUDENTS_ROSTER } from '../../../../apollo/queries';
import get from 'lodash/get';
import QueryComponent from '../../../common/network/QueryComponent';
import StudentRoster from './StudentRoster';
import { ResizeProvider } from '../../../../context/ResizeContext';
import { useTitle } from '../../../../constants/useTitle';

function Container() {
  useTitle('Student Roster');

  return (
    <React.Fragment>
      <h1 className='tdtk-h1 py-4' data-automation='heading-h1'>
        Student Roster
      </h1>
      <QueryComponent query={{ kind: 'GetNonDigitalStudentsRoster', specification: GET_NON_DIGITAL_STUDENTS_ROSTER }}>
        {(data) => {
          const students = get(data, 'viewer.site.students', [])?.map((item) => ({
            ...item,
            status: generateStatus(item),
          }));

          return students?.length ? (
            <ResizeProvider>
              <StudentRoster students={students} />
            </ResizeProvider>
          ) : (
            <div>
              <p className='pb-4'>Student roster information is unavailable.</p>
            </div>
          );
        }}
      </QueryComponent>
    </React.Fragment>
  );
}

export default Container;
