import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';
import { AUTO_ASSIGN } from '../../../apollo/mutations';
import Spinner from '../../ui/loading/SpinnerWrapper';
import { localStateReducer } from '../../../utils/common';
import TwoButtonModal from '../../ui/modal/standard/TwoButtonModal';
import { ModalDispatchContext } from '../../ui/modal/ModalContext';
import OneButtonModal from '../../ui/modal/standard/OneButtonModal';
import { YellowButton } from '@cb/apricot-react';

function AutoAssignButton() {
  function handleClick() {
    // Don't allow any additional clicks if disabled already.
    if (localState.disabled) {
      return;
    }

    // Disable the button while it's working.
    setLocalState({
      disabled: true,
    });

    function handlerFunction() {
      autoAssign()
        .then(() =>
          dispatchModal(
            <OneButtonModal
              title={'Success!'}
              modalId='autoAssignSuccess'
              variant='success'
              body={
                <span className='mb-4' key='auto_assign_success' data-automation='auto-assign-success'>
                  All registered students on your roster are assigned to rooms.
                </span>
              }
              buttonLabel='OK'
              onClose={() => {
                window.location.reload();
              }}
            />
          )
        )
        .catch(() => {
          // Auto assign failed
          dispatchModal(
            <OneButtonModal
              title={'We’re sorry.'}
              modalId='autoAssignFailed'
              variant='error'
              body={
                <span className='mb-4' key='auto_assign_failure' data-automation='auto-assign-failure'>
                  We can&rsquo;t perform auto-setup right now. Please try again later.
                </span>
              }
              onClose={() => {
                window.location.reload();
              }}
            />
          );
        });
    }

    dispatchModal(
      <TwoButtonModal
        title={'Are you sure?'}
        modalId='autoAssign'
        variant='error'
        body={
          <div>
            <p className='mb-4' key='auto_assign:body' data-automation='auto-assign-body'>
              Students will be assigned to rooms automatically.
            </p>
            <p className='mb-4' key='auto_assign:tip'>
              <strong key='auto_assign:tip:strong' data-automation='auto-assign-tip'>
                Tip:
              </strong>{' '}
              Take these steps to match certain students or testing groups to certain rooms.
            </p>
            <ol className='mb-2' key='auto_assign:tip:ordered_list' data-automation='auto-assign-list'>
              <li key='auto_assign:tip:cancel'>
                Select <strong key='auto_assign:tip:cancel:strong'>Cancel</strong>.
              </li>
              <li key='auto_assign:tip:edit_room'>
                Edit a room and select a group type or assign a student to a room.
              </li>
              <li key='auto_assign:tip:select_auto_setup'>
                Select <strong key='auto_assign:tip:select_auto_setup:strong'>Auto-Setup</strong>. We won’t overwrite
                your selections.
              </li>
            </ol>
          </div>
        }
        primaryButtonLabel='Confirm'
        primaryButtonHandler={handlerFunction}
        secondaryButtonHandler={() => {
          setLocalState({
            disabled: false,
          });
        }}
      />
    );
  }

  // Global App state.
  const dispatchModal = useContext(ModalDispatchContext);

  // Local state.
  const [localState, setLocalState] = React.useReducer(localStateReducer, {
    disabled: false,
  });

  // Apollo.
  const [autoAssign] = useMutation(AUTO_ASSIGN);

  return localState.disabled ? (
    <Spinner />
  ) : (
    <YellowButton small onClick={handleClick}>
      Auto-Setup<span className='cb-sr-only'> group types to rooms, and then auto assign students to rooms</span>
    </YellowButton>
  );
}

export default AutoAssignButton;
