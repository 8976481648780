import React from 'react';
import PropTypes from 'prop-types';

export const TourContext = React.createContext({ isOpen: false, setIsOpen: () => {} });

export const useTourContext = () => React.useContext(TourContext);

export const TourContextProvider = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  return <TourContext.Provider value={{ isOpen, setIsOpen }}>{children}</TourContext.Provider>;
};

TourContextProvider.propTypes = {
  children: PropTypes.any,
};
