import React, { useMemo } from 'react';
import { useTourContext } from './tourContext';
import { useTourCheck } from './useTourCheck';
import { BlackButton } from '@cb/apricot-react';

export const useTakeTheTour = () => {
  const { setIsOpen } = useTourContext();
  const { isUserTourValid } = useTourCheck();

  const tourButton = useMemo(() => {
    return (
      <BlackButton small onClick={() => setIsOpen(true)}>
        Take the Tour
      </BlackButton>
    );
  }, [setIsOpen]);

  return { isUserTourValid, tourButton };
};
